import _ from "lodash";
import { format } from "../localization/strings";

export default class Subscribtion {
	constructor(opts) {
		this.ws = opts.ws;
		this.smsg = opts.smsg;
		this.onResult = opts.hasOwnProperty("onResult")
			? opts.onResult
			: function () {};
		this.onError = opts.hasOwnProperty("onError")
			? opts.onError
			: function () {};
		this.onDelete = opts.hasOwnProperty("onDelete")
			? opts.onDelete
			: function () {};
		this.eternal = opts.hasOwnProperty("eternal") ? opts.eternal : false;
		if (opts.data) this.data = opts.data;
		else this.data = {};
		let msec = new Date().getTime();
		this.time = msec;
		if (this.eternal) {
			this.time = msec + 1000000000000;
		}
		this.id = ++Subscribtion.id_increment + "";
		this.smsg["I"] = this.id;
		Subscribtion.all[this.id] = this;
		if (this.ws.connected) this.ws.send(this.smsg);
	}

	static clear(ws) {
		let toDel = [];
		_.each(Subscribtion.all, function (sub, i) {
			if (sub.ws === ws) toDel.push(sub);
		});
		toDel.forEach(function (sub) {
			delete Subscribtion.all[sub];
		});
	}

	unsubscribe() {
		if (this.ws.connected) {
			this.ws.send({
				P: "u" + this.smsg["P"],
				D: this.smsg["D"],
			});
		}
		this.delete();
	}

	delete() {
		this.onDelete();
		delete Subscribtion.all[this.id];
	}

	static init() {
		let self = this;
		//if not eternal subscribtion is without response more than 3 seconds - warning
		setInterval(function () {
			let msec = new Date().getTime();
			_.each(self.all, function (sub, i) {
				if (sub.time + 1000 * 10 < msec) {
					//self.all[i].onError('Expired subscribtion id ' + i);
					if (sub.eternal) {
						console.warn("Expired subscribtion id " + i, sub.smsg);
					}/* else {
						delete self.all[i];
					}*/
					//delete self.all[i];
				}
			});
		}, 3000);
	}

	static remove(ws) {
		let self = this;
		_.each(self.all, function (sub, i) {
			if (ws === sub.ws) {
				delete Subscribtion.all[sub.id];
			}
		});
	}

	/*static request(ws) {
		let self = this;
		_.each(self.all, function (sub, i) {
			if (ws === sub.ws && sub.eternal) {
				ws.send(sub.smsg);
			}
		});
	}*/

	static parse(msg) {
		if (msg.hasOwnProperty("I")) {
			let sub = this.all[msg["I"]];
			if (sub != null) {
				if (msg["Y"] === "e") {
					let str = format(msg["D"]["c"], msg["D"]["p"]);
					console.error(str);
					sub.onError(str);
					sub.delete();
				} else {
					sub.onResult(msg);
				}
				if (!sub.eternal) {
					sub.delete();
				}
			} else {
				// no subscribtion
				console.warn("No subscr id " + msg["I"]);
			}
			return true;
		}
		return false;
	}
}

Subscribtion.all = {}; //all subscribtions
Subscribtion.id_increment = 1;

Subscribtion.init();
