//https://www.color-hex.com/color/46474b
export const col = {
  night_sky: '#18214D',
  grey_darker: '#151516',
  grey_dark: '#232325',  
  grey_dark_2: '#2a2b2e',
  grey_dark_4: '#36373B',
  grey: '#46474B',
  grey_5: '#696F86',
  grey_10: '#6b6c73',
  grey_40: 'rgba(65, 73, 114, 0.4)',
  grey_light_7: '#bfc1c4',
  grey_light_5: '#DFE2E6',
  grey_light: '#EFF2F6',
  white: '#FFFFFF',
  white_pen: '#FFFFFF',
  blue: '#35B6FF',
  red: '#EE3F34',
  green: '#3BC471',
  gold: 'gold',
};