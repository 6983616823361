import LocalizedStrings from 'react-localization';
import {decS, dt_s_dS, dt_ns_msS} from '../utils/cell-formatters'

import { api as api_en } from '../localization/api_en';
import { description as description_ru } from '../localization/description_ru';
import { description as description_en } from '../localization/description_en';

import { 
    strings as strings_en,
    enums as enums_en, 
    codes as codes_en,
} from '../localization/strings_en';

import { 
    strings as strings_ru,
    enums as enums_ru, 
    codes as codes_ru, 
} from '../localization/strings_ru';

export const languages = [
    ['en','En- English'],
    ['ru','Ru- Русский'],
]

export const strings = new LocalizedStrings({
  en: strings_en,
  ru: strings_ru,
});

export const enums = new LocalizedStrings({
  en: enums_en,
  ru: enums_ru,
});

export const codes = new LocalizedStrings({
  en: codes_en,
  ru: codes_ru,
});

export const api = {
    en: api_en,
    ru: api_en,
};

export const description = {
    en: description_en,
    ru: description_ru,
};

export function format(code, args) {
    if (!codes.hasOwnProperty(code)) {
        return 'Unsupported code: "' + code + '"!!!';
    }
    var str = codes[code];
    var j = 0;
    var res = '';
    var c = '';
    for (var i = 0; i < str.length; i++) {
        c = str[i];
        if (c === '%') {
            i++; c = str[i];
            if (c === 's') {//string
                res += args[j];
                j++;
            } else if (c === 'd') {//int_4
                res += args[j];
                j++;
            } else if (c === 'p') {//dec_8
                res += decS(args[j]);
                j++;
            } else if (c === 'f') {//float
                res += args[j];
                j++;
            } else if (c === 't') {//instrument status
                res += enums.instrument_status[args[j]];
                j++;
            } else if (c === 'i') {//dt_ns date/time
                res += dt_ns_msS(args[j]);
                j++;
            } else if (c === 'a') {//dt_s date
                res += dt_s_dS(args[j]);
                j++;
            }
        } else {
            res += c;
        }
    }
    return res;
}

