import _ from "lodash";
import BigNumber from "bignumber.js";
import Subscribtion from "../utils/subscribtion";
import Symbols from "../ws-pub/symbols";

export class TrsForChart {
  static parse(msg, wspriv) {
  	this.wspriv = wspriv;
  	//let self = this;
     if (msg["P"] === "g_aor" || msg["P"] === "n_or" || msg["P"] === "c_or") return null;
      var trs = null;
      var ors = null; 
      if (msg["D"].hasOwnProperty("ors")) ors = msg["D"]["ors"];
      if (msg["D"].hasOwnProperty("or")) {ors = []; ors.push(msg["D"]["or"])};
      if (ors != null && ors.length > 0) {
          trs = [];
          ors.forEach(function(or) {//get trades from orders updates
              if (or.hasOwnProperty("trs")) {
                  or["trs"].forEach(function (tr) {
                      tr["sid"] = or["sid"];
                      tr["d"] = or["d"];
                      tr["ono"] = or["ono"];
                      tr.shown = {};
                      trs.push(tr);
                  });
              }
          });
      }        
      if (msg["D"].hasOwnProperty("trs")) {trs = msg["D"]["trs"];}
      if (msg["D"].hasOwnProperty("tr")) {trs = []; trs.push(msg["D"]["tr"]);};
      if (trs != null) {
          trs.forEach(function (tr) {
              if (TrsForChart.trs[tr.sid] === undefined) TrsForChart.trs[tr.sid] = [];
              tr.shown = {};
              TrsForChart.trs[tr.sid].push(tr);
              //s += (tr.d == 1 ? "buy " : "sell ");
          });
          if (msg["Y"] === "r" && msg["P"] === "tr" && msg["D"].hasOwnProperty("mt")) {//init chart
              TrsForChart.trs = {};
              TrsForChart.fut_max_time = {};
              _.each(Symbols.all, function(sec, k) {
                  TrsForChart.max_time[sec.sid] = msg["D"]["mt"];
              });
              TrsForChart.ready = true;
              TrsForChart.listeners.forEach((f) => {f(true)}); //redraw
          } else if (trs.length > 0) {
              //console.log("! " + s + " " + moment.utc(trs[0].dt * 1e-6).format('DD HH:mm:ss'));
              TrsForChart.listeners.forEach((f) => {f(false, true)});
          }
      }
      return null;
  }

  static getTrs(sid, from) {
      var bt = from + '000000000';
          //console.log("getTrs max_time", TrsForChart.max_time);
      if (TrsForChart.max_time[sid] === undefined) TrsForChart.max_time[sid] = 0;
      if (TrsForChart.fut_max_time[sid] === undefined) TrsForChart.fut_max_time[sid] = 0;
      if (BigNumber(bt).comparedTo(TrsForChart.max_time[sid]) >= 0) {//have all these trades already
          //TrsForChart.listeners.forEach((f) => {f(false)});
          //console.log("getTrs1111111111111");
          return;
      }
      if (TrsForChart.fut_max_time[sid] !== 0) {
          if (BigNumber(bt).comparedTo(TrsForChart.fut_max_time[sid]) >= 0) {//have all these trades already
              //TrsForChart.listeners.forEach((f) => {f(false)});
          //console.log("getTrs2222222222222");
              return;
          }
      }
      var et = 0;
      if (TrsForChart.fut_max_time[sid] !== 0) {
          et = TrsForChart.fut_max_time[sid];
      } else {
          et = TrsForChart.max_time[sid];
      }
      TrsForChart.fut_max_time[sid] = (from - 1) + '999999999';
      new Subscribtion({//trades
          ws: TrsForChart.wspriv,
          smsg: {"P": "g_tr2", "D": {"aids": null, "sids": [sid], "bt": bt, "et": et, "hot": true}},
          eternal: false,
          onResult: function(msg) {
              TrsForChart.max_time[sid] = TrsForChart.fut_max_time[sid];
              var trs = msg["D"]["ctrs"];
              if (trs.length === 0) return;
              trs.reverse();
              trs.forEach(function(tr) {
                  tr.shown = {};
              });
              if (TrsForChart.trs[sid] === undefined) TrsForChart.trs[sid] = [];
              TrsForChart.trs[sid] = trs.concat(TrsForChart.trs[sid]);
              TrsForChart.listeners.forEach((f) => {f(false)}); // update
          },
          onError(msg) {
              console.error(msg);
          }
      });
      //console.log("getTrs33333333333333");      
  }

  static addListener(f) {
    this.listeners.push(f);
    f(false); //snapshot
  }
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }

}

TrsForChart.wspriv = null;
TrsForChart.listeners = [];
TrsForChart.trs = {}; //by sid
TrsForChart.max_time = {}; //by sid
TrsForChart.fut_max_time = {}; //by sid
