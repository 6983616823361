import React from 'react';
import _ from "lodash";

import { strings } from '../localization/strings';

import { isMobile } from "react-device-detect";

export class Theme {
  static onChange(theme) {//change theme
    document.documentElement.className = "";
    document.documentElement.classList.add(theme);
    if (isMobile) document.documentElement.classList.add("mobile");
    this.current = theme;
    this.listeners.forEach((f)=>{f()});
    localStorage["theme"] = theme;
  }

  static addListener(f) {this.listeners.push(f);}
  static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

Theme.listeners = [];
if (localStorage["theme"]) Theme.onChange(localStorage["theme"])
else Theme.onChange(isMobile ? 'light' : 'light'); //default theme

export class ThemeListener extends React.Component {
  componentDidMount() {Theme.addListener(this.props.onChange)}
  componentWillUnmount() {Theme.removeListener(this.props.onChange)}  
  render() {return null;}
}

export function withThemeKey(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        theme: Theme.current,
        key: 0,
      }
    }

    componentDidMount() {Theme.addListener(this.onChange)}
    componentWillUnmount() {Theme.removeListener(this.onChange)}  
    onChange() {
      if (this.state.theme !== Theme.current) {
        this.setState({
          theme: Theme.current,
          key: this.state.key + 1,
        });
      }
    }
    render() {
      return <WrappedComponent key={this.state.key} {...this.props} />;
    }
  };
}


export class SelectTheme extends React.Component {
  render() {
    return (
      <select value={Theme.current} onChange={(e)=>Theme.onChange(e.target.value)}>
        {['light', 'dark'].map((theme) => {return <option key={theme} value={theme}>{strings[theme]}</option>})}
      </select>
    );
  }
}
