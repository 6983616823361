/* eslint-disable eqeqeq */
import _ from "lodash";
//import pako from 'pako';
import { Inflate } from '../utils/pako_zlib_inflate';

import { Log } from "../components/log";
//import { strings } from '../localization/strings';
import { exchangeAPIUrl, globals } from "../utils/utils";

import { pageVisibility } from "../utils/page-visibility";

var r_compressed = 0;
var r_decompressed = 0;

setInterval(function() {
    console.log("recv:", r_compressed, r_decompressed, parseInt((1 - r_compressed/r_decompressed)*100) + '%');
}, 10000);

export default class WS {
    constructor(opts) {//{ url, onConnecting, onConnect, onMsg}
        this.url = opts.url;
        this.name = opts.name;
        this.onDisconnect = (opts.hasOwnProperty("onDisconnect") ? opts.onDisconnect : function() {});
        this.onConnecting = (opts.hasOwnProperty("onConnecting") ? opts.onConnecting : function() {});
        this.onConnect = (opts.hasOwnProperty("onConnect") ? opts.onConnect : function() {});
        this.onMsg = (opts.hasOwnProperty("onMsg") ? opts.onMsg : function() {});
        this.connectCondition = (opts.hasOwnProperty("connectCondition") ? opts.connectCondition : function() {return true});
        this.ws = null;
        this.connected = false;
        this.checkInterval = null;
        this.listeners = [];

        this.tt = 0;
        this.next_connect_time = 0;
        let self = this;
        pageVisibility.addListener((visible)=>{
           if (visible) self.checkState(self);
        });
    }



    send(msg) {
        //console.log("send", msg);
        if (this.connected && this.ws != null && this.ws.readyState === 1) {
            //console.log("1");
            if (typeof msg != 'string') msg = JSON.stringify(msg);
            msg = WS.convert_nsec_to_int(msg);
            if (msg != "7") console.debug(this.name, "send:", msg);
            //console.debug("send:", pako.deflate(msg, { to: 'string' }).length, msg.length, msg);
            this.ws.send(msg);
            this.last_send_time = Date.now();
            return true;
        } else {
            console.error("send error: " + this.url + " ws not connected");
            return false;
        }        
    }

    disconnect() {
        if (this.connected) {
            this.connected = false;
            if (this.ws != null) {
                this.ws.close();
                delete this.ws;
                this.ws = null;
            }
            this.onDisconnect();
            this.listeners.forEach((f)=>{f(this.connected)});
        }
    }

    onopen() {
        let self = this;
        self.before_connected = true;
        self.listeners.forEach((f)=>{f()});
        self.before_connected = false;
        self.connected = true;
        self.connecting = false;
        self.inflate = new Inflate({ to: 'string', windowBits: -15 });
        self.inflate.onData = (str) => {
            //console.log(str.length, str);
            r_decompressed += str.length;
            //try {
                let parsed = JSON.parse(WS.convert_nsec_to_string(str), function (key, value) {
                    if (value === 'NaN') return NaN;
                    if (value === 'Infinity') return Infinity;
                    if (value === '-Infinity') return -Infinity;
                    return value;
                })
                self.onMsg(parsed);
            /*} catch(exception) {
                console.log('[Error] JSON.parse ' + exception + ' "' + str +'"');
                //fileDownload
                //self.disconnect();
                //console.log(self.input);
            }*/
        };
        self.msg_queue = [];
        //self.strm = new zstream();
        self.onConnect();
        self.listeners.forEach((f)=>{f(self.connected)});
    }
    
    onmessage(msg) {
        let self = this;
        if (msg.data instanceof Blob)
        {// Recieved binary data
            if (msg.data.size === 0) return;
            r_compressed += msg.data.size;
            //console.log("ws: onmessage blob ", msg.data.size);
            let reader = new FileReader();
            reader.ref = {arrayBuffer: null};
            self.msg_queue.push(reader.ref);
            reader.addEventListener("loadend", function(e) {
                //console.log("reader.result", reader.result);
                //let lll = new TextDecoder("utf-8").decode(new Uint8Array(reader.result));
                //self.input.push(lll.toSting());
                //let arr = [];
                this.ref.arrayBuffer = e.target.result;
                while(self.msg_queue.length > 0 && self.msg_queue[0].arrayBuffer != null) {
                    self.inflate.push(self.msg_queue.shift().arrayBuffer);
                    if (self.inflate.err) { 
                        Log.error(self.name + " inflate: " + self.inflate.err);
                    }
                }
            });
            reader.readAsArrayBuffer(new Blob([msg.data, new Uint8Array(('0,0,255,255').split(','))]));
            return;
        }
        //console.log("msg.data", msg.data);
        let parsed = JSON.parse(WS.convert_nsec_to_string(msg.data), function (key, value) {
            if (value === 'NaN') return NaN;
            if (value === 'Infinity') return Infinity;
            if (value === '-Infinity') return -Infinity;
            return value;
        })
        self.onMsg(parsed);
        /*} catch(exception) {
            console.log('[Error] ' + exception);
            console.log(msg.data);
        }*/
    }

    checkState(self) {
        //console.debug("recv:", r_compressed, r_decompressed, parseInt((1 - r_compressed/r_decompressed)*100) + '%');
        let now = Date.now();
        //console.log(pageVisibility.user_doing_something_important, pageVisibility.time_user_started_doing_something_important, now - globals.ws_disconnect_on_user_doing_something_important, pageVisibility.time_user_started_doing_something_important < now - globals.ws_disconnect_on_user_doing_something_important);
        if ((
                (
                    (!pageVisibility.focus && pageVisibility.time_lost_focus < now - globals.ws_disconnect_on_lost_focus)
                    || 
                    (!pageVisibility.visible && pageVisibility.time_lost_visibility < now - globals.ws_disconnect_on_lost_visibility)
                )
                && (!pageVisibility.user_doing_something_important || pageVisibility.user_doing_something_important && pageVisibility.time_user_started_doing_something_important < now - globals.ws_disconnect_on_user_doing_something_important) 
                && !self.downloading
            ) 
            || !self.connectCondition()
            ) {
            /*if (self.name == "WSPublic") {
                console.log("WSPublic checkState disconnect");
                console.log((!pageVisibility.focus && pageVisibility.time_lost_focus < now - globals.ws_disconnect_on_lost_focus),
                    (!pageVisibility.visible && pageVisibility.time_lost_visibility < now - globals.ws_disconnect_on_lost_visibility),
                    (!pageVisibility.user_doing_something_important || pageVisibility.user_doing_something_important && pageVisibility.time_user_started_doing_something_important < now - globals.ws_disconnect_on_user_doing_something_important),
                    !self.downloading
                    );                
            }*/
            self.disconnect();
            return;
        }
        //this.tt ++;
        if (self.ws != null) 
        {//connecting or connected
            //console.log("ws", self.url, self.ws.readyState);
            if (self.ws.readyState === 3/* || this.tt %17 == 0*/)
            {//not connected
                self.ws.close();
                delete self.ws;
                self.ws = null; // will try to reconnect next checkState
            }            
        } else {
            console.log("checkState", self.name);
            if (Date.now() > self.next_connect_time) {
                self.next_connect_time = Date.now() + globals.ws_reconnect_min + 1000 * Math.floor(Math.random() * globals.ws_reconnect_rand_max);    
                console.log("checkState", self.name, "new WebSocket");
                self.ws = new WebSocket(self.url);
                self.ws.onConnect = self.onConnect;
                self.ws.onMsg = self.onMsg;
                if (!self.connecting) {
                    self.connecting = true;
                    self.onConnecting();
                    self.listeners.forEach((f)=>{f(self.connected)});
                }
                self.ws.onopen = self.onopen.bind(this);
                self.ws.onmessage = self.onmessage.bind(this);
            }
        }
    }

    addListener(f) {
        if (this.listeners.length === 0) {//Start connecting
            //console.log("start " + this.url);
            if (this.checkInterval == null) {
                this.checkState(this);
                let self = this;
                this.checkInterval = setInterval(function() {self.checkState(self)}, 1000);
            }            
        }
        this.listeners.push(f);
        //this.listeners.forEach((f)=>{f(this.connected)});
    }
    
    removeListener(f) {
        this.listeners = _.reject(this.listeners, (l) => l === f);
        if (this.listeners.length === 0) {//Disconnect and stop
            //console.log("stop " + this.url);
            let self = this;
            setTimeout(()=>{
                if (self.checkInterval != null && this.listeners.length == 0) {
                    console.log(self.name, "disconnect due to no listeners");
                    clearInterval(self.checkInterval);
                    self.checkInterval = null;
                    self.disconnect();
                }
            }, 1000);
        }
    }  

    //for bigint to be readable
    static convert_nsec_to_string(str) {
        return str.replace(/"(at|dt|mt|ct|ut|bt|et|t)":\s*(\d+)/g,'"$1":"$2"'); 
    }

    static convert_nsec_to_int(str) {
        return str.replace(/"(at|dt|mt|ct|ut|bt|et|t)":\s*"(\d+)"/g,'"$1":$2'); 
    }

    static exchangeUrl() {
        return exchangeAPIUrl;
    }
};

export class Heartbeat {
  constructor(ws) {
    this.ws = ws;
    this.reset();
    this.ws.last_send_time = Date.now();
    this.interval = setInterval(()=>{
        if (ws.connected) {
            let now = Date.now();
            if (now - 1000 > ws.last_recv_time || now - 3000 > ws.last_send_time) {
                //console.log(this.ws.url, "7");
                ws.send(7);
            }
            if (now - 3000 > ws.last_recv_time) {
                ws.last_recv_time = now;
                ws.disconnect();
            }
        }
    }, 1000);
  }

  reset() {
    this.ws.last_recv_time = Date.now();
  }

  onMsg(msg) {
    this.reset();
    if (msg == 7) return true;
    else return false;
  }
}
