import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";

import {strings} from '../localization/strings';
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import { dec, dt_s } from "../utils/cell-formatters";
import {withFilter} from '../utils/utils'
//import Select from '@material-ui/core/Select';
import { Select } from '../components/select-multiple';
import Currencies from "../ws-pub/currencies";

export default class Indexes { 
  static getHistory(iid) {
    return new Promise(function(resolve, reject) {
      new Subscribtion({
        ws: wspub,
        smsg: {"P": "g_hidx", "D": {"iids": [iid]}},
        eternal: false,
        onResult: function(msg) {
          var hidxs = [];
          if (msg["D"].hasOwnProperty("hidxs")) hidxs = msg["D"]["hidxs"];
          if (msg["D"].hasOwnProperty("hidx")) hidxs.push(msg["D"]["hidx"]);
          if (hidxs.length > 0) {
            var hidx = hidxs[0];
            var prices = [];
            hidx["ps"].forEach(function (price) {
                var idx = {};
                idx.p = price[0];
                idx.id = price[1];
                prices.push(idx);
            });
            resolve(prices);
            //Indexes.tab_hidx.setSort("id", 'desc');
          }        
        },
        onError: function(str) {
          reject(str);
        }
      });   
    });
  }

  static getListenIids() {
    let iids = [];
    if (this.listeners['_all_'].length > 0) iids = null;
    else {
      _.each(this.listeners, (listeners, iid_)=>{
        if (listeners.length > 0 && iid_ !== '_all_' && iid_ !== '_list_') iids.push(iid_);
      });        
    }
    return iids;
  }

  static addListener(f, iid) {
    if (!this.listeners.hasOwnProperty(iid)) this.listeners[iid] = [];
    //console.log("Indexes addListener " + iid + " " + this.listeners[iid].length);
    let self = this;
    if (this.listeners[iid].length === 0) {
      if (iid === '_list_') {// _list_ listeners
        this.listeners[iid].push(f);
        if (!this.got_indexes_list) {
          this.got_indexes_list = true;
          new Subscribtion({//subscribe on index updates
            ws: wspub,
            smsg: { P: "g_idx" },
            eternal: false,
            onResult: function (msg) {self.parse(msg)},
          });
        }
      } else {// normal and _all_ listeners
        let iids = this.getListenIids();
        if (iids !== null && iids.indexOf(iid) === -1) {
          if (this.subscribtion) {
            if (iid === '_all_') this.subscribtion.smsg["D"].iids = null;
            else this.subscribtion.smsg["D"].iids.push(iid);
            wspub.send({ P: "s_idx", D: {"iids": iid === '_all_' ? null : [iid]}, "I": this.subscribtion.smsg["I"]});
          } else {
            this.subscribtion = new Subscribtion({//subscribe on index updates
              ws: wspub,
              smsg: { P: "s_idx", D: {"iids": iid === '_all_' ? null : [iid]}},
              eternal: true,
              onResult: function (msg) {self.parse(msg)},
            });
          }
        }
        this.listeners[iid].push(f);
      }
    } else {
      this.listeners[iid].push(f);
    }
    f({ idxsv: this.idxsv }); //snapshot
    //console.log(this.listeners);
  }

  static removeListener(f, iid) {
    //console.log("Indexes removeListener", iid, this.subscribtion);
    this.listeners[iid] = _.reject(this.listeners[iid], (l) => l === f);
    if (!this.subscribtion) return;
    let old = _.cloneDeep(this.subscribtion.smsg["D"].iids);
    if (old == null) old = Indexes.idxsv.map((idx)=>idx.iid);
    let iids = this.getListenIids();
    if (iids != null) {
      if (iids.length === 0) {//unsub from all
        this.subscribtion.unsubscribe();
        this.subscribtion = null;
      } else {
        iids.forEach((iid)=>{old = _.reject(old, (l) => l === iid)});
        if (old.length > 0) wspub.send({ P: "us_idx", D: {"iids": old}});
        this.subscribtion.smsg["D"].iids = iids;
      }
    }
    //console.log("removeListener " + iid + " " + this.listeners[iid].length);
  }

  static request() {
    this.got_indexes_list = false;
  }

  static parse(msg) {
    var idxs = [];
    if (msg["D"].hasOwnProperty("idxs")) idxs = msg["D"]["idxs"]; 
    if (msg["D"].hasOwnProperty("idx")) idxs.push(msg["D"]["idx"]);
    if (idxs.length > 0) {
      let hasNew = false;
      idxs.forEach(function (idx) {
        idx.id = idx["iid"];
        idx["dt"] = idx["dt"] / 1000000000;
        if (!Indexes.hasOwnProperty(idx.id)) {
          Indexes[idx.id] = _.cloneDeep(idx);
          Indexes.idxsv.push(Indexes[idx.id]);
          hasNew = true;
        } else Indexes[idx.id] = _.cloneDeep(idx);
      });
      if (hasNew) {
        idxs.sort((a,b)=>a.iid > b.iid ? 1 : -1);
        Indexes.idxsv.sort((a,b)=>a.iid > b.iid ? 1 : -1);
        if (msg['Y'] === 'u' && this.listeners['_all_'].length > 0) this.subscribe('_all_');
      }
      _.each(this.listeners, (listeners)=>{
        listeners.forEach((f) => {f({ idxsv: idxs });}); // update
      });
      return true;
    }        
    return false;
  }   
}

Indexes.listeners = {'_all_':[], '_list_':[]};
Indexes.idxsv = [];

export class IndexesListener extends React.Component {
  constructor(props) {
    super(props);
    this.iid = this.props.iid;
  }
  componentDidMount() {Indexes.addListener(this.props.onChange, this.iid)}
  componentWillUnmount() {Indexes.removeListener(this.props.onChange, this.iid)}
  render() {
    if (this.props.iid !== this.iid) {
      this.componentWillUnmount();
      this.iid = this.props.iid;
      this.componentDidMount();
    }
    return null;
  }
}

//HOC
export function withIndexesList(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        idxsv: _.cloneDeep(Indexes.idxsv)
      };
    }

    componentDidMount() {Indexes.addListener(this.onChange, "_list_")}
    componentWillUnmount() {Indexes.removeListener(this.onChange, "_list_")}
    onChange() {
      if (Indexes.idxsv.length !== this.state.idxsv.length) this.setState({idxsv: _.cloneDeep(Indexes.idxsv)})
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

function ConvertBTCToUSD_(props) {
  let idx = null;
  ["csi_btc", "bn_btcusdt", "kr_btcusdt", "bn_btcusdc"].some((iid)=>{
    if (Indexes.hasOwnProperty(iid)) {idx = Indexes[iid]; return true;}
    return false;
  });
  if (!idx) return null;
  return <span className={props.className}>{parseInt((props.amount * 1e-8) * (idx.p * 1e-8))}$</span>;
}

export const ConvertBTCToUSD = withWSPubKey(withIndexesList(ConvertBTCToUSD_))

//Component
function SelectIid_(props) {
  return <select value={props.value == null ? "" : props.value} onChange={(e)=>props.onChange({iid: e.target.value})}>
      <option key={"_all_"} value={"_all_"}>{strings.AllIndexes}</option>
      {
          props.idxsv.map((idx)=><option key={idx.iid} value={idx.iid}>{idx.iid}</option>)
      }
  </select>;
}

export const SelectIid = withWSPubKey(withIndexesList(SelectIid_))

//Component
function SelectIid2_(props) {
  let options = [{value: "_all_", label: strings.AllIndexes}];
  props.idxsv.forEach((idx)=>{options.push({value: idx.iid, label: idx.iid})});
  return <Select 
    className={props.className}
    value={props.value == null ? "" : props.value} 
    onChange={(v)=>props.onChange({iid: v})}
    options={options}
    >
  </Select>;
}

export const SelectIid2 = withWSPubKey(withIndexesList(SelectIid2_))

class AllIndexesTable extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.iid = this.props.filter.iid;
  }

  componentDidMount() {
    let div = this.tabRef.current;
    div.id = "idxs_" + this.props.id;
    this.tab = new TableX("#" + div.id, {
      //height:"200px",
      columns:[
        {title:"IdxID", field:"iid", width: 100},
        {title:"Price", field:"p", formatter:dec, width: 100},
        //{title:"Base currency", field:"base_currency"},
        {title:"UpdateTime", field:"dt", formatter:dt_s},
      ],
    });
    this.tab.setSort("iid", 'desc');    
    if (this.idxs) this.tab.addData(this.idxs.idxsv);
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    this.tab = null;
  }

  onChange(idxs) {
    //console.log(idxs.idxsv);
    if (this.tab) {
      this.tab.updateOrAddData(idxs.idxsv, true);
    }
    this.idxs = idxs;
  }

  render() {
    if (this.tab) this.tab.render();
    return <React.Fragment>
        <IndexesListener iid={'_all_'} onChange={this.onChange} />
        <div ref={this.tabRef} />
      </React.Fragment>
  }
}

class IndexHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.iid = this.props.filter.iid;
  }

  componentDidMount() {
    if (wspub.connected && this.tabRef.current) {
      let div = this.tabRef.current;
      div.id = "idx_hist_" + this.props.id;
      this.tab = new TableX("#" + div.id, {
        columns:[
          {title:"Price", field:"p", formatter:dec},
          {title:"Time", field:'id', formatter:dt_s},
        ],
      });
      this.tab.setSort("id", 'desc');    
      if (this.idxs) this.onChange(this.idxs);
      Indexes.getHistory(this.iid).then((data) => {
        this.tab.updateOrAddData(data, false);
      }).catch((error) => {});        
    }
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    this.tab = null;
  }

  onChange(idxs) {
    let self = this;
    if (this.tab) {
      idxs.idxsv.forEach(function (idx) {
        if (idx.iid === self.iid) self.tab.updateOrAddData([{id: idx.dt, p: idx.p}], true);
      });
    }
    this.idxs = idxs;
  }

  render() {
    if (this.tab) this.tab.render();
    return <React.Fragment>
      <IndexesListener iid={this.props.filter.iid} onChange={this.onChange} />
      <div ref={this.tabRef} />
    </React.Fragment>
  }
}

class IndexesTable_ extends React.Component {
  render() {
    return <React.Fragment>
      {this.props.filter.iid === "_all_" ? 
        <AllIndexesTable {...this.props}/> : 
        <IndexHistoryTable {...this.props}/>}
    </React.Fragment>
  }
}

export const IndexesTable = withFilter(withWSPubKey(withSize({ monitorHeight: true })(IndexesTable_)));
