export const strings = {
  '-': '---------------------',
  MobileVersion: "Мобильная версия",
  Download_: {
    Downloading: 'Скачиваю',
    DownloadTrades: 'Скачать сделки',
    DownloadOrders: 'Скачать заявки',
    DownloadTransactions: 'Скачать транзакции',
    DownloadTransfers: 'Скачать трансферы',
    DownloadStatement: 'Скачать выписку',
    Downloaded: "{0} записей скачано",
    Failed: "Не удалось скачать",
    FromTo: `'{0}' должно быть меньше, чем '{1}'`,
  },
  Security_: {
    ChangePassword: 'Смена пароля для входа по email',
    SendPasswordResetLink: 'Изменить пароль',
    SendPasswordResetText: `Вам на почту будет отправлена ссылка для сброса пароля`,
    PasswordResetLinkSent: 'Ссылка для смены пароля отправлена на',
    PasswordResetLinkNotSent: 'Ссылка для смены пароля НЕ отправлена',
    SendVerificationEmail: 'Повторно отправить ссылку',
    EmailNotVerified: `Подтвердите свою почту`,
    VerifyWarn: 'Мы только что отправили ссылку для подтверждения почты на {0}. Чтобы получить доступ к сайту, подтвердите свою почту',
    VerificationEmailSent: 'Ссылка для подтверждения почты отправлена на',
    VerificationEmailNotSent: 'Ссылка для подтверждения почты НЕ отправлена',
    NotSignedIn: 'Вы должны войти в систему',
    Device: 'устройство',
    Platform: 'Платформа',
    Browser: 'Браузер',
    IP: 'IP',
    LastLogin: 'Последний логин',
    Devices: 'Активность',
    DeviceVerification: 'Вход с нового устройства',
    DeviceVerificationSent1: `Вы выполняете вход с устройства, с которого раньше не заходили. `,
    DeviceVerificationSent2: `Прейдите по ссылке, которую мы отправили на {0}`,
    DeviceVerificationSent3: `. Подтверждение требуется один раз для каждого нового устройства и помогает защитить ваш аккаунт.`,
  },
  MustLogIn: "Вы должны войти в систему, чтобы просмотреть этот контент",
  Login: "Войти",
  Register: "Зарегистрироваться",
  or: "или",
  AllMessages: "Все сообщения",
  SelectMassages: "Фильтр по сообщениям",
  Confirmations: "Подтветверждения",
  Address: "Адрес",
  TransactionID: "ID транзакции",
  ShowTransactions: "Посмотреть транзакции",
  ShowStatement: "Посмотреть выписку",
  Referral: "Реферальная программа",
  Referral_: {
    Link: "Реферальная ссылка",
    Payments: "Реферальные выплаты",
    Today: "За вчера",
    Yesterday: "За последние 30 дней",
    Text: `Реферальная программа предполагает сотрудничество между торговой системой и участниками торгов, где действующие участники получают вознаграждение за новых привлеченных ими клиентов из числа личных знакомых. Вознаграждением является получение части комиссий в размере 25% от уплаченных новым клиентом`,
  },
  AreYouSure: "Вы уверены?",
  Tariffs: "Тарифы",
  TariffsTable_:{
    Tariff: "Тариф",
    TariffID: "ID тарифа",
    MakerFee: "Сбор для мейкера",
    TakerFee: "Сбор для тейкера",
    ExpiryFee: "Сбор за экспирацию",
    FixedFee: "Фиксированный сбор",
    NextPayment: "Время следующего списания",
    UserID: "Пользователь",
    Commission: "Начисленная комиссия",
    CommissionDealsToday:  "За сегодняшние сделки",
    CommissionDealsYesterday: "За вчерашние сделки",
    CommissionFixed: "Фиксированная за 8 часов",
    CommissionOverdraft: "Овердрафт за 8 часов",
  },
  API: "API",
  APIKeys: "API ключи",
  APITester: "API тестер",
  APIDoc: 'API документация',
  TestEnv: 'Testnet среда',
  Api_:{
    /*keys*/
    KeyID: "ID ключа",
    Label: "Комментарий",
    Permissions: "Разрешения для ключа",
    CanTrade: "Торговля",
    CanTradeDescr: "Создание/удаление заявок",
    CanManage: "Управление",
    CanManageDescr: "Создание/трансферы/делегирование/удаление/метка аккаунтов",
    CanWithdraw: "Вывод",
    CreateTime: "Время создания",
    Create: "Создать API ключ",
    Created: "API ключ создан",
    SaveKey: "Сохраните API ключ",
    Secret: "Секрет",
    SaveNote: "ВАЖНО: Сохраните эти данные в безопасном месте. Секрет доступен для сохранения только в данный момент",
    /*doc*/
    ChooseVer: 'Выберите версию API документации',
    ProductionEnv: 'Боевая',
    TestEnv: 'Testnet среда',
    TestEnvDescription: 'Для удобства тестирования и разработки доступна testnet среда по адресу',
    TestEnvDescription2: 'Testnet среда используется нами для тестирования нового функционала. Поэтому версия API у нее может быть старше, чем у боевой',
    TableOfContents: 'Оглавление',
    /*tester*/
    Request: "Запрос",
    Reply: "Ответ",
    Send: "Отправить",
    NotValidJson: "Запрос должен быть в JSON формате",
  },
  QRCode: "QR код",
  TwoFA: "Двух-факторная аутентификация",
  TwoFA_: {
      Setup: "Настроить двух-факторную аутентификацию",
      QRCodeText: `Используйте мобильное приложение, например Authy или Google authenticator, чтобы отсканировать QR код.`,
      QRCodeText2: `Если нет возможности отсканировать QR код, введите его в ручную`,
      Enable: "Включить 2ФА",
      Disable: "Выключить 2ФА",
      Disabled: "2ФА выключена",
      Enabled: "2ФА включена",
      ResetText: `Через три дня вам на почту будет отправлена ссылка для сброса 2ФА`,
      Reset: "Потерял 2ФА?",
      EnterKey: "Введите 6-значный 2ФА ключ",
      WrongFormat: "Неправильный формат 2ФА ключа - должно быть 6 цифр",
      Verify: "Верифицировать",
  },
  Transfer_: {
      PerformTransfer: "Перевести",
      ShowTransfers: "Посмотреть переводы",
      Complete: "Перевод выполнен",
      OnlyOneAcc: "Чтобы осуществить перевод между счетами, создайте хотя бы один суб-счет",
  },
  Withdraw_: {
      Add: "Добавить адрес для вывода",
      Address: "Адрес",
      Addresses: "Адреса",
      AddTime: "Время добавления",
      Added: "Адрес для вывода добавлен",
      PerformWithdraw: "Вывести",
      TransactionFee: "Комиссия сети",
      Withdrawed: "Вывод выполнен",
      Removed: "Адрес для вывода удален",
      Label: "Комментарий",
  },
  CopiedToCB: "Скопирован в буфер обмена",
  Deposit_: {
      CoinsGoToMain: "Все депонированные монеты поступают на счет {0} (основной)",
      AddressGenerated: "Вам сгенерирован новый адрес",
      Address: 'Адрес для ввода',
      //Addresses: 'Адреса для ввода',
      NewDepositAddr: "Сгенерировать новый адрес",
      BTC : {
          addr: `Ваши личные биткоин адреса для ввода`,
          //addr: `Биткоин адреса для ввода`,
          topics: [        
              `Не отправляйте Litecoin, Bitcoin Cash, или Tether на эти адреса! К сожалению, эти сети примают биткоин адреса за свои. Любые не поддерживаемые монеты, отправленные сюда, будут утеряны.`,
              //`The minimum deposit amount is 0.0001 XBT (10000 Satoshi).`,
              `Ваш ввод будет зачислен после 6-ти подтверждений в сети биткоин.`,
              `Все адреса для ввода, это мульти-подписные биткоин адреса на холодном кошельке биржи.`
          ],
      },
      ETH : {
          addr: `Ваш личный Ethereum адрес для ввода`,
          topics: [],
      },
      BSC : {
          addr: `Ваш личный Binance Smart Chain BEP20 адрес для ввода`,
          topics: ['При отправке используйте только BEP20 токены'],
          min: 'Минимальное кол-во для ввода {0}. Переводы меньшего размера будут проигнорированы.',
      },
  },
  NoConnection: "Нет соединения",
  Security: "Безопасность",    
  From: "C",
  To: "На",
  Since: "От",
  Until: "До",
  AccInfo: "Доступно",
  AccManage: "Управление счетом", // (ввод, вывод...)
  Fees: "Комиссия",
  main: "основной",
  Next: "Далее",
  Enabled: "Включена",
  Disabled: "Выключена",
  Balance: "Баланс",
  MainAcc: "Основной счет",
  RemoveAcc:"Удалить счет",
  RevokedAcc:"Счет забран из управления",
  RefuseAcc:"Отказаться от счета",
  CreateNewAcc:"Создать суб-счет",
  DelegateAcc: "Передать в управление",
  DelegatedAcc: "Управляющие",
  AccInManagement: "В управлении от",
  "Delegated": "Передан в управление",
  "In management": "В управлении у меня",
  DelegateMsg: "Предоставить право торговать с этого счета другому пользователю. Пользователь сможет выставлять/снимать заявки, но не сможет совершать операции ввода/вывода/перевода. Вы можете предоставить право торговать нескольким пользователям одновременно.",
  Email: "Почта",
  Wallet: 'Кошелек',
  Deposit: 'Ввод',
  Withdraw: 'Вывод',
  Transfer: 'Перевод',
  order: 'заявка',
  Ok: "Ok",
  Cancel: 'Отмена',
  QTY: 'Кол-во',
  VISQTY: 'Видимое кол-во',
  PRICE: 'Цена',
  SIZE: 'Кол-во',
  TOTAL: 'Всего',
  ACTPRICE: 'Цена активации',
  EXPDATE: 'Срок действия',
  COMMENT: 'Комментарий',
  All: 'Всего',
  ID: 'ID',
  SelectAll: 'Выбрать все',
  ClearAll: 'Очистить все',
  AllColumns: 'Все колонки',
  AllAccounts: 'Все счета',
  AllInstruments: 'Все инструменты',
  AllCurrencies: 'Все валюты',
  InstrumentInfo: 'Спецификация инструмента',//Параметры инструмента
  ContractSpecs: "Спецификация инструмента",
  MarginBalance: 'Общий баланс',
  Currency: 'Валюта',
  Available: 'Доступно',
  UsedDeposit: 'Зарезервировано',
  CanWithdraw: 'Можно вывести',
  PL: 'P/L',
  Limits: 'Лимиты',
  Own: 'Своя',
  Owner: 'Владелец',
  Users: 'Делегаты',
  OrdersAndTrades: "Заявки, сделки и позиции",
  InstrumentControl: "Новая заявка",
  AllTradesIndxs: 'Все сделки и индексы',
  BuyOrder: 'КУПИТЬ',
  SellOrder: 'ПРОДАТЬ',
  Trade: 'Торговля',
  UserAccount: 'Учетная запись',
  Exchange: 'Биржа',
  AllRightsReserved: 'Все права защищены',
  Message: 'Собщение',
  Log: 'Лог',
  OrderConfirm: 'Подтвердите новую заявку',
  Iceberg: 'Айсберг',
  Trigger: 'Тригер',
  TopOfBook: 'Лучшая цена',
  Buy: 'Купля',
  Sell: 'Продажа',
  ExpDate: 'Дата экспирации',
  Limit_: 'Лимит', //Accounts limit
  Limit: 'Лимитная', //Order limit
  Market: 'Рыночная',
  Comment: 'Комментарий',
  light: 'Светлая',
  dark: 'Темная',
  TableOfContents: "Содержание",
  Login_as: 'Войти как',
  Logout: 'Выйти',
  Back: 'Назад',
  Home: 'Домой',
  Reset: 'Сбросить',
  ResetChart: 'Сбросить настройки графика',
  Layouts: 'Рабочий стол',
  BasicLayout: 'Сбросить настройки',
  OwnLayout: 'Сохраненный',
  LayoutSaved: "Рабочий стол сохранен в облако",
  LayoutLoaded: "Рабочий стол загружен из облака",
  LayoutMustLogIn: "Вам нужно войти в систему, чтобы сохранить рабочий стол",
  Misc: 'Разное',
  LeftToolbar: 'Левая панель инструментов (график)',
  TopToolbar: 'Верхняя панель инструментов (график)',
  BottomToolbar: 'Нижняя панель инструментов (график)',
  ShowTrades: 'Показать последние 1000 сделок на графике',
  SelectCurrencies: 'Фильтр по валютам',
  SelectAids: 'Фильтр по счетам',
  SelectInstruments: 'Фильтр по инструментам',
  SelectDatetime: 'Фильтр по времени до',
  SelectColumns: 'Выбрать колонки',
  WSPublic: 'Рыночные данные',
  WSPrivate: 'Торговое подключение',
  Online: 'Онлайн',
  Offline: 'Не активно',
  Instruments: 'Все инструменты',
  QuotesMonitor: 'Котировки',
  TestList: 'Группа',
  TestTable: 'Таблица',
  Orderbook: 'Стакан',
  ActiveOrders: 'Активные заявки',
  Indexes: 'Индексы',
  IndexesTable: 'Индексы',
  AllIndexes: 'Все индексы',
  AllTrades: 'Все cделки',
  MyTrades: 'Мои сделки',
  Orders: 'Заявки',
  Chart: 'График',
  ChartTrades: 'График',
  InsID: 'Код',
  Symbol: 'Имя',
  Instrument: 'Инструмент',
  Dir: 'Напр',
  Price: 'Цена',
  Size: 'Кол-во',
  VisQty: 'Видимое кол-во',
  Qty: 'Кол-во',
  Quantity: 'Кол-во',
  Amount: 'Сумма',
  Total: 'Всего',
  IdxID: 'Индекс ID',
  VolBTC: 'Объем в BTC',
  tNo: 'Номер сделки',
  Time: 'Время',
  UpdateTime: 'Время обновления',
  CreateTime: 'Время создания',
  SendTime: 'Время отправки',
  ExpiryTime: 'Срок действия',
  InitialMargin: 'Гарантийное обеспечение',
  LimDown: 'Нижний лимит цены',
  LimUp: 'Верхний лимит цены',
  Index: 'Индекс',
  Estimate: 'Расчетная цена',
  Mark: 'Расч.',
  ShowIndexPrice: 'Показать цену индекса',
  ShowMarkPrice: 'Показать расчетную цену',
  BestBuy: 'Лучшая покупка',
  QuantityBuy: 'Кол-во лучшей покупки',
  BestSell: 'Лучшая продажа',
  QuantitySell: 'Кол-во лучшей продажи',
  LastPrice: 'Цена последней сделки',
  LastQuantity: 'Кол-во последней сделки',
  Status: 'Статус',
  Description: 'Описание',
  MinStep: 'Шаг цены',
  ContractSize: 'Стоимость 1 контракта',
  ContractSizeStr: '{0} BTC (каждый контракт стоит {1} BTC за 1$ цены)',
  StepPrice: 'Стоимость шага цены',
  LotSize: 'Лот',
  PriceDecimals: 'Разряды цены',
  LotDecimals: 'Разряды лота',
  FundingRate: 'Ставка фондирования (%)',
  IcebergRatio: 'Минимальная доля видимой части айсберг заявки (%)',
  ExpiryType: 'Тип экспирации',
  Activation: 'Дата начала обращения',
  Expiration: 'Дата экспирации',
  Trades: 'Сделки',
  RecentTrades: 'Недавние сделки',
  TradesQty: 'Число сделок',
  TradesVol: 'Объем сделок',
  OpenedInterest: 'Открытый интерес',
  Account: 'Счет',
  AccountControl: 'Счет',
  Accounts: 'Счета',
  AccountsTable: 'Таблица счетов',
  AccID: 'Счет',
  oNo: 'Номер заявки',
  Maker: 'Maker',
  Taker: 'Taker',
  Label: 'Комментарий',
  Commission: 'Комиссия',
  Flags: 'Флаги',
  TradeFlags: 'Флаги', 
  Rest: 'Остаток',
  CancelReason: 'Причина снятия',
  ExtID: 'Внешний ID',
  OnlyMaker: 'Только maker',
  VisRest: 'Видимый остаток',
  Type: 'Тип',
  Stop: 'Стоп',
  Stops: 'Стопы',
  StopPrice: 'Цена стопа', 
  Profit: 'Профит',
  ActType: 'Тип активации',
  ActPrice: 'Цена активации',
  TimeInForce: 'Время жизни',
  AddedBy: 'Кем выставлена',
  Positions: 'Позиции',
  Pos: 'Позиция',
  Position: 'Позиция',
  Topic: 'Заголовок',
  Data: 'Сообщение',
  User: 'Пользователь',
  Admin: 'Администратор',
  History: 'История',
  NewOrder: 'Новая заявка',
  NewOrderSuccess: '{0} {1} цена {2} кол-во {3}',
  UserID: "Пользователь",
}

export const codes = {
  "N1":"Плановое техническое обслуживание %s продлится %s",
  "N2":"Вывод %s в настоящее время недоступен",
  "N3":"%s",
  "IF4":"Чтобы вывести, вам нужно завести базовую валюту блокчейна: введите %p %s",
}

export const enums = {
  direction: {1:"Купля", 2:"Продажа"},
}
