/* eslint-disable eqeqeq */
import React from 'react';
import _ from "lodash";
import { format } from "../localization/strings";
import moment from "moment";
import Symbols from '../ws-pub/symbols'
import Instruments from '../ws-pub/instruments'
import { strings, enums } from '../localization/strings'
//import { enums as enums_en } from '../localization/strings_en'
import Currencies from "../ws-pub/currencies";

import { wspriv } from "../ws-priv/ws-priv";

import '../styles/css/cell-formatters.css';

let dec_string_arr = ["0.00000000","0.0000000","0.000000","0.00000","0.0000","0.000","0.00","0.0","0."];

export function decS(v) {//dec8 to string
    //console.log(v);
    let sign = '';
    if (v === 0) return "0";
    if (v < 0) {
        v = -v;
        sign = '-';
    }
    v = v.toString();
    let len = v.length;
    let e;
    if (len > 8) {
        v = v.slice(0, len - 8) + '.' + v.slice(-8);
        len = v.length;
        e = len - 1;
        while (v[e] === '0' && len - e <= 8) {e--;}
        if (len - e === 9) e--;
    } else {
        v = dec_string_arr[len] + v;
        len = v.length;
        e = len - 1;
        while (v[e] === '0' && len - e <= 8) {e--;}
    }
    //console.log(v);
    return sign + v.slice(0, e + 1);
}

/*console.log(decS(1));
console.log(decS(10));
console.log(decS(100));
console.log(decS(1000));
console.log(decS(10000));
console.log(decS(100000));
console.log(decS(1000000));
console.log(decS(10000000));
console.log(decS(123456789));
console.log(decS(1234567891));
console.log(decS(12345678912));
console.log(decS(123456789123));
console.log(decS(1234567891230));
console.log(decS(12345678912300));
console.log(decS(12345600000000));*/

export function decCidS(v, row) {
  if (v === null || Currencies.all[row.cid] == null) return "";
  else return decS(v) + " " + Currencies.all[row.cid].short;
}

export function iDecCidS(v, row) {
  if (v === null || Currencies.all[row.cid] == null) return "";
  else return <span className="cid-I">{currencyS(row.cid)}{'\u00A0' + decS(v) + "\u00A0" + Currencies.all[row.cid].short}</span>;
}

export function iTextS(v, cid) {
    if (cid == null) cid = Currencies.main;
  if (v === null) return "";
  else return <span className="cid-I">{currencyS(cid)}{'\u00A0' + v}</span>;
}

export function iTextCidS(v, cid) {
    if (cid == null) cid = Currencies.main;
  if (v === null) return "";
  else return <span className="cid-I">{currencyS(cid)}{'\u00A0' + v + '\u00A0' + cid}</span>;
}

export function currencyS(v) {
  if (v === null || Currencies.all[v] == null) return null;
  return Currencies.all[v].icon({size: "13"});
}

export function currency(cell, formatterParams) {
    let v = cell.getValue();
  return Currencies.all[v].icon({size: "13"});
}

export function formatPrice(sid, price) {
  return decS(Math.round(price / (Instruments.all[sid].ms * 1e-8) + 1e-9) * (Instruments.all[sid].ms));
}

export function formatQuantity(sid, qty) {
  return decS(Math.round(Math.abs(qty) / (Instruments.all[sid].ls * 1e-8) + 1e-9) * (Instruments.all[sid].ls));
}

export function dec(cell, formatterParams) {
	let v = cell.getValue();
    if (v === undefined || v == null || v === "") return "&nbsp";   
    if (v === 0) return "0";
	return decS(cell.getValue());
}

export function dec_class(cell, formatterParams) {
    let el = cell.getElement();
    el.classList.add(formatterParams);
    return decS(cell.getValue());
}

export function dt_nsS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss.') + (v + '').slice(-9);
}

export function dt_ns(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss.') + (v + '').slice(-9);
}

export function dt_ns_msS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss.SSS');
}

export function dt_ns_ms(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss.SSS');
}

export function dt_ns_sS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss');
}

export function dt_ns_s(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v/1000000).format('YYYY-MM-DD HH:mm:ss');
}

export function dt_sS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export function dt_s(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export function dt_s_mS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD HH:mm');
}

export function dt_s_m(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD HH:mm');
}

export function dt_s_dS(v) {
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD');
}

export function dt_s_d(cell, formatterParams) {
    let v = cell.getValue();
    if (v == 0 || v == undefined) return "";
    else return moment.utc(v * 1000).format('YYYY-MM-DD');
}

export function sec_idS(v) {
    if (Symbols[v] == undefined) {
        console.log("No symbols");
        console.log(Symbols);
        console.log(v);
    }
    return Symbols[v]["sym"];
}

export function sec_id(cell, formatterParams) {
    let v = cell.getValue();
    if (Symbols[v] === undefined) {
        console.log("No symbols");
        console.log(Symbols);
        console.log(v);
        console.log(cell.getRow().getData());
    }
    return Symbols[v]["sym"];
}

export function dirS(v) {
    return enums.direction[v];
}

export function dir(cell, formatterParams) {
    let v = cell.getValue();
    let el = cell.getElement();
    let colors = {1:"#3BC471", 2:"#EE3F34"}; // green red
    el.style.color = colors[v];
    //el.style.fontWeight = "bold";
    return enums.direction[v];
}

export function ins_statusS(v) {
    return enums.instrument_status[v];
}

export function ins_status(cell, formatterParams) {
    let v = cell.getValue();
    //let el = cell.getElement();
    //let colors = {0:"black", 1:"pink", 2:"lightGrey", 3:"violet", 4:"darkGrey", 5:"orange"};
    //el.style.color = "white";
    //el.style.backgroundColor = colors[v];
    return enums.instrument_status[v];
}

export function ins_expiry_typeS(v) {
    if (v === undefined) return "";   
    return enums.expiry_type[v];
}

export function ins_expiry_type(cell, formatterParams) {
    let v = cell.getValue();
    if (v === undefined) return "";   
    //let el = cell.getElement();
    //let colors = {0:"black", 1:"pink"};
    //el.style.color = "white";
    //el.style.backgroundColor = colors[v];
    return enums.expiry_type[v];
}

export function order_statusS(v) {
    if (v === undefined) return "";   
    return enums.order_status[v];
}

export function order_status(cell, formatterParams) {
    let v = cell.getValue();
    if (v === undefined) return "";   
    if (v !== 1 && v !== 4) return enums.order_status[v]; //not Running
    let el = cell.getElement();
    let btn_cancel_order = document.createElement("span");
    btn_cancel_order.ono = cell.getData().ono;
    btn_cancel_order.sid = cell.getData().sid;
    btn_cancel_order.aid = cell.getData().aid;
    btn_cancel_order.classList.add("btn_cancel_order");
    //btn_cancel_order.classList.add("col-white-pen");
    btn_cancel_order.innerHTML = "&#10005;";
    btn_cancel_order.onclick = (el) => {//Cancel order
        console.log(el.target.ono);
        wspriv.send({"P": "c_or", "D": {"ono": el.target.ono, "sid": el.target.sid, "aid": el.target.aid}});
    };
    while (el.lastChild) el.removeChild(el.lastChild);
    el.appendChild(document.createTextNode(enums.order_status[v]));
    el.appendChild(btn_cancel_order);
    return null;
}

export function cancel_reasonS(v, row) {
    if (v === undefined) return "";   
    if (row["cu"] !== undefined) return row["cu"];
    if (enums.cancel_reason[v] === "User") return row["own"];
    return enums.cancel_reason[v];
}

export function cancel_reason(cell, formatterParams) {
    let row = cell.getRow().getData();
    let v = cell.getValue();              
    if (v === undefined) return "";   
    if (row["cu"] !== undefined) return row["cu"];
    if (enums.cancel_reason[v] === "User") return row["own"];
    return enums.cancel_reason[v];
}

export function added_byS(v, row) {
    if (v === undefined) return "";   
    if (row["au"] !== undefined) return row["au"];
    else return row["own"];
}

export function added_by(cell, formatterParams) {
    let row = cell.getRow().getData();
    let v = cell.getValue();              
    if (v === undefined) return "";   
    if (row["au"] !== undefined) return row["au"];
    else return row["own"];
}

export function order_typeS(v) {
    if (v === undefined) return "";   
    return enums.order_type[v];
}

export function order_type(cell, formatterParams) {
    let v = cell.getValue();              
    if (v === undefined) return "";   
    return enums.order_type[v];
}

export function order_flagsS(v) {
    if (v === undefined) return "";
    let ret = '';
    _.each(enums.order_flags, function(t, i) {
        if (i & v) {
            if (ret !== '') ret += ' '
            ret += t;
        }
    });
    return ret;
}

export function order_flags(cell, formatterParams) {
    let v = cell.getValue();              
    if (v === undefined) return "";
    let ret = '';
    _.each(enums.order_flags, function(t, i) {
        if (i & v) {
            if (ret !== '') ret += ' '
            ret += t;
        }
    });
    return ret;
}

export function trade_flagsS(tf, row) {
    let ret = '';
    tf = row["tf"];
    if (tf !== undefined) {
        _.each(enums.trade_flags, function(t, i) {
            if (i & tf) {
                if (ret !== '') ret += ' '
                ret += t;
            }
        });
    }
    let f = row["f"];
    if (f !== undefined) {
        _.each(enums.order_flags, function(t, i) {
            if (i & f) {
                if (ret !== '') ret += ' '
                ret += t;
            }
        });
    }
    return ret;
}

export function trade_flags(cell, formatterParams) {
    let ret = '';
    let row = cell.getRow().getData();
    let tf = row["tf"];
    if (tf !== undefined) {
        _.each(enums.trade_flags, function(t, i) {
            if (i & tf) {
                if (ret !== '') ret += ' '
                ret += t;
            }
        });
    }
    let f = row["f"];
    if (f !== undefined) {
        _.each(enums.order_flags, function(t, i) {
            if (i & f) {
                if (ret !== '') ret += ' '
                ret += t;
            }
        });
    }
    return ret;
}

export function time_in_forceS(v) {
    if (v === undefined) return "";   
    return enums.time_in_force[v];
}

export function time_in_force(cell, formatterParams) {
    let v = cell.getValue();              
    if (v === undefined) return "";   
    return enums.time_in_force[v];
}

export function activation_typeS(v) {
    if (v === undefined) return "";   
    return enums.activation_type[v];
}

export function activation_type(cell, formatterParams) {
    let v = cell.getValue();              
    if (v === undefined) return "";   
    return enums.activation_type[v];
}

export function maker_takerS(v, row) {
    if (row["f"] == 2) return ""; //Expiration
    else if (v) return strings.Maker;
    else return strings.Taker;
}

export function maker_taker(cell, formatterParams) {
    let row = cell.getRow().getData();
    let v = cell.getValue();              
    if (row["f"] == 2) return ""; //Expiration
    else if (v) return strings.Maker;
    else return strings.Taker;
}

export function string_code(cell, formatterParams) {
    let row = cell.getRow().getData();
    let v = cell.getValue();
    return format(v, row["p"]);
}

export function string_codeS(v, row) {
    return format(v, row["p"]);
}

export function transaction_direction(cell, formatterParams) {
    let v = cell.getValue();              
    //let el = cell.getElement();
    //if (v == 1) el.classList.add("col-blue");
    return enums.transaction_direction[v];
}

export function transaction_directionS(v, row) {
    return enums.transaction_direction[v];
}

export function transaction_state(cell, formatterParams) {
    let v = cell.getValue();              
    if (v === undefined) return "";   
    return enums.transaction_state[v];
}

export function transaction_state_simpleS(v, row) {
    if (v === undefined) return "";
    if (v === 2 && row["cnt"] < 6) v = 1; //Pending
    return enums.transaction_state_simple[v];
}

export function transaction_state_simple(cell, formatterParams) {
    let row = cell.getRow().getData();
    let v = cell.getValue();              
    if (v === undefined) return "";
    if (v === 2 && row["cnt"] < 6) v = 1; //Pending
    return enums.transaction_state_simple[v];
}

export function percentS(v) {
    return v + "%";
}
