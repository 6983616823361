import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import './styles/fonts/Inter-VariableFont_slnt,wght.ttf'
import "./styles/css/index.global.css"

import { PageUser } from './components/page-user';
import PageDoc from './components/page-doc';
//import PageTestZlibDict from './components/page-test-zlib-dict';

import { LanguageListener } from './localization/select-language';

import { Theme, ThemeListener } from './styles/select-theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { globalThemeLight, globalThemeDark } from './styles/material';

import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/css/toastify.css"

import { wspriv } from "./ws-priv/ws-priv";

import { NewsBanner } from "./ws-pub/news";

import { strings } from './localization/strings';

import { isMobile } from "react-device-detect";

import { parseParams, isTestEnv, exchangeUrl } from "./utils/utils";

class App extends React.Component {
  /*constructor(props) {
    super(props);
    //this.state = { width: 0 };
    //this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }*/

  /*componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }*/
  render() {    
    let referral = parseParams(window.location.hash, 'rfr='); //referral id
    if (referral) {document.cookie = "rfr=" + referral + ";domain=viking.trade;path=/";}
    
    let telegram = parseParams(window.location.hash, 'tgr='); //telegram id
    if (telegram) {document.cookie = "tgr=" + telegram + ";domain=viking.trade;path=/";}
    
    let reset_2fa = parseParams(window.location.hash, 'r2fa=');
    if (reset_2fa) {wspriv.reset_2fa = reset_2fa;}
    
    let verify_device = parseParams(window.location.hash, 'dcf=');
    if (verify_device) {wspriv.verify_device = verify_device;}

    let click_id = parseParams(window.location.hash, 'click_id='); //for marketing
    if (click_id) {localStorage["click_id"] = click_id;}
    
    if (referral || telegram || reset_2fa || verify_device || click_id) window.location.hash = "";

    let mobile = isMobile;
    //console.log(window.location.hostname);
    /*let mobile = false;
    if (this.state.width < 800) mobile = true;*/
    return <ThemeProvider theme={ Theme.current === 'light' ?  globalThemeLight : globalThemeDark }>
      {isTestEnv && <a style={{textDecoration: "none"}} href={exchangeUrl}><div className="sticky-top p1b col-white">
        {strings.TestEnv}
      </div></a>}
      <NewsBanner/>
      <LanguageListener onChange={()=>this.forceUpdate()}/>
      <ThemeListener onChange={()=>this.forceUpdate()}/>
      <BrowserRouter>
        <Switch>
          {null/*<Route path="/zlib"><PageTestZlibDict/></Route>*/}
          <Redirect from={`/user/Menu/ApiDocumentation`} to={'/doc/api'}/>
          <Redirect from={`/user/Menu/description`} to={'/doc/description'}/>
          <Route path="/doc"><PageDoc mobile={mobile}/></Route>
          <Route path="/user"><PageUser mobile={mobile}/></Route>
          <Redirect from="/api" to="/doc/api" />
          <Redirect to="/user"/>
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        transition={Zoom}
        closeOnClick
        pauseOnHover
      />
    </ThemeProvider>
  }
}

ReactDOM.render(
//  <React.StrictMode>
    <App />,
//  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
