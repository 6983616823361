/* eslint-disable eqeqeq */
import _ from "lodash";

class PageVisibility {
    constructor() {
        //console.log(zstream);
        //console.log(inflate_1);
        this.listeners = [];
        this.listeners_visible = true;
        this.visible = true;
        this.time_lost_visibility = 0;
        this.focus = true;
        this.time_lost_focus = 0;
        this.user_doing_something_important = false;
        this.time_user_started_doing_something_important = 0;
        let self = this;
        document.addEventListener("visibilitychange", ()=>{
            if (document.hidden) {
                self.visible = false;
                self.time_lost_visibility = Date.now();
                console.log("pageVisible", self.visible);
                self.onChange(false);
            } else  {
                self.visible = true;
                self.focus = true;
                console.log("pageVisible", self.visible);
                this.time_user_started_doing_something_important = Date.now();
                self.onChange(true);
            }
        }, false);

        window.addEventListener('focus', function() {
            self.focus = true;
            console.log("focus", self.focus);
            this.time_user_started_doing_something_important = Date.now();
            self.onChange(true);
        }, false);
        
        window.addEventListener('blur', function() {
            self.focus = false;
            console.log("focus", self.focus);
            self.time_lost_focus = Date.now();
            self.onChange(false);
        }, false);
    }

    addListener(f) {
        this.listeners.push(f);
        //this.listeners.forEach((f)=>{f()});
    }
    
    removeListener(f) {
        this.listeners = _.reject(this.listeners, (l) => l === f);
    }

    onChange(visible) {
        if (visible != this.listeners_visible) {
            let self = this;
            self.listeners_visible = visible;
            self.listeners.forEach((f)=>{f(visible)});
        }
    }

    userStartedDoingSmthImportant() {
        this.user_doing_something_important = true;
        this.time_user_started_doing_something_important = Date.now();
    }

    userStoppedDoingSmthImportant() {
        this.user_doing_something_important = false;
    }
};

export const pageVisibility = new PageVisibility();
