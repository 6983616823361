/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
//import { withSize } from "react-sizeme";

import {strings} from '../localization/strings';
//import { withSize } from "react-sizeme";
import { withFilter, parseParams } from '../utils/utils'

//import Instruments from '../ws-pub/instruments'

import { OBTable } from "../ws-priv/orderbook";
import { NewOrder } from "../ws-priv/new-order";
import { Chart as ChartTrades } from "../ws-pub/chart-trades";
import { withInsDefinitions/*, InstrumentsTable*/, InstrumentInfo, BestBid, BestOffer } from "../ws-pub/instruments";

import { TradesPubOwn1SecTable } from "../ws-priv/trades-pub-own";

import '../styles/css/control.css';

import { IChevronUp, IChevronDown, IArrowUp, IArrowDown } from '../styles/icons';

import { Button } from "../components/button";

const components = {
  NewOrder: NewOrder,
  Orderbook: OBTable,
  Chart: ChartTrades,
  InstrumentInfo: InstrumentInfo,
  AllTrades: TradesPubOwn1SecTable,
}

const open_next = {
  NewOrder: "AllTrades",
  Orderbook: "NewOrder",
  Chart: "Orderbook",
  InstrumentInfo: "Chart",
  AllTrades: "InstrumentInfo",
}


function NewOrderButton(props) {
  let classes = "p4b col-white " + (props.dir === "BuyOrder" ? "bg-green" : "bg-red");
  return <Button 
    onClick={props.onClick}
    className={classes}>
    {props.dir === "BuyOrder" ? <IArrowUp/> : <IArrowDown/>}{'\u00A0'}{strings[props.dir]}
  </Button>
}

function ToggleBar(id, name, props, opened_name, setOpened) {
  delete props.id;
  let opened = opened_name === name;
  let Component = components[name];
  let show_buttons = opened && name !== "NewOrder";
  let style = opened ? {
      height: "calc(100% - " + (31 * 5 - 1 + (show_buttons ? 80 : 0)) + "px)", 
      overflow: "auto", 
      transition: "0.2s",
      maxWidth: name !== "Chart" ? "390px" : "auto",
      position: "relative",
    } : {
      height: "0px",
      position: "relative",
      overflow: "hidden",
      transition: "0.2s"
    };
  return <React.Fragment>
    <div className="control-toggle cursor-pointer"
      onClick={()=>{
        if (opened) name = open_next[name];
        setOpened(name); props.onSaveData({opened: name});
      }}
      onTouchEnd={(e)=>{
        e.preventDefault();
        if (opened) name = open_next[name];
        setOpened(name); props.onSaveData({opened: name});
      }}
      >
      <div className="p3 col-grey">{strings[name]}</div>
      {opened ? <IChevronUp size="20"/> : <IChevronDown size="20"/>}
    </div>
    <div style={style}>
      {!(!opened && name !== "NewOrder") && <Component id={id + "_" + name} {...props}/>}
    </div>
    {show_buttons && <React.Fragment>
      <div className="sec-control-prices" style={{maxWidth: name !== "Chart" ? "387px" : "auto"}}>
        <div className="p3 col-grey"><BestBid sid={props.filter.sid}/></div>
        <div className="p3 col-grey"><BestOffer sid={props.filter.sid}/></div>
      </div>
      <div className="sec-control-buttons" style={{maxWidth: name !== "Chart" ? "387px" : "auto"}}>
        <div><NewOrderButton dir='BuyOrder' onClick={()=>{setOpened("NewOrder"); props.onSaveData({opened: "NewOrder"});}}/></div>
        <div><NewOrderButton dir='SellOrder' onClick={()=>{setOpened("NewOrder"); props.onSaveData({opened: "NewOrder"});}}/></div>
      </div>
    </React.Fragment>}   
  </React.Fragment>;
}

function InstrumentControl_(props) {
  //console.log("InstrumentControl", props.filter.sid);
  const [opened, setOpened] = React.useState(props.saved_data && props.saved_data.opened ? props.saved_data.opened : "NewOrder");
  const props_copy = _.cloneDeep(props);
  //console.log("InstrumentControl", props);
  /*React.useEffect(() => {
      if (!Instruments.all.hasOwnProperty(props.filter.sid)) 
          console.log("useEffect", Instruments.all.hasOwnProperty(props.filter.sid));
          console.log(props.filter.sid);
          console.log(Instruments.all);
          props.onFilterChanged({sid: Instruments.arr[0].sid});
  }, []);*/
  React.useEffect(() => {//for mobile version jump from orderbook to new order
    let sid = parseInt(parseParams(window.location.hash, 'sid='));
    //console.log("useEffect", sid, props.filter.sid);
    if (sid > 0 && sid != props.filter.sid) {
      props.onFilterChanged({sid: sid});
    }
  }, []);
	return <div className="control">
    {ToggleBar(props.id, "NewOrder", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "Orderbook", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "Chart", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "InstrumentInfo", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "AllTrades", props_copy, opened, setOpened)}
  </div>
}

export const InstrumentControl = withFilter(withInsDefinitions(InstrumentControl_));
