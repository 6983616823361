import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";
import Symbols from "../ws-pub/symbols";
//import { strings } from '../localization/strings';
import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
//import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import { copyObject } from "../utils/utils"
import {
  sec_id,
  dec,
  decS,
  dt_ns_ms,
} from "../utils/cell-formatters";
import { withFilter } from '../utils/utils'

export class Positions {
  static addListener(f) {
    this.listeners.push(f);
    //f(Positions.arr); //snapshot
  }
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }

  static request() {
    wspriv.send({"P": "g_pos"});
    Positions.requested = true;
    Positions.arrived = false;
    Positions.update = false;
  }

  static parse(msg) {
    var positions = [];
    if (msg["D"].hasOwnProperty("poss")) {
      Positions.arrived = true;
      Positions.all = {};
      Positions.arr = [];
      positions = msg["D"]["poss"];
    }
    if (msg["D"].hasOwnProperty("pos")) positions.push(msg["D"]["pos"]);
    if (positions.length > 0) {
      Positions.update = true;
      positions.forEach(function (pos) {
        pos.id = pos["aid"] + "_" + pos["sid"];
        if (!Positions.all.hasOwnProperty(pos.id)) {
          Positions.all[pos.id] = _.cloneDeep(pos);
          Positions.arr.push(Positions.all[pos.id]);
        } else {
          copyObject(Positions.all[pos.id], pos);
        }
      });
      this.listeners.forEach((f) => {f(_.cloneDeep(positions))}); // update
      return true;
    }
    return false;
  }
}

Positions.arr = [];
Positions.all = {};
Positions.listeners = [];
Positions.columns = [
  {title:"AccID", field:"aid"},
  {title:"Instrument", field:"sid", formatter: sec_id},
  {title:"Pos", field:"pos", formatter:dec},
  //{title:"LiqPrice", field:"liq_price"},
  {title:"UpdateTime", field:"ut", formatter: dt_ns_ms},
];

export class PositionsListener extends React.Component {
  componentDidMount() {Positions.addListener(this.props.onChange)}
  componentWillUnmount() {Positions.removeListener(this.props.onChange,)}
  render() {
    return null;
  }
}

//HOC
export function withPosition(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {Positions.addListener(this.onChange)}
    componentWillUnmount() {Positions.removeListener(this.onChange)}
    onChange() {
      this.forceUpdate();
    }
    render() {
      let id = this.props.aid + '_' + this.props.sid;
      let pos = Positions.all[id] ? Positions.all[id].pos : 0;
      return <WrappedComponent pos={decS(pos)} {...this.props} />;
    }
  };
}

class PositionsTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.tab = null;
  }

  initTable() {
    //console.log("initTable");
    if (Symbols.ready && this.tabRef.current && this.tab == null) {
      let div = this.tabRef.current;
      div.id = this.props.id;
      let cols = this.props.filter.cols;
      //console.log(cols);
      if (cols === '_all_' || cols === undefined) cols = Positions.columns;
      else {
        cols = cols.map((title)=>_.find(Positions.columns, { 'title': title }));
      }  
      this.tab = new TableX("#" + div.id, {
        columns: cols,
      });
      if (wspriv.authorized) {
        this.onChange(_.cloneDeep(Positions.arr));
      }
    }
  }

  componentDidMount() {
    //if (this.tab == null) console.log(this.props.id, "componentDidMount");
    this.initTable();
  }

  componentWillUnmount() {
    //console.log(this.props.id, "componentWillUnmount");
    if (this.tab) this.tab.destroy();
    this.tab = null;
  }

  onChange(positions) {
    //console.log("positions onChange", positions);
    if (!this.tab) this.componentDidMount();
    else {
      let sids = this.props.filter.sids;
      let aids = this.props.filter.aids;
      if (sids === '_all_' && aids === '_all_') {
        //console.log(_.cloneDeep(this.tab.rows), _.cloneDeep(positions));
        this.tab.updateOrAddData(_.cloneDeep(positions), true);
      } else {
        positions.forEach((pos)=>{
          if ((sids.indexOf(pos["sid"]) !== -1 || sids === '_all_') && (aids.indexOf(pos["aid"]) !== -1 || aids === '_all_')) this.tab.updateOrAddData([_.cloneDeep(pos)], true);
        });
      }
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(Positions.requested, Positions.arrived, Positions.update);
    //console.log(this.props.filter);
    //console.log(Positions.all);
    return <React.Fragment>
      <PositionsListener sids={this.props.filter.sids} onChange={this.onChange} />
      <div ref={this.tabRef} />
    </React.Fragment>
  }
}

export const PositionsTable = withFilter(withWSPrivKey(withSize({ monitorHeight: true })(PositionsTable_))
);

export const Position = withPosition((props)=>{
  return <React.Fragment>{props.pos}</React.Fragment>;
})

export function getPosition(aid, sid) {
  let id = aid + "_" + sid;
  return Positions.all[id] ? decS(Positions.all[id].pos) : 0;
}