import React from "react";
import _ from "lodash";
import { Orders, OrdersListener } from '../ws-priv/orders'
import { withSize } from "react-sizeme";
import Symbols, { SymbolsListener } from "../ws-pub/symbols";
//import { strings } from '../localization/strings';
import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";
import { enums as enums_en } from '../localization/strings_en'
import TableX from "../utils/table-x";
import { withFilter } from '../utils/utils'

export const ActiveColumns = _.reject(Orders.columns, {field:"cr"});

class ActiveTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onSymbolsChange = this.onSymbolsChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.tab = null;
  }

  initTable() {
    //console.log("initTable");
    if (this.tab == null && this.tabRef.current) {
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      let cols = this.props.filter.cols;
      //console.log(cols);
      if (cols === '_all_' || cols === undefined) cols = ActiveColumns;
      else {
        cols = cols.map((title)=>_.find(ActiveColumns, { 'title': title }));
      }  
      this.tab = new TableX("#" + div.id, {
        columns: cols,
      });
      if (Orders.max_time > 0 && Symbols.ready && wspriv.connected && wspriv.authorized) {
        self.subscribtion = new Subscribtion({
          ws: wspriv,
          smsg: {"P": "g_aor", "D": {
            //"sids": self.props.filter.sids === '_all_' ? null : self.props.filter.sids,
            "aids": self.props.filter.aids === '_all_' ? null : self.props.filter.aids,
          }},
          eternal: false,
          onResult: function(msg) {
            msg["D"]["ors"].forEach(function (order) {
              order.id = order["sid"] + '_' + order["ono"];
            });
            //console.log("Subscribtion",  msg["D"]["ors"]);
            if (msg["D"]["ors"].length > 0) self.onChange({ors: msg["D"]["ors"]});   
          }, 
          onError: function(msg) {
            console.error(msg);
          }
        });
      } 
    }
  }

  componentDidMount() {
    //console.log("componentDidMount");
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onSymbolsChange() {
    //console.log("onSymbolsChange");
    this.componentDidMount();
  }

  onChange(data) {
    //console.log("onChange", data);
    if (data.max_time > 0) {//reset table on reconnect
      this.componentWillUnmount();
      this.componentDidMount();
    }    
    if (this.tab) {
      let ors = [];
      data.ors.forEach((order)=>{
        var ss_str = enums_en.order_status[order["ss"]];
        if (!(ss_str === "Adding" || ss_str === "Canceled" || ss_str === "Filled" || order["act"] === "del")) {
          ors.push(order);
        } else if (ss_str === "Canceled" || ss_str === "Filled" || order["act"] === "del") {
          this.tab.deleteRow(order.id)
        }
      });
      let sids = this.props.filter.sids;
      let aids = this.props.filter.aids
      if (sids === '_all_' && aids === '_all_') {
        this.tab.updateOrAddData(ors, true);
      } else {
        ors.forEach((order)=>{
          if ((sids.indexOf(order["sid"]) !== -1 || sids === '_all_') && (aids.indexOf(order["aid"]) !== -1 || aids === '_all_')) this.tab.updateOrAddData([order], true);
        });
      }
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
      <OrdersListener sids={this.props.filter.sids} onChange={this.onChange} />
      <SymbolsListener onChange={this.onSymbolsChange} />
      <div ref={this.tabRef} />
      </React.Fragment>
  }
}

export const ActiveTable = withFilter(withSize({ monitorHeight: true })(ActiveTable_)
);