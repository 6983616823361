/* eslint-disable eqeqeq */
import React from "react";
import { strings } from '../localization/strings';

import { withFilter } from '../utils/utils'
import { getLim, /*Limits,*/ Accounts, withAccountsList } from "../ws-priv/accounts";

import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { SelectAid } from '../ws-priv/accounts';

import { SelectCid2 as SelectCid } from '../ws-pub/currencies';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { /*Button,*/ ButtonConfirm } from "../components/button";

import Currencies, { withCurrencies } from "../ws-pub/currencies"

import { LoginOrRegister } from "../ws-priv/login-or-register";


import '../styles/css/transfer.css';

import { 
//  IRefresh,
} from '../styles/icons';

import {
  dec,
  decS, 
  iDecCidS,
  dt_ns_ms,
  dt_ns_msS,
} from "../utils/cell-formatters";

import { toast } from 'react-toastify';

import TableX from "../utils/table-x";
import { getMinNsecTime } from '../utils/utils'

export class Transfers {
  static parse(msg) {
    return msg["D"]["tfrs"];
  }
}

Transfers.columns = [
  {title:"From", field:"f_aid"},
  {title:"To", field:"t_aid"},
  {title:"Amount", field:"amnt", formatter: dec, formatterS: decS},
  {title:"Currency", field:"cid"},
  {title:"CreateTime", field:"ct", formatter: dt_ns_ms, formatterS: dt_ns_msS},
];

export class ShowTransfers extends React.Component {
  constructor(props) {
    super(props);
    this.tabRef = React.createRef();
  }

  render() {
    let self = this;
    return <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <button {...bindTrigger(popupState)} className="p4b bg-blue">{this.props.children}</button>
          <Popover
            TransitionProps={{
              onEnter: ()=>{
                this.max_time = "18446744073709551615";
                let div = self.tabRef.current;
                div.id = "trans_" + this.props.id;
                self.tab = new TableX("#" + div.id, {
                  onScrollPromise: function(tab) {
                    return new Promise(function(resolve, reject){
                      self.subscribtion = new Subscribtion({
                        ws: wspriv,
                        smsg: {"P": "g_tfrs", "D":{"cids": [self.props.cid], "mt": self.max_time, "lim": 100}},
                        eternal: false,
                        onResult: function(msg) {
                          let tfrs = Transfers.parse(msg);
                          //console.log("Subscribtion", self.max_time, ors);
                          self.max_time = getMinNsecTime(tfrs, "ct", self.max_time);
                          if (tfrs.length > 0) resolve({data: tfrs});
                          else resolve({no_more_data: true, data: tfrs});
                        }, 
                        onError: function(msg) {
                          reject(msg);
                        }
                      });
                    });
                  },
                  columns: Transfers.columns,
                });
              },
              onExited: ()=>{
                if (self.tab) self.tab.destroy();
                self.tab = null;
              },
            }}               
            {...bindPopover(popupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={{vertical: 'top',horizontal: 'left',}}>
            <div className="default-table-popup">
              <div ref={self.tabRef}/>
            </div>       
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  }
}

//Transfer From To
export class Transfer_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    //this.cid = this.props.filter.cid; 
    this.cid = "BTC"; 
    this.step = Currencies.all[this.cid].step;
    this.state = {
      amount: this.step,
      aid1: null,
      aid2: null,
      anchorEl: null,
    }
  }

  componentDidMount() {
    Accounts.addListener(this.onChange);
  }

  componentWillUnmount() {
    this.will_unmount = true;
    Accounts.removeListener(this.onChange);
  }

  onChange() {
    if (!this.will_unmount) this.forceUpdate();
  }

  renderOneAccount(props) {
    return <div className="default-column transfer">
      <div>
        {strings.Transfer_.OnlyOneAcc}
      </div>
      <LoginOrRegister/>
    </div>;
  }

  render() {
    let self = this;
    //console.log(Accounts);
    if (!Accounts.main) return <LoginOrRegister/>;
    if (Accounts.own.length <= 1) this.renderOneAccount();
    let aid1 = this.state.aid1; 
    let aid2 = this.state.aid2; 
    let aid = this.props.filter.aid;
    aid1 = aid1 ? aid1 : (aid ? aid : Accounts.main["aid"]);
    if (!aid2 || aid2 === aid1) Accounts.own.some((acc)=>{if (acc.aid != aid1) {aid2 = acc.aid; return true;} return false;});
    //console.log("transfer", aid1, aid2);
    if (!aid1 && !aid2) return <LoginOrRegister/>;
    if (!aid1 || !aid2) return this.renderOneAccount();
    let lim1 = getLim(aid1, this.cid);
    let lim2 = getLim(aid2, this.cid);
    return <div>
      <div className="default-column transfer">
        <div className="transfer-bal">
          <SelectCid value={this.cid} onChange={(v)=>{self.cid = v.cid; self.forceUpdate();}}/>
        </div>
        <div>
          <div className="transfer-bal">
            <div className="p4b">{/*From*/strings.From}:</div>
            <div className="p4">{iDecCidS(lim1["lim"], lim1)}</div>
          </div>
          <div>
            <SelectAid value={aid1} own={true}
              onChange={(acc)=>{this.setState({aid1: acc["aid"]})}}/>
          </div>
        </div>
        <div>
          <div className="transfer-bal">
            <div className="p4b">{/*To*/strings.To}:</div>
            <div className="p4">{iDecCidS(lim2["lim"], lim2)}</div>
          </div>        
          <div>
            <SelectAid value={aid2} own={true}
              onChange={(acc)=>{this.setState({aid2: acc["aid"]})}}/>
          </div>
        </div>
        <div>
          <div className="p4b">{/*Amount*/strings.Amount}:</div>
          <input type="number" 
            step={this.step} 
            value={this.state.amount} 
            onChange={(e)=>this.setState({amount: e.target.value})} 
            onBlur={(e)=>this.setState({amount: decS(parseInt(e.target.value * 1e8 + 0.1))})}
            min={0}/>
        </div>
        <div className="transfer-percents p5b col-grey-40">
          {[5, 10, 25, 50, 100].map((p)=>{
            return <div key={p} onClick={()=>this.setState({amount: decS(parseInt((p / 100) * lim1["lim"]))})}>{p}%</div>})}
        </div>
        <div className="transfer-info">
            <ButtonConfirm onClick={()=>
              new Subscribtion({
                ws: wspriv,
                smsg: {"P": "t_acc", "D": {"f_aid": aid1, "t_aid": aid2, "amnt": parseInt(self.state.amount * 1e8 + 0.1), "cid": this.cid}},
                eternal: false,
                onResult: function(msg) {
                  //Limits.parse(msg);
                  toast.info(strings.Transfer_.Complete);
                }, 
                onError: function(msg) {
                }
              })}>{strings.Transfer_.PerformTransfer}
            </ButtonConfirm>
            {'\u00A0\u00A0'}
            <ShowTransfers id={this.props.id} cid={this.cid}>{strings.Transfer_.ShowTransfers}</ShowTransfers>
        </div>
      </div>
      <LoginOrRegister/>
    </div>
  }
}

export const Transfer = withFilter(withCurrencies(withAccountsList(Transfer_)));

export class TransferTo_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.cid = "BTC"; 
    this.step = Currencies.all[this.cid].step;
    this.state = {
      amount: this.step,
      aid1: props.filter.aid,
      aid2: null,
      anchorEl: null,
    }
  }

  componentDidMount() {
    Accounts.addListener(this.onChange);
  }

  componentWillUnmount() {
    this.will_unmount = true;
    Accounts.removeListener(this.onChange);
  }

  onChange() {
    if (!this.will_unmount) this.forceUpdate();
  }

  renderOneAccount(props) {
    return <div className="default-column transfer">
      <div>
        {strings.Transfer_.OnlyOneAcc}
      </div>
      <LoginOrRegister/>
    </div>;
  }

  render() {
    let self = this;
    if (!Accounts.main) return <LoginOrRegister/>;
    if (Accounts.own.length <= 1) return this.renderOneAccount();
    let aid1 = this.state.aid1; 
    let aid2 = this.state.aid2; 
    let aid = this.props.filter.aid;
    aid1 = aid1 ? aid1 : (aid ? aid : Accounts.main["aid"]);
    if (!aid2 || aid2 === aid1) Accounts.own.some((acc)=>{if (acc.aid != aid1) {aid2 = acc.aid; return true;} return false;});
    //console.log("transfer", aid1, aid2);
    if (!aid1 && !aid2) return <LoginOrRegister/>;
    if (!aid1 || !aid2) return this.renderOneAccount();
    let lim1 = getLim(aid1, this.cid);
    let lim2 = getLim(aid2, this.cid);
    return <div>
      <div className="default-column transfer">
        <div className="transfer-bal">
          <div className="p4b">{strings.Transfer + '\u00A0'}</div> 
          <SelectCid value={this.cid} onChange={(v)=>{self.cid = v.cid; self.forceUpdate();}}/>
        </div>
        <div>
          <div className="transfer-bal">
            <div className="p4b">{/*To*/strings.To}:</div>
            <div className="p4">{iDecCidS(lim2["lim"], lim2)}</div>
          </div>        
          <div>
            <SelectAid value={aid2} own={true}
              onChange={(acc)=>{this.setState({aid2: acc["aid"]})}}/>
          </div>
        </div>
        <div>
          <div className="p4b">{/*Amount*/strings.Amount}:</div>
          <input type="number" 
            step={this.step} 
            value={this.state.amount} 
            onChange={(e)=>this.setState({amount: e.target.value})} 
            onBlur={(e)=>this.setState({amount: decS(parseInt(e.target.value * 1e8 + 0.1))})}
            min={0}/>
        </div>
        <div className="transfer-percents p5b col-grey-40">
          {[5, 10, 25, 50, 100].map((p)=>{
            return <div key={p} onClick={()=>this.setState({amount: decS(parseInt((p / 100) * lim1["lim"]))})}>{p}%</div>})}
        </div>
        <div className="transfer-info">
            <ButtonConfirm onClick={()=>
              new Subscribtion({
                ws: wspriv,
                smsg: {"P": "t_acc", "D": {"f_aid": aid1, "t_aid": aid2, "amnt": parseInt(self.state.amount * 1e8 + 0.1), "cid": this.cid}},
                eternal: false,
                onResult: function(msg) {
                  //Limits.parse(msg);
                  toast.info(strings.Transfer_.Complete);
                }, 
                onError: function(msg) {
                }
              })}>{strings.Transfer_.PerformTransfer}
            </ButtonConfirm>
            {'\u00A0\u00A0'}
            <ShowTransfers id={this.props.id} cid={this.cid}>{strings.Transfer_.ShowTransfers}</ShowTransfers>
        </div>
      </div>
      <LoginOrRegister/>
    </div>
  }
}

export const TransferTo = withFilter(withCurrencies(withAccountsList(TransferTo_)));
