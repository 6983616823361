import React/*, {useState}*/ from 'react';

import { Button } from "../components/button";

import {withFilter} from '../utils/utils'

import { SelectSym2 as SelectSym, SelectSyms } from '../ws-pub/symbols';
import { SelectCid2 as SelectCid } from '../ws-pub/currencies';
import { SelectAid2 as SelectAid, SelectAids, TotalBalance } from '../ws-priv/accounts';
import { SelectHiss } from '../ws-priv/history';
import { SelectIid2 as SelectIid} from '../ws-pub/indexes';
import { strings } from '../localization/strings';
import { LastPrice } from "../ws-pub/instruments";
import SelectCols from '../components/select-cols';
import { DownloadPopup } from '../ws-priv/download';

import Checkbox from '../components/checkbox'
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import DateTimePicker from '../components/date-time-picker';
import moment from "moment";
import { 
	ISettings, 
//	IPlus, 
	ICross,
	IRefresh,
	IDownload, 
} from '../styles/icons';

import '../styles/css/grid-titlebar.css';

import { ToggleButton, ToggleButtonGroup }from '../components/toggle-button';

function withTitlebar_(WrappedComponent) {
  return function(props) {
	//console.log(props);
	moment.locale(strings.getLanguage());
	return <React.Fragment>
		<div className="g-i-titlebar g-i-drag">
			{/*Toggle priv table*/ props.select && props.select.indexOf("priv_table") > -1 && 
				<div className="g-i-titlebar-toggle"><ToggleButtonGroup
        value={props.filter.priv_table}
        onChange={(e, v)=>props.onFilterChanged({priv_table: v})}
        exclusive
        className="p3 toggle-btn-titlebar"
        >
        {[/*"ActiveOrders", */"Orders", "Trades", "Positions", "Stops"].map((key)=>{
          return <ToggleButton key={key} value={key}>{strings[key]}</ToggleButton>
        })}
      </ToggleButtonGroup></div>}
			<div className="g-i-titlebar-left">
				{/*Title*/ props.title && <span className="p3b col-night-sky">{strings[props.title]}</span>}
				<span className="g-i-no-drag">
					{/*Select sec*/ (props.select && (props.select.indexOf("sid_all") > -1 || props.select.indexOf("sid_active") > -1)) && <SelectSym 
						all={props.select.indexOf("sid_all") > -1}
						value={props.filter.sid}
						onChange={props.onFilterChanged}/>}
					{/*Select currency*/ (props.select && props.select.indexOf("cid") !== -1) && <SelectCid value={props.filter.cid}
						onChange={props.onFilterChanged}/>}
					{/*Select index*/ (props.select && props.select.indexOf("iid") !== -1) && <SelectIid value={props.filter.iid}
						onChange={props.onFilterChanged}/>}
					{/*Select account*/ (props.select && props.select.indexOf("aid") !== -1) && <SelectAid value={props.filter.aid}
						onChange={props.onFilterChanged}/>}
				</span>
				{/*Last price*/ props.last_price && <span className="p3b"><LastPrice sid={props.filter.sid}/></span>}
				{/*Total balance*/ props.total_balance && <React.Fragment>
					<span className="p4b">
						<TotalBalance/>
					</span>
				</React.Fragment>}
			</div>
			<div className="g-i-titlebar-right">
				{/*Download trades button*/ props.download_trades && <span className="g-i-no-drag"><DownloadPopup trades item={props}><IDownload/></DownloadPopup></span>}
				{/*Download wallet button*/ props.download_wallet && <span className="g-i-no-drag"><DownloadPopup wallet item={props}><IDownload/></DownloadPopup></span>}
				{/*Download account button*/ props.download_account && <span className="g-i-no-drag"><DownloadPopup account item={props}><IDownload/></DownloadPopup></span>}
				{/*Refresh button*/ props.refreshable && <span 
					className="g-i-no-drag"
					onClick={props.onRefresh}>
						<IRefresh/></span>}
				{null /*Add*/ /*props.onCopyItem && <span 
					className="g-i-no-drag" 
					onClick={props.onCopyItem}>
					<IPlus/>
					</span>*/}
				{/*Filters*/ props.filters && <PopupState variant="popover">
			      {(popupState) => (
			        <React.Fragment>
			          <span className="g-i-no-drag" {...bindTrigger(popupState)}>
								<ISettings/>
							  </span>
			          <Popover {...bindPopover(popupState)}
				            anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
				            transformOrigin={{vertical: 'top',horizontal: 'center',}}>
				          <div className="default-column p4">
							  		{/*Filter show index price*/ props.filters.indexOf("show_index_price")  > -1 && 
							        <div>
		  					        <Checkbox label={strings.ShowIndexPrice} checked={props.filter.show_index_price} onChange={(e)=>props.onFilterChanged({show_index_price: !props.filter.show_index_price})}/>
		  					      </div>}
							  		{/*Filter show mark price*/ props.filters.indexOf("show_mark_price")  > -1 && 
							        <div>
		  					        <Checkbox label={strings.ShowMarkPrice} checked={props.filter.show_mark_price} onChange={(e)=>props.onFilterChanged({show_mark_price: !props.filter.show_mark_price})}/>
		  					        </div>}
							  		{/*Filter show trades*/ props.filters.indexOf("show_trades")  > -1 && 
							        <div>
		  					        <Checkbox label={strings.ShowTrades} checked={props.filter.show_trades} onChange={(e)=>props.onFilterChanged({show_trades: !props.filter.show_trades})}/>
		  					        </div>}
							  		{/*Filter top toolbar*/ props.filters.indexOf("top_toolbar")  > -1 && 
							        <div>
		  					        <Checkbox label={strings.TopToolbar} checked={props.filter.top_toolbar} onChange={(e)=>props.onFilterChanged({top_toolbar: !props.filter.top_toolbar})}/>
						        </div>}
							  		{/*Filter left toolbar*/ props.filters.indexOf("left_toolbar")  > -1 && 
							        <div>
		  					        <Checkbox label={strings.LeftToolbar} checked={props.filter.left_toolbar} onChange={(e)=>props.onFilterChanged({left_toolbar: !props.filter.left_toolbar})}/>
						        </div>}
							  		{/*Filter sids*/ (props.filters.indexOf("sids_all") > -1 
							  								|| props.filters.indexOf("sids_active") > -1) && 
							        <div>
								  		<SelectSyms 
								  		label={strings.SelectInstruments}
											all={props.filters.indexOf("sids_all") > -1}
											value={props.filter.sids}
											onChange={props.onFilterChanged}
										/></div>}
							  		{/*Filter accounts*/ props.filters.indexOf("aids") > -1 && 
							        <div>
								  		<SelectAids 
											value={props.filter.aids}
											label={strings.SelectAids}
											onChange={props.onFilterChanged}
										/></div>}
							  		{/*Filter history titles*/ props.filters.indexOf("hiss") > -1 && 
							        <div>
								  		<SelectHiss 
											value={props.filter.hiss}
											onChange={props.onFilterChanged}
										/></div>}
								  	{/*Filter dt*/ props.filters.indexOf("dt") > -1 && <React.Fragment>
											<Checkbox label={strings.SelectDatetime} checked={props.filter.dt >= 0} onChange={(e)=>props.onFilterChanged({dt: -props.filter.dt})}/>
											<DateTimePicker 
												variant="outlined"
												ampm={false}
												disabled={props.filter.dt < 0}
												format={'YYYY MMM Do, HH:mm'}
												value={moment.utc(Math.abs(props.filter.dt) * 1000)} 
												onChange={(e)=>props.onFilterChanged({dt: moment.utc(e.format('YYYY-MM-DD HH:mm:ss')).unix()})} />
										</React.Fragment>}
							  		{/*Filter cols*/ props.filters.indexOf("cols") > -1 && 
							        <div>
								  		<SelectCols 
								  			label={strings.SelectColumns}
												component_={props.component_}
												value={props.filter.cols}
												onChange={props.onFilterChanged}
										/></div>}
		  					    {/*Reset saved data*/ props.filters.indexOf("reset_chart")  > -1 && 
							        <div>
		  					        <Button className="bg-blue" onClick={()=>props.onResetSavedData()}>{strings.ResetChart}</Button>
		  					        </div>}		  					        
									</div>								
				        </Popover>
			        </React.Fragment>
			      )}
			    </PopupState>					
				}
				{/*Remove*/ props.onRemoveItem && <span 
					className="g-i-no-drag" 
					onClick={props.onRemoveItem}>
					<ICross/>
				</span>}
			</div>
		</div>  
		<div style={{height: "calc(100% - " + props.rowHeight + "px)", overflow: "auto", position: "relative"}}>
			<WrappedComponent {...props}/>
		</div>
	</React.Fragment>
	}
}

export default function withTitlebar(props) {
	return withFilter(withTitlebar_(props));
}
