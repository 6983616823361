import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";
import { strings, codes } from '../localization/strings';
import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
import TableX from "../utils/table-x";
import { ExchangeTime } from "../ws-pub/exchange-time";
import Subscribtion from "../utils/subscribtion";
import {
  //message_topic,
  //dec,
  dt_ns_s,
  //json_formatter,
  //admin,
  string_code,
} from "../utils/cell-formatters";
import { withFilter, getMinNsecTime } from '../utils/utils'
import SelectMultiple from '../components/select-multiple';

import { LoginOrRegister } from "../ws-priv/login-or-register";


export class History {
  static addListener(f) {
    this.listeners.push(f);
  }
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }

  static parse(msg) {
    let hiss = null;
    if (msg["D"].hasOwnProperty("hiss")) {hiss = msg["D"]["hiss"];}
    if (msg["D"].hasOwnProperty("his")) {hiss = []; hiss.push(msg["D"]["his"]);};
    if (hiss != null) {
      //hiss.forEach(function (his) {
      //});
      if (msg.hasOwnProperty("I")) {
          return hiss;
      }
      if (hiss.length > 0)
        this.listeners.forEach((f) => {f(_.cloneDeep(hiss))}); // Update
    }
    return null;
  }
}

History.listeners = [];
History.columns = [
  //{title:"Topic", field:"P", formatter: message_topic},
  //{title:"Commission", field:"cm", formatter:dec},
  //{title:"Data", field:"D", formatter: json_formatter},
  //{title:"AccID", field: "aid"},
  {title:"Time", field:"ct", formatter: dt_ns_s},
  {title:"Message", field:"c", formatter: string_code},
  //{title:"User", field:"uid"},
  //{title:"Admin", field:"ad", formatter: admin},
];

export class HistoryListener extends React.Component {
  componentDidMount() {History.addListener(this.props.onChange)}
  componentWillUnmount() {History.removeListener(this.props.onChange,)}
  render() {
    return null;
  }
}

export function SelectHiss(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    let hiss = [];
    //let labels = {};
    _.each(codes, function(s, c) {
      if (c[0] === 'H') {
        hiss.push(c);
        /*let first_word = s.split(' ')[0];
        let l = first_word.length;
        if (first_word[l - 1] === ':') labels[c] = first_word.slice(0, l - 1);
        else labels[c] = first_word + " " + s.split(' ')[1]; //first_word + second_word*/
      }
    });
    return <SelectMultiple
      multiple
      label={props.label ? props.label : strings.SelectMassages}
      value={value}
      renderValue={(selected) => value.length === 0 ? strings.AllMessages : selected.join(', ')}
      options={hiss.map((c)=>{return {label: codes[c], value: c}})}
      onChange={(value)=>props.onChange({hiss: value.length === 0 ? '_all_' : value})}
    />
}

class HistoryTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.tab = null;
    this.loadOnScroll = 100;
    this.filter = props.filter;
  }

  initTable() {
    //console.log("initTable", this.props.id);
    if (this.tab == null && this.tabRef.current && wspriv.authorized && wspriv.connected && ExchangeTime.now > 0) {
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      let cols = this.props.filter.cols;
      //console.log(cols);
      let dt = this.props.filter.dt;
      if (dt < 0) {
        this.max_time = ExchangeTime.now + '000000000';
      } else {
        this.max_time = dt + '000000000';
      }

      if (cols === '_all_' || cols === undefined) cols = History.columns;
      else {
        cols = cols.map((title)=>_.find(History.columns, { 'title': title }));
      }  
      this.tab = new TableX("#" + div.id, {
        onScrollPromise: function(tab) {
          return new Promise(function(resolve, reject){
            self.subscribtion = new Subscribtion({
              ws: wspriv,
              smsg: {"P": "g_his", "D": {"mt": self.max_time, "lim": self.loadOnScroll, "cs": (self.filter.hiss === '_all_' ? null : self.filter.hiss)}},
              eternal: false,
              onResult: function(msg) {
                let hiss = History.parse(msg);
                self.max_time = getMinNsecTime(hiss, "ct", self.max_time);
                if (hiss.length > 0) resolve({data:hiss});
                else  resolve({no_more_data: true, data:hiss});
              }, 
              onError: function(msg) {
                reject(msg);
              }
            });
          });
        },
        columns: cols,
      });
      this.tab.setSort("ct", "desc");
    }
  }

  componentDidMount() {
    //console.log("componentDidMount");
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onChange(hiss) {
    //console.log("onChange", hiss);
    if (!this.tab) this.componentDidMount();
    else this.tab.updateOrAddData(hiss, true);
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
      <HistoryListener sids={this.props.filter.sids} onChange={this.onChange} />
      <div ref={this.tabRef} />
      <LoginOrRegister/>
    </React.Fragment>
  }
}

export const HistoryTable = withFilter(withWSPrivKey(withSize({ monitorHeight: true })(HistoryTable_))
);