import React from 'react';
//import _ from "lodash";
import { strings } from '../localization/strings';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { Button } from "../components/button";

import { IChevronDown, IChevronUp, IEmpty, ICloudUpload, ILayout } from '../styles/icons';

import { Theme } from '../styles/select-theme';
import { col } from '../styles/colors';

import '../styles/css/menu-left.css';

export default function MenuLeft(props) {
  const [openTemplates, setOpenTemplates] = React.useState(false);
  //const [openMisk, setOpenMisk] = React.useState(false);
  let key = 0;
  //console.log(_.cloneDeep(props.layout));
  //console.log(_.cloneDeep(props.layout.items));
  return <div className="menu-left"><div>
      <List
        component="nav"
      >
        {props.layout.items.map((item)=><ListItem 
          key={++key}
          button
          onClick={()=>{props.self.onAddItem(item)}}>
          <ListItemIcon>
            {new item.icon({size: "48", color: Theme.current === "light" ? col.grey_5 : col.grey_light_7})}
          </ListItemIcon>
          <ListItemText primary={item.title ? strings[item.title] : strings[item.component_]}/>
          {null/*<IPlus size="16"/>*/}
        </ListItem>)}      
        {null /*props.layout_misk.items.map((item)=><ListItem 
          key={++key}
          button
          
          onClick={()=>{props.self.onAddItem(item)}}>
          <ListItemIcon>
            {new item.icon({size: "48", color: Theme.current === "light" ? col.grey_5 : col.grey_light_7})}
          </ListItemIcon>
          <ListItemText primary={item.title ? strings[item.title] : strings[item.component_]}/>
        </ListItem>)*/}
        <ListItem button onClick={()=>setOpenTemplates(!openTemplates)}>
          <ListItemIcon>
            <ILayout size="48" color={Theme.current === "light" ? col.grey_5 : col.grey_light_7}/>
          </ListItemIcon>
          <ListItemText primary={strings.Layouts} />
          {openTemplates ? <IChevronUp size="20"/> : <IChevronDown size="20"/>}
        </ListItem>
        <Collapse in={openTemplates} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button 
              onClick={()=>{props.self.onResetLayout()}}>
              <ListItemIcon>
                <IEmpty size="48"/>
              </ListItemIcon>
              <ListItemText primary={strings.BasicLayout} />
            </ListItem>
            {[1, 2, 3].map((i)=><ListItem key={i} button 
                onClick={()=>{props.self.loadLayoutFromBackend("layout" + i)}}>
                <ListItemIcon>
                  <IEmpty size="48"/>
                </ListItemIcon>
                <ListItemText primary={strings.OwnLayout + " " + i} />
                <ListItemSecondaryAction>
                  <Button 
                    className="bg-blue"
                    onClick={()=>{props.self.saveLayoutToBackend("layout" + i)}}><ICloudUpload/></Button>
                </ListItemSecondaryAction>
              </ListItem>)
            }
          </List>
        </Collapse>
        {null /*<ListItem button style={{zIndex: "101"}} onClick={()=>setOpenMisk(!openMisk)}>
          <ListItemIcon>
            <ITrades size="48"/>
          </ListItemIcon>
          <ListItemText primary={strings.Misc} />
          {openMisk ? <IChevronUp size="20"/> : <IChevronDown size="20"/>}
        </ListItem>
        <Collapse in={openMisk} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.layout_misk.items.map((item)=><ListItem 
              key={++key}
              button
              
              onClick={()=>{props.self.onAddItem(item)}}>
              <ListItemIcon>
                {new item.icon()}
              </ListItemIcon>
              <ListItemText primary={item.title ? strings[item.title] : strings[item.component_]}/>
            </ListItem>)}     
          </List>
        </Collapse>*/}
      </List>
    </div>
  </div>
}
