import React from "react";
//import { strings } from '../localization/strings';
import { getMinNsecTime } from '../utils/utils'

import TableX from "../utils/table-x";

//import { Button } from "../components/button";

import { wspriv/*, withWSPrivKey*/ } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import {
  dec,
  decS, 
  dt_ns_ms,
  dt_ns_msS,
  //transaction_state,
  transaction_direction,
  transaction_directionS,
  transaction_state_simple,
  transaction_state_simpleS,
} from "../utils/cell-formatters";


export class Transactions {
  static parse(msg) {
    return msg["D"]["trns"];
  }
}

Transactions.columns = [
  {title:"Dir", field:"io", width:40, hozAlign:"center", formatter: transaction_direction, formatterS: transaction_directionS},
  {title:"Status", field:"stt", formatter: transaction_state_simple, formatterS: transaction_state_simpleS},
  {title:"Confirmations", field:"cnt"},
  {title:"Amount", field:"amnt", formatter: dec, formatterS: decS},
  {title:"Comment", field:"ll"},
  {title:"Currency", field:"cid"},
  //{title:"Status", field:"stt", formatter: transaction_state},
  {title:"CreateTime", field:"ct", formatter: dt_ns_ms, formatterS: dt_ns_msS},
  {title:"TransactionID", field:"txid"},
  {title:"Address", field:"did"},
  {title:"Account", field:"aid"},
];

export class ShowTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.tabRef = React.createRef();
  }

  render() {
    let self = this;
    return <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <button {...bindTrigger(popupState)} className="p4b bg-blue">{this.props.children}</button>
          <Popover
            TransitionProps={{
              onEnter: ()=>{
  					    this.max_time = "18446744073709551615";
                let div = self.tabRef.current;
                div.id = "trans_" + this.props.id;
                self.tab = new TableX("#" + div.id, {
                  onScrollPromise: function(tab) {
                    return new Promise(function(resolve, reject){
                      self.subscribtion = new Subscribtion({
                        ws: wspriv,
                        smsg: {"P": "g_trns", "D":{"io": self.props.io, "cids": [self.props.cid], "mt": self.max_time, "lim": 100}},
                        eternal: false,
                        onResult: function(msg) {
                          let trns = Transactions.parse(msg);
                          //console.log("Subscribtion", self.max_time, ors);
                          self.max_time = getMinNsecTime(trns, "ct", self.max_time);
                          if (trns.length > 0) resolve({data: trns});
                          else resolve({no_more_data: true, data: trns});
                        }, 
                        onError: function(msg) {
                          reject(msg);
                        }
                      });
                    });
                  },
                  columns: Transactions.columns,
                });
              },
              onExited:()=>{
                if (self.tab) self.tab.destroy();
                self.tab = null;
              },
            }}               
            {...bindPopover(popupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={{vertical: 'top',horizontal: 'left',}}>
            <div className="default-table-popup">
              <div ref={self.tabRef}/>
            </div>       
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  }
}
