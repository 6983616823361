//{'\u00A0'}
//onClick={popupState.close}
//<Link className="p2 col-white-pen-2" to="/api">{strings.APIDocumentation}</Link>
import React from 'react';
//import _ from "lodash";

import { api, description } from '../localization/strings';
import { SelectLanguage } from '../localization/select-language';
import { SelectTheme } from '../styles/select-theme';
import { APIDocumentation } from "../components/api-documentation";

import '../styles/css/page-user.css';
import '../styles/css/page-user-m.css';
import '../styles/css/page-api.css';

import { 
	IViking, 
	IVikingWhite,
	IMenu,
} from '../styles/icons';

import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

function BtnUser(props) {
  return <PopupState variant="popover">
    {(popupState) => (
      <div>
        <div {...bindTrigger(popupState)} className="cursor-pointer"><IMenu/></div>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
          transformOrigin={{vertical: 'top',horizontal: 'center',}}>
          <div className="default-popup">
						<div><SelectLanguage/></div>
						<div><SelectTheme/></div>
	        </div>
        </Popover>
      </div>
    )}
  </PopupState>;
}

function PageDocDesktop(props) {
	let match = useRouteMatch();
	//console.log("grid render", this.state.grid_key, this.layout);
	//return <div style={{width: "calc(99.9%)"}}>
	return <div className="page-user">
		<div className="page-user-header page-api-header">
			<div className="page-user-header-left">
				<Link to="/user"><IViking className="page-user-header-icon"/></Link>
				<span className="htitle col-white-pen-2"><b>Viking Trade</b> Futures Exchange</span>
			</div>
			<div className="page-user-header-mid">
			</div>
			<div className="page-user-header-right">
				<BtnUser/>
			</div>
		</div>
		<div className="page-api-mid">
			{<Switch>
        <Route path={`${match.path}/description`}>
			    <APIDocumentation api={description}/>
        </Route>
        <Route path={`${match.path}/api`}>
			    <APIDocumentation api={api}/>
        </Route>
        <Redirect to={`${match.path}/api`}/>
      </Switch>}
     	{null /*<Switch>
        <Route path={`${match.path}/doc`}>
			    <APIDocumentation api={doc}/>
        </Route>
	      <Redirect strict path={`${match.path}/`} to={match.path}/>
        <Route path={`${match.path}`}>
			    <APIChooseVersion/>
			    <APITester/>
        </Route>
      </Switch>*/}
		</div>
		<div className="page-api-footer">
			<div className="page-api-footer-left p3 col-white-pen-2">
				© 2021 Viking Trade
			</div>
			<div className="page-api-footer-right">
				<Link to="/user"><IVikingWhite className="page-user-header-icon"/></Link>
			</div>
		</div>
	</div>
}

function PageDocMobile(props) {
	let match = useRouteMatch();
	//console.log("grid render", this.state.grid_key, this.layout);
	//return <div style={{width: "calc(99.9%)"}}>
	return <div className="page-m-user">
		<div className="page-user-m-header">
			<div className="page-user-m-header-left">
					<Link to="/user"><IViking className="page-user-m-header-icon" size="21"/></Link>
				</div>
			<div className="page-user-m-header-mid">
			</div>
			<div className="page-user-m-header-right">
				<BtnUser/>
			</div>
		</div>
		<div className="page-api-mid">
			{<Switch>
        <Route path={`${match.path}/description`}>
			    <APIDocumentation api={description}/>
        </Route>
        <Route path={`${match.path}/api`}>
			    <APIDocumentation api={api}/>
        </Route>
        <Redirect to={`${match.path}/api`}/>
      </Switch>}
     	{null /*<Switch>
        <Route path={`${match.path}/doc`}>
			    <APIDocumentation api={doc}/>
        </Route>
	      <Redirect strict path={`${match.path}/`} to={match.path}/>
        <Route path={`${match.path}`}>
			    <APIChooseVersion/>
        </Route>
      </Switch>*/}
		</div>
	</div>
}

export default function PageDoc(props) {
	return props.mobile ? <PageDocMobile/> : <PageDocDesktop/>;
}
