/* eslint-disable eqeqeq */
import React from "react";
//import _ from "lodash";

import { strings } from '../localization/strings';

import '../styles/css/wallet.css';

import { ToggleButton, ToggleButtonGroup } from '../components/toggle-button';
import { SelectCid2 as SelectCid } from '../ws-pub/currencies';

import Currencies from "../ws-pub/currencies";
import { Deposit } from '../ws-priv/deposit';
import { Withdraw } from '../ws-priv/withdraw';
//import { Transfer } from '../ws-priv/transfer';

export function Wallet(props) {
	return <div className="wallet">
    <div style={{padding: "10px"}}>
      <SelectCid value={props.filter.cid}
        onChange={props.onFilterChanged}/>
    </div>
    <div className="wallet-toggles"> 
  		<ToggleButtonGroup
        value={props.filter.action}
        onChange={(e, v)=>props.onFilterChanged({action: v})}
        exclusive
        className="p3 toggle-btn-1"
        >
        {["Deposit", "Withdraw"/*, "Transfer"*/].map((key)=>{
          return <ToggleButton key={key} value={key}>{strings[key]}</ToggleButton>
        })}
      </ToggleButtonGroup>
  		{ null /*Currencies.arr.length > 1 && <ToggleButtonGroup
        value={props.filter.cid}
        onChange={(e, v)=>props.onFilterChanged({cid: v})}
        exclusive
        className="p3 toggle-btn-1"
        >
        {Currencies.arr.map((key)=>{
          return <ToggleButton key={key} value={key}>{key}</ToggleButton>
        })}
      </ToggleButtonGroup>*/}
    </div>
    <div style={{
      position: "relative",
      //height: "calc(100% - " + (Currencies.arr.length > 1 ? "58" : "29") +  "px)",
      height: "calc(100% - 72px)",
      overflow: "auto",
    }}>
      {props.filter.action === "Deposit" && <Deposit {...props}/>}
      {props.filter.action === "Withdraw" && <Withdraw {...props}/>}
      {null/*props.filter.action === "Transfer" && <Transfer {...props}/>*/}
    </div>
	</div>;
}