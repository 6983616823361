import _ from "lodash";
//import { withSize } from "react-sizeme";

//import {strings} from '../localization/strings';
import { wspub } from "../ws-pub/ws-pub";
import Subscribtion from "../utils/subscribtion";
//import Instruments, {InstrumentsListener} from "../ws-pub/instruments";

export class Candles {
  constructor(sid, resolution) {
    this.listeners = [];
    this.sid = sid;
    this.resolution = resolution;
  }

  addListener(f) {
    let self = this;
    //console.log("addListener " + this.listeners.length);
    if (this.listeners.length === 0) {
      this.subscribtion = new Subscribtion({//subscribe on candles updates
        ws: wspub,
        smsg: {"P": "s_cs" + self.resolution, "D": {"sids": [self.sid]}},
        eternal: true,
        onResult: function(msg) {
          //console.log("onTick");
          self.listeners.forEach((f) => {f(msg["D"]["cs"])}); // update
        },
      });
    }
    this.listeners.push(f);
    //f(); //snapshot
  }

  removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
    if (this.listeners.length === 0) {
      this.subscribtion.unsubscribe();
    }
  }

  static parse(msg) {
    if (msg["P"].slice(0, 4) === "s_cs" && msg["Y"] === "u") {
      let resolution = msg["P"].slice(4);
      let sid = msg["D"]["cs"][0][6]; //first candle sid
      let id = sid + '_' + resolution;
      if (this[id]) this[id].listeners.forEach((f) => {f(msg["D"]["cs"])}); // update
      return true;
    }
    return false;
  }

  static addListener(f, sid, resolution) {
    let id = sid + '_' + resolution;
    if (!this.hasOwnProperty(id)) {
      this[id] = new Candles(sid, resolution);
    }
    this[id].addListener(f);
  }
  static removeListener(f, sid, resolution) {
    let id = sid + '_' + resolution;
    this[id].removeListener(f);
    if (this[id].listeners.length === 0) {
      delete this[id];
    }
  }

  static get(sid, resolution, bt, et, onResult, onError) {
    new Subscribtion({
      ws: wspub,
      smsg: {"P": "g_cs" + resolution, "D": {"sids": [sid], "bt": bt, "et": et}},
      eternal: false,
      onResult: function(msg) {onResult(msg["D"]["cs"])}, 
      onError: function(msg) {onError(msg)},
    });
  }
}