import React from 'react';
import { strings } from '../localization/strings';
//import _ from "lodash";
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { ButtonConfirm2FA as ButtonConfirm2FAOrig, ButtonConfirm2FADialog } from '../ws-priv/two-fa';

import { isMobile } from "react-device-detect";

import '../styles/css/button-confirm.css';

export function Button(props) {
  return <button
    onTouchStart={(e)=>{console.log("onTouchStart");}}
    onClick={(e)=>{
      props.onClick();
    }} 
    onTouchEnd={(e)=>{
      e.preventDefault();
      props.onClick();
    }} 
    className={props.className}  
  >{props.children}
  </button>
}

export function ButtonConfirm(props) {
  return <PopupState variant="popover">
    {(popupState) => (
      <React.Fragment>
        <button {...bindTrigger(popupState)} className="p4b bg-blue">{props.children}</button>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          <div className="default-popup popup-confirm">
            <div>
              <div className="p4">{props.text ? props.text : strings.AreYouSure}</div>
            </div>
            <div className="confirm-buttons">
              <Button onClick={(event)=>{
                props.onClick();
                popupState.close();
              }} 
                className="p4b bg-blue">
                {strings.Ok}
              </Button>
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </div>        
        </Popover>
      </React.Fragment>
    )}
  </PopupState>; 
}

export function ButtonConfirm2FA(props) {
  return <ButtonConfirm2FADialog {...props}/>;
  //if (isMobile) return <ButtonConfirm2FADialog {...props}/>
  //else return <ButtonConfirm2FAOrig {...props}/>
}


