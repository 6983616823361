//import React/*, {useState}*/ from 'react';
import _ from "lodash";
import { createTheme } from "@material-ui/core";
import { col } from '../styles/colors';

let lightRoot = {
  root: {
    backgroundColor: col.white,
    borderRadius: '8px',
    fontFamily: 'Inter',
  },
  //rounded : {borderRadius: "8px"}
};

let darkRoot = {
  root: {
    color: col.grey_light_5,
    backgroundColor: col.grey_dark, /*col.grey_darker*/
    borderRadius: '8px',
    fontFamily: 'Inter',
  },
  //rounded : {borderRadius: "8px"}
};

let commons = {overrides: {
  MuiPaper: {rounded : {borderRadius: "8px"}},
  /*MuiInputBase:{
    root:{fontFamily: 'Inter', fontSize: '14px', display: 'block'},
    input:{height: 'auto'}
  },
  MuiInput:{
    underline:{
      '&:before':{borderBottom: 'none'},
      '&:after':{borderBottom: 'none'},
      '&:hover:not(.Mui-disabled):before':{borderBottom: 'none'},
      '&.Mui-disabled:before':{borderBottom: 'none'},
      '&.Mui-disabled:after':{borderBottom: 'none'},
    }
  },*/
  MuiFormLabel:{root:{fontFamily: 'Inter', fontSize: '14px'}},
  MuiTypography:{
    subtitle1:{fontFamily: 'Inter', fontSize: '14px'},
    body2:{fontFamily: 'Inter', fontSize: '14px'},
    h4:{fontFamily: 'Inter', fontSize: '20px'},
    h3:{fontFamily: 'Inter', fontSize: '20px'},
    body1:{fontFamily: 'Inter', fontSize: '14px'},
  },
  MuiListItem: {
    root: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    gutters: {
      paddingLeft: "6px",
      paddingRight: "12px",
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: "58px",
    },
  },
  MuiListItemSecondaryAction: {
    root: {
      right: "12.5px",
    },
  },
}};

let themeLight = _.merge({overrides:{
  MuiPaper: lightRoot,
  MuiFormLabel: lightRoot,
  MuiInputBase: lightRoot,
  MuiList: lightRoot,  

  //Pickers
  PrivateTabIndicator: {colorSecondary: {backgroundColor: col.blue}},
  MuiPickersClock: {pin: {backgroundColor: col.blue}},
  MuiPickersClockPointer: {pointer: {backgroundColor: col.blue}, noPoint: {backgroundColor: col.blue}, thumb: {backgroundColor: col.blue, border: '14px solid ' + col.blue}},
  MuiPickersToolbar: {toolbar: {backgroundColor: col.grey_40,},},
  MuiPickerDTTabs: {
    tabs: {
      backgroundColor: col.grey_40,
      color: col.night_sky,
    },
  },
  MuiPickersToolbarText: {
    toolbarBtnSelected: {color: col.night_sky},
    toolbarTxt: {color: col.night_sky,},
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      //backgroundColor: lightBlue.A200,
      //color: "white",
    },
    iconButton: {
      backgroundColor: 'inherit',
    }
  },
  MuiButton: {
    textPrimary: {
      color: col.grey,
    },
  },
  MuiTypography: {colorPrimary: {color: col.blue}},
  MuiPickersDay: {
    /*day: {
      color: lightBlue.A700,
    },*/
    daySelected: {
      backgroundColor: col.blue,
    },
    /*dayDisabled: {
      color: lightBlue["100"],
    },*/
    current: {
      color: col.blue,
    },
  },
  /*MuiPickersModal: {
    dialogAction: {
      color: lightBlue["400"],
    },
  },*/
}}, commons)


let themeDark = _.merge({overrides: {
  MuiPaper: darkRoot,
  MuiFormLabel: darkRoot,
  MuiInputBase: darkRoot,
  MuiList: darkRoot,

  //Pickers
  PrivateTabIndicator: {colorSecondary: {backgroundColor: col.blue}},
  MuiPickersClock: {pin: {backgroundColor: col.blue}, clock: {color: col.grey_light_5}},
  MuiPickersClockPointer: {pointer: {backgroundColor: col.blue}, noPoint: {backgroundColor: col.blue}, thumb: {backgroundColor: col.blue, border: '14px solid ' + col.blue}},
  MuiPickersToolbar: {toolbar: {backgroundColor: col.grey_40,},},
  MuiPickerDTTabs: {
    tabs: {
      backgroundColor: col.grey_40,
      color: col.grey_light_5,
    },
  },
  MuiPickersToolbarText: {
    toolbarBtnSelected: {color: col.grey_light_5},
    toolbarTxt: {color: col.grey_light_5,},
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      //backgroundColor: lightBlue.A200,
      //color: "white",
    },
    iconButton: {
      backgroundColor: col.grey_dark,
    },
    dayLabel: {
      color: col.grey_light_5,
    },
  },
  MuiButton: {
    textPrimary: {
      color: col.grey_light_5,
    },
    label: {
      color: col.grey_light_5,
    }
  },
  MuiTypography: {colorPrimary: {color: col.grey_light_5}},
  /*MuiPickersYear: {
    yearSelected: {
      color: col.grey_dark,
    },
  },*/
  MuiPickersDay: {
    /*day: {
      color: lightBlue.A700,
    },*/
    daySelected: {
      backgroundColor: col.blue,
      /*border: "1px solid white",*/
    },
    /*dayDisabled: {
      color: lightBlue["100"],
    },*/
    current: {
      color: col.grey_light_5,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      color: col.grey_light_5,
    },
  },
  MuiIconButton: {
    label: {
      color: col.grey_light_5,
    },
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      backgroundColor: "inherit",
    },
  },
  /*.MuiPickersClock-clock, .MuiPickersToolbarText-toolbarTxt, .MuiPickersToolbarText-toolbarBtnSelected, ., .': {
    color: col.grey_light_5,
  }*/
  /*MuiPickersModal: {
    dialogAction: {
      color: lightBlue["400"],
    },
  },*/
}}, commons)

export const globalThemeLight = createTheme(themeLight);

export const globalThemeDark = createTheme(themeDark);
