import React from 'react';

import '../styles/css/toggle-button.css';

export function ToggleButton(props) {
	return <div>{props.children}</div>
}

export function ToggleButtonGroup(props) {
	//console.log(props);
	return <div className={props.className}>{props.children.map((child)=>{
		return <div key={child.key} 
			onClick={(e)=>props.onChange(e, child.props.value)}
			onTouchEnd={(e)=>{
				e.preventDefault();
				props.onChange(e, child.props.value)
			}}
			className={props.value === child.props.value ? "selected col-grey" : "col-grey-40"}>
			{child.props.children}
		</div>})}
	</div>
}