import React from 'react';
import _ from "lodash";
import moment from "moment";

//Parser
export class ExchangeTime {
    static parse(msg) {
        if (msg.hasOwnProperty("T")) {
            this.now = msg["T"]; //sync time
            this.last_sync_time = Date.now();
            this.listeners.forEach((f)=>{f(true)}); //sync
            if (ExchangeTime.interval) {
              clearInterval(ExchangeTime.interval);
              ExchangeTime.interval = null;
            }
            if (this.interval == null) {//tick secondly
                this.listeners.forEach((f)=>{f()});
                this.now += 1;
                var self = this;
                this.interval = setInterval( function() {
                    if (self.now > 0) {
                        self.listeners.forEach((f)=>{f()});
                        self.now += 1;
                    }
                }, 1000);
            }
            return true;
        }
        return false;
    }

  static addListener(f) {this.listeners.push(f);}
  static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

ExchangeTime.last_sync_time = 0; //seconds epoch
ExchangeTime.now = 0; //seconds epoch
ExchangeTime.interval = null;
ExchangeTime.listeners = [];

//HOC
export function withExchangeTime(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.state = {
        time: ExchangeTime.now
      };
    }

    componentDidMount() {ExchangeTime.addListener(this.handleChange)}
    componentWillUnmount() {ExchangeTime.removeListener(this.handleChange)}
    handleChange() {this.setState({time: ExchangeTime.now})}

    render() {
      return <WrappedComponent time={this.state.time} {...this.props} />;
    }
  };
}

//Component
function Time(props) {
    let time = moment.utc(props.time * 1000).format('HH:mm:ss')
    return <div className="col-grey-light">{time} (UTC)</div>;
}

export default withExchangeTime(Time);