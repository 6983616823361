/* eslint-disable eqeqeq */
import React from 'react';
import _ from "lodash";
import { format } from '../localization/strings';
import { wspub } from "../ws-pub/ws-pub";

export default class News {
	static request() {
	    wspub.send({"P":  "g_n"});
	}

	static parse(msg) {
	  if (msg["D"].hasOwnProperty("nws")) {
	  		News.arr = [];
	  		msg["D"]["nws"].forEach((n)=>News.arr.push(n));
	  		this.listeners.forEach((f) => {f()}); // update
	      return true;
	  }
	  return false;
	}

  static addListener(f) {
    this.listeners.push(f);
  }

  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }
}

News.listeners = [];
News.arr = [];

export class NewsBanner extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {News.addListener(this.onChange)}
  componentWillUnmount() {News.removeListener(this.onChange)}

  onChange() {
  	this.forceUpdate();
  }

  render() {
  	if (News.arr.length == 0) return null;
  	return <React.Fragment>{News.arr.map((n)=><div key={n.n} className="sticky-top p1b col-white">
        {format(n.n, n.p)}
      </div>)}</React.Fragment>
  }
}
