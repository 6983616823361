/* eslint-disable eqeqeq */
import React, { useRef } from "react";
import _ from "lodash";
import { strings, enums } from '../localization/strings';
import { withSize } from "react-sizeme";
import { withFilter, noExponent, end_of_day } from '../utils/utils'
import { OBTable } from "../ws-pub/orderbook";
import { wspriv } from "../ws-priv/ws-priv";
import { Accounts, SelectAid, withAccountsList } from "../ws-priv/accounts";
import { Position, getPosition } from "../ws-priv/positions";
import Instruments, { withInsDefinitions, BestBid, BestOffer } from "../ws-pub/instruments";
import { 
  decS,
  formatQuantity,
  formatPrice,
 } from "../utils/cell-formatters";

import '../styles/css/new-order.css';

import { Button } from "../components/button";

import { IArrowUp, IArrowDown } from '../styles/icons';

import Checkbox from '../components/checkbox'
import { ToggleButton, ToggleButtonGroup }from '../components/toggle-button';

import moment from "moment";

import DateTimePicker from '../components/date-time-picker';

import { LoginOrRegister } from "../ws-priv/login-or-register";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

function orderType(vals) {
  if (vals.header ==  'Limit') {
    return strings.Limit
  } else if (vals.header ==  'Market') {
    return strings.Market
  } else if (vals.header ==  'Stops') {
    return (strings[vals.activation_type == '1' ? "Stop" : "Profit"] + "-" + strings[vals.order_type == '0' ? "Limit" : "Market"]);
  }
}

function sendNewOrder(dir, vals, price) {
    //console.log(dir, vals, price);
    //const now = Date.now(); // for ext_id
    let str = '{"P": "n_or", "D": {' + 
      '"aid": ' + vals.aid + ', ' + 
      '"sid": ' + vals.sid + ', ' +
      '"q": ' + parseInt(vals.quantity * 1e8 + 0.1) + ', ' +
      '"d": ' + (dir == "BuyOrder" ? '1' : '2') + ', ' +
      //'"eid": "' + (now) + '", ' +
      '"oy": ' + vals.order_type + ', ' +
      '"ay": ' + vals.activation_type + ', ' +
      '"ll": "' + vals.comment + '"';
    if (vals.order_type == '0') {
        str += ', ' +
      '"p": ' + parseInt(price * 1e8 + 0.1);
        str += ', ' +
      '"tif": ' + vals.time_in_force;
        str += ', ' +
      '"m": ' + vals.only_maker;
    }
    if (vals.order_type == '0' && vals.time_in_force == '3') {
        str += ', ' +
      '"ed": ' + (vals.expiration !== '' ? vals.expiration : 0);
    }
    if (vals.order_type == '0' && vals.iceberg) {
        str += ', ' +
      '"vq": ' + parseInt(vals.visible_quantity * 1e8 + 0.1);
    }
    if (vals.activation_type == '1' || vals.activation_type == '2') {
        str += ', ' +
      '"ap": ' + parseInt(vals.activation_price * 1e8 + 0.1);
    }    
    str += '}}';
    wspriv.send(str);
}

function NewOrder_(props) {
    //console.log("NewOrder", props.id);
    //console.log(this.props.filter);
    //console.log(this.props.size);
    let ls = Instruments.all[props.filter.sid] ? Instruments.all[props.filter.sid].ls : 0;
    const [price, setPrice] = React.useState('');
    const [vals, setValues] = React.useState({
      header: 'Limit',
      activation_type: '0',
      order_type: '0',
      direction: '',
      quantity: decS(ls),
      time_in_force: '0',
      aid: Accounts.arr.length > 0 ? Accounts.arr[0].aid : null,
      sid: props.filter.sid,
      activation_price: '',
      expiration: end_of_day.unix(),
      only_maker: false,
      iceberg: false,
      visible_quantity: decS(ls),
      comment: '',
    });

    //console.log("render", vals);

    const handleInput = (prop) => (event) => {
      let val = event.target.value;
      if (prop == 'comment' && val.length > 6) return;
      //if (prop == 'activation_price') val = formatPrice(vals.sid, val);
      //if (prop == 'quantity') val = formatQuantity(vals.sid, val);
      //if (prop == 'visible_quantity') val = formatQuantity(vals.sid, val);
      setValues({ ...vals, [prop]: val });
    };

    const handleToggle = (prop) => (event, val) => {
      console.log("handleToggle", prop, event.target, val);
      if (val != null) setValues({ ...vals, [prop]: val });
    };

    const handleHeader = (header, order_type, activation_type) => (event) => {
      event.preventDefault();
      setValues({ ...vals, 'header': header, 'order_type': order_type, 'activation_type': activation_type });
    };

    const anchorRef = useRef(null);

    let size = _.clone(props.size);
    size.height -= 82;

    if (!Instruments.all[props.filter.sid]) {
      return null;
    }

    return <div className="new-order-grid" ref={anchorRef}>
      <div className="new-order-account"><SelectAid value={vals.aid} onChange={(aid)=>setValues({ ...vals, ...aid})}/></div>
      <div className="new-order-header p3 toggle-btn-1">
        <div 
          className={vals.header == 'Limit' ? 'selected col-grey' : 'col-grey-40'}
          onClick={handleHeader('Limit', '0', '0')}
          onTouchEnd={handleHeader('Limit', '0', '0')}
          >{strings.Limit}</div>
        <div 
          className={vals.header == 'Market' ? 'selected col-grey' : 'col-grey-40'}
          onClick={handleHeader('Market', '1', '0')}
          onTouchEnd={handleHeader('Market', '1', '0')}
          >{strings.Market}</div>
        <PopupState variant="popover">
          {(popupState) => (
            <React.Fragment>
              <div {...bindTrigger(popupState)}
                className={vals.header == 'Stops' ? 'selected col-grey' : 'col-grey-40'}
                >{vals.header !== 'Stops' ? strings.Stops : orderType(vals)}</div>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="new-order-stops"
                  onClick={popupState.close}
                  onTouchEnd={popupState.close}
                >
                  <div 
                    onClick={handleHeader('Stops', '1', '1')}
                    onTouchEnd={handleHeader('Stops', '1', '1')}
                    className={vals.header == 'Stops' && vals.order_type == '1' && vals.activation_type == '1' ? 'col-grey' : 'col-grey-40'}>
                    {strings.Stop + "-" + strings.Market}</div>
                  <div 
                    onClick={handleHeader('Stops', '0', '1')}
                    onTouchEnd={handleHeader('Stops', '0', '1')}
                    className={vals.header == 'Stops' && vals.order_type == '0' && vals.activation_type == '1' ? 'col-grey' : 'col-grey-40'}>
                    {strings.Stop + "-" + strings.Limit}</div>
                  <div 
                    onClick={handleHeader('Stops', '1', '2')}
                    onTouchEnd={handleHeader('Stops', '1', '2')}
                    className={vals.header == 'Stops' && vals.order_type == '1' && vals.activation_type == '2' ? 'col-grey' : 'col-grey-40'}>
                    {strings.Profit + "-" + strings.Market}</div>
                  <div 
                    onClick={handleHeader('Stops', '0', '2')}
                    onTouchEnd={handleHeader('Stops', '0', '2')}
                    className={vals.header == 'Stops' && vals.order_type == '0' && vals.activation_type == '2' ? 'col-grey' : 'col-grey-40'}>
                    {strings.Profit + "-" + strings.Limit}</div>
                </div>
              </Popover>
            </React.Fragment>
          )}
        </PopupState>
      </div>
      <div className="new-order-mid">
    		<div className="new-order-mid-left">
          <OBTable 
            id={props.id}
            filter={{sid: vals.sid, "cols": ["Price", "Size"], "show_index_price": true, "show_mark_price": false }}
            size={size}
            depth={5}
            rowHeight={props.rowHeight}
            rowClick={(p)=>setPrice(decS(p))}
          />
    		</div>
    		<div className="new-order-mid-right">
          <div className="new-order-mid-right-qty">
            <div className="p4b col-grey">{/*Quantity*/strings.QTY}:</div>
            <div 
              onClick={()=>setValues({ ...vals, "quantity": formatQuantity(vals.sid, getPosition(vals.aid, vals.sid))
              })}
              onTouchEnd={(e)=>{
                e.preventDefault();
                setValues({ ...vals, "quantity":  formatQuantity(vals.sid, getPosition(vals.aid, vals.sid))
              })}}
              className="p4 col-grey cursor-pointer"
            ><Position aid={vals.aid} sid={vals.sid}/>{'\u00A0'}{strings.All}</div>
          </div>
          <div>
            <input type="number" 
              value={noExponent(vals.quantity)}
              step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ls * 1e-8 : 1}
              min={0}
              onChange={handleInput('quantity')}
              onBlur={(event)=>setValues({ ...vals, 'quantity': formatQuantity(vals.sid, event.target.value)})}
              placeholder={/*strings.Quantity*/null}
              label={strings.Quantity} variant="outlined" 
            />
          </div>
          <div className="new-order-mid-right-percents p5b col-grey-40">
            {[5, 10, 25, 50, 100].map((p)=>{
              return <div key={p} 
              onClick={()=>setValues({ ...vals, "quantity":  formatQuantity(vals.sid, Accounts.all[vals.aid].avl / Instruments.all[vals.sid].im * p / 100)
              })}
              onTouchEnd={(e)=>{
                e.preventDefault();
                setValues({ ...vals, "quantity":  formatQuantity(vals.sid, Accounts.all[vals.aid].avl / Instruments.all[vals.sid].im * p / 100)
              })}}
              >{p}%</div>})}
          </div>
          {/*Activation Price*/ vals.header == 'Stops' && <React.Fragment>
            <div className="p4b col-grey">{strings.ACTPRICE}:</div>
            <div>
              <input type="number" 
                placeholder={/*strings.ActPrice*/null}
                value={noExponent(vals.activation_price)}
                min={0}
                step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ms * 1e-8 : 1}
                onChange={handleInput('activation_price')}
                onBlur={(event)=>setValues({ ...vals, 'activation_price': formatPrice(vals.sid, event.target.value)})}
                label={strings.ActPrice} variant="outlined" 
              />
            </div>
          </React.Fragment>}
          {/*Price*/ vals.order_type == '0' && <div>
            <div className="p4b col-grey">{strings.PRICE}:{'\u00A0'}</div>
            <input type="number" 
              value={noExponent(price)}
              min={0}
              step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ms * 1e-8 : 1}
              onChange={(event)=>setPrice(event.target.value)}
              onBlur={(event)=>setPrice(formatPrice(vals.sid, event.target.value))}
            />
          </div>}
          {/*Time in force*/ (vals.header == 'Limit' || (vals.header == 'Stops' && vals.order_type == '0')) && <React.Fragment>
            {null/*<div>{strings.TimeInForce}:</div>*/}
            <div>
              <ToggleButtonGroup
                value={vals.time_in_force}
                onChange={handleToggle('time_in_force')}
                exclusive
                aria-label="text alignment"
                className="p5b toggle-btn-dflt"
                >
                {Object.keys(enums.time_in_force).map((key)=>{
                  return <ToggleButton key={key} value={key}>{enums.time_in_force[key]}</ToggleButton>
                })}
              </ToggleButtonGroup>
            </div>
          </React.Fragment>}
          {/*Expiration*/ (vals.header == 'Limit' || (vals.header == 'Stops' && vals.order_type == '0')) && vals.time_in_force == '3' && <React.Fragment>
            <div className="p4b col-grey">{strings.EXPDATE}:{'\u00A0'}</div>
            <div>
              <DateTimePicker 
                variant="outlined" 
                ampm={false}
                disabled={vals.time_in_force !== '3'}
                format={'YYYY MMM Do, HH:mm'}
                value={moment.utc(Math.abs(vals.expiration) * 1000)} 
                onChange={(e)=>{let dt = moment.utc(e.format('YYYY-MM-DD HH:mm:ss')).unix(); setValues({ ...vals, 'expiration': dt })}} />
            </div>
          </React.Fragment>}
          {/*Only maker*/(vals.header == 'Limit' || (vals.header == 'Stops' && vals.order_type == '0')) && <div className="p4">
            <Checkbox
              right
              label={strings.OnlyMaker}
              checked={vals.only_maker}
              onChange={()=>setValues({ ...vals, 'only_maker': !vals.only_maker })}
            />
          </div>}
          {/*Is iceberg*/(vals.header == 'Limit' || (vals.header == 'Stops' && vals.order_type == '0')) && <div className="p4">
              <Checkbox
                right
                label={strings.Iceberg}
                checked={vals.iceberg}
                onChange={()=>setValues({ ...vals, 'iceberg': !vals.iceberg })}
              />
            </div>}
          {/*Visible qty*/(vals.header == 'Limit' || (vals.header == 'Stops' && vals.order_type == '0')) && vals.iceberg && <div>
              <div className="p4b col-grey">{strings.VISQTY}:{'\u00A0'}</div>
              <input type="number" 
                disabled={!vals.iceberg}
                value={noExponent(vals.visible_quantity)}
                min={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ls * 1e-8 : 1}
                step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ls * 1e-8 : 1}
                onChange={handleInput('visible_quantity')}
                onBlur={(event)=>setValues({ ...vals, 'visible_quantity': formatQuantity(vals.sid, event.target.value)})}
                label={strings.VisQty} variant="outlined" 
              />
          </div>}
          {/*Comment<div>
            <div className="p4b col-grey">{strings.COMMENT}:{'\u00A0'}</div>
            <input type="text" 
              value={vals.comment}
              onChange={handleInput('comment')}
              variant="outlined" 
              />
          </div>*/}
    		</div>
      </div>
      <div>
        <div className="new-order-prices">
          <div className="p3 col-grey cursor-pointer" 
            onClick={()=>setPrice(decS(Instruments.all[vals.sid].bs))}
            onTouchEnd={(e)=>{
              e.preventDefault();
              setPrice(decS(Instruments.all[vals.sid].bs))
            }}
          ><BestOffer sid={vals.sid}/></div>
          <div className="p3 col-grey cursor-pointer" 
            onClick={()=>setPrice(decS(Instruments.all[vals.sid].bb))}
            onTouchEnd={(e)=>{
              e.preventDefault();
              setPrice(decS(Instruments.all[vals.sid].bb))
            }}
          ><BestBid sid={vals.sid}/></div>
        </div>
        <div className="new-order-footer">
          {/*Confirmation buttons*/['BuyOrder','SellOrder'].map((dir)=>{
  let classes = "p4b col-white cursor-pointer " + (dir == "BuyOrder" ? "bg-green" : "bg-red");
  return <PopupState key={dir} variant="popover">
    {(popupState) => (
      <div>
        <button {...bindTrigger(popupState)} className={classes}>{dir == "BuyOrder" ? <IArrowUp/> : <IArrowDown/>}{'\u00A0'}{strings[dir]}</button>
        <Popover
          {...bindPopover(popupState)}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <div className="new-order-confirm-title p3b">{/*Title*/orderType(vals) + '\u00A0' + strings.order}</div>
          <div className="new-order-confirm-mid default-popup">
            <div>
              <div className="p3">{/*Symbol*/strings.Symbol}:</div>
              <div className="p3b">{Instruments.all[vals.sid].sym}</div>
            </div>
            <div>
              <div className="p3">{/*Account*/strings.Account}:</div>
              <div className="p3b">{vals.aid}</div>
            </div>
            <div>
              <div className="p3">{/*Quantity*/strings.Quantity}:</div>
              <div>
                <input type="number" 
                  value={noExponent(vals.quantity)}
                  className={"new-order-confirm-quantity"}
                  step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ls * 1e-8 : 1}

                  onChange={(e)=>setValues({ ...vals, 'quantity': e.target.value})} 
                  onBlur={(e)=>setValues({ ...vals, 'quantity': formatQuantity(vals.sid, e.target.value)})}
                  min={0}
                />
              </div>
            </div>
            {/*Activation price */ (vals.activation_type == '1' || vals.activation_type == '2') && <React.Fragment>
              <div>
                <div className="p3">{strings.ActPrice}:</div>
                <div className="p3b">{vals.activation_price}</div>
              </div>
              <div>
                <div className="p3">{strings.Trigger}:</div>
                <div className="p3b">{strings.TopOfBook}</div>
              </div>            
              </React.Fragment>}
            {/*Price*/ vals.order_type == '0' && <div>
              <div className="p3">{strings.Price}:</div>
              <div>
                <input type="number" 
                className={"new-order-confirm-price"}
                  value={noExponent(price)}
                  min={0}
                  onChange={(e)=>setPrice(e.target.value)} 
                  onBlur={(e)=>setPrice(formatPrice(vals.sid, e.target.value))}
                  step={Instruments.all[vals.sid] ? Instruments.all[vals.sid].ms * 1e-8 : 1}
                />                
              </div>
            </div>}
            {/*TimeInForce*/ vals.order_type == '0' && vals.time_in_force > 0 && vals.time_in_force < 3 && <div>
              <div className="p3">{strings.TimeInForce}:</div>
              <div className="p3b">{enums.time_in_force_full[vals.time_in_force]}</div>
            </div>}
            {/*Expiration date*/ (vals.order_type == '0' && vals.time_in_force == 3) && <div>
              <div className="p3">{strings.Expiration}:</div>
              <div className="p3b">{moment.utc(Math.abs(vals.expiration) * 1000).format('YYYY MMMM Do, HH:mm')}</div>
            </div>}
            {/*Visible quantity*/ vals.order_type == '0' && vals.iceberg && <div>
              <div className="p3">{strings.VisQty}:</div>
              <div className="p3b">{vals.visible_quantity}</div>
            </div>}
            {/*Only maker*/ vals.order_type == '0' && vals.only_maker && <div className="p3b">{vals.only_maker && strings.OnlyMaker}</div>}
            {/*Comment<div>
              <div className="p3">{strings.Comment}:</div>
              <div className="p3b">{vals.comment}</div>
            </div>*/}
          </div>
          <div className="new-order-confirm-button">
            <Button className={classes} 
              onClick={()=>{sendNewOrder(dir, vals, price); popupState.close();}}
              onTouchEnd={(e)=>{
                e.preventDefault();
                sendNewOrder(dir, vals, price); popupState.close();
              }}
            >{dir == "BuyOrder" ? <IArrowUp/> : <IArrowDown/>}{'\u00A0'}{strings[dir]}</Button>
            <Button className="p4b col-white bg-blue cursor-pointer" 
              onClick={()=>{popupState.close();}}
              onTouchEnd={(e)=>{
                e.preventDefault();
                popupState.close();
              }}
            >{strings.Cancel}</Button>
          </div>
          <LoginOrRegister/>
        </Popover>
      </div>
    )}
  </PopupState>; 
  })}
        </div>
      </div>
    </div>
  }

export const NewOrder = withFilter(withInsDefinitions(withSize({ monitorHeight: true })(NewOrder_)));
