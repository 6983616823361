import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";
//import { strings } from '../localization/strings';
import TableX from "../utils/table-x";
import {
  dt_s,
} from "../utils/cell-formatters";
import { withFilter } from '../utils/utils'

import { toast } from 'react-toastify';

import { format } from "../localization/strings";

export class Log {
	static log(str) {
		str = {dt: Math.floor(new Date().getTime() / 1000), m: str};
		Log.snapshot.push(str);
		this.listeners.forEach((f) => {f([str])}); // update   
	}

	static errorMsg(msg) {
		let str = format(msg["D"]["c"], msg["D"]["p"])
		Log.error(str);
	}

  static error(str) {
    Log.log('[Error] ' + str);
    toast.error(str);
    console.error(str);
  }

	static debug(str) {
		Log.log('[Debug] ' + str);
		console.debug(str);
	}

	static warn(str) {
		Log.log('[Warn] ' + str);
    toast.warn(str);
		console.warn(str);
	}

	static info(str) {
		Log.log('[Info] ' + str);
    toast.info(str);
		console.info(str);
	}

  static success(str) {
    Log.log('[Success] ' + str);
    toast.info(str);
    console.info(str);
  }

  static addListener(f) {
    this.listeners.push(f);
    f(Log.snapshot); //snapshot
  }

  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }
}

Log.snapshot = [];
Log.listeners = [];

export class LogListener extends React.Component {
  componentDidMount() {Log.addListener(this.props.onChange)}
  componentWillUnmount() {Log.removeListener(this.props.onChange,)}
  render() {
    return null;
  }
}

class LogTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.tab = null;
    this.snapshot = null;
  }

  componentDidMount() {
  	if (this.tab == null && this.tabRef.current) {
	    let div = this.tabRef.current;
	    div.id = this.props.id;
	    this.tab = new TableX("#" + div.id, {
	      columns: [
	      	{title:"Time", field:"dt", formatter: dt_s},
					{title:"Message", field:"m", width: 1000},
				],
	    });
	    this.onChange(_.cloneDeep(Log.snapshot));   
	  }
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    this.tab = null;
  }

  onChange(data) {
    //console.log("onChange");
		if (this.tab) this.tab.updateOrAddData(data, true);
		else this.componentDidMount();
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props);
    return <React.Fragment>
      <LogListener filter={this.props.filter} onChange={this.onChange} />
      <div ref={this.tabRef} />
    </React.Fragment>
  }
}

export const LogTable = withFilter(withSize({ monitorHeight: true })(LogTable_));
