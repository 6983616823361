import React, { useRef } from "react";

import { withSize } from "react-sizeme";

import { Button } from "../components/button";

import { IndexAndEstimate, OBRows, getColsDepthHeightStyle } from "../ws-pub/orderbook";

import {strings} from '../localization/strings';
import { 
  decS,
  formatQuantity,
  formatPrice,
} from "../utils/cell-formatters";
import {withFilter} from '../utils/utils'

import Instruments, { withInsDefinitions } from "../ws-pub/instruments";

import { wspriv } from "../ws-priv/ws-priv";
import { IArrowUp, IArrowDown } from '../styles/icons';
import { Accounts, SelectAid } from "../ws-priv/accounts";
import { Position, getPosition } from "../ws-priv/positions";

import '../styles/css/orderbook.css';

import Popover from '@material-ui/core/Popover';
//import Dialog from '@material-ui/core/Dialog';

import { LoginOrRegister } from "../ws-priv/login-or-register";

import { pageVisibility } from "../utils/page-visibility";


function OBTable_ (props) {
  let sid = Instruments.all[props.filter.sid] ? props.filter.sid : Instruments.arr[0].sid;
  //console.log("OBTable_", props);
  const [cols, showIndex, indexHeight, depth, style] = getColsDepthHeightStyle(props);
  let col_keys = {};
  cols.forEach((col) => col_keys[col.field] = true);

  //for new order popover
  //console.log(sid);
  //console.log(Instruments.arr);
  const [aid, setAccount] = React.useState(Accounts.arr.length > 0 ? Accounts.arr[0].aid : null);
  const [price, setPrice] = React.useState(0);
  const [dir, setDir] = React.useState(1);
  const [quantity, setQuantity] = React.useState(decS(Instruments.all[sid].ls));
  const [open, setOpen] = React.useState(false);
  let setClose = ()=>{
    setOpen(false);
    pageVisibility.userStoppedDoingSmthImportant();
  };
  let rowClick = props.rowClick ? props.rowClick : (p, bids)=>{setDir(bids ? "SellOrder" : "BuyOrder"); setPrice(decS(p)); setOpen(true); pageVisibility.userStartedDoingSmthImportant();};
  const anchorRef = useRef(null);

  return <div className="t-ob col-grey" style={style} ref={anchorRef}>
    <table>
      <thead className="p4b">
        <tr>
          {cols.map((col) => {return <th key={col.field}>
            {strings[col.title]}
          </th>})}
        </tr>
      </thead>
      <tbody className="p4">
        <OBRows depth={depth} sid={sid} bids={false} col_keys={col_keys} rowClick={rowClick}/>
        {showIndex ? 
          <IndexAndEstimate height={indexHeight} sid={sid} filter={props.filter} cols={cols}/> : 
          <tr style={{height: "10px", width: "100%"}}><td colSpan={cols.length}/></tr>}
        <OBRows depth={depth} sid={sid} bids={true} col_keys={col_keys} rowClick={rowClick}/>
      </tbody>
    </table>
    <Popover
      open={open}
      onClose={()=>setClose()}
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <div>
        <div className="ob-order-title p3b">{/*Title*/strings.Limit + '\u00A0' + strings.order}</div>
      </div>
      <div className="ob-order">
        <div>
          <div className="p3">{/*Symbol*/strings.Symbol}:</div>
          <div className="p3b">{'\u00A0' + Instruments.all[sid].sym}</div>
        </div>
        <div>
          <div className="p3"></div>
          <div className="p3b"><SelectAid value={aid} onChange={(acc)=>setAccount(acc.aid)}/></div>
        </div>
        <div className="ob-order-qty">
          <div className="p4b col-grey">{/*Quantity*/strings.Quantity}:</div>
          <div 
            onClick={()=>setQuantity(formatQuantity(sid, getPosition(aid, sid)))}
            onTouchEnd={(e)=>{
              e.preventDefault();
              setQuantity(formatQuantity(sid, getPosition(aid, sid)))}}
            className="p4 col-grey cursor-pointer"
          ><Position aid={aid} sid={sid}/>{'\u00A0'}{strings.All}</div>
        </div>
        <div>
          <input type="number" 
            value={quantity}
            step={Instruments.all[sid] ? Instruments.all[sid].ls * 1e-8 : 1}
            onChange={(e)=>setQuantity(e.target.value)} 
            onBlur={(e)=>setQuantity(formatQuantity(sid, e.target.value))}
            min={0}
          />
        </div>
        <div className="ob-order-percents p5b col-grey-40">
          {[5, 10, 25, 50, 100].map((p)=>{
            return <div key={p} 
            onClick={()=>setQuantity(formatQuantity(sid, Math.abs(Accounts.all[aid].avl / Instruments.all[sid].im * p / 100)))}
            onTouchEnd={(e)=>{
              e.preventDefault();
              setQuantity(formatQuantity(sid, Math.abs(Accounts.all[aid].avl / Instruments.all[sid].im * p / 100)));
            }}
            >{p}%</div>})}
        </div>
        <div>
          <div className="p4b col-grey">{strings.PRICE}:{'\u00A0'}</div>
          <input type="number" 
            value={price}
            min={0}
            step={Instruments.all[sid] ? Instruments.all[sid].ms * 1e-8 : 1}
            onChange={(e)=>setPrice(e.target.value)} 
            onBlur={(e)=>setPrice(formatPrice(sid, e.target.value))}
          />                
        </div>
      </div>
      <div className="new-order-confirm-button">
        <Button className={"p4b col-white " + (dir === "BuyOrder" ? "bg-green" : "bg-red")} onClick={()=>{//send new order
            wspriv.send({"P": "n_or", "D": {
              "aid": aid,
              "sid": sid,
              "q": parseInt(quantity * 1e8 + 0.1),
              "d": dir === "BuyOrder" ? 1 : 2,
              //"eid": "" + Date.now() * 1000000,
              "oy": 0,
              "ay": 0,
              "p": parseInt(price * 1e8 + 0.1),
              "tif": 0}
            });
            setClose();
          }}
        >{dir === "BuyOrder" ? <IArrowUp/> : <IArrowDown/>}{'\u00A0'}{strings[dir]}</Button>
        <Button className="p4b col-white bg-blue" onClick={()=>{setClose()}}
        >{strings.Cancel}</Button>
      </div>
      <LoginOrRegister/>
    </Popover>    
  </div>
}

export const OBTable = withFilter(withInsDefinitions(withSize({ monitorHeight: true })(OBTable_)));
