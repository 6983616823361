import React from "react";

import { strings } from '../localization/strings';

import { Button } from "../components/button";

import MomentUtils from '@date-io/moment';
import {
  DateTimePicker as DTPicker ,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import moment from "moment";

import { dt_sS } from "../utils/cell-formatters";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import '../styles/css/date-time-picker.css';

export default function DateTimePicker(props) {
  const [time, setTime] = React.useState(props.value);
  return <div>
    <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <div {...bindTrigger(popupState)}>
            <input type="text" 
              value={dt_sS(props.value / 1000)}
              onChange={()=>null}
              />
          </div>
          <Popover
            TransitionProps={{
              onEnter: ()=>setTime(props.value),
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={{vertical: 'top',horizontal: 'left',}}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={strings.getLanguage()}>
              <DTPicker 
                variant="static"
                ampm={false}
                disabled={props.disabled}
                format={props.format}
                value={time} 
                onChange={setTime} />
            </MuiPickersUtilsProvider>
            <div className="date-time-picker-btns">
              <Button onClick={()=>{
                props.onChange(time);
                popupState.close();
                }}
                className="p4b bg-blue">
                {strings.Ok}
              </Button>
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  </div>
}

