import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";

import {strings} from '../localization/strings';
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import Subscribtion from "../utils/subscribtion";
import { 
  dec, 
  decS,
} from "../utils/cell-formatters";
import {withFilter} from '../utils/utils'
import Instruments, { withSecPrices } from "../ws-pub/instruments";

import '../styles/css/orderbook.css';

export class OB {
  constructor(sid) {
    this.listeners = [];
    this.sid = sid;
    this.clear();
    this.got_snapshot = false;
  }

  clear() {
    this.bids = {};
    this.bidsv = [];
    this.offers = {};
    this.offersv = [];
  }

  update(bids, ob, lvls, lvlsv) {
    if (ob.length > 0) {
      ob.forEach(function (lvl) {
        if (lvl[1] === 0) {
          //remove lvl
          if (lvls[lvl[0]]) {
            if (bids) lvls[lvl[0]][0] = -1e100;
            else lvls[lvl[0]][0] = +1e100;
            delete lvls[lvl[0]];
          } else {//no lvl to remove
            console.error("OB no lvl to remove", bids ? "bids" : "offers", _.cloneDeep(ob), _.cloneDeep(lvls), _.cloneDeep(lvlsv));
          }
        } else {
          if (!lvls.hasOwnProperty(lvl[0])) {
            //add lvl
            lvl.push(0);
            lvlsv.push(lvl);
            lvls[lvl[0]] = lvl;
          } else lvls[lvl[0]][1] = lvl[1]; //update lvl
        }
      });
    } else {
      //clear all lvls
      lvls = {};
      lvlsv = [];
    }
    if (bids)
      lvlsv.sort(function (a, b) {
        return b[0] - a[0];
      });
    else
      lvlsv.sort(function (a, b) {
        return a[0] - b[0];
      });
    for (let i = lvlsv.length - 1; i >= 0; --i) {
      if (Math.abs(lvlsv[i][0]) === 1e100) {
        lvlsv.pop();
      }
    }
    //set cumulative volume
    if (lvlsv.length > 0) lvlsv[0][2] = lvlsv[0][1];
    for (let i = 1; i < lvlsv.length; ++i) {
      lvlsv[i][2] = lvlsv[i][1] + lvlsv[i - 1][2];
    }
    //console.log(lvlsv);
  }

  addListener(f) {
    let self = this;
    //console.log("addListener " + this.listeners.length);
    if (this.listeners.length === 0) {
      this.subscribtion = new Subscribtion({
        //subscribe on orderbook updates
        ws: wspub,
        smsg: { P: "s_ob20", D: { sids: [self.sid] } },
        eternal: true,
        onResult: function (msg) {
          OB.parse(msg);
        },
      });
    }
    this.listeners.push(f);
    f({ bidsv: this.bidsv, offersv: this.offersv }); //snapshot
  }

  removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
    if (this.listeners.length === 0) {
      this.subscribtion.unsubscribe();
    }
  }

  static parse(msg) {
    let obs = [];
    if (msg["D"].hasOwnProperty("obs")) obs = msg["D"]["obs"];
    if (msg["D"].hasOwnProperty("ob")) obs.push(msg["D"]["ob"]);
    if (obs.length > 0) {
      let update = {};
      obs.forEach(function (ob) {
        if (OB.hasOwnProperty(ob.sid)) {
          let self = OB[ob.sid];
          if (msg["Y"] === "r") {
            //clear on snapshot
            self.clear();
            update = { bidsv: [], offersv: [] };
            self.got_snapshot = true;
          }
          if (self.got_snapshot) {
            if (ob.hasOwnProperty("b")) {
              self.update(true, ob["b"], self.bids, self.bidsv);
              update.bidsv = self.bidsv;
            }
            if (ob.hasOwnProperty("s")) {
              self.update(false, ob["s"], self.offers, self.offersv);
              update.offersv = self.offersv;
            }
            self.listeners.forEach((f) => {f(update)}); // update
          }
        }
      });
    }    
  }

  static addListener(f, sid) {
    if (!OB.hasOwnProperty(sid)) {
      OB[sid] = new OB(sid);
    }
    OB[sid].addListener(f);
  }
  static removeListener(f, sid) {
    OB[sid].removeListener(f);
    if (OB[sid].listeners.length === 0) {
      delete OB[sid];
    }
  }
}

OB.columns = [
  { title: "Price", field: "p", formatter: dec },
  { title: "Size", field: "s", formatter: dec },
  { title: "Total", field: "t", formatter: dec },
];

//HOC
function withOrderbook_(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        bidsv: [],
        offersv: [],
      }
    }

    componentDidMount() {OB.addListener(this.onChange, this.props.sid)}
    componentWillUnmount() {OB.removeListener(this.onChange, this.props.sid)}
    onChange(ob) {
      //console.log("OB onChange", this.props);
      if (ob.bidsv && this.props.bids) this.setState({bidsv: ob.bidsv});
      if (ob.offersv && !this.props.bids) this.setState({offersv: ob.offersv});
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

export function withOrderbook(WrappedComponent) {
  return withWSPubKey(withOrderbook_(WrappedComponent))
}

function IndexAndEstimate_(props) {
  //console.log("IndexAndEstimate", props);
  //console.log(Instruments.arr);
  let sec = Instruments.all[props.sid];
  if (!sec || !sec.ip  || !sec.ep) return null;
  return <tr style={{height: props.height + "px"}}><td colSpan={props.cols.length}><div className="index">
    {props.filter.show_index_price && <div>
      <span className="p4">{strings["Index"]}</span>
      <span className="p4b"> {decS(Instruments.all[props.sid].ip)}</span>
    </div>}
    {props.filter.show_mark_price && <div>
      <span className="p4">{strings["Mark"]}</span>
      <span className="p4b"> {decS(Instruments.all[props.sid].ep)}</span>
    </div>}
  </div></td></tr>;
}

export const IndexAndEstimate = withSecPrices(IndexAndEstimate_);

function decSEmpty(v) {
  if (v === null) return "";
  else return decS(v);
}

function OBRows_(props) {
  //console.log("OBRows", props);
  let className = props.bids ? "col-green" : "col-red";
  let rows = props.bids ? _.cloneDeep(props.bidsv) : _.cloneDeep(props.offersv);
  while (rows.length < props.depth) rows.push([null, null, null]);
  while (rows.length > props.depth) rows.pop();
  let i = 0;
  if (!props.bids) rows.reverse();
  return <React.Fragment>{rows.map((row) => {return <tr 
    key={row[0] ? row[0] : ++i}
    className={props.rowClick ? "cursor-pointer" : null}
    onClick={()=>{if (props.rowClick && row[0]) props.rowClick(row[0], props.bids)}}
    >
      {props.col_keys["p"] && <td className={className}>{decSEmpty(row[0])}{'\u00A0'}</td>}
      {props.col_keys["s"] && <td>{decSEmpty(row[1])}{'\u00A0'}</td>}
      {props.col_keys["t"] && <td>{decSEmpty(row[2])}{'\u00A0'}</td>}
  </tr>})}</React.Fragment>;
}

export const OBRows = withOrderbook(OBRows_);

export function getColsDepthHeightStyle(props) {
  let cols = props.filter.cols;
  if (cols === '_all_' || cols === undefined) cols = OB.columns;
  else {
    cols = [];
    OB.columns.forEach((col) => {
      if (props.filter.cols.indexOf(col.title) > -1) cols.push(col);
    });
  };
  let showIndex = props.filter.show_index_price || props.filter.show_mark_price;
  let depth;
  let height; //indexHeight
  let style = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 12px 0px 12px",
  }
  if (props.depth) {
    depth = props.depth;
    height = 30;
    style.padding = "0px";
  } else {
    height = showIndex ? 25 : 10;
    depth = Math.floor(Math.floor((props.size.height - 18 - height) / 18) / 2);
    if (depth <= 0) {
      depth = 0;
      height = 0;
      style = {display: "block"};
    } else {
      height = (props.size.height - (depth * 2 * 18 + 18));
      //console.log("height", props.size.height, depth, height);
    }
  }
  return [cols, showIndex, height, depth, style];
}

function OBTable_ (props) {
  //console.log("OBTable_", props);
  const [cols, showIndex, indexHeight, depth, style] = getColsDepthHeightStyle(props);
  let col_keys = {};
  cols.forEach((col) => col_keys[col.field] = true);

  return <div className="t-ob col-grey" style={style}>
    <table>
      <thead className="p4b">
        <tr>
          {cols.map((col) => {return <th key={col.field}>
            {strings[col.title]}
          </th>})}
        </tr>
      </thead>
      <tbody className="p4">
        <OBRows depth={depth} sid={props.filter.sid} bids={false} col_keys={col_keys} rowClick={props.rowClick}/>
        {showIndex ? 
          <IndexAndEstimate height={indexHeight} sid={props.filter.sid} filter={props.filter} cols={cols}/> : 
          <tr style={{height: "10px", width: "100%"}}><td colSpan={cols.length}/></tr>}
        <OBRows depth={depth} sid={props.filter.sid} bids={true} col_keys={col_keys} rowClick={props.rowClick}/>
      </tbody>
    </table>
  </div>
}

export const OBTable = withFilter(withSize({ monitorHeight: true })(OBTable_));
