/* eslint-disable eqeqeq */
import React from 'react';
import _ from "lodash";
import { strings } from '../localization/strings';
import { wspriv/*, WSPrivListener*/ } from "../ws-priv/ws-priv";
//import Subscribtion from "../utils/subscribtion";
import { AuthFirebase as Auth} from '../ws-priv/auth-firebase'

import { withFilter } from '../utils/utils'
import { withSize } from "react-sizeme";

import { LoginOrRegister } from "../ws-priv/login-or-register";

import { withCurrencies } from "../ws-pub/currencies"

import { 
  IChevronUp,
  IChevronDown,
 } from '../styles/icons';

import '../styles/css/accounts.css';

import { 
  decS,
  dt_ns_msS,
  iTextS,
  iDecCidS,
} from "../utils/cell-formatters";

//import Select from '@material-ui/core/Select';
import SelectMultiple, { Select } from '../components/select-multiple';
//import MenuItem from '@material-ui/core/MenuItem';
//import ListItemText from '@material-ui/core/ListItemText';
//import Checkbox from '@material-ui/core/Checkbox';

export function getLim(aid, cid) {
    let acc = Accounts.all[aid];
    let lim;
    if (!acc || !acc["lims"].some((lid)=>{
        lim = Limits.all[lid];
        //console.log(lim);
        if (lim && lim.cid === cid) return true;
        return false;
      })) return {lim: 0, cid: cid};
    return lim;
  }

export class Limits {
  static request(ws) {
    wspriv.send({"P": "g_lim"});
  }

  static parse(msg) {
    var supported = false;
    var lims = [];
    if (msg["D"].hasOwnProperty("lims")) {
      lims = msg["D"]["lims"];
      supported = true;
      //Limits.all = {};
    }
    if (msg["D"].hasOwnProperty("lim")) lims.push(msg["D"]["lim"]);
    if (lims.length > 0) {
      //var accs = [];
      let needUpdate = false;
      lims.forEach(function (lim) {
        lim.vis_aid = lim["lid"];
        lim.id = "l" + lim["lid"];
        lim["fb"] = lim["lim"];
        if (!Limits.all.hasOwnProperty(lim["lid"])) {
          if (lim["act"] !== "del") {
            if (lim["aid"] == null) {// add unattached limit
              lim.type = "Unattached limits";
            } else {// add attached limit
              lim.type = "Attached limits";
              if (lim.mn) {
                Accounts.all[lim.aid].mn = true;
                Accounts.main = Accounts.all[lim.aid];
              }
            }
            needUpdate = true;
            Limits.all[lim["lid"]] = _.cloneDeep(lim);
          }
        } else {
          lim = Object.assign(Limits.all[lim["lid"]], lim);
          if (lim["act"] === "del") {
            delete Limits.all[lim["lid"]];
          }
        }
      });
      Accounts.listeners.forEach((f)=>{f(needUpdate)}); //update        
      supported = true;
    }
    return supported;
  }
}

export class Accounts {
  static clear() {
    //console.log("clear");
    Accounts.main = null;
    Accounts.all = {};
    Limits.all = {};
    Accounts.arr = [];
    Accounts.own = [];
    //Accounts.listeners.forEach((f)=>{f(true)});
  }

  static welcome() {
    Accounts.main = {
      vis_aid: "#demo",
      id:"#demo",
      aid: "#demo",
      avl: 1000000,
      cid: "BTC",
      fb: 1000000,
      lims: ["demo"],
      ll: "Demo account",
      mn: true,
      own: "user@mail.com",
      pl: 0,
      ud: 0,
      usrs: [],
      ut: "0",
      type: "",
    }
    Accounts.all = {"#demo": Accounts.main};
    Limits.all = {"#demo": {
      mn: true, 
      lid: "#demo",
      aid: "#demo",
      cid: "BTC",
      fb: 1000000,
      lim: 1000000,
      ll: "Demo limit",
      own: "user@mail.com",
      ut: "0",
      type: "Attached limits", 
    }};
    Accounts.arr = [Accounts.main];
    Accounts.own = [Accounts.main];
    //Accounts.listeners.forEach((f)=>{f(true)});
  }

  static request(ws) {
    wspriv.send({"P": "g_acc"});
    //this.clear();
    //Accounts.listeners.forEach((f)=>{f(true)}); //update
  }

  static parse(msg) {
    var supported = false;
    var accs_ = [];
    if (msg["D"].hasOwnProperty("accs")) {
      accs_ = msg["D"]["accs"];
      supported = true;
      this.clear();
    }
    if (msg["D"].hasOwnProperty("acc")) accs_.push(msg["D"]["acc"]);
    if (accs_.length > 0) {
      //var accs = [];
      accs_.forEach(function (acc) {
        acc.vis_aid = acc["aid"];
        acc.id = "a" + acc["aid"];
        if (msg["P"] !== "l_acc") {
          if (acc["own"] && acc["own"] !== Auth.email) acc.type = "In management"
          else if (acc["usrs"] !== undefined && acc["usrs"].length > 0) acc.type = "Delegated"
          else acc.type = "";
        }
        if (!Accounts.all.hasOwnProperty(acc["aid"])) {
          if (acc["act"] !== "del") {
            Accounts.all[acc["aid"]] = _.cloneDeep(acc);
            if (acc.type != "In management") {
              Accounts.own.push(Accounts.all[acc["aid"]]);
              Accounts.own.sort((a,b)=>a.aid > b.aid ? 1 : -1);
            }
            Accounts.arr.push(Accounts.all[acc["aid"]]);
            Accounts.arr.sort((a,b)=>a.aid > b.aid ? 1 : -1);
          }
        } else {
          if (acc["act"] === "del") {
            _.remove(Accounts.own, (n) => n.aid === acc["aid"]);
            _.remove(Accounts.arr, (n) => n.aid === acc["aid"]);
            delete Accounts.all[acc["aid"]];
            Accounts.listeners.forEach((f)=>{f(true)}); //update
          } else {
            Object.assign(Accounts.all[acc["aid"]], acc);
            Accounts.listeners.forEach((f)=>{f(false)}); //update
          }
        }
      });
      supported = true;
      if (msg["Y"] === "r") Limits.request();
    }
    if (Limits.parse(msg)) return true;
    return supported;
  }

  static addListener(f) {
    this.listeners.push(f);
    f(false); //snapshot
  }
  static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

Limits.all = {};
Accounts.all = {};
Accounts.arr = [];
Accounts.own = [];
Accounts.main = null;
Accounts.listeners = [];

//HOC
export function withAccountsList(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        key: 1,
      }
    }

    componentDidMount() {Accounts.addListener(this.onChange)}
    componentWillUnmount() {this.will_unmount = true; Accounts.removeListener(this.onChange)}
    onChange(needUpdate) {if (!this.will_unmount && needUpdate) this.setState({key: this.state.key + 1})};

    render() {
      //console.log("withAccountsList", this.props.id, Accounts.arr.length, Accounts.main);
      return <React.Fragment>{Accounts.arr.length > 0 && Accounts.main != null && <WrappedComponent accounts={this.props.own ? Accounts.own : Accounts.arr} {...this.props} {...this.state}/>}</React.Fragment>;
    }
  };
}

//Component
function SelectAid_(props) {
  React.useEffect(() => {
    //console.log("SelectAid");
    if (Accounts.main && !Accounts.all.hasOwnProperty(props.value)) 
      props.onChange({aid: parseInt(Accounts.main.aid)});
  }, [])  
  return <select value={Accounts.all.hasOwnProperty(props.value) ? props.value : Accounts.main.aid} onChange={(e)=>props.onChange({aid: parseInt(e.target.value)})}>
      {
          props.accounts.map((acc)=><option key={acc.aid} value={acc.aid}>{strings.Account + " " + acc.aid + (acc.mn && acc.type != "In management" ? " (" + strings.main + ")" : "") /*+ (acc.type === "Delegated" ? " >" : "") + (acc.type === "In management" ? " <" : "")*/}</option>)
      }
  </select>;
}

export const SelectAid = withAccountsList(SelectAid_)

//Component
function SelectAid2_(props) {
  React.useEffect(() => {
    //console.log("SelectAid");
    if (Accounts.main && !Accounts.all.hasOwnProperty(props.value)) 
      props.onChange({aid: parseInt(Accounts.main.aid)});
  }, [])  
  return <Select 
    className={props.className}
    value={Accounts.all.hasOwnProperty(props.value) ? props.value : Accounts.main.aid} 
    onChange={(v)=>props.onChange({aid: parseInt(v)})}
    options={props.accounts.map((acc)=>{return {value: acc.aid, label: (strings.Account + " " + acc.aid + (acc.mn && acc.type != "In management" ? " (" + strings.main + ")" : ""))}})}
    >
  </Select>;/*+ (acc.type === "Delegated" ? " >" : "") + (acc.type === "In management" ? " <" : "")*/
}

export const SelectAid2 = withAccountsList(SelectAid2_)

function SelectAids_(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <SelectMultiple
            multiple
            value={value}
            label={props.label ? props.label : strings.SelectAids}
            renderValue={(selected) => value.length === 0 ? strings.AllAccounts : selected.map((s)=>strings[s]).join(', ')}
            options={Accounts.arr.map((acc)=>{return {label: strings.Account + " " + acc.aid, value: acc.aid}})}
            onChange={(value)=>props.onChange({aids: value.length === 0 ? '_all_' : value})}/>
}

//Component
/*function SelectAids_(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <Select
            labelId="select-aids"
            multiple
            value={value}
            renderValue={(selected) => selected.join(', ')}
            onChange={(e)=>props.onChange({aids: e.target.value.length === 0 ? '_all_' : e.target.value})}
        >
        {Accounts.arr.map((acc) => (
            <MenuItem key={acc.aid} value={acc.aid}>
                <Checkbox checked={value.indexOf(acc.aid) > -1} />
                <ListItemText primary={acc.aid} />
            </MenuItem>
        ))}
    </Select>
}*/

export const SelectAids = withAccountsList(SelectAids_)


export class TotalBalance_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      total: "",
    }
  }

  componentDidMount() {Accounts.addListener(this.onChange)}
  componentWillUnmount() {this.will_unmount = true; Accounts.removeListener(this.onChange)}
  onChange() {
    if (!this.will_unmount) {
      let total = 0;
      Accounts.arr.forEach((acc)=>total += acc.fb)
      this.setState({total: total});
      console.log('total', total);
    }
  }

  render() {
    //console.log("withAccountsList", this.props.id, Accounts.arr.length, Accounts.main);
    return <div style={{display: "flex"}}>{strings.Total}{':\u00A0'}{'\u00A0'}{iDecCidS(this.state.total, {cid: "BTC"})}</div>;
  }
}

export const TotalBalance = withCurrencies(TotalBalance_);

Accounts.columns = [
  //{title:"", field:"type", visible: false, minWidth: 100},
  {title:"ID", field:"vis_aid", formatter:vis_aid_formatter, minWidth: 140},
  //{title:"ID", field:"id", minWidth: 100},
  //{title:"AccID", field:"aid", minWidth: 100},
  //{title:"", field:"actions", hozAlign:"center", formatter:actions, minWidth: 100},
  {title:"MarginBalance", field:"fb", hozAlign:"right", formatter:free_balance_formatter, minWidth: 160},
  {title:"Currency", field:"cid"/*, formatter:currencyS*/, minWidth: 100},
  {title:"Available", field:"avl", hozAlign:"right", formatter:decS, minWidth: 160},
  {title:"UsedDeposit", field:"ud", hozAlign:"right", formatter:decS, minWidth: 160},
  //{title:"CanWithdraw", field:"cw", hozAlign:"right", formatter:decS, minWidth: 100},
  {title:"PL", field:"pl", hozAlign:"right", formatter:decS, minWidth: 160},
  {title:"Label", field:"ll", minWidth: 100},
  //{title:"Limits", field:"lims", minWidth: 100},
  //{title:"LimID", field:"lid", minWidth: 100},
  //{title:"Owner", field:"own", minWidth: 100},
  //{title:"Users", field:"usrs", minWidth: 100},
  {title:"UpdateTime", field:"ut", formatter: dt_ns_msS, minWidth: 180},
  {title:"Type", field:"type", formatter: (v)=>strings[v], minWidth: 100},
];

function free_balance_formatter(v, is_account, opened, cid) {
  if (!is_account) {
    return iTextS(decS(v), cid);
  }
  return decS(v);
}

function vis_aid_formatter(v, is_account, opened) {
  if (is_account) {
    return <React.Fragment>
      {opened ? <IChevronUp size="13"/> : <IChevronDown size="13"/>}{'\u00A0'}{strings.Account}{'\u00A0'}{v}
    </React.Fragment>
  }
  return null;//<span style={{paddingLeft: "39px"}}>{'\u00A0'}{strings.Limit_}{'\u00A0'}{v}</span>;
}

class AccountsTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      opened: {},
    }
  }

  componentDidMount() {
    Accounts.addListener(this.onChange);
  }

  componentWillUnmount() {
    Accounts.removeListener(this.onChange);
  }

  onChange() {
    //console.log(Accounts.arr);
    //console.log(Limits.all);
    this.forceUpdate();
  }

  render() {
    //console.log(this.props.filter);
    return <div className="t-accs">
      <table>
        <thead className="p3 col-night-sky"><tr>
           {Accounts.columns.map((col)=>{return <th key={col.field} className="bg-grey-light" style={{minWidth: col.minWidth + "px"}}>
              <div className="th-content">
                <div className="th-title">
                  {strings[col.title]}
                </div>
              </div>
            </th>})}
        </tr></thead>
        <tbody className="p3 col-grey">
        {Accounts.arr.map((acc)=>{return <React.Fragment key={acc.id}>
          <tr key={acc.id} className="cursor-pointer" onClick={()=>this.setState(prevState => {
              let opened = { ...prevState.opened };
              opened[acc.aid] = !opened[acc.aid];     
              return { opened }; 
            })}>
            {Accounts.columns.map((col)=>{return <td key={col.field}>
              { acc[col.field] ? (col.formatter ? col.formatter(acc[col.field], true, this.state.opened[acc.aid]) : acc[col.field]
                  ) : ""
              }
              </td>})
            }
          </tr>
          {this.state.opened[acc.aid] && acc.lims.map((lid)=>{let lim = Limits.all[lid]; if (!lim) return null; else return <tr key={lid} onClick={()=>null}>
            {Accounts.columns.map((col)=>{return <td key={col.field}>
              {lim[col.field] ? (col.formatter ? col.formatter(lim[col.field], false, null, lim["cid"]) : lim[col.field]) : ""}
              </td>})
            }
            </tr>
          })}
          </React.Fragment>})
        }
        </tbody>
      </table>
      <LoginOrRegister/>
    </div>
  }
}

export const AccountsTable = withFilter(withSize({ monitorHeight: true })(AccountsTable_)
);