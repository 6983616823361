/* eslint-disable eqeqeq */
import React from 'react';
//import _ from "lodash";
//import { ICircle } from '../styles/icons'

import { Log } from "../components/log";
import WS, { Heartbeat } from '../utils/ws'
//import { strings } from '../localization/strings';
import { ExchangeTime } from '../ws-pub/exchange-time'
import Symbols from '../ws-pub/symbols'
import Currencies from '../ws-pub/currencies'
import News from '../ws-pub/news'
import Instruments from '../ws-pub/instruments'
import Indexes from '../ws-pub/indexes'
import { OB } from '../ws-pub/orderbook'
import { Candles } from '../ws-pub/candles'
import TradesPub from '../ws-pub/trades-pub'
import Subscribtion from '../utils/subscribtion'

export const wspub = new WS({
  name: "WSPublic",
  url: WS.exchangeUrl() + "/public",
  onDisconnect: function() {
    console.log("WSPublic onDisconnect");
    Subscribtion.clear(wspub);
  },
  onConnecting: function() {
    this.heartbeat.reset();
  },
  onConnect: function() {
    this.heartbeat.reset();
    Currencies.request();
    News.request();
    Symbols.request();
    Instruments.request();
    Indexes.request();
  },
  onMsg: function(msg) {
    //console.debug(msg);
    if (this.heartbeat.onMsg(msg)) return;
    if (msg["Y"] === "e") {Log.errorMsg(msg)}
    //if (msg["P"] === "s_sta" || msg["P"] === "s_ob20" || msg["P"] === "s_be"  || msg["P"] === "s_all" || msg["P"] === "s_pr" || msg["P"] === "s_set" || msg["P"] === "s_tr" || msg["P"] === "s_cs1m" || msg.hasOwnProperty("T") || msg["P"] === "s_idx") ;
    if (msg["Y"] === "u") ;
    else console.debug(msg);
    //console.debug(msg);
    if (ExchangeTime.parse(msg)) return;
    if (Subscribtion.parse(msg)) return;
    if (OB.parse(msg)) return;
    TradesPub.parse(msg);
    if (Candles.parse(msg)) return;
    Symbols.parse(msg);
    Instruments.parse(msg);
    if (Indexes.parse(msg)) return;
    if (Currencies.parse(msg)) return;
    if (News.parse(msg)) return;
  },
});

wspub.heartbeat = new Heartbeat(wspub);

//HOC
export function withWSPubKey(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        connected: wspub.connected,
        key: 0,
        null: false,
      }
    }

    componentDidMount() {wspub.addListener(this.onChange)}
    componentWillUnmount() {this.will_unmount = true; wspub.removeListener(this.onChange)}  
    onChange() {
      if (this.will_unmount) return;
      //console.log("withWSPubKey", wspub.connected);
      if (wspub.before_connected) {
        this.setState({
          null: true,
          key: this.state.key + 1,
        });
      } else if (this.state.connected !== wspub.connected) {
        this.setState({
          null: false,
          connected: wspub.connected,
          key: wspub.connected ? this.state.key + 1 : this.state.key,
        });
      }
    }
    render() {
      if (this.state.null) return null;
      return <WrappedComponent key={this.state.key} {...this.props} />;
    }
  };
}

export class WSPubListener extends React.Component {
  componentDidMount() {wspub.addListener(this.props.onChange)}
  componentWillUnmount() {wspub.removeListener(this.props.onChange)}  
  render() {return null;}
}


/*export class WSPubWidget extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      connected: wspub.connected,
    }
  }

  componentDidMount() {wspub.addListener(this.onChange)}
  componentWillUnmount() {wspub.removeListener(this.onChange)}  
  onChange() {this.setState({connected: wspub.connected})}

  render() {
    //console.log("WSPubWidget render connected = ", this.state.connected);
    return <React.Fragment>
      {this.state.connected ? <button disabled><ICircle color="#3BC471"/>{'\u00A0\u00A0'}{strings.WSPublic}</button> : <button disabled><ICircle color="#EE3F34"/>{'\u00A0\u00A0'}{strings.WSPublic}</button>}
    </React.Fragment>;
  }
}

*/