/* eslint-disable eqeqeq */
import React/*, { useRef }*/ from "react";
//import _ from "lodash";
import { withFilter } from '../utils/utils'

import { strings } from '../localization/strings';

import '../styles/css/account.css';

//import { col } from '../styles/colors';

import Subscribtion from "../utils/subscribtion";
import { wspriv } from "../ws-priv/ws-priv";

import Currencies from "../ws-pub/currencies";
import { ConvertBTCToUSD } from "../ws-pub/indexes";
import { getLim, /*Limits,*/ Accounts, withAccountsList } from "../ws-priv/accounts";
//import { Deposit } from '../ws-priv/deposit';
//import { Withdraw } from '../ws-priv/withdraw';
//import { Transfer } from '../ws-priv/transfer';

import { Button, ButtonConfirm, ButtonConfirm2FA } from "../components/button";
import { ShowStatement } from '../ws-priv/account-statement';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { TwoFA, TwoFAState } from "../ws-priv/two-fa";

import {
  //decS,
  decCidS,
  iDecCidS,
} from "../utils/cell-formatters";

//import { FaCoins,  FaPen } from 'react-icons/fa';

import { 
  //ICoins,
  IBtnCross,
} from '../styles/icons';

import { Log } from '../components/log';

class AccInfo_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      acc: {},
      ll: null,
    }    
  }

  componentDidMount() {
    Accounts.addListener(this.onChange);
  }

  componentWillUnmount() {
    this.will_unmount = true;
    Accounts.removeListener(this.onChange);
  }

  onChange() {
    if (!this.will_unmount) {
      let aid = this.props.filter.aid;
      let acc = aid && Accounts.all[aid] ? Accounts.all[aid] : Accounts.main;
      this.setState({acc: acc});
      if (this.state.ll === null) this.setState({ll: acc["ll"]});
    }
  }

  render() {
    //console.log("InstrumentInfo", Instruments[this.props.filter.sid]);
    //let aid = this.props.filter.aid;
    let acc = this.state.acc;
    //console.log("Filter", this.props.filter);
    //console.log("Limits", Limits.all);
    if (!acc) return null;
    //console.log("render", acc.aid, acc.type);
    //acc.usrs = ["qweq","qweq","qweq","qweq","qweq"];
    return <div className="default-column">
      <div className="default-info-column">
        {[
          //{title:"Label", field:"ll"},
          {title:"MarginBalance", field:"fb", hozAlign:"right", formatter:decCidS},
          //{title:"Currency", field:"cid"/*, formatter:currencyS*/},
          {title:"Available", field:"avl", hozAlign:"right", formatter:decCidS},
          {title:"UsedDeposit", field:"ud", hozAlign:"right", formatter:decCidS},
          //{title:"CanWithdraw", field:"cw", hozAlign:"right", formatter:decCidS},
          {title:"PL", field:"pl", hozAlign:"right", formatter:decCidS},
        ].map((col)=>
          <div key={col.field}>
            <div className="p4">{strings[col.title]}:</div>
            {acc.hasOwnProperty(col.field) && <div className="p4b">{col.formatter ? col.formatter(acc[col.field], acc) : acc[col.field]} <ConvertBTCToUSD className="p5b col-grey-40" amount={acc[col.field]}/></div>}
          </div>)}
      </div>
      {acc.type !== "In management" && <div className="default-info-column">
        {Currencies.arr.map((cid)=>{
          let lim = getLim(acc.aid, cid);
          if (lim.lim == 0) return null;
          return <div key={cid}>
            <div className="p4">{strings.Balance}:</div>
            <div className="p4b">{iDecCidS(lim.lim, lim)}
            </div>
          </div>
        })}
      </div>}
      {acc["ll"] && <div className="default-info-column">
        <div>
          <div className="p4">{strings.Label}:</div>
          <div className="p4b">{acc["ll"]}</div>
        </div>
      </div>}
      {acc.type === "In management" && <div className="default-info-column">
        <div>
          <div className="p4">{strings.AccInManagement}:</div>
          <div className="p4b">{acc.own}</div>
        </div>
      </div>}
      {acc.type === "Delegated" && <div className="acc-delegated">
        <div className="p4">{strings.DelegatedAcc}:</div>
        {acc.usrs.map((email, i)=>{return <div key={i} className="p4b acc-delegated-usr">
        {email}{i < acc.usrs.length - 1 && ','}</div>})}
      </div>}
    </div>
  }
}

export const AccInfo = withFilter(withAccountsList(AccInfo_));

export function ButtonDelegate(props) {
  const [email, setEmail] = React.useState('');
  return <PopupState variant="popover">
    {(popupState) => (
      <React.Fragment>
        <button {...bindTrigger(popupState)} className="p4b bg-blue">{props.children}</button>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          {!TwoFAState.current && <TwoFA/>}
          {TwoFAState.current && <div className="default-popup popup-confirm" style={{maxWidth: "300px"}}>
            <div>
              <div className="p4">{strings.DelegateMsg}</div>
            </div>
            <div>
              <div className="p4b">{strings.Email}:</div>
              <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <div className="confirm-buttons">
              <ButtonConfirm2FA 
                onClick={(key)=>{
                  popupState.close();
                  new Subscribtion({
                    ws: wspriv,
                    smsg: {"P": "cn_acc", "D":{"aid": props.aid, "uid": email, "2fa": key}},
                    eternal: false,
                    onResult: function(msg) {
                    }, 
                    onError: function(msg) {
                    }
                  });
                }}>
                {strings.Next}
              </ButtonConfirm2FA>
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </div>}        
        </Popover>
      </React.Fragment>
    )}
  </PopupState>; 
}

class AccManage_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      acc: {},
      anchorEl: null,
      action: "",
      cid: null,
      ll: null,
    }    
  }

  componentDidMount() {
    Accounts.addListener(this.onChange);
  }

  componentWillUnmount() {
    this.will_unmount = true;
    Accounts.removeListener(this.onChange);
  }

  onChange() {
    if (!this.will_unmount) {
      let aid = this.props.filter.aid;
      let acc = aid && Accounts.all[aid] ? Accounts.all[aid] : Accounts.main;
      this.setState({acc: acc});
      if (this.state.ll === null) this.setState({ll: acc["ll"]});
    }
  }

  render() {
    let self = this;
    /*const handleClick = (event, action, cid) => {
      this.setState({anchorEl: event.target, action: action, cid: cid});
    };*/
    let acc = this.state.acc;
    //console.log(acc);
    if (!acc) return null;
    let aid = acc.aid;
    return <div className="default-column">
      {acc.type === "In management" && <div className="default-info-column">
        <div>
          <div className="p4">{strings.AccInManagement}:</div>
          <div className="p4b">{acc.own}</div>
        </div>
      </div>}
      <div className="acc-manage">
        {acc.type !== "In management" && <ButtonConfirm onClick={()=>
          new Subscribtion({
            ws: wspriv,
            smsg: {"P": "c_acc", "D": {"ll": ""}},
            eternal: false,
            onResult: function(msg) {
              Accounts.parse(msg);
              self.props.onFilterChanged({aid: msg["D"]["acc"]["aid"]});
            }, 
          })}
        >{strings.CreateNewAcc}</ButtonConfirm>}
        {(!acc.mn && acc.type !== "In management") && <ButtonConfirm onClick={()=>wspriv.send({"P": "d_acc", "D": {"aid": aid}})}>{strings.RemoveAcc}</ButtonConfirm>}      
        {acc.type === "In management" && <ButtonConfirm onClick={()=>wspriv.send({"P": "dcn_acc", "D": {"aid": aid}})}>{strings.RefuseAcc}</ButtonConfirm>}
      </div>
      {acc.type === "Delegated" && <div className="acc-delegated">
        <div className="p4">{strings.DelegatedAcc}:</div>
        {acc.usrs.map((email, i)=>{return <div key={i} className="p4b acc-delegated-usr">
          {email}{'\u00A0'}
          <ButtonConfirm className="p4b bg-blue"
            onClick={(key)=>{
              new Subscribtion({
                ws: wspriv,
                smsg: {"P": "dcn_acc", "D":{"aid": acc.aid, "uid": email}},
                eternal: false,
                onResult: function(msg) {
                  Accounts.parse(msg);
                  Log.success(strings.RevokedAcc);
                  //self.setState({addrs: _.reject(self.state.addrs, {did: msg["D"]["did"]})});
                }, 
                onError: function(msg) {
                }
              });
            }}>
            <IBtnCross size="13"/>
          </ButtonConfirm>
        </div>})}          
      </div>}
      {(acc.type === "" || acc.type === "Delegated") && <div className="acc-manage">
        <ButtonDelegate aid={aid}>{strings.DelegateAcc}</ButtonDelegate>
      </div>}
      {null /*{acc.type !== "In management" && <div className="lim-info">
        {Currencies.arr.map((cid)=>{
          let lim = getLim(acc.aid, cid);
          return <div key={cid}>
            <div className="p4b">{iDecCidS(lim.lim, lim)}</div>
            <div className="lim-info-butons">
              <Button onClick={(e)=>handleClick(e, "Transfer", cid)} className="p4b bg-blue">{strings.Transfer + " " + cid}</Button>
              <Button onClick={(e)=>handleClick(e, "Withdraw", cid)} className="p4b bg-blue">{strings.Withdraw + " " + cid}</Button>
              {acc.mn && <Button onClick={(e)=>handleClick(e, "Deposit", cid)} className="p4b bg-blue">{strings.Deposit + " " + cid}</Button>}
            </div>
          </div>
        })}
      </div>}
      <Popover
        open={this.state.anchorEl ? true : false}
        onClose={()=>this.setState({anchorEl: null})}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
        transformOrigin={{vertical: 'top',horizontal: 'left',}}>
      {this.state.action === "Transfer" && <Transfer filter={{cid: this.state.cid, aid: aid}}/>}
      {this.state.action === "Withdraw" && <Withdraw filter={{cid: this.state.cid, aid: aid}}/>}
      {this.state.action === "Deposit" && <Deposit filter={{cid: this.state.cid}}/>}
      </Popover>*/}
      {acc.type !== "In management" && <div className="acc-label">
        <div>
          <div className="p4b">{strings.Label}:</div>
          <div>
            <input type="text" 
              value={this.state.ll === null ? "" : this.state.ll}
              onBlur={(e)=>wspriv.send({"P": "l_acc", "D": {"aid": acc.aid, "ll": e.target.value}})}
              onChange={(e)=>{this.setState({ll: e.target.value})}}
            />
          </div>
        </div>
      </div>} 
      <div>
        <ShowStatement aids={[aid]}>{strings.ShowStatement}</ShowStatement>  
      </div>
    </div>
  }
}

export const AccManage = withFilter(withAccountsList(AccManage_));
