import React from "react";
import _ from "lodash";
//import { withSize } from "react-sizeme";

import {strings} from '../localization/strings';
//import { withSize } from "react-sizeme";
import { withFilter } from '../utils/utils'

import { AccInfo, AccManage } from "../ws-priv/account";
import { Tariffs } from "../ws-priv/tariffs";
import { Transfer } from "../ws-priv/transfer";

import '../styles/css/control.css';

import { IChevronUp, IChevronDown } from '../styles/icons';

import { LoginOrRegister } from "../ws-priv/login-or-register";


const components = {
  AccInfo: AccInfo,
  AccManage: AccManage,
  Fees: Tariffs,
  Transfer: Transfer,
}

const open_next = {
  AccInfo: "Transfer",
  AccManage: "AccInfo",
  //Fees: "AccManage",
  Fees: "AccManage",
  Transfer: "Fees"
}

function ToggleBar(id, name, props, opened_name, setOpened) {
  delete props.id;
  let opened = opened_name === name;
  let Component = components[name];
  let style = opened ? {
      height: "calc(100% - " + (31 * 4 - 1) + "px)", 
      overflow: "auto", 
      transition: "0.2s",
      position: "relative",
      //maxWidth: name !== "Chart" ? "390px" : "auto",
    } : {
      height: "0px",
      position: "relative",
      overflow: "hidden",
      transition: "0.2s"
    };
  return <React.Fragment>
    <div className="control-toggle cursor-pointer"
      onClick={()=>{
          if (opened) name = open_next[name];
          setOpened(name); props.onSaveData({opened: name});
      }}
      onTouchEnd={(e)=>{
        e.preventDefault();
        if (opened) name = open_next[name];
        setOpened(name); props.onSaveData({opened: name});
      }}
      >
      <div className="p3 col-grey">{strings[name]}</div>
      {opened ? <IChevronUp size="20"/> : <IChevronDown size="20"/>}
    </div>
    <div style={style}>
      {opened && <Component id={id + "_" + name} {...props}/>}
      <LoginOrRegister/>
    </div>
  </React.Fragment>;
}

function AccountControl_(props) {
  //console.log("AccountControl", props);
  const [opened, setOpened] = React.useState(props.saved_data && props.saved_data.opened ? props.saved_data.opened : "AccInfo");
  const props_copy = _.cloneDeep(props);
  //console.log("InstrumentControl", props);
  return <div className="control">
    {ToggleBar(props.id, "AccInfo", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "AccManage", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "Fees", props_copy, opened, setOpened)}
    {ToggleBar(props.id, "Transfer", props_copy, opened, setOpened)}
  </div>
}

export const AccountControl = withFilter(AccountControl_);

function AccountControlGrid_(props) {
  //console.log("AccountControl", props);
  const props_copy = _.cloneDeep(props);
  //console.log("InstrumentControl", props);
  return <div>
    <div className="control-accs-grid">
      <AccInfo id={props.id + "_AccInfo"} {...props_copy}/>
      {null/*<TransferTo id={props.id + "_TransferTo"} {...props_copy}/>*/}
      <AccManage id={props.id + "_AccManage"} {...props_copy}/>
      <Tariffs id={props.id + "_Tariffs"} {...props_copy}/>
    </div>
    <LoginOrRegister/>
  </div>
}

export const AccountControlGrid = withFilter(AccountControlGrid_);