import React from "react";
//import _ from "lodash";
//import { withSize } from "react-sizeme";

//import {strings} from '../localization/strings';
import {withFilter} from '../utils/utils'

import { ActiveTable } from "../ws-priv/active";
import { OrdersTable } from "../ws-priv/orders";
import { StopsTable } from "../ws-priv/stops";
import { TradesTable } from "../ws-priv/trades";
import { PositionsTable } from "../ws-priv/positions";
import { LoginOrRegister } from "../ws-priv/login-or-register";


class OrdersAndTrades_ extends React.Component {
  render() {
  	return <React.Fragment>
  		{this.props.filter.priv_table === "ActiveOrders" && <ActiveTable {...this.props}/>}
  		{this.props.filter.priv_table === "Orders" && <OrdersTable {...this.props}/>}
  		{this.props.filter.priv_table === "Trades" && <TradesTable {...this.props}/>}
  		{this.props.filter.priv_table === "Positions" && <PositionsTable {...this.props}/>}
  		{this.props.filter.priv_table === "Stops" && <StopsTable {...this.props}/>}
      <LoginOrRegister/>
    </React.Fragment>
  }
}

export const OrdersAndTrades = withFilter(OrdersAndTrades_);
