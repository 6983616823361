/* eslint-disable eqeqeq */
import React from 'react'
import _ from "lodash"
//import { ICircle } from '../styles/icons'

import WS, { Heartbeat } from '../utils/ws'
//import { strings } from '../localization/strings'
import { Log } from "../components/log";
import { AuthBack } from '../ws-priv/auth-back'
import { AuthFirebase } from '../ws-priv/auth-firebase'
import Subscribtion from '../utils/subscribtion'
import { Orders } from "../ws-priv/orders"
import { Trades } from "../ws-priv/trades"
import { TrsForChart } from "../ws-priv/trades-for-chart"
import { ExchangeTime } from '../ws-pub/exchange-time'
import { Positions } from "../ws-priv/positions"
import { Accounts } from "../ws-priv/accounts"
import { TwoFAState } from "../ws-priv/two-fa"
import Symbols from '../ws-pub/symbols'
//import Indexes from '../ws-pub/indexes'
//import Instruments from '../ws-pub/instruments'

import { parseParams, isLocalhost, exchangeUrl, globals } from "../utils/utils";
import Fingerprint2 from 'fingerprintjs2'
import parser from 'ua-parser-js'

function parseFingerprint(components) {
  wspriv.device_info = {};
  var values = components.map(function (component) { 
    if (component.key == "userAgent") {
      var result = parser(component.value);
      //console.log(result.browser.name);
      wspriv.device_info["br"] = result.browser.name;
    }
    if (component.key == "platform") wspriv.device_info["pl"] = component.value;
    //if (component.key == "timezone") wspriv.device_info["tz"] = component.value;
    return component.value 
  })
  wspriv.device_id = Fingerprint2.x64hash128(values.join(''), 31)
  wspriv.device_info["vid"] = wspriv.device_id;
  //console.log(components);
}

if (window.requestIdleCallback) {
    requestIdleCallback(function () {
        Fingerprint2.get(function (components) {
          parseFingerprint(components);
        })
    })
} else {
    setTimeout(function () {
        Fingerprint2.get(function (components) {
          parseFingerprint(components);
        })
    }, 500)
}

export const wspriv = new WS({
  name: "WSPrivate",
  url: WS.exchangeUrl() + "/private",
  connectCondition: function() {
    //console.log(Date.now() - ExchangeTime.last_sync_time);
    //console.log("!!!!!!!! connectCondition AuthFirebase.emailVerified", AuthFirebase.emailVerified);
    return wspriv.get_cookie_token() &&  (wspriv.connected || (!wspriv.connected && wspriv.device_id && (Date.now() - ExchangeTime.last_sync_time < 15000))) && Symbols.ready && !wspriv.welcome && (AuthFirebase.api_key || (!AuthFirebase.api_key && AuthFirebase.emailVerified));
  },
  onDisconnect: function() {
    console.log("WSPrivate onDisconnect");
    wspriv.authorized = false;
    wspriv.two_fa = false;
  },
  onConnecting: function() {
    //console.log("!!!!!!!! onConnecting")
    this.heartbeat.reset();
    wspriv.authorized = false;
    wspriv.two_fa = false;
  },
  onConnect: function() {
    this.heartbeat.reset();
    wspriv.authorized = false;
    wspriv.two_fa = false;
    if (AuthFirebase.api_key) {
      let uid = parseParams(window.location.hash, 'uid=');
      let kid = parseParams(window.location.hash, 'kid=');
      let key = parseParams(window.location.hash, 'key=');
      if (uid && kid && key) {//admin logins by api key
        AuthBack.requestAPI(wspriv, kid, key);
        window.location.hash = "";
      }
    } else AuthBack.requestFirebase(wspriv, 
      isLocalhost ? localStorage["token_2fa"] : undefined, 
      wspriv.reset_2fa, 
      wspriv.verify_device, 
      wspriv.device_info,
    );
  },
  onMsg: function(msg) {
    if (this.heartbeat.onMsg(msg)) return;
    if (msg["Y"] === "e") {Log.errorMsg(msg)}
    if (msg["P"] === "or" || msg["P"] == "lim" || msg["P"] == "acc" || msg["P"] == "pos") ; 
    else console.debug(_.cloneDeep(msg));
    //console.debug(_.cloneDeep(msg));
    if (ExchangeTime.parse(msg)) return;
    if (Subscribtion.parse(msg)) return;
    Orders.parse(msg);
    Trades.parse(msg);
    TrsForChart.parse(msg, this);
    if (Positions.parse(msg)) return;
    if (Accounts.parse(msg)) return;
    if (msg["P"] === "auth") {
      //console.log("!!!!!!!! AuthBack", msg["Y"], wspriv.got_cookie_token);
      wspriv.got_cookie_token = false; //request cookie before each wspriv connection
    }
    if (AuthBack.parse(msg)) {
      //AuthFirebase.emailVerified = true;
      if (msg["D"]["dcf"]) {//need device confirmation
        wspriv.device_verification = true;
        this.listeners.forEach((f) => {f()}); // update
        return;
      } else wspriv.device_verification = false;
      if (msg["D"]["2fa"]) {//need 2fa auth
        wspriv.two_fa = true;
        TwoFAState.setState(true);
        this.listeners.forEach((f) => {f()}); // update
        delete localStorage["token_2fa"];
        return;
      } else wspriv.two_fa = false;
      //auth success
      delete wspriv.verify_device;
      delete wspriv.reset_2fa;
      if (isLocalhost && msg["D"]["token_2fa"]) {
        localStorage["token_2fa"] = msg["D"]["token_2fa"];
        //console.log("token_2fa", localStorage["token_2fa"]);
      }
      if (localStorage["click_id"] != undefined) {
        let url = `https://fcttrk.com/click.php?cnv_id=` + localStorage["click_id"] + `&payout=0&cnv_status=vikingreg`;
        fetch(url, {
          method: 'GET',
          /*headers: {
            'Content-Type': 'application/json'
          },*/
          //credentials: 'same-origin',
          mode: 'no-cors',
          cache: 'no-cache',
        })
        .then(response => {
          console.log("!!!!!!!! REST Click_id: ", response.status);
          if (response.status == 200) {
            localStorage["click_id"] = undefined;
          }
        })
        .then(contents => null /*console.log("contents", contents)*/)
        .catch(() => {
          Log.error("!!!!!!!! REST Click_id: Can’t access " + url + " response. Blocked by browser?")
        })
      }
      document.cookie = "rfr=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      wspriv.authorized = true;
      this.listeners.forEach((f) => {f()}); // update
      //Subscribtion.request(wspriv);
      Accounts.request();
      Positions.request();
      TwoFAState.request();
      return;
    }
    if (TwoFAState.parse(msg)) return;
  },
});

wspriv.heartbeat = new Heartbeat(wspriv);
wspriv.authorized = false;
wspriv.welcome = false; //welcome page - don't connect
wspriv.two_fa = false; //need 2fa code to login
wspriv.downloading = false; //download active - don't disconnect
wspriv.next_get_cookie_token_time = 0;
wspriv.get_cookie_token = ()=>{
  let nrt =localStorage["next_get_cookie_token_time"];
  if (wspriv.authorized || wspriv.got_cookie_token || wspriv.device_verification || wspriv.two_fa || (nrt && Date.now() < nrt) || isLocalhost) {
    return true;
  } else {
    if (!wspriv.getting_cookie_token && Date.now() > wspriv.next_get_cookie_token_time && AuthFirebase.authorized && AuthFirebase.emailVerified) {
      console.log("!!!!!!!! GET_cookie_token getting_cookie_token: ", wspriv.getting_cookie_token);
      wspriv.getting_cookie_token = true;
      wspriv.next_get_cookie_token_time = Date.now() + globals.rest_reconnect_min + 1000 * Math.floor(Math.random() * globals.rest_reconnect_rand_max); //to not re-request cookies too often
      AuthFirebase.getToken().then((token) => {
        let url = exchangeUrl + "/v1/private/auth";
        fetch(url, {
          method: 'POST',
          /*headers: {
            'Content-Type': 'application/json'
          },*/
          credentials: 'same-origin',
          mode: 'same-origin',
          cache: 'no-cache',
          body: JSON.stringify({"token": token}),
        })
        .then(response => {
          console.log("!!!!!!!! REST Cookie: ", response.status);
          if (response.status == 200) {
            wspriv.got_cookie_token = true;
            localStorage["next_get_cookie_token_time"] = Date.now() + globals.rest_reconnect_min + 1000 * Math.floor(Math.random() * globals.rest_reconnect_rand_max); //to not re-request cookies too often between tabs
            wspriv.checkState(wspriv);
          }
          wspriv.getting_cookie_token = false;
        })
        .then(contents => null /*console.log("contents", contents)*/)
        .catch(() => {
          Log.error("!!!!!!!! REST Cookie: Can’t access " + url + " response. Blocked by browser?")
          wspriv.getting_cookie_token = false;        
        })
      }).catch((error) => {
        wspriv.getting_cookie_token = false;        
      });
    }
    return false;
  }
}


export function withWSPrivKey(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        authorized: wspriv.authorized,
        key: 0,
      }
    }

    componentDidMount() {wspriv.addListener(this.onChange)}
    componentWillUnmount() {wspriv.removeListener(this.onChange)}  
    onChange() {
      if (this.state.authorized !== wspriv.authorized) {
        this.setState({
          authorized: wspriv.authorized,
          key: wspriv.authorized ? this.state.key + 1 : this.state.key,
        });
      }
    }
    render() {
      return <WrappedComponent key={this.state.key} {...this.props} />;
    }
  };
}

export class WSPrivListener extends React.Component {
  componentDidMount() {wspriv.addListener(this.props.onChange)}
  componentWillUnmount() {wspriv.removeListener(this.props.onChange)}  
  render() {return null;}
}

/*
export class WSPrivWidget extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.state = {
      connected: wspriv.connected,
    }
  }

  componentDidMount() {
    wspriv.addListener(this.onChange)
    ExchangeTime.addListener(this.onTimeChange)
  }
  componentWillUnmount() {
    wspriv.removeListener(this.onChange)
    ExchangeTime.removeListener(this.onTimeChange)
  }  
  onChange() {this.setState({connected: wspriv.connected, authorized: wspriv.authorized})}
  onTimeChange(sync) {
    if (sync) wspriv.checkState(wspriv);
  }

  render() {
    return <React.Fragment>
      {wspriv.authorized ? <Button disabled><ICircle color="#3BC471"/>{'\u00A0\u00A0'}{strings.WSPrivate}</Button> : <Button disabled><ICircle color="#EE3F34"/>{'\u00A0\u00A0'}{strings.WSPrivate}</Button>}
    </React.Fragment>;
  }
}
*/