/* eslint-disable eqeqeq */
import React from 'react';
import _ from "lodash";
import {strings} from '../localization/strings';

import { Button } from "../components/button";

import Dialog from '@material-ui/core/Dialog';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';

import { wspriv } from "../ws-priv/ws-priv";
import { wspub } from "../ws-pub/ws-pub";

import '../styles/css/auth.css';

import { Log } from '../components/log';

import { parseParams, isTestEnv, exchangeUrl, /*amplitudeClient*/ } from "../utils/utils";

import { pageVisibility } from "../utils/page-visibility"; //to detect when user returns to tab after clicking the link to reload page

export class AuthFirebase {	
	static init() {
		firebase.initializeApp(isTestEnv ? 
		{//art.viking.trade
	    apiKey: "AIzaSyChpNWRxVaVN8QnWV4WTpNFi2tClVn-Qis",
		  authDomain: "auth.viking.trade",
	    //authDomain: "viking-trade-art.firebaseapp.com",
	    projectId: "viking-trade-art",
	    storageBucket: "viking-trade-art.appspot.com",
	    messagingSenderId: "73014294086",
	    appId: "1:73014294086:web:2a4793d95ff74d6df53c47"
	  } :
	  {//viking.trade
	    apiKey: "AIzaSyBIKoDmI_3FGPbm56xuGvOBE2z8cqYRROU",
		  authDomain: "auth.viking.trade",
	    //authDomain: "viking-trade.firebaseapp.com",
	    projectId: "viking-trade",
	    storageBucket: "viking-trade.appspot.com",
	    messagingSenderId: "829624860128",
	    appId: "1:829624860128:web:564cb3de893c3d0e5e7f85"
		});

		/*firebase.auth().onIdTokenChanged(function(user) {
	  	console.log("!!!!!!!! onIdTokenChanged", user);
		  if(user) {
		  	console.log("!!!!!!!! onIdTokenChanged", user.emailVerified);
		  	if (user.emailVerified != AuthFirebase.emailVerified) {
		  		AuthFirebase.emailVerified = user.emailVerified;
		  		AuthFirebase.listeners.forEach((f)=>{f()});
		  	}
		  }
		});*/

		firebase.auth().onAuthStateChanged(function(user) {
		  if(user) {
		  	//console.log("!!!!!!!! onAuthStateChanged", user.emailVerified);
		  	AuthFirebase.user = user;
		  	AuthFirebase.emailVerified = user.emailVerified;
		  	//console.log("!!!!!!!!",AuthFirebase.user);
		  	AuthFirebase.api_key = false;
		  	AuthFirebase.authorizeAsUser(user.email);
		  	//amplitudeClient.setUserId(user.email);
		  	//amplitudeClient.logEvent("user auth");
		  } else {
		  	AuthFirebase.user = null;
		  	AuthFirebase.emailVerified = false;
		  	AuthFirebase.signOut();
		  }

	  	//check if admin logins by api key
	    let uid = parseParams(window.location.hash, 'uid=');
	    let kid = parseParams(window.location.hash, 'kid=');
	    let key = parseParams(window.location.hash, 'key=');
  		if (uid && kid && key) {//admin logins by api key
	      AuthFirebase.api_key = true;
	      AuthFirebase.authorizeAsUser(uid);
	    }
		});		
	}

	static getToken() {
		return new Promise(function(resolve, reject){
	    firebase.auth().currentUser.getIdToken(true).then(function(token) {
	    	resolve(token);
	    }).catch(function(error) {
	    	Log.error("Failed to get firebase auth token: " + error);
	    	reject(error);
	    });
    });
	}

	static authorizeAsUser(email) {
  	AuthFirebase.email = email;
    document.title = email;
  	//Log.info("onAuthStateChanged " + email);
    AuthFirebase.authorized = true;
    AuthFirebase.listeners.forEach((f)=>{f()});
	}

	static signOut() {
  	AuthFirebase.email = "";
  	document.title = "";
  	//Log.info("onAuthStateChanged false");
    AuthFirebase.authorized = false;
  	wspriv.disconnect();
  	wspub.disconnect();
    AuthFirebase.listeners.forEach((f)=>{f()});
	}

  static addListener(f) {
    this.listeners.push(f);
  }
  
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }  

	static sendVerificationEmail(silent)
	{
		let now = Date.now();
	  if (now - AuthFirebase.last_time_verification_email_sent > 10000) {
      AuthFirebase.last_time_verification_email_sent = now;
      if (AuthFirebase.authorized)
      {
        var actionCodeSettings = {
          url: exchangeUrl + '/?email=' + firebase.auth().currentUser.email,
        };
        AuthFirebase.user.sendEmailVerification(actionCodeSettings).then(function() {
          if (!silent) Log.success(strings.Security_.VerificationEmailSent + ' ' + firebase.auth().currentUser.email);
        }).catch(function(error) {
          Log.error(strings.Security_.VerificationEmailNotSent + ' ' + error);
        });
      } else
      {
	      Log.error(strings.Security_.NotSignedIn);
      }
	  }
	}

	static sendPasswordChangeEmail()
	{
	  if (AuthFirebase.authorized)
	  {
	      var auth = firebase.auth();
	      auth.sendPasswordResetEmail(auth.currentUser.email).then(function() {
	          Log.success(strings.Security_.PasswordResetLinkSent + ' ' + auth.currentUser.email);
	      }).catch(function(error) {
	          Log.error(strings.Security_.PasswordResetLinkNotSent + '. ' + error);
	      });
	  } else
	  {
	      Log.error(strings.Security_.NotSignedIn);
	  }
	}  

}

AuthFirebase.authorized = null;
AuthFirebase.listeners = [];
AuthFirebase.init();
AuthFirebase.last_time_verification_email_sent = 0;


export function withAuthFirebaseKey(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        authorized: AuthFirebase.authorized,
        key: 0,
      }
    }

    componentDidMount() {AuthFirebase.addListener(this.onChange)}
    componentWillUnmount() {this.will_unmount = true; AuthFirebase.removeListener(this.onChange)}  
    onChange() {
    	if (!this.will_unmount) {
	      if(this.state.authorized !== AuthFirebase.authorized) {
	        this.setState({
	          authorized: AuthFirebase.authorized,
	          key: AuthFirebase.authorized ? this.state.key + 1 : this.state.key,
	        });
	      } else this.forceUpdate();
	    }
    }
    render() {
      return <WrappedComponent key={this.state.key} {...this.props} />;
    }
  };
}

export function LogInDialog(props) {
  const [open, setOpen] = React.useState(false);
  //const [email, setEmail] = React.useState("121@gmail.com");
  return (
    <div>
    	{props.link ? 
    		<div className={props.className} 
    			onClick={()=>setOpen(true)}
          onTouchEnd={(e)=>{
            e.preventDefault();
            setOpen(true);
          }}
    		>{props.children}</div> : 
    		<Button className="bg-blue" onClick={()=>setOpen(true)}>{props.children}</Button>}
	    <Dialog onClose={()=>setOpen(false)} open={open}>
	    	<br/>
				{null/*<div style={{display: "flex", justifyContent: "center"}}>
					{strings.Login_as}:{'\u00A0'}
					<div><input value={email} onChange={(e)=>setEmail(e.target.value)}/></div>
		    </div>
				<div style={{padding: "10px 30px 10px 30px"}}>
					<div style={{display: "flex", flexWrap: "wrap", width: "300px"}}>
						{[email, "andrey@gmail.com", "vlad@gmail.com", "dima@gmail.com", "ramiz@gmail.com", "roma@gmail.com", "103@gmail.com", "110@gmail.com"].map((uid)=>{return <div key={uid} style={{padding: "8px"}}><Button className="bg-blue" onClick={()=>{
								AuthFirebase.api_key = true;
								AuthFirebase.authorizeAsUser(uid);
							}}>{uid}</Button></div>
						})}
			    </div>
		    </div>*/}
	    	<br/>
		    <StyledFirebaseAuth uiConfig={{
				  // Popup signin flow rather than redirect flow.
				  signInFlow: 'popup',
				  // We will display Google and Facebook as auth providers.
				  callbacks: {
				      /*uiShown: function() {
				      // The widget is rendered.
				      // Hide the loader.
				      $('.loader').hide();
				    }*/
				    // Avoid redirects after sign-in.
				    signInSuccessWithAuthResult: () => false,
				  },
				  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
				  signInSuccessUrl: '.',
				  signInOptions: isTestEnv ? [
				      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				      firebase.auth.EmailAuthProvider.PROVIDER_ID,
				  ] : [
				      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
				      //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
				      //firebase.auth.GithubAuthProvider.PROVIDER_ID,
				      firebase.auth.EmailAuthProvider.PROVIDER_ID,
				      //firebase.auth.PhoneAuthProvider.PROVIDER_ID
				  ],
				  // Terms of service url.
				  tosUrl: 'https://viking.trade/terms_of_use.pdf',
				  privacyPolicyUrl: 'https://viking.trade/privacy_policy.pdf',
				}} firebaseAuth={firebase.auth()}/>
	    </Dialog>
    </div>
  );
};

export function LogOut(props) {
	if (wspriv.welcome) return null;
  return <div>
  	<Button className="bg-blue p4b" onClick={()=>{
  		window.location.hash = "";
	    if (AuthFirebase.api_key) {
	    	AuthFirebase.signOut();
	    } else {
	    	if (wspriv.authorized) wspriv.send({"P": "lgt"});
				firebase.auth().signOut().then(function() {
		      //Log.success('Firebase: Signed Out Success');
		    }, function(error) {
		      Log.error('Firebase: Sign Out Error', error);
		    });
			}
  	}}>{props.children}</Button>
  </div>
}

export class VerifyEmailDialog_ extends React.Component {
  constructor(props) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  componentDidMount() {
  	if (!AuthFirebase.api_key && !AuthFirebase.emailVerified && AuthFirebase.authorized) {
  		AuthFirebase.sendVerificationEmail(true);
  		pageVisibility.addListener(this.onVisibilityChange);
  	}
  }

  componentWillUnmount() {
  	pageVisibility.removeListener(this.onVisibilityChange);
  }

  onVisibilityChange(page_visibile) {
  	if (page_visibile) {
	  	let self = this;
	  	firebase.auth().currentUser.reload().then(() => {
	  		let emailVerified = firebase.auth().currentUser.emailVerified;
		  	if (emailVerified != AuthFirebase.emailVerified) {
		  	 	AuthFirebase.emailVerified = emailVerified;
		  	 	pageVisibility.removeListener(self.onVisibilityChange);
		  	 	self.forceUpdate();
		  	}
	  	});
	  }
  }

  render() {
  	let open = !AuthFirebase.api_key && !AuthFirebase.emailVerified;  	
    return <div>
      { open && <Dialog open={open}>
        <div className="default-popup-title p4b">
          {strings.Security_.EmailNotVerified}
        </div>
        <div className="default-popup popup-confirm">
          <div>
            <div className="p4">{strings.formatString(strings.Security_.VerifyWarn, AuthFirebase.email)}</div>
          </div>
          <br/>
          <div className="confirm-buttons-wrap">
            <Button onClick={()=>AuthFirebase.sendVerificationEmail()} className="p4b bg-blue">
              {strings.Security_.SendVerificationEmail}
            </Button>
            <LogOut>{strings.Cancel}</LogOut>
          </div>
        </div>
      </Dialog>}
    </div>
  }
};

export const VerifyEmailDialog = withAuthFirebaseKey(VerifyEmailDialog_)
