/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { strings } from '../localization/strings';

//import { withFilter } from '../utils/utils'

import { Accounts, withAccountsList } from "../ws-priv/accounts";

import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

//import { ToggleButton, ToggleButtonGroup } from '../components/toggle-button';

import '../styles/css/tariffs.css';
import '../styles/css/accounts.css';

import { AuthFirebase as Auth} from '../ws-priv/auth-firebase'

import { dec, decS, dt_ns_msS, iTextCidS, percentS} from "../utils/cell-formatters";

//import { ButtonConfirm, ButtonConfirm2FA } from "../components/button";

//import { TwoFA, TwoFAState } from "../ws-priv/two-fa";

//import { Log } from '../components/log';
//import { toast } from 'react-toastify';

export class TariffsTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trfs: null,
    }
    this.columns = [
      {title:"TariffID", field:"tid"},
      {title:"MakerFee", field:"m_f", formatter: percentS},
      {title:"TakerFee", field:"t_f", formatter: percentS},
      {title:"ExpiryFee", field:"e_f", formatter: percentS},
      {title:"FixedFee", field:"f_f", formatter: dec},
      {title:"NextPayment", field:"n_pt", formatter: dt_ns_msS},
      {title:"UserID", field:"uid"},
    ]
  }

  componentDidMount() {
    if (wspriv.authorized) {    
      let self = this;
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "g_trf"},
        eternal: false,
        onResult: function(msg) {
          if (self.will_unmount) return;
          let trfs = _.cloneDeep(msg["D"]["trfs"]);
          trfs.sort(function (a, b) {return b["tid"] - a["tid"];});
          self.setState({trfs: trfs});
        }, 
      });
    }
  }

  componentWillUnmount() {
    this.will_unmount = true;
  }

  render() {
    //console.log("render", this.state.addrs);
    let self = this;
    //console.log(lim);
    if (this.state.trfs === null) return null;
    return <div>
      {this.state.trfs.length > 0 && <div className="t-accs">
        <table>
          <thead className="p3 col-night-sky"><tr>
             {this.columns.map((col)=>{return <th key={col.title} className="bg-grey-light">
                <div className="th-content">
                  <div className="th-title">
                    {strings.TariffsTable_[col.title]}
                  </div>
                </div>
              </th>})}
          </tr></thead>
          <tbody className="p3 col-grey">
          {this.state.trfs.map((trf)=>{return <tr key={trf.uid}>
              {this.columns.map((col)=>{return <td key={col.title}>
                { trf[col.field]
                  ? (col.formatter 
                    ? col.formatter(trf[col.field], self) 
                    : trf[col.field])
                  : ""
                }
                </td>})
              }
            </tr>})
          }
          </tbody>
        </table>
      </div>}    
    </div>
  }
}

export const TariffsTable = withWSPrivKey(TariffsTable_);

export class Tariffs_ extends React.Component {
  constructor(props) {
    super(props);
    let acc = null;
    if (props.filter && props.filter.aid) {
      let aid = this.props.filter.aid;
      acc = aid && Accounts.all[aid] ? Accounts.all[aid] : Accounts.main;
      this.acc = acc;
    }
    this.state = {
      trfs: null,
      uid: acc ? acc["own"] : Auth.email,
      showSelect: acc ? false : true,
      comis_fixed: null,
      comis_deals_today: null,
      comis_deals_yesterday: null,
      comis_overdraft: null,
    }
  }

  componentDidMount() {
    let self = this;
    if (wspriv.authorized) {    
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "g_trf"},
        eternal: false,
        onResult: function(msg) {
          if (self.will_unmount) return;
          let trfs = _.cloneDeep(msg["D"]["trfs"]);
          trfs.sort(function (a, b) {return b["tid"] - a["tid"];});
          self.setState({trfs: trfs});
        }, 
      });
      if (this.acc) {
        new Subscribtion({
          ws: wspriv,
          smsg: {"P": "g_cmss", "D": {"aid": this.acc.aid, "cs": ["H21", "H22", "H23"]}},
          eternal: false,
          onResult: function(msg) {
            if (self.will_unmount) return;
            self.setState({
              comis_fixed: msg["D"]["cmss"]["H21"][0]["cm"],
              comis_deals_today: msg["D"]["cmss"]["H22"][0]["cm"],
              comis_deals_yesterday: msg["D"]["cmss"]["H22"][1]["cm"],
              comis_overdraft: msg["D"]["cmss"]["H23"][0]["cm"],
            });
          }, 
        });
      }
    }
  }

  componentWillUnmount() {
    this.will_unmount = true;
  }

  render() {
    //let self = this;
    let trfs = this.state.trfs;
    if (trfs === null) return null;
    let trf;
    trfs.some((t)=>{if (t["uid"] === this.state.uid) {trf = t; return true;} else return false;});
    return <div className="tariffs">
      {this.state.showSelect && <div>
        <select value={this.state.uid}
          onChange={(e)=>this.setState({uid: e.target.value})}>
          {trfs.map((trf)=><option key={trf["uid"]} value={trf["uid"]}>{trf["uid"]}</option>)}
        </select>
      </div>}
      <div>
          <div className="p4b">{strings.TariffsTable_.Commission}</div>
      </div>
      <div className="default-info-column">
        {this.state.comis_deals_today != null && <React.Fragment><div>
            <div className="p4">{strings.TariffsTable_.CommissionDealsToday}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.comis_deals_today))}</div>
          </div>
          <div>
            <div className="p4">{strings.TariffsTable_.CommissionDealsYesterday}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.comis_deals_yesterday))}</div>
        </div></React.Fragment>}
        {this.state.comis_fixed != null && <div>
            <div className="p4">{strings.TariffsTable_.CommissionFixed}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.comis_fixed))}</div>
        </div>}
        {this.state.comis_overdraft != null && <div>
            <div className="p4">{strings.TariffsTable_.CommissionOverdraft}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.comis_overdraft))}</div>
        </div>}
        {null/*trf.uid !== Auth.email && <div>
            <div className="p4">{strings.AccInManagement}:</div>
            <div className="p4b">{trf["uid"]}</div>
        </div>*/}
      </div>
      <div>
          <div className="p4b">{strings.TariffsTable_.Tariff}</div>
      </div>
      <div className="default-info-column">
        {[
          //{title:"TariffID", field:"tid"},
          {title:"MakerFee", field:"m_f", formatter: percentS},
          {title:"TakerFee", field:"t_f", formatter: percentS},
          {title:"ExpiryFee", field:"e_f", formatter: percentS},
          {title:"FixedFee", field:"f_f", formatter: (v)=>iTextCidS(decS(v))},
          //{title:"NextPayment", field:"n_pt", formatter: dt_ns_msS},
          //{title:"UserID", field:"uid"},
        ].map((col)=>
          <div key={col.field}>
            <div className="p4">{strings.TariffsTable_[col.title]}:</div>
            <div className="p4b">{trf.hasOwnProperty(col.field) ? (col.formatter ? col.formatter(trf[col.field]) : trf[col.field]) : null}</div>
          </div>)}
      </div>
    </div>
  }
}

export const Tariffs = withAccountsList(withWSPrivKey(Tariffs_));

