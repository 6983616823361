import elliptic from 'elliptic'
import { buf2hex/*, parseParams*/ } from '../utils/utils'
import { ExchangeTime } from '../ws-pub/exchange-time'
import { AuthFirebase } from '../ws-priv/auth-firebase'

//var got_cookie = false;

export class AuthBack {	
	static requestFirebase(ws, token_2fa, reset_2fa, verify_device, device_info) {
		AuthFirebase.getToken().then((token) => {
				ws.send({"P": "auth_firebase", "D":{"token": token, "r2fa": reset_2fa, "token_2fa": token_2fa, "ldcf": verify_device, "di": device_info}});
		    }).catch((error) => {
	    });
	}

	static async requestAPI(ws, kid, key) {
		let ec = new elliptic.ec('secp256k1');
		let k = ec.keyFromPrivate(key);
		let ts = ExchangeTime.now;
		let h  = buf2hex(await crypto.subtle.digest('SHA-256', new TextEncoder().encode(kid + ' ' + ts.toString())));
		let s = k.sign(h);
		let sig = s.r.toString(16, 64) + s.s.toString(16, 64);
		ws.send({"P": "auth_key", "D":{"kid":kid,"ts":ts, 'sig':sig}});
	}

	static parse(msg) {
		if (msg["P"] === "auth" && msg["Y"] === "r") return true;
		return false;
	}
}
