import React from 'react';
import _ from "lodash";

import {strings, codes, enums, languages} from '../localization/strings';
//import {api} from '../localization/api';

import "moment/locale/ru";
import moment from "moment";

export class Language {
  static onChange(lang) {//change language
    strings.setLanguage(lang);
    codes.setLanguage(lang);
    enums.setLanguage(lang);
    //api.setLanguage(lang);
    Language.current = lang;
    this.listeners.forEach((f)=>{f()});
    moment.locale(strings.getLanguage());
    localStorage["language"] = lang;
  }

  static addListener(f) {this.listeners.push(f);}
  static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

Language.listeners = [];
Language.current = strings.getLanguage();
if (localStorage["language"]) Language.onChange(localStorage["language"])
//Language.onChange("en");

export function withLanguageKey(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        language: Language.current,
        key: 0,
      }
    }

    componentDidMount() {Language.addListener(this.onChange)}
    componentWillUnmount() {Language.removeListener(this.onChange)}  
    onChange() {
      if (this.state.language !== Language.current) {
        this.setState({
          language: Language.current,
          key: this.state.key + 1,
        });
      }
    }
    render() {
      return <WrappedComponent key={this.state.key} {...this.props} />;
    }
  };
}

export class LanguageListener extends React.Component {
  componentDidMount() {Language.addListener(this.props.onChange)}
  componentWillUnmount() {Language.removeListener(this.props.onChange)}  
  render() {return null;}
}

export class SelectLanguage extends React.Component {
  render() {
    return (
      <select value={strings.getLanguage()} onChange={(e)=>Language.onChange(e.target.value)}>
          {languages.map((lang) => {return <option key={lang} value={lang[0]}>{lang[1]}</option>})}
        </select>
    );
  }
}
