import React from 'react';
import { strings } from '../localization/strings';
import { wspriv } from "../ws-priv/ws-priv";
import { LogInDialog } from "../ws-priv/auth-firebase";

import '../styles/css/login-or-register.css';

export function LoginOrRegister(props) {
	if (!wspriv.welcome) return null;
	return  <div className="welcome-overlay">
		<div>
			<div className="col-white">{strings.MustLogIn}{'\u00A0'}</div>
		</div>
		<div>
			<LogInDialog link className="link p3 cursor-pointer col-white">{strings.Login}</LogInDialog>
			<div className="col-white">{'\u00A0'}{strings.or}{'\u00A0'}</div>
			<LogInDialog link className="link p3 cursor-pointer col-white">{strings.Register}</LogInDialog>
		</div>
	</div>;
}
