/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";

//import {strings} from '../localization/strings';
import { wspub/*, WSPubListener*/ } from "../ws-pub/ws-pub";
import { wspriv/*, WSPrivListener*/ } from "../ws-priv/ws-priv";
import TradesPub, { TradesPubListener }  from "../ws-pub/trades-pub";
import { /*Trades,*/ TradesListener }  from "../ws-priv/trades";
//import Symbols from "../ws-pub/symbols";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import { sec_id, dir, dec, dt_ns_ms, decS, dt_sS } from "../utils/cell-formatters";
import {withFilter, getMinNsecTime} from '../utils/utils'
import BigNumber from "bignumber.js";

import '../styles/css/trades-pub-own.css';

class TradesPubOwnTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onPubChange = this.onPubChange.bind(this);
    this.onPrivChange = this.onPrivChange.bind(this);
    this.tabRef = React.createRef();
    this.filter = _.cloneDeep(this.props.filter);
		this.loadOnScroll = 100;
		this.tab = null;
    this.max_time_priv = null;
    this.max_time_pub = null;
    this.will_unmount = false;

    this.cols = [
      {title:"Own", field:"m", formatter: (cell)=>{
        let row = cell.getRow().getData();
        //console.log("Own", row);
        if (row.tf == 1) return "Cross";
        if (row.m !== undefined) return row.m ? "Maker" : "Taker";
        else return null;
      }, minWidth: 70},
      {title:"Instrument", field:"sid", formatter: sec_id, minWidth: 110},
      {title:"Dir", field:"d", formatter: dir, minWidth: 45},
      {title:"Price", field:"p", formatter:dec, minWidth: 100},
      {title:"Qty", field:"q", formatter:dec, minWidth: 100},
      {title:"VolBTC", field:"v", formatter:dec, minWidth: 100},
      {title:"tNo", field:"tno", minWidth: 100},
      {title:"Time", field:"dt", formatter:dt_ns_ms, minWidth: 180},
    ];
  }

  initTable() {
    //console.log("TradesPubOwnTable", this.tab, wspriv.authorized, wspub.connected);
    if (!this.tab && this.tabRef.current && wspub.connected && this.max_time_pub) {
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      if (wspriv.authorized && this.max_time_priv) {
      	this.max_time = BigNumber(this.max_time_priv).comparedTo(this.max_time_pub) > 0 
          ? this.max_time_priv : this.max_time_pub;
      	this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
    		      self.subscribtion = new Subscribtion({
    						ws: wspriv,
    						smsg: {"P": "g_tr3", "D": {"sids": self.filter.sids === '_all_' ? null : self.filter.sids, "mt": self.max_time, "lim": self.loadOnScroll}},
    					  eternal: false,
    					  onResult: function(msg) {
                  var trs = TradesPub.parse(msg);
                  //console.log("Subscribtion");
                  //console.log(trs);
    	            self.max_time = getMinNsecTime(trs, "dt", self.max_time);
                  if (trs.length > 0) resolve({data:trs});
                  else resolve({no_more_data: true, data:trs});
    	          }, 
    	          onError: function(msg) {
                  reject(msg);
    	          }
    		      });
    			  });
    			},
    			columns: this.cols,
    		});
        this.tab.setSort("dt", "desc");
      } else if (wspriv.welcome) {
        this.max_time = this.max_time_pub;
        this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
              self.subscribtion = new Subscribtion({
                ws: wspub,
                smsg: {"P": "g_tr", "D": {"sids": self.filter.sids === '_all_' ? null : self.filter.sids, "mt": self.max_time, "lim": self.loadOnScroll}},
                eternal: false,
                onResult: function(msg) {
                  var trs = TradesPub.parse(msg);
                  //console.log("Subscribtion");
                  //console.log(trs);
                  self.max_time = getMinNsecTime(trs, "dt", self.max_time);
                  if (trs.length > 0) resolve({data:trs});
                  else resolve({no_more_data: true, data:trs});
                }, 
                onError: function(msg) {
                  reject(msg);
                }
              });
            });
          },
          columns: this.cols,
        });
        this.tab.setSort("dt", "desc");
      }
    }
  }

  componentDidMount() {
    //console.log("componentDidMount", this.tab, wspriv.authorized, wspub.connected);
    if (this.props.filter.dt > 0) {
      this.max_time_priv = this.max_time_pub = this.props.filter.dt * 1000000000;
    }
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onPubChange(data) {
    //console.log("onPubChange", data);
    if (data.max_time) {
      this.max_time_pub = data.max_time;
      this.componentWillUnmount();
	  	this.initTable(data.max_time);
    }
    this.updateOrAddData(data);
  }

  onPrivChange(data) {
    //console.log("onPrivChange", data);
    if (this.props.filter.dt > 0) {
      if (this.tab == null) this.componentDidMount();
      return;
    }
    if (data.max_time > 0) {//reset table on reconnect
      this.max_time_priv = data.max_time;
      this.componentWillUnmount();
      this.componentDidMount();
    }  
    this.updateOrAddData(data);
  }

  updateOrAddData(data) {
    if (this.tab) {
      let trs = _.cloneDeep(data.trs);
      let rows = this.tab.getRows();
      trs.forEach((trade)=>{
        trade.id = trade["sid"] + '_' + trade["tno"];
        let r = rows[trade.id];
        if (r && r.data.tf == 1) {//cross should update only once for correct direction, because the arrive twice for both dirs
          trade.id = "";
        }
      })
      trs = _.reject(trs, (trade)=>trade.id == "");
      if (this.filter.sids === '_all_') {
        this.tab.updateOrAddData(trs, true);
      } else {
        let trs = [];
        trs.push();
        trs.forEach((trade)=>{
          if (this.filter.sids.indexOf(trade.sid) !== -1) trs.push(trade);
        });
        this.tab.updateOrAddData(trs, true);
      }
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
      <TradesListener onChange={this.onPrivChange} />
    	{this.props.filter.dt <= 0 && <React.Fragment>
      	<TradesPubListener sids={this.props.filter.sids} onChange={this.onPubChange} />
      </React.Fragment>}
      <div ref={this.tabRef} />
      </React.Fragment>
  }
}

export const TradesPubOwnTable = withFilter(withSize({ monitorHeight: true })(TradesPubOwnTable_));

class TradesPubOwn1SecTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onPubChange = this.onPubChange.bind(this);
    this.onPrivChange = this.onPrivChange.bind(this);
    this.tabRef = React.createRef();
    this.filter = _.cloneDeep(this.props.filter);
    this.loadOnScroll = 100;
    this.tab = null;
    this.max_time_priv = null;
    this.max_time_pub = null;
    this.will_unmount = false;
    this.sid = this.props.filter.sid;
    this.cols = [
      {title:"Own", field:"m", formatter: (cell)=>{
        let row = cell.getRow().getData();
        //console.log("Own", row);
        if (row.tf == 1) return "Cross";
        if (row.m !== undefined) return row.m ? "Maker" : "Taker";
        else return null;
      }},
      {title:"Price", field:"p", formatter: (cell, formatterParams)=>{
        let v = cell.getValue();
        let row = cell.getRow().getData();
        let el = cell.getElement();
        el.classList.add(row.d === 1 ? "col-green" : "col-red");
        return decS(v);
      }},
      {title:"Qty", field:"q", formatter: (cell, formatterParams)=>{
        let v = cell.getValue();
        let row = cell.getRow().getData();
        let el = cell.getElement();
        el.classList.add(row.d === 1 ? "col-green" : "col-red");
        return decS(v);
      }},
      {title:"Dir", field:"d", formatter: (cell, formatterParams)=>{
        //let v = cell.getValue() / 1000000000;
        let row = cell.getRow().getData();
        let el = cell.getElement();
        el.classList.add(row.d === 1 ? "col-green" : "col-red");
        return row.d === 1 ? "B" : "S";
      }},
      {title:"Time", field:"dt", formatter: (cell, formatterParams)=>{
        let v = cell.getValue() / 1000000000;
        let row = cell.getRow().getData();
        let el = cell.getElement();
        el.classList.add(row.d === 1 ? "col-green" : "col-red");
        return dt_sS(v);
      }},
    ];
  }

  initTable() {
    //console.log("TradesPubOwnTable", this.tab, wspriv.authorized, wspub.connected);
    if (!this.tab && this.tabRef.current && wspub.connected && this.max_time_pub) {
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      if (wspriv.authorized && this.max_time_priv) {
        this.max_time = BigNumber(this.max_time_priv).comparedTo(this.max_time_pub) > 0 
          ? this.max_time_priv : this.max_time_pub;
        this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
              self.subscribtion = new Subscribtion({
                ws: wspriv,
                smsg: {"P": "g_tr3", "D": {"sids": [self.sid], "mt": self.max_time, "lim": self.loadOnScroll}},
                eternal: false,
                onResult: function(msg) {
                  var trs = TradesPub.parse(msg);
                  //console.log("Subscribtion");
                  //console.log(trs);
                  self.max_time = getMinNsecTime(trs, "dt", self.max_time);
                  if (trs.length > 0) resolve({data:trs});
                  else resolve({no_more_data: true, data:trs});
                }, 
                onError: function(msg) {
                  reject(msg);
                }
              });
            });
          },
          headerVisible: false,
          theadClassNames: ["p5b"],
          tbodyClassNames: ["p5", "col-grey"],
          columns: this.cols,
        });
        this.tab.setSort("dt", "desc");
      } else if (wspriv.welcome) {
        this.max_time = this.max_time_pub;
        this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
              self.subscribtion = new Subscribtion({
                ws: wspub,
                smsg: {"P": "g_tr", "D": {"sids": [self.sid], "mt": self.max_time, "lim": self.loadOnScroll}},
                eternal: false,
                onResult: function(msg) {
                  var trs = TradesPub.parse(msg);
                  //console.log("Subscribtion");
                  //console.log(trs);
                  self.max_time = getMinNsecTime(trs, "dt", self.max_time);
                  if (trs.length > 0) resolve({data:trs});
                  else resolve({no_more_data: true, data:trs});
                }, 
                onError: function(msg) {
                  reject(msg);
                }
              });
            });
          },
          headerVisible: false,
          theadClassNames: ["p5b"],
          tbodyClassNames: ["p5", "col-grey"],
          columns: this.cols,
        });
        this.tab.setSort("dt", "desc");
      }
    }
  }

  componentDidMount() {
    //console.log("componentDidMount", this.tab, wspriv.authorized, wspub.connected);
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onPubChange(data) {
    //console.log("onPubChange1", data);
    if (data.max_time) {
      this.max_time_pub = data.max_time;
      this.componentWillUnmount();
      this.initTable(data.max_time);
    }
    this.updateOrAddData(data);
  }

  onPrivChange(data) {
    //console.log("onPrivChange1", data);
    if (data.max_time > 0) {//reset table on reconnect
      this.max_time_priv = data.max_time;
      this.componentWillUnmount();
      this.componentDidMount();
    }  
    this.updateOrAddData(data);
  }

  updateOrAddData(data) {
    if (this.tab) {
      let trs = _.cloneDeep(data.trs);
      let rows = this.tab.getRows();
      trs.forEach((trade)=>{
        trade.id = trade["sid"] + '_' + trade["tno"];
        let r = rows[trade.id];
        if (r && r.data.tf == 1) {//cross should update only once for correct direction, because the arrive twice for both dirs
          trade.id = "";
        }
      })
      trs = _.reject(trs, (trade)=>trade.id == "");
      trs.forEach((trade)=>{
        if (trade.sid === this.sid) this.tab.updateOrAddData([trade], true);
      });
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <div className="pub-tab bc-grey-40">
      <TradesListener onChange={this.onPrivChange} />
      <TradesPubListener sids={[this.sid]} onChange={this.onPubChange} />
      <div ref={this.tabRef} />
    </div>
  }
}

export const TradesPubOwn1SecTable = withFilter(withSize({ monitorHeight: true })(TradesPubOwn1SecTable_));


