/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import React from "react";
import _ from "lodash";
import { strings } from '../localization/strings';
import fileDownload from 'js-file-download';

import { Button } from "../components/button";

import { /*SelectSym,*/ SelectSyms } from '../ws-pub/symbols';
import { /*SelectAid,*/ SelectAids } from '../ws-priv/accounts';
import { SelectCids } from '../ws-pub/currencies';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { 
	//IDownload, 
	IPlay,
	IPause,
	IStop,
} from '../styles/icons';

import DateTimePicker from '../components/date-time-picker';
import moment from "moment";

import {/*withFilter,*/ getMinNsecTime, end_of_day } from '../utils/utils'

import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { Trades } from '../ws-priv/trades';
import { Orders } from '../ws-priv/orders';
import { Transfers } from '../ws-priv/transfer';
import { Transactions } from '../ws-priv/transactions';
import { Statement } from '../ws-priv/account-statement';

import { toast } from 'react-toastify';

import '../styles/css/download.css';
import '../styles/css/grid-titlebar.css';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export class Downloader {
	static download(data) {
		let D = Downloader;
		if (data.from > data.to) {
			toast.error(strings.formatString(strings.Download_.FromTo, strings.Since, strings.Until));
			return;
		}
		if (D.state == "free" || D.state == "pause" || D.state == "disconnect") {
	  	if (!wspriv.authorized) {
	  		toast.info(strings.Security_.NotSignedIn);
	  	} else {
	  		if (D.state == "free") D.rows = [];
				D.state = "downloading";
				wspriv.downloading = true;
  			wspriv.addListener(D.onWSStateChange);
				D.data = _.cloneDeep(data);
				D.downloadPromise(data.max_time, data.from, data.to, data.P, data.parse, data.tt, data.opts).then(() => {
					wspriv.downloading = false;
					//console.log(D.data.max_time, D.data.from_);
	  			wspriv.removeListener(D.onWSStateChange);
					if ((D.state == "pause" || D.state == "disconnect") && parseInt(D.data.max_time) > parseInt(D.data.from)) return;
					D.finished();
				});
			}
		}
		D.listeners.forEach((f)=>{f()});
	}

	static onWSStateChange() {
		let D = Downloader;
		if (wspriv.authorized) {
			if (D.state == "disconnect") {
				if (D.subscribtion) D.subscribtion.delete();
				D.download(D.data);
				D.listeners.forEach((f)=>{f()});
			}
		} else {
			D.pause();
			D.state = "disconnect";
			D.listeners.forEach((f)=>{f()});
		}
	}

	static pause() {
		let D = Downloader;
		if (D.state == "downloading") {
			D.state = "pause";
			D.listeners.forEach((f)=>{f()});
		}
	}

	static resume() {
		let D = Downloader;
		if (D.state == "pause" || D.state == "disconnect") {
			if (D.subscribtion) D.subscribtion.delete();
			D.download(D.data);
		}
	}

	static stop() {
		let D = Downloader;
		if (D.state == "downloading") {
			if (D.subscribtion) D.subscribtion.delete();
			D.finished();
		} else if (D.state == "pause" || D.state == "disconnect") D.finished();
	}

	static finished() {
		let D = Downloader;
		D.progress = 1;
    D.state = "free";
		toast.info(strings.formatString(strings.Download_.Downloaded, D.rows.length));
		D.listeners.forEach((f)=>{f()});
		let str = "";
		if (D.rows.length > 0) {
			D.data.columns.forEach((col)=>{
				str += strings[col.title] + ";";
			})
			str += "\n";
			D.rows.forEach((row)=>{
				D.data.columns.forEach((col)=>{
					let v = row[col.field];
					str += ( v != null ? (col.formatterS ? col.formatterS(v, row) : v ) : "") + ";";
				})
				str += "\n";
			})
			fileDownload(str, D.data.title + '_' + moment.utc(Math.abs(D.data.from) / 1000000).format('YYYY-MM-DD_HH:mm:ss') + '_' + moment.utc(Math.abs(D.data.to) / 1000000).format('YYYY-MM-DD_HH:mm:ss') + '.csv');
			D.rows = [];		
		}
	}

	static downloadPromise = async (max_time, from_, to, P, parse, tt, opts) => {
		let D = Downloader;
		await sleep(200);
    return new Promise((resolve) => {
    	/*if (!wspriv.authorized) {
    	}*/
		  D.subscribtion = new Subscribtion({
		    ws: wspriv,
		    smsg: {"P": P, "D": Object.assign({
		      "mt": max_time,
		      "lim": 1000,
		    }, opts)},
		    eternal: false,
		    onResult: function(msg) {
      		D.subscribtion = null;
		      var rows = parse(msg);
		      //console.log("+", rows);
		      D.data.max_time = max_time = getMinNsecTime(rows, tt, max_time);
		      rows.forEach((row)=>{
		      	if (parseInt(row[tt]) > parseInt(from_)) {
							delete row["id"];
		      		D.rows.push(row);
		      	}
		      });
	        if (parseInt(max_time) > parseInt(from_) && rows.length > 0 && D.state == "downloading") {
	        		D.progress = (parseInt(to) - parseInt(max_time)) / (parseInt(to) - parseInt(from_));
	        		D.listeners.forEach((f)=>{f()});
	            return resolve(D.downloadPromise(max_time, from_, to, P, parse, tt, opts))
	        } else {
	        		D.listeners.forEach((f)=>{f()});
	            return resolve()
	        }
		    }, 
		    onError: function(msg) {
      		D.subscribtion = null;
		      D.state = "free";
      		toast.info(strings.Download_.Failed);
      		D.listeners.forEach((f)=>{f()});
		      return resolve();
		    }
		  });
    })
	}

  static addListener(f) {
    this.listeners.push(f);
  }
  
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }  
}

Downloader.rows = [];
Downloader.state = "free";
Downloader.listeners = [];
Downloader.progress = 0; //[0, 1]

export class DownloadWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.progressUpdate = this.progressUpdate.bind(this);
  }

  componentDidMount() {
  	Downloader.addListener(this.progressUpdate);
  }

  componentWillUnmount() {
  	this.will_unmount = true;
  	Downloader.removeListener(this.progressUpdate);
  }

  progressUpdate() {
  	if (this.will_unmount) return;
  	//console.log("progress", Downloader.progress);
  	this.forceUpdate();
  }

  render() {
  	let state = Downloader.state;
  	//return <div className="download-footer">"asdfasdfasdf"</div>;
  	if (state == "free") return null;
  	return <div className="download-footer">
  		<div>
  			<div>{strings.Download_.Downloading}:</div>
  			{'\u00A0\u00A0'}
	  		{state == "downloading" || state == "pause" || state == "disconnect" ? ' ' + parseInt(Downloader.progress * 100) + '%\u00A0' : ""}
				{(state == "downloading" || state == "disconnect") && <React.Fragment>
					<Button className="p4b bg-blue" onClick={()=>{Downloader.pause()}}><IPause size="13px"/></Button>
					{'\u00A0\u00A0'}
				</React.Fragment>}
				{(state == "pause" || state == "disconnect") && <React.Fragment>
					<Button className="p4b bg-blue" onClick={()=>{Downloader.resume()}}><IPlay size="13px"/></Button>
					{'\u00A0\u00A0'}
				</React.Fragment>}
				{state != "free" && <React.Fragment>
					<Button className="p4b bg-blue" onClick={()=>{Downloader.stop()}}><IStop size="13px"/></Button>
					{'\u00A0\u00A0'}
				</React.Fragment>}
			</div>
		</div>
	}  
}

export class DownloadPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	sids: "_all_",
    	aids: "_all_",
    	curs: "_all_",
    	from: (props.item.saved_data && props.item.saved_data.from ) ? props.item.saved_data.from : end_of_day.unix(),
    	to: (props.item.saved_data && props.item.saved_data.to ) ? props.item.saved_data.to : end_of_day.unix(),
    }
    this.progressUpdate = this.progressUpdate.bind(this);
  }

  componentDidMount() {
  	Downloader.addListener(this.progressUpdate);
  }

  componentWillUnmount() {
  	this.will_unmount = true;
  	Downloader.removeListener(this.progressUpdate);
  }

  progressUpdate() {
  	if (this.will_unmount) return;
  	//console.log("progress", Downloader.progress);
  	this.forceUpdate();
  }

  render() {
  	let state = this.state;
  	let props = this.props;
		return <PopupState variant="popover">{(popupState) => (
      <React.Fragment>
        <span className={props.className} {...bindTrigger(popupState)}>
					{props.children}
			  </span>
        <Popover {...bindPopover(popupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
            transformOrigin={{vertical: 'top',horizontal: 'center',}}>
          <div className="default-column">
			  		{props.trades && <React.Fragment>
				  		<div>
					  		<SelectSyms 
					  		label={strings.SelectInstruments}
								all={true}
								value={state.sids}
								onChange={(v)=>{this.setState({sids: v.sids})}}
							/></div>
			        <div>
					  		<SelectAids 
								value={state.aids}
								label={strings.SelectAids}
								onChange={(v)=>{this.setState({aids: v.aids})}}
							/></div>
						</React.Fragment>}        
			  		{props.wallet && <React.Fragment>
				  		<div>
					  		<SelectCids 
					  		label={strings.SelectCurrencies}
								value={state.curs}
								onChange={(v)=>{this.setState({curs: v.curs})}}
							/></div>
						</React.Fragment>} 
			  		{props.account && <React.Fragment>
			        <div>
					  		<SelectAids 
								value={state.aids}
								label={strings.SelectAids}
								onChange={(v)=>{this.setState({aids: v.aids})}}
							/></div>
						</React.Fragment>}
						<div>
							<span className="p4">{strings.Since}:</span>
							<DateTimePicker 
								variant="outlined"
								ampm={false}
								disabled={false}
								format={'YYYY MMM Do, HH:mm'}
								value={moment.utc(Math.abs(state.from) * 1000)} 
								onChange={(e)=>{
									let v = moment.utc(e.format('YYYY-MM-DD HH:mm:ss')).unix();
									this.setState({from: v});
									props.item.onSaveData({from: v});
								}}/>
						</div>
						<div>
							<span className="p4">{strings.Until}:</span>
							<DateTimePicker 
								variant="outlined"
								ampm={false}
								disabled={false}
								format={'YYYY MMM Do, HH:mm'}
								value={moment.utc(Math.abs(state.to) * 1000)} 
								onChange={(e)=>{
									let v = moment.utc(e.format('YYYY-MM-DD HH:mm:ss')).unix();
									this.setState({to: v});
									props.item.onSaveData({to: v});
								}}/>
						</div>
			  		{props.trades && <div>
							<Button className="p4b bg-blue" onClick={()=>{
									//console.log(from_, to);
									Downloader.download({
										max_time: state.to + "000000000",
										from: state.from + "000000000", 
										to: state.to + "000000000", 
										P: "g_tr",
										parse: Trades.parse,
										columns: Trades.columns,
										tt: "dt",
										title: "trades",
										opts: {
								      sids: state.sids === '_all_' ? null : state.sids,
								      aids: state.aids === '_all_' ? null : state.aids,
								      hot: true,
										}
									});
								}}>{strings.Download_.DownloadTrades}
							</Button>
						</div>}  
						<br/>      
			  		{props.trades && <div>
							<Button className="p4b bg-blue" onClick={()=>{
									//console.log(from_, to);
									Downloader.download({
										max_time: state.to + "000000000",
										from: state.from + "000000000", 
										to: state.to + "000000000", 
										P: "g_or",
										parse: Orders.parse,
										columns: Orders.columns,
										tt: "ct",
										title: "orders",
										opts: {
								      sids: state.sids === '_all_' ? null : state.sids,
								      aids: state.aids === '_all_' ? null : state.aids,
								      hot: true,
										}
									});
								}}>{strings.Download_.DownloadOrders}
							</Button>
						</div>}        
			  		{props.wallet && <div>
							<Button className="p4b bg-blue" onClick={()=>{
									//console.log(from_, to);
									Downloader.download({
										max_time: state.to + "000000000",
										from: state.from + "000000000", 
										to: state.to + "000000000", 
										P: "g_trns",
										parse: Transactions.parse,
										columns: Transactions.columns,
										tt: "ct",
										title: "transactions",
										opts: {
											cids: state.cids, 
										}
									});
								}}>{strings.Download_.DownloadTransactions}
							</Button>
						</div>}        
			  		{props.wallet && <div>
							<Button className="p4b bg-blue" onClick={()=>{
									//console.log(from_, to);
									Downloader.download({
										max_time: state.to + "000000000",
										from: state.from + "000000000", 
										to: state.to + "000000000", 
										P: "g_tfrs",
										parse: Transfers.parse,
										columns: Transfers.columns,
										tt: "ct",
										title: "transfers",
										opts: {
											cids: state.cids, 
										}
									});
								}}>{strings.Download_.DownloadTransfers}
							</Button>
						</div>}  
			  		{props.account && <div>
							<Button className="p4b bg-blue" onClick={()=>{
									//console.log(from_, to);
									Downloader.download({
										max_time: state.to + "000000000",
										from: state.from + "000000000", 
										to: state.to + "000000000", 
										P: "g_ast",
										parse: Statement.parse,
										columns: Statement.columns,
										tt: "ct",
										title: "statement",
										opts: {
								      aids: state.aids === '_all_' ? null : state.aids,
										}
									});
								}}>{strings.Download_.DownloadStatement}
							</Button>
						</div>}        
					</div>								
	      </Popover>
      </React.Fragment>
    )}</PopupState>		
  }
}
