//import React from 'react';
import React from 'react';
import { IBTC, IETH } from '../styles/icons';
import _ from "lodash";
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import SelectMultiple, { Select } from '../components/select-multiple';
import { strings } from '../localization/strings';
import { hex2ascii } from '../utils/utils'

export default class Currencies {
	static request() {
	    wspub.send({"P":  "g_cur"});
	}

	static parse(msg) {
	  if (msg["D"].hasOwnProperty("curs")) {
        //console.log(msg);
	  		Currencies.arr = [];
	  		msg["D"]["curs"].forEach((cur)=>{
                let c = Currencies.all[cur["cid"]] = _.cloneDeep(cur);
		        //Object.assign(Currencies.all[cur["cid"]], cur);
	  			Currencies.arr.push(cur["cid"]);
                c.min_step = Math.pow(0.1, cur.dec);
                //c.fullname = c.short + ' (' + c.ll + ')';
                c.svg = hex2ascii(c.svg);
                //c.svg = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="props.size" height="props.size" viewBox="0 0 339.43 295.27"><title>tether-usdt-logo</title><path d="M62.15,1.45l-61.89,130a2.52,2.52,0,0,0,.54,2.94L167.95,294.56a2.55,2.55,0,0,0,3.53,0L338.63,134.4a2.52,2.52,0,0,0,.54-2.94l-61.89-130A2.5,2.5,0,0,0,275,0H64.45a2.5,2.5,0,0,0-2.3,1.45h0Z" style="fill:#50af95;fill-rule:evenodd"/><path d="M191.19,144.8v0c-1.2.09-7.4,0.46-21.23,0.46-11,0-18.81-.33-21.55-0.46v0c-42.51-1.87-74.24-9.27-74.24-18.13s31.73-16.25,74.24-18.15v28.91c2.78,0.2,10.74.67,21.74,0.67,13.2,0,19.81-.55,21-0.66v-28.9c42.42,1.89,74.08,9.29,74.08,18.13s-31.65,16.24-74.08,18.12h0Zm0-39.25V79.68h59.2V40.23H89.21V79.68H148.4v25.86c-48.11,2.21-84.29,11.74-84.29,23.16s36.18,20.94,84.29,23.16v82.9h42.78V151.83c48-2.21,84.12-11.73,84.12-23.14s-36.09-20.93-84.12-23.15h0Zm0,0h0Z" style="fill:#fff;fill-rule:evenodd"/></svg>';
                c.icon = (props)=>{
                    //console.log("this.svg", c.svg);
                    return <div>
                          <img src={`data:image/svg+xml;utf8,${encodeURIComponent(c.svg.replace('props.size', props.size))}`}/>
                        </div>
                }
	  		});
	        this.listeners.forEach((f)=>{f()});
        //console.log(Currencies.all);
        return true;
	  }
	  return false;
	}


	static addListener(f) {this.listeners.push(f);}
	static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

Currencies.main = "BTC";
Currencies.arr = []; //["BTC", "ETH"];
Currencies.all = {};
/*{
	"BTC": {
		step: 0.0001,
		min_step: 0.00000001,
		decimals: 8,
		icon: (props)=><IBTC {...props}/>,
	},
	"ETH": {
		step: 0.0001,
		min_step: 0.00000001,
		decimals: 8,
		icon: (props)=><IETH {...props}/>,
	},
};*/
Currencies.listeners = [];

//HOC
export function withCurrencies(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {Currencies.addListener(this.onChange)}
    componentWillUnmount() {Currencies.removeListener(this.onChange)}
    onChange() {this.forceUpdate();}

    render() {
    	if (Currencies.arr.length == 0) return null;
      	return <WrappedComponent {...this.props} />;
    }
  };
}


//Component
function SelectCid_(props) {
    if (Currencies.arr.length > 0 && !Currencies.arr.some((cur)=>cur == props.value)) {
        setTimeout(()=>props.onChange({cid: Currencies.arr[0]}), 0);
    }
    if (Currencies.arr.length === 0) return null;
    return <select value={Currencies.all.hasOwnProperty(props.value) ? props.value: Currencies.arr[0].cid} onChange={(e)=>props.onChange({cid: e.target.value})}>
        {
            Currencies.arr.map((cur)=><option key={cur} value={cur}>{Currencies.all[cur].short + '\u00A0\u00A0' + Currencies.all[cur].ll}</option>)
        }
    </select>;
}

export const SelectCid = withWSPubKey(withCurrencies(SelectCid_))

//Component
function SelectCid2_(props) {
    if (Currencies.arr.length > 0 && !Currencies.arr.some((cur)=>cur == props.value)) {
        setTimeout(()=>props.onChange({cid: Currencies.arr[0]}), 0);
    }
    if (Currencies.arr.length === 0) return null;    
    return <Select 
        className={props.className}
        value={Currencies.all.hasOwnProperty(props.value) ? props.value: Currencies.arr[0].cid} 
        onChange={(v)=>props.onChange({cid: v})}
        options={Currencies.arr.map((cur)=>{return {value: cur, label: Currencies.all[cur].short, label2: Currencies.all[cur].ll}})}
        >
    </Select>;
}

export const SelectCid2 = withWSPubKey(withCurrencies(SelectCid2_))


export function SelectCids_(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <SelectMultiple
        multiple
        label={props.label ? props.label : strings.SelectCurrencies}
        value={value}
        renderValue={(selected) => value.length === 0 ? strings.AllCurrencies : selected.join(', ')}
        options={Currencies.arr.map((cur)=>{return {label: cur, value: cur}})}
        onChange={(value)=>props.onChange({curs: value.length === 0 ? '_all_' : value})}
    />
}

export const SelectCids = withWSPubKey(withCurrencies(SelectCids_))
