//{'\u00A0'}
//onClick={popupState.close}
//<Link className="p2 col-white-pen-2" to="/api">{strings.APIDocumentation}</Link>
import React from 'react';
import { strings } from '../localization/strings';
//import _ from "lodash";

import { wspriv } from "../ws-priv/ws-priv";

import Grid from '../components/grid'
import Stack from '../components/stack'
import { SelectLanguage } from '../localization/select-language';
import { Theme, SelectTheme } from '../styles/select-theme';
import { OnlineIndicator } from '../components/online-indicator';
/*import { WSPubWidget } from '../ws-pub/ws-pub';
import { WSPrivWidget } from '../ws-priv/ws-priv';*/
import { /*AuthFirebase,*/ LogInDialog/*, LogOut*/ } from '../ws-priv/auth-firebase'
import { TwoFaDialog } from '../ws-priv/two-fa';
import { VerifyDeviceDialog } from '../ws-priv/security';
//import { PageWelcomeMobile, PageWelcomeDesktop } from '../components/page-welcome';
import { 
	AuthFirebase, 
	withAuthFirebaseKey, 
	LogOut,
	VerifyEmailDialog,
} from '../ws-priv/auth-firebase'
import Time from '../ws-pub/exchange-time';
//import { APIDocumentation } from "../components/api-documentation";
//import { withCurrencies } from "../ws-pub/currencies"

import '../styles/css/page-user.css';

import { Accounts } from "../ws-priv/accounts"
import { DownloadWidget } from "../ws-priv/download"

import { InstrumentsList }from '../ws-pub/instruments'

import { 
	IViking, 
	IVikingWhite, 	
//	ICopy, 
	IUser, 
	IChevronLeft,
//	ICross 
} from '../styles/icons';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import QrCode from "qrcode.react";

import PageUserMobile from "../components/page-user-mobile"

function BtnUser(props) {
	//console.log(window.location.href);
  return <PopupState variant="popover">
    {(popupState) => (
      <div>
        <div {...bindTrigger(popupState)} className="cursor-pointer"><IUser/></div>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
          transformOrigin={{vertical: 'top',horizontal: 'center',}}>
          <div className="default-popup">
          	<div>{AuthFirebase.email}</div>
						<div><SelectLanguage/>{'\u00A0'}<SelectTheme/></div>
						{null /*<Link className="p3" to="/user/trade">{strings.Trade}</Link>
						<Link className="p3" to="/user/account">{strings.UserAccount}</Link>*/}
						{props.welcome && <React.Fragment>
							<Link className="p3" to="/user/trade">{strings.Trade}</Link>
							<Link className="p3" to="/user/wallet">{strings.Wallet}</Link>
							<Link className="p3" to="/user/accounts">{strings.Accounts}</Link>
						</React.Fragment>}
						<Link className="p3" to="/user/referral">{strings.Referral}</Link>
						<Link className="p3" to="/user/api">{strings.API}</Link>
						<Link className="p3" to="/user/security">{strings.Security}</Link>
						<Link className="p3" to="/user/contract_specs">{strings.ContractSpecs}</Link>
						<Link className="p3" to="/doc/description">{strings.Description}</Link>
						{null/*<Link className="p3" to="/api">{strings.APIDoc}</Link>*/}
						<LogOut>{strings.Logout}</LogOut>
						<br/>
						<div>{strings.MobileVersion}:</div>
            <QrCode
                value={window.location.href}
                size={128}
                bgColor={Theme.current === "dark" ? "#000000" : "#FFFFFF"}
                fgColor={Theme.current === "dark" ? "#FFFFFF" : "#000000"}
            />						
	        </div>
        </Popover>
      </div>
    )}
  </PopupState>;
}

function PageUserDesktop(props) {
	let match = useRouteMatch();
	return <div className="page-user">
		<div className="page-user-header">
			<div className="page-user-header-left">
				<Link to="/user"><IViking className="page-user-header-icon"/></Link>
				<span className="htitle col-white-pen-2"><b>Viking Trade</b> Futures Exchange</span>
				<div className="page-user-header-left-right">
				</div>
			</div>
			<div className="page-user-header-mid">
				{props.welcome ? <React.Fragment>
						<LogInDialog link className="link p3 cursor-pointer col-white-pen-2">{strings.Login}</LogInDialog>
						<LogInDialog link className="link p3 cursor-pointer col-white-pen-2">{strings.Register}</LogInDialog>
					</React.Fragment> :
					<React.Fragment>
						<Link className="p3 col-white-pen-2" to="/user/trade">{strings.Trade}</Link>
						<Link className="p3 col-white-pen-2" to="/user/wallet">{strings.Wallet}</Link>
						<Link className="p3 col-white-pen-2" to="/user/accounts">{strings.Accounts}</Link>
					</React.Fragment>
				}
			</div>
			<div className="page-user-header-right">
				{props.welcome ? <OnlineIndicator pub/> : <OnlineIndicator pub priv/>}
				<BtnUser {...props}/>
				<span className="p3 page-user-header-time"><Time/></span>
			</div>
		</div>
		<div className="page-user-mid">
     	<Switch>
        <Route path={`${match.path}/accounts`}>
          <Grid key="1" layout="layout_accounts"/>
        </Route>
        <Route path={`${match.path}/trade`}>
          <Grid key="2" layout="layout_trade" layout_left_menu="layout_trade_menu" localStorage/>
        </Route>
        <Route path={`${match.path}/api`}>
          <Grid key="3" layout="layout_api"/>
        </Route>
        <Route path={`${match.path}/security`}>
          <Grid key="4" layout="layout_security"/>
        </Route>
        <Route path={`${match.path}/wallet`}>
          <Grid key="5" layout="layout_wallet"/>
        </Route>
        <Route path={`${match.path}/referral`}>
          <Grid key="6" layout="layout_referral"/>
        </Route>
        <Route path={`${match.path}/contract_specs`}>
         <InstrumentsList/>
        </Route>
        <Redirect to={`${match.path}/trade`}/>
      </Switch>		
		</div>
		<div className="page-user-footer">
			<div className="page-user-footer-left">
				{null/*<Link className="p2 col-white-pen-2" to={`/api/doc`}>{strings.APIDoc}</Link>*/}
				<span className="p3 col-white-pen-2">© 2021 Viking Trade {null/*strings.AllRightsReserved*/}</span>
			</div>
			<div className="page-user-footer-right">
				<Link to="/user"><IVikingWhite className="page-user-header-icon"/></Link>
			</div>
		</div>
		{!props.welcome && <React.Fragment>
			<TwoFaDialog/>
			<VerifyEmailDialog/>
			<VerifyDeviceDialog/>
			<DownloadWidget/>
		</React.Fragment>}
	</div>;
}

function PageUser_(props) {
	if (AuthFirebase.authorized === null) return null;
	else if (AuthFirebase.authorized === false) {
	  wspriv.welcome = true;
	  Accounts.welcome();
		return props.mobile ? <PageUserMobile key={1} welcome={true}/> : <PageUserDesktop welcome={true}/>;
	} else {
		if (wspriv.welcome) {
		  wspriv.welcome = false;
		  Accounts.clear();
		}
		return props.mobile ? <PageUserMobile key={2} welcome={false}/> : <PageUserDesktop welcome={false}/>;
	}
}

export const PageUser = withAuthFirebaseKey(PageUser_);
