import React from 'react';
//import _ from "lodash";
import { strings } from '../localization/strings';

//import Select from '@material-ui/core/Select';
import SelectMultiple from '../components/select-multiple';
/*import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';*/

import Instruments from '../ws-pub/instruments'
import { OB } from '../ws-pub/orderbook'
import { Orders } from '../ws-priv/orders'
import { Trades } from '../ws-priv/trades'
import { ActiveColumns } from '../ws-priv/active'
import { Positions } from '../ws-priv/positions'
import { History } from '../ws-priv/history'
const columns = {
    Orderbook: OB.columns,
	InstrumentsTable: Instruments.columns,
    QuotesTable: Instruments.columns,
    OrdersTable: Orders.columns,
    ActiveTable: ActiveColumns,
    TradesTable: Trades.columns,
    PositionsTable: Positions.columns,
    HistoryTable: History.columns,
};

/*

export default function SelectCols(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <Select
            labelId="select-cols"
            //id="demo-simple-select"
            multiple
            value={value}
            renderValue={(selected) => selected.map((s)=>strings[s]).join(', ')}
            onChange={(e)=>props.onChange({cols: e.target.value.length === 0 ? '_all_' : e.target.value})}
        >
        {columns[props.component_].map((col) => (
            <MenuItem key={col.field} value={col.title}>
                <Checkbox checked={value.indexOf(col.title) > -1} />
                <ListItemText primary={strings[col.title]} />
            </MenuItem>
        ))}
    </Select>
}*/

//Component
export default function SelectCols(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <SelectMultiple
            labelId="select-cols"
            //id="demo-simple-select"
            multiple
            value={value}
            label={props.label ? props.label : strings.SelectColumns}
            renderValue={(selected) => value.length === 0 ? strings.AllColumns : selected.map((s)=>strings[s]).join(', ')}
            options={columns[props.component_].map((col)=>{return {label: strings[col.title], value: col.title}})}
            onChange={(value)=>props.onChange({cols: value.length === 0 ? '_all_' : value})}
        />
}
