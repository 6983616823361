/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";
import Symbols, { SymbolsListener } from "../ws-pub/symbols";
//import { strings } from '../localization/strings';
import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import {
  dec,
  sec_id,
  dir,
  dt_s,
  dt_ns_ms,
  order_status,
  cancel_reason,
  order_flags,
  order_type,
  //activation_type,
  time_in_force,
  added_by,
  decS, 
  sec_idS, 
  dirS, 
  dt_sS,
  dt_ns_msS, 
  order_statusS,
  cancel_reasonS,
  order_flagsS,
  order_typeS,
  //activation_typeS,
  time_in_forceS,
  added_byS,
} from "../utils/cell-formatters";
import { withFilter, getMinNsecTime } from '../utils/utils'

import { Orders, OrdersListener } from '../ws-priv/orders'

class StopsTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onSymbolsChange = this.onSymbolsChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.loadOnScroll = 100;
    this.tab = null;
    this.snapshot = null;
    this.max_time = 0;
    this.hot = props.filter.dt < 0;
  }

  initTable() {
    //console.log("initTable", this.max_time);
    if (this.tab == null && this.tabRef.current) {
      let columns = [
        //{title:"ID", field:"id"},
        {title:"AccID", field:"aid", minWidth: 100},
        {title:"Instrument", field:"sid", formatter: sec_id, formatterS: sec_idS, minWidth: 110},
        {title:"oNo", field:"ono", minWidth: 110},
        {title:"Dir", field:"d", formatter: dir, formatterS: dirS, minWidth: 50},
        {title:"Price", field:"p", formatter:dec, formatterS: decS, minWidth: 100},
        {title:"Qty", field:"q", formatter:dec, formatterS: decS, minWidth: 100},
        {title:"Rest", field:"ar", formatter:dec, formatterS: decS, minWidth: 100},
        {title:"Status", field:"ss", formatter: order_status, formatterS: order_statusS, minWidth: 100},
        {title:"Type", field:"oy", formatter: order_type, formatterS: order_typeS, minWidth: 60},
        //{title:"ActType", field:"ay", formatter: activation_type, formatterS: activation_typeS, minWidth: 100},
        {title:"ActPrice", field:"ap", formatter: dec, formatterS: decS, minWidth: 100},
        {title:"CancelReason", field:"cr", formatter: cancel_reason, width: 60, formatterS: cancel_reasonS, minWidth: 120},
        {title:"ExtID", field:"eid", minWidth: 140},
        {title:"Flags", field:"f", formatter: order_flags, formatterS: order_flagsS, minWidth: 100},
        {title:"Label", field:"ll", width: 60, minWidth: 80},
        {title:"OnlyMaker", field:"m", formatter: "tickCross", formatterS: (v)=>v, minWidth: 50},
        {title:"VisRest", field:"vr", formatter:dec, formatterS: decS, minWidth: 100},
        {title:"UpdateTime", field:"ut", formatter: dt_ns_ms, formatterS: dt_ns_msS, minWidth: 180},
        {title:"CreateTime", field:"ct", formatter: dt_ns_ms, formatterS: dt_ns_msS, minWidth: 180},
        //{title:"SendTime", field:"st", formatter: dt_ns_ms, formatterS: dt_ns_msS, minWidth: 180},
        {title:"TimeInForce", field:"tif", formatter: time_in_force, formatterS: time_in_forceS, minWidth: 100},
        {title:"ExpiryTime", field:"ed", formatter: dt_s, formatterS: dt_sS, minWidth: 140},
        {title:"AddedBy", field:"own", formatter: added_by, formatterS: added_byS, minWidth: 100},
      ];
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      let cols = this.props.filter.cols;
      //console.log(cols);
      if (cols === '_all_' || cols === undefined) cols = columns;
      else {
        cols = cols.map((title)=>_.find(columns, { 'title': title }));
      } 
      if (this.snapshot && this.max_time > 0 && Symbols.ready && wspriv.connected && wspriv.authorized) {
        this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
              self.subscribtion = new Subscribtion({
                ws: wspriv,
                smsg: {"P": "g_sor", "D": {
                  "sids": self.props.filter.sids === '_all_' ? null : self.props.filter.sids,
                  "aids": self.props.filter.aids === '_all_' ? null : self.props.filter.aids,
                  "mt": self.max_time,
                  "lim": self.loadOnScroll,
                }},
                eternal: false,
                onResult: function(msg) {
                  let ors = Orders.parse(msg);
                  //console.log("Subscribtion", self.max_time, ors);
                  self.max_time = getMinNsecTime(ors, "ct", self.max_time);
                  //console.log("onScrollPromise", tab.getRowsLength(), ors.length);
                  if (ors.length > 0) resolve({data: ors});
                  else resolve({no_more_data: true, data: []});
                }, 
                onError: function(msg) {
                  reject(msg);
                }
              });
            });
          },
          columns: cols,
        });
        this.tab.setSort("ct", "desc");
        this.onChange({ors: _.cloneDeep(this.snapshot)});   
      } else {
        this.tab = new TableX("#" + div.id, {
          columns: cols,
        });        
      }
    }
  }

  componentDidMount() {
    //console.log("componentDidMount");
    if (this.props.filter.dt > 0) {
      this.snapshot = [];
      this.max_time = this.props.filter.dt * 1000000000;
    }
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onSymbolsChange() {
    //console.log("onSymbolsChange");
    this.componentDidMount();
  }

  onChange(data) {
    //console.log("onChange");
    if (this.props.filter.dt > 0) {
      if (this.tab == null) this.componentDidMount();
      return;
    }
    if (data.max_time > 0) {//reset table on reconnect
      this.snapshot = Orders.snapshot;
      this.max_time = Orders.max_time;
      this.componentWillUnmount();
      this.componentDidMount();
    }    
    if (this.tab) {
      let sids = this.props.filter.sids;
      let aids = this.props.filter.aids
      let ors_ = [];
      data.ors.forEach((or)=>{
        if (Orders.isStop(or)) ors_.push(or);
      });
      if (sids === '_all_' && aids === '_all_') {
        this.tab.updateOrAddData(ors_, true);
      } else {
        ors_.forEach((order)=>{
          if ((sids.indexOf(order["sid"]) !== -1 || sids === '_all_') && (aids.indexOf(order["aid"]) !== -1 || aids === '_all_')) this.tab.updateOrAddData([order], true);
        });
      }
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
      <OrdersListener sids={this.props.filter.sids} onChange={this.onChange} />
      <SymbolsListener onChange={this.onSymbolsChange} />
      <div ref={this.tabRef} />
      </React.Fragment>
  }
}

export const StopsTable = withFilter(withSize({ monitorHeight: true })(StopsTable_));
