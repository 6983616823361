import React from "react";
import _ from "lodash";

import { strings } from '../localization/strings';

import { wspriv, WSPrivListener, withWSPrivKey } from "../ws-priv/ws-priv";
import Dialog from '@material-ui/core/Dialog';

import { AuthFirebase, LogOut } from '../ws-priv/auth-firebase'
//import { Theme } from '../styles/select-theme';

import Subscribtion from "../utils/subscribtion";

//import QrCode from "qrcode.react";

//import '../styles/css/two-fa.css';

//import Popover from '@material-ui/core/Popover';

import { LoginOrRegister } from "../ws-priv/login-or-register";

import { Button, ButtonConfirm } from "../components/button";

import { TwoFA } from "../ws-priv/two-fa";

/*import { 
  ICheck, 
} from '../styles/icons';*/

import {
  dt_ns_sS,
} from "../utils/cell-formatters";

import '../styles/css/accounts.css';

export function Security(props) {
	return <React.Fragment>
    <div className="default-column">
  		<TwoFA not_standalone/>
      <br/>
      <Password/>
      <br/>
      <Devices/>
    </div>
		<LoginOrRegister/>
	</React.Fragment>
}

export function Password(props) {
  return <React.Fragment>
    <div className="default-info">
      <div className="p4">{strings.Security_.ChangePassword}:</div>
        <ButtonConfirm text={strings.Security_.SendPasswordResetText} 
          onClick={()=>{AuthFirebase.sendPasswordChangeEmail();}}>
          {strings.Security_.SendPasswordResetLink}
        </ButtonConfirm>{'\u00A0\u00A0'}
    </div>
  </React.Fragment>
}

class Devices_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devs: [],
      enabled: false,
    }
    this.columns = [
      //{title:"Show", field:"did", formatter: QRCodeFormatter},
      //{title:"Addresses", field:"did", formatter:AddressFormatter},
      //{title:"Device", field:"vid"},
      {title:"Platform", field:"pl"},
      {title:"Browser", field:"br"},
      {title:"IP", field:"ip"},
      {title:"LastLogin", field:"ut", formatter: dt_ns_sS},
    ];
  }

  componentDidMount() {
    let self = this;
    /*new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_dcf"},
      eternal: false,
      onResult: function(msg) {
        if (!this.will_unmount) self.setState({enabled: msg["D"]["e"]});
      }, 
      onError: function(msg) {
      }
    });*/
    new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_dev"},
      eternal: false,
      onResult: function(msg) {
        msg["D"]["devs"].sort(function (a, b) {return b["ut"] - a["ut"];});
        msg["D"]["devs"].forEach((dev)=>{
          _.forEach(dev.data, (v, k)=>dev[k] = dev.data[k]);
        })
        if (!this.will_unmount) self.setState({devs: msg["D"]["devs"]});
      }, 
      onError: function(msg) {
      }
    });
  }
  componentWillUnmount() {
    this.will_unmount = true;
  }

  render() {
    //let props = this.props;
    let state = this.state;
    let self = this;
    console.log(state.devs);
    return <React.Fragment>
      {null/*<div className="default-info">
        <div className="p4">{strings.Security_.Devices}:</div>
        {state.enabled ? <ICheck color="#3BC471"/> : null}{'\u00A0'}
        {state.enabled ? <div className="p4b">{strings.Enabled}</div> : <div className="p4b col-red">{strings.Disabled}</div>} 
        <Button onClick={()=>{
          AuthFirebase.sendPasswordChangeEmail();
          }} 
          className="p4b bg-blue">
            {strings.Security_.SendPasswordResetLink}
        </Button>{'\u00A0\u00A0'}
      </div>*/}
      <div className="default-info">
        <div className="p4">{strings.Security_.Devices}:</div>
      </div>
      {state.devs.length > 0 && <div className="t-accs">
        <table>
          <thead className="p3 col-night-sky"><tr>
             {this.columns.map((col)=>{return <th key={col.title} className="bg-grey-light">
                <div className="th-content">
                  <div className="th-title">
                    {strings.Security_[col.title]}
                  </div>
                </div>
              </th>})}
          </tr></thead>
          <tbody className="p3 col-grey">
          {state.devs.map((dev, i)=>{return <tr key={i}>
              {this.columns.map((col)=>{return <td key={col.title}>
                { dev[col.field]
                  ? (col.formatter 
                    ? col.formatter(dev[col.field], self) 
                    : dev[col.field])
                  : ""
                }
                </td>})
              }
            </tr>})
          }
          </tbody>
        </table>      
      </div>} 
    </React.Fragment>
  }
}

export const Devices = withWSPrivKey(Devices_);

export class VerifyDeviceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onWSStateChange = this.onWSStateChange.bind(this);
    this.state = {
      open: wspriv.device_verification,
    }
  }

  onWSStateChange() {
    this.setState({open: wspriv.device_verification});
    //this.setState({open: true});
  }

  render() {
    return <div>
      <WSPrivListener onChange={this.onWSStateChange}/>
      {this.state.open && <Dialog 
        open={this.state.open}
        >
        <div className="default-popup-title p4b">
          {strings.Security_.DeviceVerification}
        </div>
        <div className="default-popup popup-confirm">
          <div>
            <span className="p4">{strings.formatString(strings.Security_.DeviceVerificationSent1, AuthFirebase.email)}{'\u00A0'}</span>
            <span className="p4b">{strings.formatString(strings.Security_.DeviceVerificationSent2, AuthFirebase.email)}</span>
          </div>
          <br/>
          <div className="confirm-buttons">
            <Button onClick={()=>{
              wspriv.send({"P": "cnf_dev"});
            }} className="p4b bg-blue">
              {strings.Security_.SendVerificationEmail}
            </Button>
            <LogOut>{strings.Cancel}</LogOut>
          </div>
        </div>
      </Dialog>}
    </div>
  }
};