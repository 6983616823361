/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React from 'react';
import _ from "lodash";
import { strings, enums } from '../localization/strings';
//import { api } from '../localization/api';

import {
//  Link,
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import '../styles/css/api-documentation.css';

import { isMobile } from "react-device-detect";
import { isTestEnv } from "../utils/utils";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Language, withLanguageKey } from '../localization/select-language';
import { IChevronDown, IChevronUp } from '../styles/icons';

let id_map = {};

//recursive
function populate_id_map(chapter) {
	if (chapter.chapters) {
		chapter.chapters.forEach(function(ch) {
			id_map[ch.id] = ch.name;
			populate_id_map(ch);
		});
	}
}

function field(param, type, description) {
	return '<tr><td>' + param + '   </td><td><a href="#' + type + '">' + type + '</a></td><td>' + anchors(description) + '</td></tr>'
}

function anchors(str) {
  //str = str.replace(/\[\[(.*?)\]\]/g,'<a href="#$1">$1</a>');
  //str = str.replace(/"> *?<\/a>/g,'_');
	str = str.replace(/\[\[(.*?)\]\]/g, (match, val) => '<a href="#' + val + `">` + ( id_map.hasOwnProperty(val) ? id_map[val] : val ) + `</a>`);
	str = str.replace(/\[youtube\[(.*?)\]\]/g, (match, val) => `<div class="resp-container">
		<iframe class="resp-iframe" width="560" height="315" src="https://www.youtube.com/embed/` + val + `" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>`);
	str = str.replace(/\[img\[(.*?)\]\]/g, (match, val) => `<img src="/v2/img/` + val + `"></img>`);
  str = str.replace(/\"\> *?\<\/\a\>/g,'_');
	return str;
}

function chapter(ch) {
	let api = '';
	//description
	if (ch.hasOwnProperty('description') && ch.description !== '') {
		if (!ch.description.startsWith('<br>')) api += ' - ';
		api += '<span>' + anchors(ch.description) + '</span>';
	}
	//message examples
	let tags = ['request', 'subscribe', 'error', 'snapshot', 'reply', 'update', 'unsubscribe', 'unsubscribe-error', 'unsubscribe-reply'];
	let titles = ['request', 'subscribe', 'error', 'snapshot', 'reply', 'update', 'unsubscribe', 'error', 'reply'];
	for (let k = 0; k < tags.length; k++) {
		if (ch.hasOwnProperty(tags[k])) {
			api += '<br/><table>';
			for (let k = 0; k < tags.length; k++) {
				let examples = ch[tags[k]];
				if (examples !== undefined) 
					for (let j = 0; j < examples.length; j++) {
						let text = examples[j];
						api += '<tr><td><code>' + titles[k] + ':</code></td><td><code>' + text + '</code></td></tr>';
					}
			}
			api += '</table>';
			break;
		}
	}
	//change log
	if (ch["change_log"] !== undefined) {
		api += '</br><table><tr><th>Version</th><th>Date</th><th>Description</th></tr>';
		_.each(ch["change_log"], function(val, key) {
				api += '<tr><td>' + key + '   </td><td>' + val[0] + '</td><td>' + val[1] + '</td></tr>';
		});
		api += '</table>';
	}
	//types
	if (ch.types !== undefined) {
		api += '</br><table><tr><th>Parameter</th><th>Description</th></tr>';
		_.each(ch.types, function(val, key) {//simple type
			if (typeof val == "string") {
				if (enums.hasOwnProperty(key)) {//enums from commons
					let val_ = ":";
					_.each(enums[key], function(val1, key1) {
						if (key1 !== -1) {
							if (val_ !== ":") val_ += ",";
							val_ += " " + val1 + " = " + key1;
						}
					});
					val += val_;
				}
				api += '<tr><td id="' + key + '">' + key + '   </td><td>' + anchors(val) + '</td></tr>';
			} else if (typeof val == 'object') {//nested table
				let nestedTable = '<table><tr><th>Parameter</th><th>Type</th><th>Description</th></tr>';
				_.each(val, function(val1, key1) {
					nestedTable += field(key1, val1[0], val1[1]);
				});
				nestedTable += '</table>';
				api += '<tr><td id="' + key + '">' + key + '   </td><td>' + nestedTable + '</td></tr>';
			}

		});
		api += '</table>';
	}
	//error codes
	if (ch["codes"] !== undefined) {
		let codes = ch["codes"];
		if (codes instanceof Function) codes = codes();
		//console.log(codes);
		api += '</br><table><tr><th>Code</th><th>String</th></tr>';
		_.each(codes, function(val, key) {
			if (!key.startsWith('_'))
				api += '<tr><td id="' + key + '">' + key + '   </td><td>' + val + '</td></tr>';
		});
		api += '</table>';
	}
	//parameters
	if (ch["params"] !== undefined) {
		api += '</br><table><tr><th>Parameter</th><th>Type</th><th>Description</th></tr>';
		_.each(ch["params"], function(val, key) {
			api += field(key, val[0], val[1]);
		});
		api += '</table>';
	}
	//general_params
	if (ch["general_params"] !== undefined) {
		api += '</br><table><tr><th>Parameter</th><th>Type</th><th>Description</th></tr>';
		_.each(ch["general_params"], function(val, key) {
			api += '<tr><td id="' + key + '">' + key + '   </td><td><a href="#' + val[0] + '">' + val[0] + '</a></td><td>' + anchors(val[1]) + '</td></tr>';
		});
		api += '</table>';
	}
	//footer
	if (ch.hasOwnProperty('footer')) {
		api += '<span>' + anchors(ch.footer) + '</span>';
	};
  	return api;
}

export class Chapters extends React.Component {
  render() {
  	let nrs = this.props.numbers;
  	let chrs = this.props.chapters;
  	nrs.push(0);
  	return <React.Fragment>
  		{chrs.map((ch)=>{
			//let href = ch.name.replace(/ /g,'_');
			let href = ch.id;
			let number = '';
			++nrs[nrs.length - 1];
			nrs.forEach(function (nr) {
				number += nr + '.';
			});
  			return <div key={ch.name}  className="p3">
  				<br/><br/><br/><b id={href} className="api-chapter">{number} {ch.name}</b>
  				<span dangerouslySetInnerHTML={{__html: chapter(ch)}}/>
  				{ch.chapters && <Chapters chapters={ch.chapters} numbers={_.cloneDeep(nrs)}/>}
  			</div>
  		})}
  	</React.Fragment>;
  }
}

export class TableOfContents extends React.Component {
  render() {
  	let nrs = this.props.numbers;
  	let chrs = this.props.chapters;
  	nrs.push(0);
  	return <React.Fragment>
  		{chrs.map((ch)=>{
				//let href = ch.name.replace(/ /g,'_');
				let href = ch.id;
				let number = '';
				++nrs[nrs.length - 1];
				nrs.forEach(function (nr) {
					number += nr + '.';
			});
			let margin = '';
			for (let i = 0; i < (nrs.length - 1) * 2; i++) {
				margin += '\u00A0\u00A0';
			}
  			return <div className="api-table-of-contents" key={ch.name}>
  				{nrs.length === 1 && 
  					<React.Fragment>
  						<br/>{margin}<HashLink className="p2b" to={'#' + href}>{number} {ch.name}</HashLink>
  					</React.Fragment>}
  				{nrs.length > 1 && 
  					<React.Fragment>
  						{margin}<HashLink className="p2" to={'#' + href}>{number} {ch.name}</HashLink>
  					</React.Fragment>}
  				{ch.chapters && <TableOfContents chapters={ch.chapters} numbers={_.cloneDeep(nrs)}/>}
  			</div>
  		})}
  	</React.Fragment>;
  }
}

function APIDocumentation_(props) {
	let api = props.api[Language.current];
	populate_id_map(api);
	//console.log(id_map);	  
	return <React.Fragment>
		{ !isMobile &&	<APIMenuLeft api={api}/> }
		<div className="api">
			<div className="api-title p1b">{api.name}</div>
			<div className="api-title p2">{api.version}</div>
			<div className="p2b">{strings.TableOfContents}</div>
			<TableOfContents chapters={api.chapters} numbers={[]}/>
			<br/>
			<Chapters chapters={api.chapters} numbers={[]}/>
		</div>
	</React.Fragment>
}

export const APIDocumentation = withLanguageKey(APIDocumentation_);


/*class SelectedAnchor {
	constructor() {
		SelectedAnchor.observer = new IntersectionObserver((entries, observer)=>{
				if (Date.now() - SelectedAnchor.last_hash_change > 300)
					//console.log("observer " + entries[0].target.id);
					SelectedAnchor.listeners.forEach((f)=>{f(entries[0].target.id)});
			}, 
			{root: document.querySelector('.api-chapter')}
		);

		window.addEventListener("hashchange", ()=>{
			SelectedAnchor.last_hash_change = Date.now();
			//console.log("hash " + window.location.hash.substring(1));
			SelectedAnchor.listeners.forEach((f)=>{f(window.location.hash.substring(1))});
		}, false);

		SelectedAnchor.last_hash_change = 0;
		SelectedAnchor.listeners = [];
	}

	static bindApiChapters() {
	  document.querySelectorAll('.api-chapter').forEach((chapter) => {
	    SelectedAnchor.observer.observe(chapter);
		});
	}

  static addListener(f) {
    this.listeners.push(f);
  }
  
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }  	
}

new SelectedAnchor();*/

//export function APIMenuLeft(props) {
class APIMenuLeft extends React.Component {
  constructor(props) {
    super(props);
    this.api = props.api;
  }

  componentDidMount() {
    let self = this;
		window.addEventListener("hashchange", ()=>{
	  	self.setClosed(self.api);
	  	let id = window.location.hash.substring(1);
	  	self.setOpened(id, self.api);
	  	self.forceUpdate();
		}, false);
  }

  componentWillUnmount() {
  }

  setClosed(chapter) {//recursive
  	let self = this;
  	chapter.open = false;
  	if (chapter.chapters) chapter.chapters.forEach((ch)=>self.setClosed(ch));
  }

  setOpened(id, chapter) {//recursive
  	let self = this;
  	if (chapter.chapters) chapter.open = chapter.chapters.some((ch)=>self.setOpened(id, ch));
		if (chapter.id == id) chapter.open = true;
		return chapter.open;
  }

	render() {
		return <div className="api-menu-left" style={{top: isTestEnv ? 43 + 15 : 15 }}>
      <List component="nav">
      	<ListItem 
      		key="Table_of_contents"
			    button
			    onClick={()=>{window.location.hash = ""}}>
			    <div className="p2">{strings.TableOfContents}</div>
			  </ListItem>
        {this.api.chapters.map((ch)=><APIMenuChapter key={ch.id} ch={ch}/>)}
      </List>
	  </div>	
	}
}

class APIMenuChapter extends React.Component {
	render() {
		let ch = this.props.ch;
	  //const [open, setOpen] = React.useState(false);
	  if (ch.chapters) return <React.Fragment key={ch.id + "_"}>
			<ListItem key={ch.id + "_list_item"} button onClick={()=>{ch.open = !ch.open; window.location.hash = ch.id; this.forceUpdate();}}>
	      {'\u00A0'}{ch.open ? <IChevronUp size="15"/> : <IChevronDown size="15"/>}
	      <div className={ch.open ? "p2b" : "p2"}>{ch.name}</div>
	    </ListItem>
	    <Collapse key={ch.id + "_collapse"} in={ch.open} timeout="auto" unmountOnExit>
	      <List component="div" disablePadding style={{paddingLeft: "20px"}}>
	        {ch.chapters.map((ch)=><APIMenuChapter key={ch.id} ch={ch}/>)}
	      </List>
	    </Collapse>
	  </React.Fragment>
		else return <ListItem 
	    key={ch.id}
	    button
	    onClick={()=>{window.location.hash = ch.id}}>
	    <div className={ch.open ? "p2b" : "p2"}>{ch.name}</div>
	  </ListItem>;
	}
}


