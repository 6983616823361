import React from "react";
import { isMobile } from "react-device-detect";

export function bindTrigger(state) {
  return {
    ref: state.self.anchorRef,
    onClick: ()=>state.self.setState({open: true}),
    onTouchEnd: (e)=>{
      e.preventDefault();
      state.self.setState({open: true})
    },
  };
}

export function bindPopover(state) {
  return {
    open: state.open,
    onClose: ()=>state.self.setState({open: false}),
    anchorEl: state.self.anchorRef.current,
  };
}

function hasSomeParentTheClass(element, classname) {
  /*console.log("1", element.className, element.hasOwnProperty("className"));
  if (typeof element.className === 'string') console.log("!", element.className, element.className.split(' ').indexOf(classname)>=0); */
  //console.log(element.hasOwnProperty("className"));
  if (typeof element.className === 'string' && element.className.split(' ').indexOf(classname)>=0) return true;
  return element.parentElement && hasSomeParentTheClass(element.parentElement, classname);
}

export default class PopupState extends React.Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.state = { 
      open: false,
      self: this,
      close: ()=>this.setState({open: false}),
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {if (isMobile) document.addEventListener('touchend', this.handleClickOutside);}
  componentWillUnmount() {if (isMobile) document.removeEventListener('touchend', this.handleClickOutside);}
  handleClickOutside(e) {//will work without this, but with is better on high load
    let r = hasSomeParentTheClass(e.target, "MuiPopover-root");
    let p = hasSomeParentTheClass(e.target, "MuiPopover-paper");
    //console.log(r, p);
    if (r && !p) this.setState({open: false});
  }

  render() {
    return this.props.children(this.state);
  }
}
