import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";
import { withInsDefinitions } from "../ws-pub/instruments";
//import { strings } from '../localization/strings';
import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import {
  dec,
  sec_id,
  dir,
  dt_ns_ms,
  maker_taker,
  trade_flags,
  decS, 
  sec_idS, 
  dirS, 
  dt_ns_msS, 
  maker_takerS,
  trade_flagsS,
} from "../utils/cell-formatters";
import { withFilter, getMinNsecTime, hot_table_length } from '../utils/utils'

export class Trades {
  static addListener(f) {
    this.listeners.push(f);
    if (wspriv.connected && Trades.max_time > 0) f({ trs: Trades.snapshot, max_time: Trades.max_time }); //snapshot
  }
  static removeListener(f) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }

  static parse(msg) {
    if (msg["P"] === "g_aor" || msg["P"] === "n_or" || msg["P"] === "c_or") return null;
    var trs = null;
    var ors = null; 
    if (msg["D"].hasOwnProperty("ors")) ors = msg["D"]["ors"];
    if (msg["D"].hasOwnProperty("or")) {ors = []; ors.push(msg["D"]["or"])};
    if (ors != null && ors.length > 0) {
      trs = [];
      ors.forEach(function(or) {//get trades from orders updates
        if (or.hasOwnProperty("trs")) {
            or["trs"].forEach(function (tr) {
            tr["aid"] = or["aid"];
            tr["sid"] = or["sid"];
            tr["ono"] = or["ono"];
            tr["d"] = or["d"];
            tr["f"] = or["f"];
            tr["ll"] = or["ll"];
            trs.push(tr);
          });
        }
      });
      if (trs.length === 0) return;
    }    
    if (msg["D"].hasOwnProperty("trs")) {trs = msg["D"]["trs"];}
    if (msg["D"].hasOwnProperty("tr")) {trs = []; trs.push(msg["D"]["tr"]);};
    if (trs != null) {
      var trades = [];
      trs.forEach(function (trade) {
        trade.id = trade["sid"] + '_' + trade["tno"] + '_' + trade["d"];
        trades.push(trade);
      });
      if (msg.hasOwnProperty("I")) {//subscribtion scroll update
        return trades;
      }
      if (msg["Y"] === "r" && msg["P"] === "tr") {//snapshot
        Trades.max_time = msg["D"]["mt"];
        Trades.snapshot = [];
      } 
      trs.forEach(function (trade) {Trades.snapshot.push(trade)      });
      this.listeners.forEach((f) => {f({ trs: _.cloneDeep(trades), max_time: msg["D"]["mt"]});}); // update      
    }
  }
}

Trades.snapshot = [];
Trades.max_time = 0;
Trades.listeners = [];
Trades.columns = [
  {title:"AccID", field:"aid", minWidth: 100},
  {title:"Instrument", field:"sid", formatter: sec_id, formatterS: sec_idS, minWidth: 110},
  {title:"oNo", field:"ono", minWidth: 110},
  {title:"Dir", field:"d", formatter: dir, formatterS: dirS, minWidth: 50},
  {title:"Price", field:"p", formatter:dec, formatterS: decS, minWidth: 100},
  {title:"Qty", field:"q", formatter:dec, formatterS: decS, minWidth: 100},
  {title:"VolBTC", field:"v", formatter:dec, formatterS: decS, minWidth: 100},
  {title:"Maker", field:"m", formatter: maker_taker, formatterS: maker_takerS, minWidth: 100},
  {title:"tNo", field:"tno", minWidth: 110},
  {title:"Label", field:"ll", width: 60, minWidth: 80},
  {title:"CreateTime", field:"dt", formatter: dt_ns_ms, formatterS: dt_ns_msS, minWidth: 180},
  {title:"Commission", field:"cm", formatter:dec, formatterS: decS, minWidth: 100},
  //{title:"Flags", field:"f", formatter: order_flags, formatterS: order_flagsS, minWidth: 100},
  {title:"TradeFlags", field:"ono", formatter: trade_flags, formatterS: trade_flagsS, minWidth: 100},
];



export class TradesListener extends React.Component {
  componentDidMount() {Trades.addListener(this.props.onChange)}
  componentWillUnmount() {Trades.removeListener(this.props.onChange,)}
  render() {
    return null;
  }
}

class TradesTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.loadOnScroll = 100;
    this.tab = null;
    this.max_time = 0;
    this.hot = props.filter.dt < 0;
  }

  initTable() {
    //console.log("initTable", Symbols.ready);
    if (this.tab == null && this.tabRef.current) {
      let self = this;
      let div = this.tabRef.current;
      div.id = this.props.id;
      let cols = this.props.filter.cols;
      //console.log(cols);
      if (cols === '_all_' || cols === undefined) cols = Trades.columns;
      else {
        cols = cols.map((title)=>_.find(Trades.columns, { 'title': title }));
      }      
      if (this.max_time > 0 && wspriv.connected && wspriv.authorized) {
        this.tab = new TableX("#" + div.id, {
          onScrollPromise: function(tab) {
            return new Promise(function(resolve, reject){
              self.subscribtion = new Subscribtion({
                ws: wspriv,
                smsg: {"P": "g_tr", "D": {
                  "sids": self.props.filter.sids === '_all_' ? null : self.props.filter.sids,
                  "aids": self.props.filter.aids === '_all_' ? null : self.props.filter.aids,
                  "mt": self.max_time,
                  "lim": self.loadOnScroll,
                  "hot": self.hot,
                }},
                eternal: false,
                onResult: function(msg) {
                  var trs = Trades.parse(msg);
                  //console.log("Subscribtion");
                  //console.log(trs);
                  self.max_time = getMinNsecTime(trs, "dt", self.max_time);
                  console.log("onScrollPromise", tab.getRowsLength(), trs.length);
                  if (trs.length > 0) resolve({data: trs});
                  else {
                    if (self.hot && (tab.getRowsLength() > hot_table_length || self.props.filter.sids !== '_all_' || self.props.filter.aids !== '_all_')) {//request from cold
                      self.hot = false;
                      resolve({request_again: true, data: []});
                    } else {//looks like no more data
                      resolve({no_more_data: true, data: []});
                    }
                  }
                }, 
                onError: function(msg) {
                  reject(msg);
                }
              });
            });
          },
          columns: cols,
        });
        this.tab.setSort("dt", "desc");
        this.onChange({trs: Trades.snapshot});   
      } else {
        this.tab = new TableX("#" + div.id, {
          columns: cols,
        });
      }
    }
  }

  componentDidMount() {
    //console.log("componentDidMount");
    if (this.props.filter.dt > 0) {
      this.max_time = this.props.filter.dt * 1000000000;
    }
    this.initTable();
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onChange(data) {
    //console.log("onChange");
    if (this.props.filter.dt > 0) {
      if (this.tab == null) this.componentDidMount();
      return;
    }
    if (data.max_time > 0) {//reset table on reconnect
      this.max_time = Trades.max_time;
      this.componentWillUnmount();
      this.componentDidMount();
    }    
    if (this.tab) {
      let sids = this.props.filter.sids;
      let aids = this.props.filter.aids
      if (sids === '_all_' && aids === '_all_') {
        this.tab.updateOrAddData(data.trs, true);
      } else {
        data.trs.forEach((trade)=>{
          if ((sids.indexOf(trade["sid"]) !== -1 || sids === '_all_') && (aids.indexOf(trade["aid"]) !== -1 || aids === '_all_')) this.tab.updateOrAddData([trade], true);
        });
      }
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
      <TradesListener sids={this.props.filter.sids} onChange={this.onChange} />
      <div ref={this.tabRef} />
    </React.Fragment>
  }
}

export const TradesTable = withFilter(withInsDefinitions(withSize({ monitorHeight: true })(TradesTable_)));