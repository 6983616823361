import {strings} from '../localization/strings';
import '../styles/css/table-x.css';

export default class TableX {
    constructor(el_id, options) {
        let self = this;
        this.lastRowId = 0;
        this.el_id = el_id.slice(1);
        this.options = options;
        this.colsv = [];
        this.cols = {}; //by field
        this.rows = {}; //by id
        this.el = document.getElementById(this.el_id);
        this.el.style.overflowX = "auto";
        this.el.style.width = "100%";
        this.el.classList.add("tablex");
        this.el.style.overflowY = "auto";
        this.el.style.height = (options.height ? options.height : "100%"); 
        this.table = document.createElement("table");
        this.trs = this.table.rows;
        this.colgroup = document.createElement("colgroup");
        this.thead = document.createElement("thead");
        this.tbody = document.createElement("tbody");
        this.table.appendChild(this.colgroup);
        this.table.appendChild(this.thead);
        this.table.appendChild(this.tbody);

        let tr = document.createElement("tr");
        if (options.theadClassNames) {
            options.theadClassNames.forEach((c)=>this.thead.classList.add(c));
        } else {
            this.thead.classList.add("p3");
            this.thead.classList.add("col-night-sky");
        }
        if (options.tbodyClassNames) {
            options.tbodyClassNames.forEach((c)=>this.tbody.classList.add(c));
        } else {
            this.tbody.classList.add("p3");
            this.tbody.classList.add("col-grey");
        }
        for (let i = 0; i < this.options.columns.length; i++) {
            let col = {def: this.options.columns[i]};
            col.field = col.def.field;
            if (col.def.visible === undefined || col.def.visible === true) col.visible = true;
            else col.visible = false;
            if (col.visible) {
                let th = document.createElement("th");
                th.classList.add("bg-grey-light");
                let th_content = document.createElement("div");
                th_content.classList.add("th-content");
                let th_title = document.createElement("div");
                th_title.classList.add("th-title");
                let ecol = document.createElement("col");
                //ecol.style.width = (col.def.width ? col.def.width + "px" : "auto");
                th_title.textContent = col.def.title;
                th_content.appendChild(th_title);
                th.appendChild(th_content);
                tr.appendChild(th);
                this.colgroup.appendChild(ecol);
                col.th = th;
                col.th_title = th_title;
                col.ecol = ecol;
                if (options.headerSort !== false) {//add sort capability
                    let th_arrow = document.createElement("div");
                    th_arrow.classList.add("th-arrow");
                    th_arrow.classList.add("th-arrow-default");
                    col.th_arrow = th_arrow;
                    th_content.appendChild(th_arrow);
                    th.classList.add("sortable");
                    th.field = col.field;
                    if (col.def.minWidth) th.style.minWidth = col.def.minWidth + "px";
                    col.lastSortOrder = 0; //default
                    th.addEventListener("click", function(e) {//sort
                        console.log(self.defaultSortField, self.defaultSortOrder);
                        let col = self.cols[this.field];
                        ++col.lastSortOrder;
                        if (col.lastSortOrder > 2) {
                            col.lastSortOrder = 0;
                            if (self.defaultSortField === undefined) col.lastSortOrder = 1;
                        }
                        let orders = ["default", "desc", "asc"];
                        if (col.lastSortOrder === 0)
                            self.sortTable(self.defaultSortField, self.defaultSortOrder);
                        else 
                            self.sortTable(col.field, orders[col.lastSortOrder]);
                        self.colsv.forEach(function(col_) {
                            col_.th_arrow.classList.remove("th-arrow-up");
                            col_.th_arrow.classList.remove("th-arrow-down");
                            col_.th_arrow.classList.remove("th-arrow-default");
                            if (col_.field !== col.field) col_.lastSortOrder = 0;
                            
                            if (col_.lastSortOrder === 0) {
                                col_.th_arrow.classList.add("th-arrow-default");
                            } else if (col_.lastSortOrder === 1) {
                                col_.th_arrow.classList.add("th-arrow-down");
                            } else {
                                col_.th_arrow.classList.add("th-arrow-up");
                            }
                        });
                    });
                }
            }
            this.cols[col.field] = col;
            this.colsv.push(col);
        }
        if (options.headerVisible === false) tr.style.display = "none";
        this.thead.appendChild(tr);

        //for formatters and editors
        this.cell_ = {
            getValue: function() {return self.val;},
            getElement: function() {return self.td;},
            getRow: function() {return self.row_;},
            getColumn: function() {return self.col_;},
            getTable: function() {return self;},
            getData: function() {return self.data;},
        }
        this.row_ = {
            getData: function() {return self.data;},
            getElement: function() {return self.tr;},
        }
        this.col_ = {
            getDefinition: function() {return self.col.def;},
            getElement: function() {return self.col.th;},
        }

        this.fixedLayout = false;
        this.el.appendChild(this.table);

        //load on scroll
        if (options.onScrollPromise) {
            this.promise = null;
            this.noMoreDataToLoad = false;
            this.loadDataOnScroll();
        }

        //virtual dom
        this.virtualDomBuffer = 200; //pixels
        this.trTop = document.createElement('tr');
        this.tdTop = document.createElement('td');
        this.trTop.appendChild(this.tdTop);
        this.tdTop.colSpan = 1000;
        this.trBottom = document.createElement('tr');
        this.tdBottom = document.createElement('td');
        this.trBottom.appendChild(this.tdBottom);
        this.tdBottom.colSpan = 1000;
        this.tbody.appendChild(this.trTop);
        this.tbody.appendChild(this.trBottom);
        this.heightTop = 0;
        this.heightBottom = 0;

        this.trHeight = 23;

        this.rowsv = [];
        this.firstReal = 0;
        this.lastReal = -1;

        this.onScroll = this.onScroll.bind(this);
        this.el.addEventListener("scroll", this.onScroll);
        
        this.render();
    }

//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//Private methods
    onScroll() {
        //console.log("onScroll " + this.el.scrollTop);
        this.redraw();
    }

    fixLayout() {
        if (!this.fixedLayout) {
            this.fixedLayout = true;
            let width = 0;
            for (let i = 0; i < this.colsv.length; i++) {
                let col = this.colsv[i];
                if (col.visible) {
                    if (col.ecol.style.width === "auto") {
                        col.ecol.style.width = col.th.offsetWidth + "px";//fit table to first row
                    }
                    width += parseInt(col.ecol.style.width.slice(0, -2));
                }
            }
            if (this.options.layout === "fixed") {
                this.table.style.width = width + "px";
                this.table.style.tableLayout = "fixed";
            }
            if (this.table.rows.length > 3) this.trHeight = this.table.rows[2].offsetHeight;
        }   
    }     

    loadDataOnScroll() {
        if (this.noMoreDataToLoad) return;
        if (this.promise != null) return; //requesting already
        this.promise = this.options.onScrollPromise(this);
        let self = this;
        this.promise.then((data) => {
            self.addData(data.data, false);
            self.noMoreDataToLoad = data.no_more_data;
            this.promise = null;
            if (data.request_again) this.loadDataOnScroll();
        }).catch((error) => {
            //Log.error(error);
            console.error(error);
            this.promise = null;
        });        
    }

    addRow(data, top) {
        if (data.id === undefined) {
            data.id = ++this.lastRowId;
        }
        //console.log("add " + data.id);
        data.id += '';
        let row = {tr: null, tds: null, data: data};
        this.rows[data.id] = row;
        //console.log("length "+ this.rowsv.length);
        if (top) {
            this.rowsv.push(row);
            ++this.firstReal;
            ++this.lastReal;
            if (this.el.scrollTop > 0) {
                this.modifyVirtualHeight(true, +this.trHeight);
            }
        }
        else {
            this.rowsv.unshift(row);
            this.modifyVirtualHeight(false, +this.trHeight);
        }
        //console.log("length " + this.rowsv.length);
    }

    modifyVirtualHeight(top, val) {
        //console.log("modify " + (top ? "top" : "bottom") + " " + val);
        if (top) {
            this.heightTop += val;
            this.tdTop.style.height = this.heightTop + "px";

        } else {
            this.heightBottom += val;
            this.tdBottom.style.height = this.heightBottom + "px";
        }
    }

    setVirtualHeight(top, val) {
        //console.log("set " + (top ? "top" : "bottom") + " " + val);
        if (top) {
            this.heightTop = val;
            this.tdTop.style.height = this.heightTop + "px";

        } else {
            this.heightBottom = val;
            this.tdBottom.style.height = this.heightBottom + "px";
        }
    }

    redraw() {
        //console.log("redraw " + this.el_id);
        var length = this.rowsv.length;
        var rowsToDraw = parseInt(this.el.offsetHeight / this.trHeight) + 6;
        var first = parseInt(this.el.scrollTop / this.trHeight) - 5; // first row index
        first = (first >= length ? length - rowsToDraw : first);
        if (first < 0) first = 0;
        this.first = first;
        var last = first + rowsToDraw - 1; // last row index
        this.last = last = (last >= length ? length - 1 : last);

        var count = 0, i = 0, tr = null;
        //console.log(this.firstReal, this.lastReal, this.trs.length - 3); 
        //console.log(length - 1 - first, rowsToDraw, length - 1 - last); 

        /*
        let self = this;
        console.log("el.offsetHeight " + self.el.offsetHeight + ", " +
            "el.scrollHeight " + self.el.scrollHeight + ", " +
            "el.offsetHeight " + self.el.offsetHeight + ", " +
            "tr.offsetHeight " + self.trs[2].offsetHeight + ", " +
            "el.scrollTop " + self.el.scrollTop + ", " +
            "first " + self.first + ", " +
            "last " + self.last + ", " +
            "firstReal " + self.firstReal + ", " +
            "lastReal " + self.lastReal + ", " +
            "topHeight " + self.heightTop + ", " +
            "bottomHeight " + self.heightBottom + ", " +
            "rowsv.length " + length + ", " +
            "self.trHeight " + self.trHeight + ", " +
            "rowsv.length " + self.rowsv.length + ", " +
            "rowsToDraw " + rowsToDraw + "");*/


        if (this.firstReal < first || this.lastReal < last) {//remove out of view
            //console.log('+');
            count = first - this.firstReal;
            count = (count > this.trs.length - 3 ? this.trs.length - 3 : count);
            for(i = 0; i < count; ++i) {
                tr = this.trs[2];
                tr.row_ref.tr = null;
                tr.row_ref.tds = {};
                tr.remove();
            }
            this.setVirtualHeight(true, first * this.trHeight);
            this.firstReal += count; 
            if (this.trs.length === 3) {//removed all
                this.firstReal = first;
                this.lastReal = first - 1;
            }
            if (last > this.lastReal) {//draw in view
                for(i = this.lastReal + 1; i <= last; ++i) {
                    this.tbody.insertBefore(this.drawRow(this.rowsv[length - 1 - i]), this.trBottom);//add to the bottom            
                }
                this.lastReal = last;
                this.setVirtualHeight(false, (length - last - 1) * this.trHeight);
            }
            if (this.lastReal >= length - 10 && this.options.onScrollPromise) this.loadDataOnScroll();
        }
        if (first < this.firstReal) {
            //console.log('- ' + first + " " + this.firstReal);
            count = this.lastReal - last;
            count = (count > this.trs.length - 3 ? this.trs.length - 3 : count);
            for(i = 0; i < count; ++i) {
                tr = this.trs[this.trs.length - 2];
                tr.row_ref.tr = null;
                tr.row_ref.tds = {};
                tr.remove();
            }
            //console.log("countToDel " + count);
            this.setVirtualHeight(false, (length - last - 1) * this.trHeight);
            this.lastReal -= count; 

            if (this.trs.length === 3) {//removed all
                this.firstReal = last + 1;
                this.lastReal = last;
            }
            if (first < this.firstReal) {
                for(i = this.firstReal - 1; i >= first; --i) {
                    this.tbody.insertBefore(this.drawRow(this.rowsv[length - 1 - i]), this.trTop.nextSibling);//add to the top
                }
                this.firstReal = first;
                this.setVirtualHeight(true, first * this.trHeight);
            }
        }

        /*
        let self = this;
        $("#log___").html(
            "el.offsetHeight " + self.el.offsetHeight + "<br>" +
            "el.scrollHeight " + self.el.scrollHeight + "<br>" +
            "el.offsetHeight " + self.el.offsetHeight + "<br>" +
            "tr.offsetHeight " + self.trs[2].offsetHeight + "<br>" +
            "el.scrollTop " + self.el.scrollTop + "<br>" +
            "first " + self.first + "<br>" +
            "last " + self.last + "<br>" +
            "firstReal " + self.firstReal + "<br>" +
            "lastReal " + self.lastReal + "<br>" +
            "topHeight " + self.heightTop + "<br>" +
            "bottomHeight " + self.heightBottom + "<br>" +
            "rowsv.length " + length + "<br>" +
            "self.trHeight " + self.trHeight + "<br>" +
            "rowsv.length " + self.rowsv.length + "<br>" +
            "rowsToDraw " + rowsToDraw + "<br>"
        );*/
    }

    drawRow(row) {
        //console.log(row.data.id);
        this.tr = row.tr = document.createElement('tr');
        this.data = row.data;
        row.tds = {};
        let col;
        for (let i = 0; i < this.colsv.length; i++) {
            col = this.col = this.colsv[i];
            if (col.visible) {
                this.val = this.data[col.field];
                this.td = document.createElement('td');
                if (this.val !== undefined) {
                    this.format();
                }
                this.tr.appendChild(this.td);
                row.tds[col.field] = this.td;
            }
        }
        row.tr.row_ref = row;
        //tr.row_id = row.data.id;

        if (this.options.rowClick) {//handle row click
            let self = this;
            row.tr.addEventListener("click", function(e) {
                let row = this.row_ref;
                self.data = row.data; 
                self.tr = row.tr;
                self.options.rowClick(e, self.row_);
            });
        }

        return row.tr;
    }

    updateRow(r, data) {
        //console.log(update);
        let self = this;
        let diff = [];
        for(let k in data) { 
            if (r.data[k] !== data[k]) diff.push(k);
            r.data[k] = data[k];
        }
        this.data = r.data;
        if (r.tr == null) return;
        this.tr = r.tr;
        diff.forEach(function(k) {
            self.col = self.cols[k];
            if (self.col) {
                self.val = data[k];
                if (self.col.visible) {
                    self.td = r.tds[k];            
                    if (self.val !== undefined) {
                        self.format();
                    }
                }
            }
        });     
    }

    format() {//cell
        let col = this.col;
        let td = this.td;
        let f = col.def.formatter;
        if (f) {
            if (typeof f == "string") {
                if (f === "tickCross") {
                    td.style.fontWeight = "bold";
                    if (this.val) {
                        //td.style.color = "green";
                        td.innerHTML = "&#10003;";
                    } /*else {
                        td.innerHTML = "&#10005;";
                        //td.style.color = "red";
                        //td.textContent = "&#65794;&#10003;";
                    }*/
                }/* else if (f === "html") {
                    td.innerHTML = this.val;
                }*/
            } else {
                let val = f(this.cell_, col.def.formatterParams);
                if (val) td.textContent = val;
            }
        } else {
            td.textContent = this.val;
        }
    }

//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//****************************************************************************************
//Public methods

    addData(rows, top) {
        let scrollTop = this.el.scrollTop;
        for (let i = 0; i < rows.length; i++) {
            this.addRow(rows[i], top);
        } 
        this.redraw();
        if (!this.fixedLayout) this.fixLayout();      
        if (top && scrollTop > 0 && scrollTop === this.el.scrollTop) this.el.scrollTop += rows.length * this.trHeight; //preserve scroll position
    }

    updateOrAddData(datas, top) {
        let add = 0;
        let scrollTop = this.el.scrollTop;
        for (let i = 0; i < datas.length; i++) {
            let data = datas[i];
            if (data.id !== undefined) {
                let r = this.rows[data.id];        
                if (r !== undefined) {
                    this.updateRow(r, data);
                } else {
                    ++add;
                    this.addRow(data, top);
                }
            } else {
                ++add;
                this.addRow(data, top);
            }
        }  
        if (add > 0) {
            this.redraw();
            if (!this.fixedLayout) this.fixLayout();      
            if (top && scrollTop > 0 && scrollTop === this.el.scrollTop) this.el.scrollTop += add * this.trHeight; //preserve scroll position
        }
    }

    updateData(datas) {
        for (let i = 0; i < datas.length; i++) {
            let data = datas[i];
            if (data.id !== undefined) {
                let r = this.rows[data.id];        
                if (r !== undefined) {
                    this.updateRow(r, data);
                }
            }
        }       
    }

    deleteRow(rids) {
        let self = this;
        if (!Array.isArray(rids)) {
            rids = [rids];
        }
        rids.forEach(function(id) {
            id += '';
            let r = self.rows[id]; 
            if (r !== undefined) {
                if (r.tr != null) {
                    r.tr.remove();
                    r.tr = null;
                }
                r.data = null;
                delete self.rows[id];
            }
        });
        let length = this.rowsv.length;
        for(let i = length - 1; i >= 0; --i) {
            if (this.rowsv[i].data == null) {
                this.rowsv.splice(i, 1);
                let iReal = length - 1 - i;
                if (iReal >= this.firstReal && iReal <= this.lastReal) --this.lastReal;
            }
        }
        this.redraw();
    }

    clearData() {
        let rids = [];
        for(let k in this.rows) {
            rids.push(k);
        }
        this.deleteRow(rids);
    }

    replaceData(rows) {
        this.clearData();
        this.addData(rows);
    }

    destroy() {
        this.table.remove();
        this.el.removeEventListener("scroll", this.onScroll);
        delete this.rows;
        delete this.rowsv;
        delete this.cols;
        delete this.colsv;
        delete this.options;
    }

    getRows() {
        return this.rows;
    }

    getRowsLength() {
        return this.rowsv ? this.rowsv.length : 0;
    }

    getDefinitions() {return this.options.columns;}    

    setSort(field, order) {
        if (this.cols.hasOwnProperty(field)) {
            this.defaultSortField = field;
            this.defaultSortOrder = order;
            this.sortTable(field, order);
        } else {
            console.warn("TableX '" + this.el_id + "' setSort no column with field " + field);
        }
    }

    sortTable(field, order) {
        //console.log(field + " " + order);
        let asc = (order === "asc");
        let i, tr;
        if (asc) this.rowsv.sort(function(a, b) {return a.data[field] > b.data[field] ? -1 : 1;});
        else this.rowsv.sort(function(a, b) {return a.data[field] < b.data[field] ? -1 : 1;});
        let length = this.trs.length;
        for (i = length - 2; i >= 2; --i) {
            tr = this.trs[i];
            tr.row_ref.tr = null;
            tr.row_ref.tds = {};
            tr.remove();
        }
        this.firstReal = 0;
        this.lastReal = -1;
        this.el.scrollTop = 0;
        this.redraw();
    }

    render() {
        this.redraw();
        let lang = strings.getLanguage();
        if (this.language !== lang) {//set language
            this.language = lang;
            for (let i = 0; i < this.colsv.length; i++) {
                let col = this.colsv[i];
                if (col.visible) {
                    col.th_title.textContent = strings[col.def.title];
                }
            }
        }
    }
}
