/* eslint-disable no-sparse-arrays */

export const description = {
	'name':"Description of the exchange",
	'id':"Description_of_the_exchange",
	//'version':"Version 1.0",
	"chapters":[
	{
	'name':`Description of the workspace`,
	'id':`Getting_started`,
	'description':`<br><br>
	Workspace consists of several main parts.
	`,
	"chapters":[
		{
		'name':`Managing the Workspace`,
		'id':`Workspace`,
		'description':`<br><br>
		Here is the name of the trading system and the registration buttons, login and logout buttons, language switch and access to documentation including API documentation, account settings:
		<ul>
		<li>Security. Enabling or disabling two-factor authentication. Changing the access password. 
		<li>API keys. Generating and managing API keys for gateway connection.
		<li>Referral program.
		<li>Wallet. Depositing and withdrawal of funds. Transferring funds between own accounts (including those created and rendered for running by other traders). View your recent transfers.
		<li>Desktop. A side menu that appears on the left when the mouse cursor is hovered over.
		The menu consists of shortcuts to all tables available to bidders, including buttons for saving the template layout of windows.
		To save the template, click the blue cloud icon under the "Desktop" button.
		To return to the template window layout, you need to name one of the template names.
		</ul>
		`,
		},
		{
		'name':`Main workspace`,
		'id':`Main_workspace`,
		'description':`<br><br>
		All available tables can be placed on it. Tables can be moved around the workspace by gripping the top of the table with the mouse cursor and change their size. 
		Unnecessary tables can be deleted with a cross in the top right corner. The table can be returned via the left side menu. 
		By clicking the gear in the upper right corner, the contents of any table can be filtered by tools, date, time, select only the columns you need, etc. 
		Some tables allow you to download data to your computer.
		`,
		"chapters":[
			{
			'name':`All instruments`,
			'id':`All_instruments`,
			'description':`<br><br>
			A summary table of all available financial instruments. Below are the main parameters of this table:
			<ul>
			<li>Name – a short name of the instrument
			<li>Type – shows which group this instrument belongs to.
			<li>Description – full name of the instrument
			<li>Collateral. Shows how much BTC you need to have in order to be able to buy or sell one full lot. For example, to buy or sell 1 BTC, you need to have 0.1 BTC on your account, respectively, to make a transaction with 0.001 BTC you need to have 0.0001 BTC on your account.
			<li>The price step value. The value of the minimum possible price change for a given instrument, indicated in BTC.
			<li>Lower Price Limit. The price below which no order can be placed. If the order was placed earlier, when the limit allowed placing an order at this price, the order remains in the system, but new ones are not accepted.
			<li>Upper price limit. Similar to the lower one, only with a restriction in placing orders at prices that are higher than the limit.
			<li>Index. The value of the index for the given instrument.
			<li>Settlement price. The calculated value of the price taking into account the current bid, offer, last transaction price and index for this instrument.
			<li>Price Step. The minimum possible price change for the given instrument.
			<li>Lot. A minimal lot which it is possible to make a deal with.
			<li>Funding rate. The amount of write-offs and accruals every eight hours for the position holders in the given instrument. Indicated in percentage.
			<li>Minimal portion of a visible part of an iceberg order. Indicated in percentage.
			<li>Expiration type. 
			</ul>
			`,
			},
			{
			'name':`New order`,
			'id':`New_order`,
			'description':`<br><br>
			A table showing the basic parameters of a separate instrument, including risk parameters, and through which it is possible to place an order for a trade.
			`,
			},
			{
			'name':`All transactions`,
			'id':`All_transactions`,
			'description':`<br><br>
			The table reflects all trades on all instruments in one table. 
			Through the special menu in the upper right corner you can filter the content by the needed instruments, 
			as well as download the data for the specified period and the selected instruments.
			`,
			},
			{
			'name':`Accounts table`,
			'id':`Accounts_table`,
			'description':`<br><br>
			It reflects the current data on the trader’s account.<br>
			<b>Available</b>: current available balance for trading. <br>
			<b>Balance</b>: total revaluation of all assets, including all positions and free balances as well as P/L.  <br>
			<b>Reserved</b>: the amount of BTC reserved to maintain the current position and active orders. <br>
			<b>Account Management</b>. Each participant can create several additional accounts. 
			They can be used independently to segregate positions and can also be transferred to other registered participants. 
			The other participant will have access only to trading operations (without the right to deposit and withdraw) and only the limit that will be granted to him.<br>
			<b>Commission</b>. This table shows statistics on commission fees and current tariff plan set for the member.
			`,
			},
			{
			'name':`Orders, transactions and positions`,
			'id':`Orders_transactions_positions`,
			'description':`<br><br>
			Reflects the entire trading history for the account. It can be downloaded through the corresponding button in the upper right corner of the table 
			(selecting too deep history to download may lead to a long waiting period). There is also a filter by instrument and by time.
			`,
			},
			{
			'name':`Order book`,
			'id':`Order_book`,
			'description':`<br><br>
			A table in a minimalist style. You can place any number of them on all instruments simultaneously on your screen. 
			Clicking on any price in the order book opens a form for placing an order.
			`,
			},
			{
			'name':`History`,
			'id':`History`,
			'description':`<br><br>
			The table contains all the history of write-offs and accruals, including commissions, funding rates and other actions related to the account.
			`,
			},
			],
		},
		],
	},
	{
		'name':`Trading platforms`,
		'id':`Trading_platforms`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Futures contracts`,
			'id':`Futures_contracts`,
			'description':`<br><br>
			Futures contracts (specification of each contract is available in the table New Order - Instrument Parameters).<br>
			BTCUSDZ20 25th of December,  2020
			`,
			},
			{
			'name':`CFD, Permanent Contracts`,
			'id':`CFD`,
			'description':`<br><br>
			CFD, Permanent Contracts (the specification of each contract is available in the table New Order - Instrument Parameters).<br>
			BTCUSD permanent contract.
			`,
			},
			],
		},
	{
	'name':`Indexes`,
	'id':` Indexes  `,
	'description':`<br> <br>
	The index values are calculated based on data for a number of sites that are most popular in the corresponding type of asset.<br>
	Indexes reflect the market regardless of the state of individual assets and exchanges. The calculation base is regularly updated.
	`,
	},
	{
	'name':`Connection to bidding`,
	'id':`Connection_to_bidding`,
	'description':`<br><br>
	Connection to bidding is possible in two ways. Via the viking.trade website or using the API.
	`,
	"chapters":[
		{
		'name':`Exchange website`,
		'id':`Exchange_website`,
		'description':`<br><br>
		After registration on exchange website the user receives the whole set of tools for trading. 
		To register at the exchange it is necessary to provide a short set of basic personal data.
		`,
		},
		{
		'name':`API`,
		'id':`Gateway_connection`,
		'description':`<br><br>
		Also, there is an API for the possibility of using automated trading systems. See the documentation.
		`,
		},
		],
		},
		{
		'name':`Fees and commissions`,
		'id':`Fees_and_commissions`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Write-offs and accruals`,
			'id':`Write_offs_and_accruals`,
			'description':`<br>
			`,
			"chapters":[
				{
				'name':`Exchange Trade Commission`,
				'id':`Exchange_Trade_Commission`,
				'description':`<br><br>
				Maker and taker commissions for transactions are stipulated by the exchange rules, calculated and deducted in BTC at the moment of transaction. 
				Fixed commission is also calculated in BTC and makes a fixed amount. Usually used in individual tariff plans with a reduced taker and maker rate.<br><br>
				<table>
				<tr>
				<td>Maker</td>
				<td>0,02%</td>
				</tr>
				<tr>
				<td>Taker</td>
				<td>0,05%</td>
				</tr>
				<tr>
				<td>Fix</td>
				<td>0 BTC</td>
				</tr>
				</table>
				<br><br>				
				The commission for exit on expiration is calculated and deducted in BTC. The amount of the fee depends on the volume of the position at the time of expiration.<br><br>
				<table>
				<tr>
				<td>Expiration</td>
				<td>0,04%</td>
				</tr>
				</table>
				`,
				},
				{
				'name':`Individual tariff plan`,
				'id':`Individual_tariff_plan`,
				'description':`<br><br>
				Individual tariff plan gives the possibility to significantly reduce the costs of exchange trading commission for active users with high turnover. 
				Send offers for individual tariff to our contact email address.
				`,
				},
			],
		},
		{
		'name':`Funding rate`,
		'id':`Funding_rate`,
		'description':`<br><br>
		Mechanism of encouraging participants to trade towards the fairest price, namely, holders of long position to sell if the price at the moment 
		is overvalued against the "fair" one and holders of short position to buy if the price is undercut against the "fair" one.
		The exchange does not take any of it, in fact, the bulls pay the bears if the Funding rate at the time of calculation was positive and 
		the bears pay the bulls if the rate was negative. The current Funding rate is displayed in the corresponding table. 
		The final amount of the write-off or accrual depends on the Funding rate and the position at the time of the write-off. 
		Write-offs and accruals occur once an hour.
		The history of all write-offs and accruals of the Funding rate is recorded in the History table.
		`,
		},
		{
		'name':`Examples`,
		'id':`Examples`,
		'description':`<br>
		`,
		},
		{
		'name':`Commission fee on liquidation`,
		'id':`Commission_fee_on_liquidation`,
		'description':`<br><br>
		A commission fee is charged for transactions in cases of forced position reduction. 
		`,
		},
		{
		'name':`Commission for depositing and withdrawal`,
		'id':`Commission_for_depositing_and_withdrawal`,
		'description':`<br><br>
		There is no commission for depositing funds on the exchange. The commission for the withdrawal of funds depends on the blockchain to which the withdrawal is made. 
		For some blockchains, the withdrawal fee is limited to the network commission, for other blockchains, 
		the additional costs of the exchange associated with processing incoming and outgoing transactions are included in the withdrawal fee.
		If the withdrawal of funds is carried out to an address that is the address of the user's account (the same or another) on the exchange, then such a transaction is carried out quickly and without commission, since no transaction is created on the blockchain. The ID of such transactions in the user's transaction table begins with "internal" prefix.
		`,
		},
		{
		'name':`Overdraft`,
		'id':`Overdraft`,
		'description':`<br><br>
		Overdraft - the technical overdraft occurs when a client account BTC balance goes down to below zero, resulting in a negative balance. 
		In this case may be charged an additional commission.
		If your BTC balance is negative your non-BTC collateral can be traded into BTC.

		`,
		},
		],
	},
	{
	'name':`Types of Orders`,
	'id':`Types_of_order`,
	'description':`<br><br>
	Types of orders available for use:
	`,
	"chapters":[
		{
		'name':`Limit order`,
		'id':`Limit order`,
		'description':`<br><br>
		Limit order is used by default unless another order type is specifically selected. 
		In this mode the order immediately goes into the order book queue at a price and quantity that are specified by the user.<br>
		Types of limit orders:
		<ul>
		<li>GTC (Good Till Cancelled) - type of the order which is active by default. Once placed, 
		the order is valid until it is completely closed into deals or cancelled by the user (except in the case of margin call, 
		when the order can be canceled by the trading system).
		<li>FOK (Fill or Kill) - type of limit order requiring either immediate and full execution or immediate cancellation. 
		I.e. this type of order when being placed will be closed into a deal only if there is enough counter volume in the order book. 
		If the required volume is not found, the entire order is canceled. I.e. partial execution of the order is not allowed.
		<li> IOC (Immediate or Cancel) - the type of the limit order, which is placed in the order book at a specified price and is immediately canceled. 
		If you select IOC, the order will be fully executed if the counter volume is enough for the entire required volume. 
		It will be partially filled if the counter volume is less than the required volume and the remainder of the order is canceled immediately. 
		If there is no counter volume at a given price, the order will be immediately cancelled completely. 
		This type of order is suitable in case of high volatility on the market. It differs from FOK in that partial execution is allowed here.
		<li>GTD (Good Till Date) - type of the limit order valid till the moment of closing into deals or till the certain date. 
		The moment when you want to cancel the order is set in the appropriate field, which is activated when GTD is selected.
		</ul>
		`,
		},
		{
		'name':`Market order`,
		'id':`Market_order`,
		'description':`<br><br>
		The user specifies only the quantity necessary to buy or sell. The price is set automatically on pressing the confirmation and is equal to the counter price in the order book. 
		If the user wants to buy, the automatically selected price will be equal to the current offer in the order book. 
		If the user wants to sell, the price in the order will be equal to the current bid in the order book. 
		This provides an increased probability that the order will be immediately closed into a deal, but it is not guaranteed.
		`,
		},
		{
		'name':` Stop order`,
		'id':`Stop_order`,
		'description':`<br><br>
		This is a form of a deferred stock exchange order to automatically perform a transaction for buying or 
		selling a specified amount of an asset at the moment when the market price moves past the specified price point.
		`,
		"chapters":[
			{
			'name':`Stop market order`,
			'id':`Stop_Market_Order`,
			'description':`<br><br>
			Order is recommended when current positions are open and you want to protect yourself from excessive losses when the price goes to the unfavorable position. 
			This type of order specifies the activation price and amount. After confirmation of an order, it is not immediately placed in the queue in the order book, 
			but only after the market reaches the activation price. In fact, you are ordering the exchange to place a limit order to buy or sell at the current market price 
			only when a certain price is reached. To buy using this type of order, the market price must become higher or equal to the specified target price. 
			To sell, the market price must become less than or equal to the specified target price.
			`,
			},
			{
			'name':`Profit-market order`,
			'id':`Profit_market_order`,
			'description':`<br><br>
			Profit-market order is recommended when current positions are open and you want to place a closing order only when the activation price is reached. 
			In effect, you are instructing the exchange to automatically place an order to sell at the current prices only if the market price on the exchange is 
			equal to or higher than the activation price. In case of buying, on the contrary, an order to buy will be placed only if the price on the exchange will be 
			equal to or lower than that specified in the signal price.
			`,
			},
			{
			'name':`Stop-limit order`,
			'id':`Stop_limit_order`,
			'description':`<br><br>
			Unlike the profit-market order, when the activation price triggers, the order is placed at a specified price.
			`,
			},
			{
			'name':`Profit-limit order`,
			'id':`Profit_limit_order`,
			'description':`<br><br>
			Unlike the profit-market order, when the activation price triggers, the order is placed at a specified price.
			`,
			},
		],
		},
		{
		'name':`Iceberg order`,
		'id':`Iceberg_Order`,
		'description':`<br><br>
		A special type of order which allows a trader to hide from other participants a part of his volume in the placed order to minimize the influence on the market price. 
		In the "Visible lots" field the number that is visible to all other traders is entered.<br>
		When placing an iceberg order, a participant specifies the total volume of the order and the visible part that constantly pops up. 
		The visible portion is that portion of the volume which is visible to other participants. 
		When the visible portion is executed, the next visible portion will appear and get to the end of the queue at its price level and so on until total volume of the entire order is executed. 
		Thus the influence of large orders on the market price is eliminated.<br>
		The trading system sets the minimum portion of a visible part of an iceberg from total volume.
		`,
		},
		],
	},
	{
	'name':`Trading procedure`,
	'id':`Trading_procedure`,
	'description':`<br><br>
	Trade is carried out in a mode of anonymous transactions. Orders are closed into deals with observance of principles of classical auction: 
	closing of orders into transaction is carried out with priority of those orders which have offered the best price earlier than others. 
	Cancellation of the executed transaction is not possible. 
	Parameters of each transaction remain in the trading history forever and are available to all participants of the trade in anonymous mode. 
	Absolutely all participants have the same technical solutions for access to trading. 
	In the personal section, a participant can access the history of all his executed transactions
	`,
	},
	{
	'name':`Trading mode`,
	'id':`Trading_mode`,
	'description':`<br><br>
	The trading mode of the trading system is 24/7 and without days off. Breaks are allowed for a short-term planned technical maintenance and software updates. 
	There will be preliminary messages about planned technical works. Trading takes place in the mode of continuous clearing.
	`,
	},
	{
	'name':`Account and Wallet`,
	'id':`Account_and_wallet`,
	'description':`<br><br>
	An account and a wallet are created automatically after the registration procedure of a participant in the trading system.
	`,
	"chapters":[
		{
		'name':`Account and Subaccount`,
		'id':`Account_and_Subaccount`,
		'description':`<br><br>
		One account is automatically created for each participant. 
		Each participant can create additional subaccounts for himself or delegate (give control to another registered participant). 
		Only the participant who created the subaccounts can control over the movement of funds on all subaccounts.
		`,
		},
		{
		'name':`Wallet`,
		'id':`Wallet `,
		'description':`<br><br>
		The wallet in a trading system is multicurrency. For access to trading, a participant must deposit his wallet with any of the currencies for which depositing is open. 
		Any credited currency is accepted as collateral and allows you to make transactions with all instruments available in the trading system.  <br>
		The single cryptocurrency BTC is accepted as the main currency for calculations, income accrual, charging of commissions and losses write-off for all instruments. 
		All other currencies accepted as collateral for trading are converted into the amount equivalent to BTC at the current exchange rate.<br>
		A trader is allowed to have a negative BTC balance only if there are enough equivalent funds in other currencies. 
		With a negative BTC balance, an equivalent amount in another currency is blocked for withdrawal.
		`,
		},
	],
	},
	{
	'name':`Referral Program`,
	'id':`Referral_Program`,
	'description':`<br><br>
	The referral program implies cooperation between the trading system and traders, 
	where existing participants receive a reward for new clients attracted by them from among personal acquaintances. 
	The reward is to receive a part of commissions in the amount of 25% of the fees paid by a new client. 
	The amount of remuneration may change in the future.
	`,
	},
	{
	'name':`Prices Manipulation`,
	'id':`Prices_Manipulation`,
	'description':`<br><br>
	In order to prevent and detect market manipulation, the trading system controls operations with financial instruments. <br>
	Market manipulation is understood as actions bearing signs of non-market behavior among trading participants: <br>
	<ul>
	<li>execution of operations with financial instruments by prior collusion between trading participants in order to distort the price; 
	<li>multiple placing of orders without the purpose of transactions; 
	<li>multiple placing of orders and execution of transactions with participants by prior collusion in order to mislead other participants about the price of a financial instrument. 
	</ul>
	The trading system reserves the right to block trading of any trading participant with an exhaustive number of signs of the above described behavior and to issue a withdrawal request. 
	Prior to blocking, a trading participant is sent a notice of suspension of trading with a request to explain the economic sense of the transactions being executed.
	`,
	},
	{
	'name':`Security`,
	'id':`Security`,
	'description':`<br><br>
	Trading system security, hacking resistance, protection of personal data and deposited assets of traders is the priority in the work of our team. 
	We work with the best professionals in the field of information systems security and we appreciate all cyber security researchers who contact us 
	to inform us about potential vulnerabilities found in any modules of our system.<br>
	We would appreciate everyone's help in identifying vulnerabilities. If you think you have found a vulnerability, please inform us in compliance with the following rules:
	<ul>
	<li>Do not resort to it yourselves and do not provoke the third parties to actions aimed at a fraud.
	<li>Do not resort to the actions that can damage the trading system, slow down or suspend its work, or cause damage to its members.
	<li>Do not distribute and do not store any information which can reveal vulnerability in security of the trading system or cause harm to participants of trades.
	</ul>
	`,
	},
	{
	'name':`Notification of Risks`,
	'id':`Risk_notification`,
	'description':`<br><br>
	Before you start using our system, it is important that you have read and understood the list of risks described in this notice. 
	The list of risks is not exhaustive and does not guarantee break-even trading and investment.
	`,
	"chapters":[
		{
		'name':`Recommendations and tips`,
		'id':`Recommendations_and_tips`,
		'description':`<br><br>
		Trading system cannot recommend certain transactions. Does not give any advice related to investing in financial instruments. 
		All information distributed by the trading system is only one of many points of view regarding the market.
		`,
		},
		{
		'name':`High volatility risk`,
		'id':`High_volatility_risk`,
		'description':`<br><br>
		The risk of high volatility is an integral part in trading and describes the level of fluctuations in the prices for financial assets. 
		Various events can lead to high volatility and cause a sharp change in price. 
		At such moments, the presence of open positions requires extra attention to one’s maintenance to avoid a margin call.
		`,
		},
		{
		'name':`Margin risk`,
		'id':`Margin_risks`,
		'description':`<br><br>
		Margin trading allows you to use leverage, involving only a part of your real assets. 
		Such type of trading allows earning more quickly in case of correctly calculated strategy, but also incurring more losses in case of errors.
		`,
		},
		{
		'name':`Legal and system risks`,
		'id':`Legal_risks`,
		'description':`<br><br>
		Each trader is solely responsible for knowing and understanding 
		how transactions in certain financial instruments are regulated and taxed by the jurisdiction in which he is a resident.
		`,
		},
		{
		'name':`Risk of not knowing the instruments`,
		'id':`Risk_of_not_knowing_the_instruments`,
		'description':`<br><br>
		Commission fees and understanding of specification of all instruments. 
		Before the beginning of trade each participant should get acquainted with the given tariff plan and understand specification of those tools which he plans to work with. Otherwise, 
		it can lead to unexpected profits or losses.
		`,
		},
		{
		'name':`General information`,
		'id':`General_information`,
		'description':`<br><br>
		<b>To sum up:</b> For successful trading, it is important to understand all types of risks you may encounter. 
		Understanding many risks allows you to protect your trading portfolio in case of unfavorable price behavior and increase its profitability. 
		`,
		},
		],
	},
	{
	'name':`Risk-Module of Trading System`,
	'id':`Risk_Module_of_Trading_System`,
	'description':`<br><br>
	The main functions of a trading system are the execution of clearing obligations and control over the fulfillment 
	of obligations between counterparties in transactions executed on the trading system platforms.  <br>
	To maintain the required level of reliability of a trading system, the risk module controls risk parameters, 
	which consist of the requirements to trading participants and various levels of protection and procedures to prevent defaults among trading participants.
	`,
	"chapters":[
		{
		'name':`Requirements to traders`,
		'id':`Requirements_to_Traders`,
		'description':`<br><br>
		In order to secure transactions and prevent default scenarios, bidders shall deposit Collateral. 
		BTC and other cryptocurrencies are accepted as Collateral (the list of accepted currencies will be updated).  <br>
		The sufficiency of the Collateral deposited by a trader to maintain the current position and open a new position is determined by using the "Available" indicator. 
		For the purpose of calculation of this indicator, the total Collateral for all open positions, active bids and P/L is used.
		`,
		},
		{
		'name':`Risk parameters`,
		'id':`Risk_parameters`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Static risk parameters`,
			'id':`Static_risk_parameter`,
			'description':`<br><br>
			This category includes parameters that are not changed on regular basis and which are revised if necessary.
			<ul>
			<li>Minimum collateral amount.
			<li>Overdraft rates.
			<li>The discount rates in cases of collateral being not in the main currency accepted by trading system.
			<li>Specification of financial instruments.
			</ul>
			`,
			},
			{
			'name':`Dynamic risk parameters`,
			'id':`Dynamic_Risk_Parameters`,
			'description':`<br><br>
			This category includes the following parameters:
			<ul>
			<li>Upper and lower limit of the price corridor. To avoid erroneous transactions and excessive volatility the parameters of Upper and Lower Price Limits are adopted.
			<li>Calculated value of an instrument. It is used for calculation of the required minimum level of funds to maintain the position, for funding rate calculation, 
			for estimating the upper and lower limits of the price corridor, for regulation of default procedures among participants, etc.
			<li>Other parameters are not disclosed and are intermediate.
			</ul>
			The trading system can make the decision on change of risk parameters both in a mode of suspension of trading, and during trading.
			`,
			},
		],
		},
		{
		'name':`Margin Call or liquidation`,
		'id':`Margin_Call_or_Liquidation`,
		'description':`<br><br>
		In a default scenario, the automatic mode for managing positions and active orders of a trader is activated. 
		It can be used only in case of deficit of own funds in the wallet of a trader after receiving a negative result on transactions or 
		after increase of the Collateral on the financial instruments in which a trader is in positions. <br> 
		When this mode triggers, all active orders, including deferred orders, are removed. 
		Further, if there is a deficit of own funds for maintaining the current position, a margin call order is automatically placed.
		A Margin Call Order cannot be cancelled by a trader. <br><br>
		Parameters of a margin call order:<br>
		<ul>
		<li>Type - limit order.
		<li>Instrument in an order - a financial instrument from those that are available in a trader's open positions and has the largest ratio of borrowed funds.  
		<li>Direction - the counter one to the current position in the corresponding financial instrument.
		<li>Quantity - sufficient to close the deficit of own funds.
		<li>Price - the best bid or the best offer, depending on the direction of an order leading to immediate execution. If there is no necessary price, the calculated value is accepted.
		</ul>
		<br><br>
		* The final volume of a margin call order and the price of the final transaction can change. 
		If there was not enough volume for immediate execution of an order at the price of order placement, the special mode of order price recalculation is triggered. 
		At the moment of re-placement of the order, the volume of this order is recalculated once again. 
		There may be only one margin call order for a single financial instrument, but there may be several margin call orders for different instruments 
		(if a trader’s portfolio contains several financial instruments).
		`,
		},
		],
},

	],			
}
