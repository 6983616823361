/* eslint-disable eqeqeq */
import React/*, { useRef }*/ from "react";
import _ from "lodash";
import { strings, enums } from '../localization/strings';

import { withFilter, noExponent } from '../utils/utils'
import { getLim/*, Limits*/, Accounts, withAccountsList } from "../ws-priv/accounts";

import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { SelectAid } from '../ws-priv/accounts';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

//import { ToggleButton, ToggleButtonGroup } from '../components/toggle-button';

//import BigNumber from "bignumber.js";

import Currencies, { withCurrencies } from "../ws-pub/currencies"

import '../styles/css/withdraw.css';
import '../styles/css/accounts.css';

import { ShowTransactions } from '../ws-priv/transactions';

import { LoginOrRegister } from "../ws-priv/login-or-register";

import Dialog from '@material-ui/core/Dialog';


import { 
  IBtnRefresh,
  IBtnCross,
} from '../styles/icons';

import {
  decS,
  decCidS,
  iDecCidS,
  iTextS,
  dt_ns_msS,
} from "../utils/cell-formatters";

import { Button, ButtonConfirm, ButtonConfirm2FA } from "../components/button";

import { TwoFA, TwoFAState } from "../ws-priv/two-fa";

import { Log } from '../components/log';
//import { toast } from 'react-toastify';
import { pageVisibility } from "../utils/page-visibility";

import { format } from "../localization/strings";

export function ButtonAddWithdrawalAddress(props) {
  const [address, setAddress] = React.useState('');
  const [label, setLabel] = React.useState('');
  return <PopupState variant="popover">
    {(popupState) => (
      <React.Fragment>
        <button {...bindTrigger(popupState)} className="p4b bg-blue">{props.children}</button>
        <Popover
          TransitionProps={{
            onEnter: ()=>{pageVisibility.userStartedDoingSmthImportant()},
            onExit: ()=>{pageVisibility.userStoppedDoingSmthImportant()},
          }}
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          {!TwoFAState.current && <TwoFA/>}
          {TwoFAState.current && <div className="default-popup popup-confirm">
            <div>
              <div className="p4b">{iTextS(strings.Withdraw_.Address + ":", props.self.cid)}</div>
              <input type="text" value={address} onChange={(e)=>setAddress(e.target.value)}/>
            </div>
            <div>
              <div className="p4b">{strings.Label}:</div>
              <input type="text" value={label} onChange={(e)=>setLabel(e.target.value)}/>
            </div>
            <div className="confirm-buttons">
              <ButtonConfirm2FA 
                onClick={(key)=>{
                  popupState.close();
                  new Subscribtion({
                    ws: wspriv,
                    smsg: {"P": "c_wadr", "D":{"ll":label, "did":address, "cid": props.self.cid, "2fa": key}},
                    eternal: false,
                    onResult: function(msg) {
                      Log.success(strings.Withdraw_.Added);
                      let addrs = _.cloneDeep(props.self.state.addrs);
                      addrs.push(msg["D"]["adr"]);
                      addrs.sort(function (a, b) {return b["ct"] - a["ct"];});
                      props.self.setState({addrs: addrs});
                    }, 
                    onError: function(msg) {
                    }
                  });
                }}>
                {strings.Next}
              </ButtonConfirm2FA>
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </div>}        
        </Popover>
      </React.Fragment>
    )}
  </PopupState>; 
}

export function ButtonWithdraw(props) {
  let step = Currencies.all[props.self.cid].step;
  let min_step = Currencies.all[props.self.cid].min_step;
  const [amount, setAmount] = React.useState(step);
  //const [priority, setPriority] = React.useState("1");
  const [fees, setFees] = React.useState(null);
  const [fee, setFee] = React.useState(min_step);
  const [activeStep, setActiveStep] = React.useState(0);
  const [label, setLabel] = React.useState('');
  const [open, setOpen] = React.useState(false);
  return <React.Fragment>
        <Button onClick={()=>setOpen(!open)} className="p4b bg-blue">{props.children}</Button>
        {open &&
        <Dialog
          open={open}
          TransitionProps={{
            onEnter: ()=>{pageVisibility.userStartedDoingSmthImportant(); setActiveStep(0);},
            onExit: ()=>{pageVisibility.userStoppedDoingSmthImportant();}
          }}
          >
          {!TwoFAState.current && <TwoFA/>}
          {TwoFAState.current && <div className="default-popup popup-confirm">
            {activeStep === 0 && <React.Fragment>
              <div>
                <div className="default-info">
                  <div className="p4">{strings.CanWithdraw}:</div>
                  <div className="p4b">{decCidS(props.self.state.can_withdraw, {cid: props.self.cid})}</div>
                  <Button onClick={()=>props.self.requestCanWithdraw()} className="p4b bg-blue">
                    <IBtnRefresh size="14px"/>
                  </Button>
                </div>
              </div>
              <div>
                <div className="p4b">{iTextS(strings.Amount + ":", props.self.cid)}</div>
                <input type="number" 
                  step={step} 
                  value={noExponent(amount)} 
                  onChange={(e)=>setAmount(e.target.value)} 
                  onBlur={(e)=>setAmount(decS(parseInt(e.target.value * 1e8 + 0.1)))}
                  min={step}/>
                <div className="withdraw-percents p5b col-grey-40">
                  {[5, 10, 25, 50, 100].map((p)=>{
                    return <div key={p} onClick={()=>setAmount(decS(parseInt((p / 100) * props.self.state.can_withdraw)))}>{p}%</div>})}
                </div>
              </div>
              <div>
                <div className="p4b">{strings.Label}:</div>
                <input type="text" value={label} onChange={(e)=>setLabel(e.target.value)}/>
              </div>
              <div className="confirm-buttons">
                <Button 
                  onClick={()=>{
                    setFees(null);
                    setActiveStep(1);
                    new Subscribtion({
                      ws: wspriv,
                      smsg: {"P": "g_tfs", "D":{"did": props.address, "cid": props.self.cid, "amnt": parseInt(amount * 1e8 + 0.1)}},
                      eternal: false,
                      onResult: function(msg) {
                        setFee(decS(msg["D"]["tfs"][0]));
                        setFees(_.cloneDeep(msg["D"]["tfs"]));
                      }, 
                      onError: function(msg) {
                        setActiveStep(1);
                      }
                    });
                  }}
                  className="p4b bg-blue">
                  {strings.Next}
                </Button>
                <Button 
                  onClick={()=>setOpen(false)} 
                  className="p4b bg-blue">                  
                  {strings.Cancel}
                </Button>
              </div>
            </React.Fragment>}
            {activeStep === 1 && <React.Fragment>
              <div>
                <div className="p4b">{strings.Withdraw_.TransactionFee}:</div>
                <input type="number" 
                  step={min_step} 
                  value={noExponent(fee)} 
                  onChange={(e)=>setFee(e.target.value)} 
                  onBlur={(e)=>setFee(decS(parseInt(e.target.value * 1e8 + 0.1)))}
                  min={min_step}/>
                <div className="withdraw-percents p5b col-grey-40">
                  {fees && Object.keys(enums.transaction_priority).map((key)=>{
                    let str = enums.transaction_priority[key];
                    return <div key={key} onClick={()=>setFee(decS(fees[key]))}>{str}</div>})}
                </div>
              </div>
              {null/*<div>
                <div className="p4b">{strings.Withdraw_.Priority}:</div>
                <ToggleButtonGroup
                  value={priority}
                  onChange={(e, v)=>setPriority(v)}
                  exclusive
                  className="p3 toggle-btn-dflt"
                  >
                  {Object.keys(enums.transaction_priority).map((key)=>{
                    let str = enums.transaction_priority[key];
                    return <ToggleButton key={key} value={key}>{str}</ToggleButton>
                  })}
                </ToggleButtonGroup>
              </div>*/}
              <div className="confirm-buttons">
                <ButtonConfirm2FA 
                  onClick={(key)=>{
                    setOpen(false);
                    new Subscribtion({
                      ws: wspriv,
                      smsg: {"P": "wthd", "D":{"aid": props.self.aid, "did": props.address, "cid": props.self.cid, "tf": parseInt(fee * 1e8 + 0.1), "2fa": key, "amnt": parseInt(amount * 1e8 + 0.1), "ll": label}},
                      eternal: false,
                      onResult: function(msg) {
                        Log.success(strings.Withdraw_.Withdrawed);
                      }, 
                      onError: function(msg) {
                      }
                    });
                  }}>
                  {strings.Next}
                </ButtonConfirm2FA>
                <Button 
                  onClick={()=>setActiveStep(0)} 
                  className="p4b bg-blue">
                  {strings.Back}
                </Button>
              </div>
            </React.Fragment>}
          </div>}        
        </Dialog>}
      </React.Fragment>
}

function AddressFormatter(v, self) {
  return <div style={{display: "flex", alignItems: "center"}}>
    <ButtonWithdraw self={self} address={v}>{strings.Withdraw_.PerformWithdraw}</ButtonWithdraw>
    {'\u00A0\u00A0'}
    <ButtonConfirm className="p4b bg-blue"
      onClick={(key)=>{
        new Subscribtion({
          ws: wspriv,
          smsg: {"P": "d_wadr", "D":{"did":v, "cid": self.cid/*, "2fa": key*/}},
          eternal: false,
          onResult: function(msg) {
            Log.success(strings.Withdraw_.Removed);
            self.setState({addrs: _.reject(self.state.addrs, {did: msg["D"]["did"]})});
          }, 
          onError: function(msg) {
          }
        });
      }}>
      <IBtnCross size="13"/>
    </ButtonConfirm>
    {'\u00A0\u00A0' + v}
  </div>
}

export class Withdraw_ extends React.Component {
  constructor(props) {
    super(props);
    this.cid = this.props.filter.cid; 
    this.aid = null;
    Accounts.own.some((acc)=>{if (acc.aid == this.props.filter.aid) {this.aid = acc.aid; return true;} return false;});
    if (!this.aid) this.aid = Accounts.main["aid"];
    this.acc = Accounts.all[this.aid];
    if (!this.acc) return null;
    this.lim = getLim(this.acc.aid, this.cid);    
    this.state = {
      addrs: [],
      can_withdraw: 0,
      c: null,
      p: null,
    }
    this.columns = [
      //{title:"Show", field:"did", formatter: QRCodeFormatter},
      {title:"Addresses", field:"did", formatter:AddressFormatter},
      {title:"Label", field:"ll"},
      {title:"AddTime", field:"ct", formatter: dt_ns_msS},
    ]
    this.tabRef = React.createRef();
    this.max_time = 99999999999;
  }

  componentDidMount() {
    if (wspriv.authorized) {    
      let self = this;
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "g_wadr", "D":{"cids":[this.cid]}},
        eternal: false,
        onResult: function(msg) {
          let addrs = _.cloneDeep(msg["D"]["adrs"]);
          addrs = _.reject(addrs, {"did": ""});
          addrs.sort(function (a, b) {return b["ct"] - a["ct"];});
          self.setState({addrs: addrs});
        }, 
      });
      this.requestCanWithdraw();  
    }
  }

  requestCanWithdraw() {
    if (!this.lim.lid) return;
    if (wspriv.authorized) {    
      let self = this;
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "cw_acc", "D":{"aid": this.aid, "cid": this.cid}},
        eternal: false,
        onResult: function(msg) {
          //if (msg["D"]["c"]) console.log(format(msg["D"]["c"], msg["D"]["p"]));
          self.setState({can_withdraw: msg["D"]["cw"], c: msg["D"]["c"], p: msg["D"]["p"]});
        }, 
      });
    }
  }

  componentWillUnmount() {
  }

  render() {
    //console.log("render", this.state.addrs);
    let self = this;
    let acc = this.acc;
    let aid = this.aid;
    let lim = this.lim;
    if (!aid || !acc || !lim) return <LoginOrRegister/>;
    //console.log(lim);
    return <div className="default-column">
      <div>
        <div className="p3"></div>
        <div className="p3b"><SelectAid own value={acc.aid} onChange={(acc)=>this.props.onFilterChanged({aid: acc["aid"]})}/></div>
      </div>
      <div className="default-info">
        <div className="p4">{strings.Balance}:</div>
        <div className="p4b">{iDecCidS(lim.lim, lim)}</div>
      </div>
      {this.state.c && <div className="default-info">
        <div className="p4b col-red">{format(this.state.c, this.state.p)}</div>
      </div>}
      <div className="default-info">
        <div className="p4">{strings.CanWithdraw}:</div>
        <div className="p4b">{decCidS(this.state.can_withdraw, lim)}</div>
        <Button onClick={()=>this.requestCanWithdraw()} className="p4b bg-blue">
          <IBtnRefresh size="14px"/>
        </Button>
      </div>
      <div className="default-info">
        <ButtonAddWithdrawalAddress self={this}> 
          {strings.Withdraw_.Add}
        </ButtonAddWithdrawalAddress>
        {'\u00A0\u00A0'}
        <ShowTransactions id={this.props.id} /*io={1}*/ cid={this.cid}>{strings.ShowTransactions}</ShowTransactions>
      </div>
      {this.state.addrs.length > 0 && <div className="t-accs">
        <table>
          <thead className="p3 col-night-sky"><tr>
             {this.columns.map((col)=>{return <th key={col.title} className="bg-grey-light">
                <div className="th-content">
                  <div className="th-title">
                    {strings.Withdraw_[col.title]}
                  </div>
                </div>
              </th>})}
          </tr></thead>
          <tbody className="p3 col-grey">
          {this.state.addrs.map((addr)=>{return <tr key={addr.did}>
              {this.columns.map((col)=>{return <td key={col.title}>
                { addr[col.field]
                  ? (col.formatter 
                    ? col.formatter(addr[col.field], self) 
                    : addr[col.field])
                  : ""
                }
                </td>})
              }
            </tr>})
          }
          </tbody>
        </table>
      </div>} 
      <LoginOrRegister/>   
    </div>
  }
}

export const Withdraw = withFilter(withCurrencies(withAccountsList(Withdraw_)));
