export const strings = {
  '-': '---------------------',
  MobileVersion: "Mobile version",
  Download_: {
    Downloading: 'Downloading',
    DownloadTrades: 'Download trades',
    DownloadOrders: 'Download orders',
    DownloadTransactions: 'Download transactions',
    DownloadTransfers: 'Download transfers',
    DownloadStatement: 'Download statement',
    Downloaded: "{0} records downloaded",
    Failed: "Download failed",
    FromTo: "'{0}' should be less than '{1}'",
  },
  Security_: {
    ChangePassword: 'Change password for email sign in',
    SendPasswordResetLink: 'Change password',
    SendPasswordResetText: `You will recieve email with password reset link`,
    PasswordResetLinkSent: 'Password reset email sent to',
    PasswordResetLinkNotSent: 'Password reset email NOT sent',
    SendVerificationEmail: 'Re-send verification link',
    EmailNotVerified: `Verify your email`,
    VerifyWarn: 'We have just sent a verification link to {0}. You must verify your email to gain access to this site',
    VerificationEmailSent: 'Verification email sent to',
    VerificationEmailNotSent: 'Verification email NOT sent',
    NotSignedIn: 'You must be logged in',
    Device: 'Device',
    Platform: 'Platform',
    Browser: 'Browser',
    IP: 'IP',
    LastLogin: 'Last login',
    Devices: 'Activity',
    DeviceVerification: 'Authorize new device',
    DeviceVerificationSent1: `It looks like you are signing in from a computer or device we haven't seen before. Please`,
    DeviceVerificationSent2: `click the confirmation link in the email we just sent to {0}`,
    DeviceVerificationSent3: `. This is a one time process that protects the security of your account`,
  },
  MustLogIn: "You must be logged in to view this content",
  Login: "Login",
  Register: "Register",
  or: "or",
  AllMessages: "All messages",
  SelectMassages: "Filter messages",
  Confirmations: "Confirmations",
  Address: "Address",
  TransactionID: "Transaction ID",
  ShowTransactions: "View transactions",
  ShowStatement: "View statement",
  Referral: "Referral program",
  Referral_: {
    Link: "Referral link",
    Payments: "Referral payments",
    Today: "For yesterday",
    Yesterday: "For last 30 days",
    Text: `The referral program implies cooperation between the trading system and traders, where existing participants receive a reward for new clients attracted by them from among personal acquaintances. The reward is to receive a part of commissions in the amount of 25% of the fees paid by a new client`,
  },
  AreYouSure: "Are you sure?",
  Tariffs: "Tariffs",
  TariffsTable_:{
    Tariff: "Tariff",
    TariffID: "Tariff ID",
    MakerFee: "Maker fee",
    TakerFee: "Taker fee",
    ExpiryFee: "Expiry fee",
    FixedFee: "Fixed fee",
    NextPayment: "Next payment",
    UserID: "User",
    Commission: "Accrued commission",
    CommissionDealsToday:  "For today deals",
    CommissionDealsYesterday: "For yesterday deals",
    CommissionFixed: "Fixed for last 8 hours",
    CommissionOverdraft: "Overdraft for last 8 hours",
  },
  API: "API",
  APIKeys: "API keys",
  APITester: "API tester",
  APIDoc: 'API documentation',
  TestEnv: 'Testnet environment',
  Api_:{
    /*keys*/
    KeyID: "Key ID",
    Label: "Label",
    Permissions: "Key permissions",
    CanTrade: "Trade",
    CanTradeDescr: "Add/cancel orders",
    CanManage: "Manage",
    CanManageDescr: "Create/transfer/delegate/remove/label account",
    CanWithdraw: "Withdraw",
    CreateTime: "Create time",
    Create: "Create API key",
    Created: "API key created",
    SaveKey: "Save API key",
    Secret: "Secret",
    SaveNote: "IMPORTANT: Save and store this key in a safe place. You can only view key secret in this step",
    /*doc*/
    ChooseVer: 'Choose API documentation version',
    ProductionEnv: 'Production',
    TestEnv: 'Testnet environment',
    TestEnvDescription: 'There is a testnet environment available for the testing purposes',
    TestEnvDescription2: 'We use testnet environment for testing new features. It may have later API version than production',
    TableOfContents: 'Table Of сontents',
    /*tester*/
    Request: "Request",
    Reply: "Reply",
    Send: "Send",
    NotValidJson: "Request should be in JSON format",
  },
  QRCode: "QR code",
  TwoFA: "Two-factor authentication",
  TwoFA_: {
      Setup: "Setup two-factor authentication",
      QRCodeText: `Please use your authentication app (such as Authy or Google authenticator) to scan this QR code.`,
      QRCodeText2: `If you can't scan QR code, enter this code manually`,
      Enable: "Enable 2FA",
      Disable: "Disable 2FA",
      Disabled: "2FA disabled",
      Enabled: "2FA enabled",
      ResetText: `You will recieve email with 2FA reset link in 3 days`,
      Reset: "Lost 2FA?",
      EnterKey: "Enter 6-digit 2FA key",
      WrongFormat: "Wrong 2FA key format - must be 6 digits",
      Verify: "Verify",
  },
  Transfer_: {
      PerformTransfer: "Transfer",
      ShowTransfers: "View transfers",
      Complete: "Transfer completed",
      OnlyOneAcc: "In order to be able to transfer funds between accounts, create at least one sub-account",
  },
  Withdraw_: {
      Add: "Add withdrawal address",
      Address: "Withdrawal address",
      AddTime: "Adding time",
      Addresses: "Withdrawal addresses list",
      Added: "New withdrawal address added",
      PerformWithdraw: "Withdraw",
      TransactionFee: "Transaction fee",
      Withdrawed: "Withdraw successful",
      Removed: "Withdrawal address removed",
      Label: "Label",
  },
  CopiedToCB: "Copied to clipboard",
  Deposit_: {
      CoinsGoToMain: "All deposited coins go to account {0} (main)",
      AddressGenerated: "New address generated",
      Address: 'Deposit address',
      //Addresses: 'Deposit addresses list',
      NewDepositAddr: "Generate new address",
      BTC : {
          addr: `Your personal bitcoin deposit addresses`,
          //addr: `Bitcoin deposit addresses`,
          topics: [        
              `Do not send Litecoin, Bitcoin Cash, or Tether to this address! Unfortunately, these networks will accept Bitcoin multisig addresses as valid. Any unsupported coins sent here will be lost.`,
              //`The minimum deposit amount is 0.0001 XBT (10000 Satoshi).`,
              `Your deposit will be credited after 6 confirmation on the Bitcoin network.`,
              `All Viking.trade deposit addresses are multi-signature cold wallet addresses. No wallets are ever read by an Internet-connected machine.`
          ],
      },
      ETH : {
          addr: `Your personal ethereum deposit address`,
          topics: [],
      },
      BSC : {
          addr: `Your personal Binance Smart Chain BEP20 deposit address`,
          topics: ['Please send only BEP20 tokens to this address'],
          min: 'Minimal deposit amount is {0}. Lesser transactions will be ignored.',
      },
  },
  NoConnection: "No connection",
  Security: "Security",
  From: "From",
  To: "To",
  Since: "Since",
  Until: "Until",
  AccInfo: "Available",
  AccManage: "Manage account", //  (deposit, withdraw...)
  Fees: "Fees",
  main: "main",
  Next: "Next",
  Enabled: "Enabled",
  Disabled: "Disabled",
  Balance: "Balance",
  MainAcc: "Main account",
  RemoveAcc:"Remove account",
  RevokedAcc:"Account access revoked",
  RefuseAcc:"Refuse from account",
  CreateNewAcc:"Create sub-account",
  DelegateAcc: "Delegate trading rights",
  DelegatedAcc: "Managed by ",
  AccInManagement: "Delegated by",
  "Delegated": "Delegated",
  "In management": "Managed by me",
  DelegateMsg: "Delegate trading rights for this account to another user. This user will be able to place orders, but will not be able to withdraw/deposit/transfer funds. You can delegate account to multiple users.",
  Email: "Email",
  Wallet: 'Wallet',
  Deposit: 'Deposit',
  Withdraw: 'Withdraw',
  Transfer: 'Transfer',
  order: 'order',
  Ok: "Ok",
  Cancel: 'Cancel',
  QTY: 'QTY',
  VISQTY: 'VIS QTY',
  PRICE: 'PRICE',
  SIZE: 'SIZE',
  TOTAL: 'TOTAL',
  ACTPRICE: 'TRIGGER PRICE',
  EXPDATE: 'EXP DATE',
  COMMENT: 'COMMENT',
  All: 'All',
  ID: 'ID',
  SelectAll: 'Select all',
  ClearAll: 'Clear all',
  AllColumns: 'All columns',
  AllAccounts: 'All accounts',
  AllInstruments: 'All instruments',
  AllCurrencies: 'All currencies',
  InstrumentInfo: 'Contract specification', //Instrument info
  ContractSpecs: "Contract specification",
  MarginBalance: 'Margin balance',
  Currency: 'Currency',
  Available: 'Available',
  UsedDeposit: 'Used deposit',
  CanWithdraw: 'Can withdraw',
  PL: 'P/L',
  Limits: 'Limits',
  Own: 'Own',
  Owner: 'Owner',
  Users: 'Users',
  OrdersAndTrades: "Orders, Trades and Positions",
  InstrumentControl: "New order",
  AllTradesIndxs: 'All trades & Indexes',
  BuyOrder: 'BUY ORDER',
  SellOrder: 'SELL ORDER',
  Trade: 'Trade',
  UserAccount: 'User account',
  Exchange: 'Exchange',
  AllRightsReserved: 'All rights reserved',
  Message: 'Message',
  Log: 'Log',
  OrderConfirm: 'Confirm new order',
  Iceberg: 'Iceberg',
  Trigger: 'Trigger',
  TopOfBook: 'Top of book',
  Buy: 'Buy',
  Sell: 'Sell',
  ExpDate: 'Expiration date',
  Limit_: 'Limit', //Accounts limit
  Limit: 'Limit', //Order limit
  Market: 'Market',
  Comment: 'Comment',
  light: 'Light',
  dark: 'Dark',
  TableOfContents: "Table of contents",
  Login_as: 'Login as',
  Logout: 'Logout',
  Back: 'Back',
  Home: 'Home',
  Reset: 'Reset',
  ResetChart: 'Reset chart',
  Layouts: 'Workspace',
  BasicLayout: 'Reset to default',
  OwnLayout: 'Saved',
  LayoutSaved: "Workspace saved",
  LayoutLoaded: "Workspace loaded",
  LayoutMustLogIn: "You must be logged in to save workspace",
  Misc: 'Misc',
  LeftToolbar: 'Left toolbar (chart)',
  TopToolbar: 'Top toolbar (chart)',
  BottomToolbar: 'Bottom toolbar (chart)',
  ShowTrades: 'Show last 1000 trades on chart',
  SelectCurrencies: 'Filter by currency',
  SelectAids: 'Filter by account',
  SelectInstruments: 'Filter by instrument',
  SelectDatetime: 'Filter by date until',
  SelectColumns: 'Select visible columns',
  WSPublic: 'Market data connection',
  WSPrivate: 'Trading connection',
  Online: 'Online',
  Offline: 'Offline',
  Instruments: 'Instruments',
  QuotesMonitor: 'Quotes',
  TestList: 'List',
  TestTable: 'Table',
  Orderbook: 'Orderbook',
  ActiveOrders: 'Active orders',
  Indexes: 'Indexes',
  IndexesTable: 'Indexes',
  AllIndexes: 'All indexes',
  AllTrades: 'All trades',
  MyTrades: 'My trades',
  Orders: 'Orders',
  Chart: 'Chart',
  ChartTrades: 'Chart',
  InsID: 'ID',
  Symbol: 'Symbol',
  Instrument: 'Instrument',
  Dir: 'Dir',
  Price: 'Price',
  Size: 'Size',
  VisQty: 'Vis. qty',
  Qty: 'Qty',
  Quantity: 'Quantity',
  Amount: 'Amount',
  Total: 'Total',
  IdxID: 'Index ID',
  VolBTC: 'Volume in BTC',
  tNo: 'Trade ID',
  Time: 'Time',
  UpdateTime: 'Update time',
  CreateTime: 'Create time',
  SendTime: 'Send time',
  ExpiryTime: 'Exp. time',
  InitialMargin: 'Initial margin',
  LimDown: 'Lower price limit',
  LimUp: 'Upper price limit',
  Index: 'Index',
  Estimate: 'Estimate',
  Mark: 'Mark',
  ShowIndexPrice: 'Show index price',
  ShowMarkPrice: 'Show mark price',
  BestBuy: 'Best buy',
  QuantityBuy: 'Quantity buy',
  BestSell: 'Best sell',
  QuantitySell: 'Quantity sell',
  LastPrice: 'Last price',
  LastQuantity: 'Last quantity',
  Status: 'Status',
  Description: 'Description',
  MinStep: 'Min step',
  ContractSize: 'Contract size',
  ContractSizeStr: '{0} BTC (each contract size is worth {1} BTC per 1$ price)',
  StepPrice: 'Step price',
  LotSize: 'Lot size',
  PriceDecimals: 'Price decimals',
  LotDecimals: 'Lot decimals',
  FundingRate: 'Funding rate (%)',
  IcebergRatio: 'Minimum iceberg visible quantity ratio (%)',
  ExpiryType: 'Expiry type',
  Activation: 'Activation',
  Expiration: 'Expiration',
  Trades: 'Trades',
  RecentTrades: 'Recent trades',
  TradesQty: 'Trades',
  TradesVol: 'Trades volume',
  OpenedInterest: 'Opened interest',
  Account: 'Account',
  AccountControl: 'Account',
  Accounts: 'Accounts',
  AccountsTable: 'Accounts table',
  AccID: 'Account ID',
  oNo: 'Order ID',
  Maker: 'Maker',
  Taker: 'Taker',
  Label: 'Label',
  Commission: 'Commission',
  Flags: 'Flags',
  TradeFlags: 'Flags',
  Rest: 'Rest',
  CancelReason: 'Cancel reason',
  ExtID: 'External ID',
  OnlyMaker: 'Only maker',
  VisRest: 'Visible rest',
  Type: 'Type',
  Stop: 'Stop',
  Stops: 'Stops',
  StopPrice: 'Stop price',
  Profit: 'Profit',
  ActType: 'Activation type',
  ActPrice: 'Trigger price',
  TimeInForce: 'Time in force',
  AddedBy: 'Added by',
  Positions: 'Positions',
  Pos: 'Position',
  Position: 'Position',
  Topic: 'Topic',
  Data: 'Data',
  User: 'User',
  Admin: 'Admin',
  History: 'History',
  NewOrder: 'New order',
  NewOrderSuccess: '{0} {1} price {2} qty {3}',
  UserID: "User",
}

export const codes = {
  "H00":"Withdraw: txID=%s, address=%s, currency=%s, amount=%p, fee=%p, comment=%s, accountID=%d, state=%d, count=%d, updated=%i",
  "H01":"Deposit: txID=%s, address=%s, currency=%s, amount=%p, accountID=%d, state=%d, count=%d, updated=%i",
  "H02":"Transfer %p %s coins from %d to %d with label \"%s\" by %s",// t_acc
  "H03":"Convert %p %s coins to %s by rate %p on account %d (label=\"%s\")",// conv_acc
  "H04":"Subtract %p BTC from account %d to exchange (label=\"%s\")",// from_acc
  "H05":"Receive %p BTC on account %d from exchange (label=\"%s\")",// to_acc
  "H10":"Create account %d with label \"%s\" by %s",// c_acc
  "H11":"Delegate account %d to %s by %s",// cn_acc
  "H12":"Revoke account %d from %s by %s",// dcn_acc
  "H13":"Remove account %d by %s",// d_acc
  "H20":"Change tariff plan to %d by %s",// ch_tariff
  "H21":"Fixed fee %p",// cms
  "H22":"Daily deals fee summary %p for account %d",// cmsd
  "H23":"Limits overdraft fee %p (overdraft=%p, rate=%p%%)",//cmsl
  "H24":"Referrals fee summary %p",// cmsr
  "H25":"Funding for account %d, instrument %s: %p (pos=%p, funding rate=%p)",// cmsf
  "H26":"Funding for account %d (instead of %d), instrument %s: %p (pos=%p, funding rate=%p)",// cmsf
  "H30":"User %s registered",// c_usr
  
  "H40":"Fixed fee",//cms
  "H41":"Limits overdraft fee (overdraft=%p, rate=%p%%)",//cmsl
  "H42":"Referrals fee summary",//cmsr
  "H43":"Funding for instrument %s (pos=%p, funding rate=%p)",//cmsf
  "H44":"Funding (instead of account %d) for instrument %s (pos=%p, funding rate=%p)",//cmsf
  "H45":"Transfer from %d to %d with label \"%s\"",//t_acc
  "H46":"Deposit: txID=%s, address=%s",//inp
  "H47":"Withdraw: address=%s, fee=%p, comment=%s, ext_id=%s",//wthd
  "H48":"Rejected withdraw: address=%s, fee=%p, comment=%s, ext_id=%s, time=%i",//rj_wthd
  "H49":"Fixed P/L (%p) + deals fee (%p) until %i",//pl
  "H50":"Convert %p %s coins to %s by rate %p (label=\"%s\")",// conv_acc1
  "H51":"Receive %p %s coins from convertion of %p %s coins by rate %p (label=\"%s\")",// conv_acc2
  "H52":"Subtract BTC to exchange (label=\"%s\")",// from_acc
  "H53":"Receive BTC from exchange (label=\"%s\")",// to_acc
  
  "EX0":"Exchange is overloaded",
  "EX1":"Exchange is not working",
  "EX2":"Operation is disabled now, please try again later",
  "EX3":"Operation timeout, please try again later",
  "EX4":"Operation rate limit exceeded, please try again later",
  "EX5":"Can not calculate transaction fee",
  "EX6":"Address is not valid",
  
  "AU0":"Already authorized",
  "AU1":"Not authorized",
  "AU2":"Authentication error",
  "AU3":"User does not exist",
  "AU4":"Permission denied",
  "AU5":"Google auth error",
  "AU6":"Waiting 2FA",
  "AU7":"Wrong authentication message format",
  "AU8":"Wrong key",
  "AU9":"Wrong signature",
  "AU10":"Wrong timestamp",
  "AU11":"Key does not exist",
  "AU12":"Wrong 2FA",
  "AU13":"Email not verified",
  "AU14":"Wrong user token",

  "DB0":"Data base connection error",

  "IF0":"Insufficient funds, account is in margin call state",
  "IF1":"Insufficient account funds, you have only %p, but %p needed",
  "IF2":"Insufficient user funds, you have only %p, but %p needed",
  "IF3":"Transaction fee is greater then withdraw amount",
  "IF4":"To withdraw, you need to get the base currency of the blockchain: deposit %p %s",

  "WP0":"Unexpected field %s",
  "WP1":"%s field has unknown value",
  "WP2":"%s field with type %s is mandatory",
  "WP3":"epoch_sec ed is mandatory for GTD order",
  "WP4":"dec_8 p is mandatory for nonmarket order",
  "WP5":"dec_8 ap is mandatory for nonimmediate order",
  "WP6":"uuid eid is mandatory",
  "WP7":"Field %s length should be in range %s",
  "WP8":"Field %s value should have type %s", 
  "WP9":"Field %s value should have type %s or null",
  "WP10":"Field %s value should be nonempty list or null",
  "WP11":"Field %s value should be %s",
  "WP12":"Field %s value should be in range %s",
  "WP13":"Field %s value should be unique",
  "WP14":"Field %s value should be multiple of %s",
  "WP15":"bt should be <= than et",
  "WP16":"New label value is equal to current value",
  "WP17":"Field %s value should be %s %p",
  "WP18":"Field %s length should be %s",
  "WP19":"Expiration date has already passed",
  "WP20":"Key not found",
  "WP21":"string eid should be unique for account_id+sec_id for active orders",
  "WP22":"Field %s value should be in range [%p, %p]",
  "WP23":"Field %s value should be unique for %s",
  "WP24":"Wrong referral %s",
  "WP25":"Referral update is not enabled",
  "WP26":"eid should be uuid",
  "WP27":"ap is not enabled for immediate order",
  "WP28":"Output transaction not found or is in incorrect state", 
  "WP29":"to_btc1 should be set for is_not_btcusd security",
  "WP30":"You should specify %s param(s)",
  "WP31":"to_btc1 can not be set for non is_not_btcusd security",
  "WP32":"Can not increase et",
  "WP33":"Nothing to change",
  "WP34":"New default tariff can not be tariff with fixed fee",
  "WP35":"Can not create second default tariff",
  "WP36":"Can not edit self permissions",
  "WP37":"symbol %s should be unique",
  "WP38":"Too low min step for %s",
  "WP39":"Transaction fee is too small. It can be %p %s or greater",

  "AL0":"Currencies are not equal",
  "AL1":"Can not delete main account",
  "AL2":"Account not found",
  "AL3":"Active orders limit %d is reached for account %d",
  "AL5":"Account is not empty",
  "AL6":"Target user is account's owner",
  "AL11":"Not enough currency to transfer",
  "AL16":"Account must have BTC limit",
  "AL17":"Limit on accounts quantity reached",
  "AL20":"Max keys limit reached",
  "AL21":"Account is already added to user",
  "AL22":"Account is not added to user",
  "AL23":"Account is not ready to trade",
  "AL24":"IDs of accounts must be different",
  "AL26":"Can not revoke self owned account",

  "O0":"Order not found",
  
  "W0":"Withdraw not found",
  
  "T0":"Transfer not found",

  "WS0":"Wrong instrument trading status. Current status is %t",

  "WPM0":"Can not cancel margin call order",
  "WPM1":"Can not cancel expiration order",
  "WPM2":"Trading is disabled for this user or key",
  "WPM3":"Managing is disabled for this user or key",
  "WPM4":"You are not the limit owner",
  "WPM5":"Viewing/editing keys is disabled for key-authorized session",
  "WPM6":"You are not the account owner",
  "WPM7":"Operation is not allowed",
  "WPM8":"Can not delete traded security",
  "WPM9":"Can not delete security which is already is processing operation on",
  "WPM10":"Withdrawing is disabled for this currency",
  "WPM11":"Withdrawing is disabled for this user or key",
  "WPM12":"Wrong 2FA key",
  "WPM13":"Can not delete address which is in transfer processing",
  "WPM14":"You should configure 2FA",
  "WPM15":"Can not transfer %s delegated account %s",
  "WPM16":"Please use all existing addresses for once, before creating new address",
  "WPM17":"You are not the account owner",
  "WPM18":"Wrong permissions, can not do any operation with tariff already is processing operation on",
  "WPM19":"Wrong permissions, can not delete default tariff",
  
  "WA0":"Limit on withdraw addresses quantity reached",
  "WA1":"Limit on deposit addresses quantity reached",
  "WA2":"Can not find free deposit address, retry later",
  "WA3":"Withdraw address currency %s is not equal to limit currency %s",
  
  "2FA0":"2FA is enabled already, you should disable it first",
  "2FA1":"2FA is disabled already",
  "2FA2":"2FA reset error",
  "2FA3":"2FA is already resetting",
  "2FA4":"Enable 2FA to view this content",
  "2FA5":"Too many 2FA attempts, try again in %d minutes",
  
  "DCF0":"Device confirmation is enabled already, you should disable it first",
  "DCF1":"Device confirmation is disabled already",
  "N1":"Scheduled maintenance %s for %s",
  "N2":"Withdraw of %s is currently unavailable",
  "N3":"%s"
}




export const enums = {
  direction: {1:"Buy", 2:"Sell"},
  instrument_status: {0:"Active", 1:"Cancel only", 2:"Inactive", 3:"Expiration", 4:"Disabled", 5:"Temporary prohibited"},
  expiry_type: {0:"Payment", 1:"Supply",2:"Perpetual"},
  order_status: {0:"Adding", 1:"Running", 2:"Canceled", 3:"Filled", 4:"Pending"},
  cancel_reason: {0:"FOK", 1:"IOC", 2:"Maker", 3:"Cross", 4:"Market", 5:"GTD", 6:"User", 7:"Margin call", 8:"Expiration", 9:"Margin call ended"},
  order_type: {0:"Limit", 1:"Market"},
  order_flags: {1:"Margin call", 2:"Expiration", 4:"Stop loss", 8:"Take profit"},
  trade_flags: {1:"Cross"},
  time_in_force: {0:"GTC", 1:"FOK", 2:"IOC", 3:"GTD"},
  activation_type: {0:"Immediate", 1:"Stop loss", 2:"Take profit"},
  transaction_priority: {0:"Low", 1:"Normal", 2:"High", 3:"Very high", 4:"Insane"},
  transaction_direction: {0:"Deposit", 1:"Withdrawal"},
  transaction_state: {
    0:"Pending (New)", 
    1:"Pending (Cosigning)", 
    2:"Successfull", 
    3:"Rejected", 
    4:"Successfull (reject failed)", //for withdrawal
    5:"Pending (Insufficient funds on hot wallet)",
    6:"Pending (Recreating)",
    7:"Pending (Resending)"
  },
  transaction_state_simple: {
    0:"Pending", 
    1:"Pending", 
    2:"Successfull", 
    3:"Rejected", 
    4:"Successfull",
    5:"Pending",
    6:"Pending",
    7:"Pending"
  },
  time_in_force_full: {1:"Fill or kill", 2:"Immediate or cancel"},
  trigger: {0:"Last",1:"Mark",2:"Index"},
};
