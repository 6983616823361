/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { strings } from '../localization/strings';
import { Theme } from '../styles/select-theme';

import { withFilter } from '../utils/utils'
import { getLim/*, Limits*/, Accounts, withAccountsList } from "../ws-priv/accounts";

import { Button } from "../components/button";

import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { Log } from '../components/log';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import QrCode from "qrcode.react";
import Popover from '@material-ui/core/Popover';

import { LoginOrRegister } from "../ws-priv/login-or-register";

import Currencies, { withCurrencies } from "../ws-pub/currencies"

import '../styles/css/deposit.css';
import '../styles/css/accounts.css';

import { 
//  IRefresh,
  IBtnCopyClipboard,
} from '../styles/icons';

import {
//  decS,
  iDecCidS,
  decCidS,
} from "../utils/cell-formatters";

import { ButtonConfirm } from "../components/button";

import { ShowTransactions } from '../ws-priv/transactions';

/*function QRCodeFormatter(v) {
  return <Button onClick={()=>{
  }} className="p4b bg-blue">QRCode</Button>
}*/

function AddressFormatter(v, self) {
  return <div style={{display: "flex", alignItems: "center"}}>
    <Button onClick={()=>{
        self.setState({show: true, qrcode: v});
      }} className="p4b bg-blue">{strings.QRCode}</Button>{'\u00A0\u00A0'}
    <Button onClick={()=>{
        copy(v);
        toast.info(strings.CopiedToCB, {autoClose: 3000});
      }} className="p4b bg-blue"><IBtnCopyClipboard size="13"/></Button>
    {'\u00A0\u00A0' + v}
  </div>
}

export class Deposit_ extends React.Component {
  constructor(props) {
    super(props);
    this.cid = this.props.filter.cid; 
    this.cur = Currencies.all[this.cid];
    this.state = {
      addrs: [],
      show: false,
      qrcode: "",
    }
    this.columns = [
      //{title:"Show", field:"did", formatter: QRCodeFormatter},
      {title:"Addresses", field:"did", formatter:AddressFormatter},
    ]
    this.anchorRef = React.createRef();
  }

  componentDidMount() {
    let self = this;
    if (wspriv.authorized) {
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "g_dadr", "D":{"cids":[this.cid], "mt": "18446744073709551615", "lim": 5}},
        eternal: false,
        onResult: function(msg) {
          if (!self.will_unmount) {
            let addrs = _.cloneDeep(msg["D"]["adrs"]);
            addrs.sort(function (a, b) {return b["ct"] - a["ct"];});
            addrs.splice(5, addrs.length - 5);
            self.setState({addrs: addrs});
          }
        }, 
      });
    }
  }

  componentWillUnmount() {
    this.will_unmount = true;
  }

  render() {
    let self = this;
    let acc = Accounts.main;
    if (!acc) return <LoginOrRegister/>;
    let lim = getLim(acc.aid, this.cid);
    //console.log(this.cid, strings.Deposit_[this.cid], strings.Deposit_);
    //console.log("render", this.state.addrs);
    return <div className="default-column" ref={this.anchorRef}>
      <div>
        <div className="p4">{strings.formatString(strings.Deposit_.CoinsGoToMain, Accounts.main.aid)}</div>
      </div>
      <div className="default-info">
        <div className="p4">{strings.Balance}:</div>
        <div className="p4b">{iDecCidS(lim.lim, lim)}</div>
      </div>
      <br/>
      <div className="t-accs">
        <table>
          <thead className="p3 col-night-sky"><tr>
             {this.columns.map((col)=>{return <th key={col.title} className="bg-grey-light">
                <div className="th-content">
                  <div className="th-title">
                    {null/*strings.Deposit_[col.title]*/}
                    {strings.Deposit_[this.cur.bcid].addr}:
                  </div>
                </div>
              </th>})}
          </tr></thead>
          <tbody className="p3 col-grey">
          {this.state.addrs.map((addr)=>{return <tr key={addr.did}>
              {this.columns.map((col)=>{return <td key={col.title}>
                { addr[col.field]
                  ? (col.formatter 
                    ? col.formatter(addr[col.field], self) 
                    : addr[col.field])
                  : ""
                }
                </td>})
              }
            </tr>})
          }
          </tbody>
        </table>
      </div>      
      <div className="default-info">
        {(this.state.addrs.length < 1 || this.cur.bcid == "BTC") && <React.Fragment><ButtonConfirm onClick={()=>{
              new Subscribtion({
                ws: wspriv,
                smsg: {"P": "n_dadr", "D":{"cid": this.cid}},
                eternal: false,
                onResult: function(msg) {
                  let addrs = _.cloneDeep(self.state.addrs);
                  addrs.push(msg["D"]["adr"]);
                  addrs.sort(function (a, b) {return b["ct"] - a["ct"];});
                  addrs.splice(5, addrs.length - 5);
                  Log.success(strings.Deposit_.AddressGenerated);
                  self.setState({addrs: addrs});
                }, 
                onError: function(msg) {
                }
              });
            }} className="p4b bg-blue">{strings.Deposit_.NewDepositAddr}
        </ButtonConfirm>{'\u00A0\u00A0'}</React.Fragment>}
        <ShowTransactions id={this.props.id} /*io={0}*/ cid={this.cid}>{strings.ShowTransactions}</ShowTransactions>
      </div>
      <br/>
      {null/*<div className="default-info p4b">{strings.Deposit_[this.cur.bcid].addr}</div>*/}
      {(strings.Deposit_[this.cur.bcid].min && this.cur.mind > 0) && <div className="p4">- {strings.formatString(strings.Deposit_[this.cur.bcid].min, decCidS(this.cur.mind, this.cur))}</div>}
      {strings.Deposit_[this.cur.bcid].topics.map((text, i)=><div key={i} className="p4">- {text}</div>)} 
      <Popover
        open={this.state.show}
        onClose={()=>this.setState({show: false})}
        anchorEl={this.anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="cursor-pointer"
            onClick={()=>this.setState({show: false})}>
          <div className="deposit-qr-title p3b">{strings.Deposit_.Address}</div>
          <div className="deposit-qr">
            <div>
              <QrCode
                  value={this.state.qrcode}
                  size={256}
                  bgColor={Theme.current === "dark" ? "#000000" : "#FFFFFF"}
                  fgColor={Theme.current === "dark" ? "#FFFFFF" : "#000000"}
                  imageSettings={{
                    //src: "/favicon.ico",
                    src: "/icons/" + this.cid + ".png",
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}                  
              />
            </div>
          </div>
          </div>
      </Popover>    
      <LoginOrRegister/>
    </div>
  }
}

export const Deposit = withFilter(withCurrencies(withAccountsList(Deposit_)));
