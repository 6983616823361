/* eslint-disable no-sparse-arrays */

export const description = {
	'name':"Описание биржи",
	'id':"Description_of_the_exchange",
	//'version':"Version 1.0",
	"chapters":[
	{
	'name':`Описание рабочего пространства`,
	'id':`Getting_started`,
	'description':`<br><br>
	Рабочее пространство состоит из нескольких основных частей.
	`,
	"chapters":[
		{
		'name':`Управление рабочим пространстом`,
		'id':`Workspace`,
		'description':`<br><br>
		Здесь находится название торговой системы и кнопки регистрации, входа и выхода из системы, смена языка и доступом к документации, в т.ч. API документации, настройками учётной записи:
		<ul>
		<li>Безопасность. Включение и отключение двух-факторной аутентификации. Смена пароля доступа.
		<li>API ключи. Создание и управление API ключами.
		<li>Реферальная программа.
		<li>Кошелек. Ввод и вывод средств. Перевод средств между своими счетами (в т.ч. созданными и предоставленными в управление другим участникам торгов). Просмотр последних переводов.
		<li>Рабочий стол. Боковое меню выезжающее слева при наведении курсора мыши. 
		Меню состоит из ярлыков ко всем таблицам доступным участникам торгов, в том числе кнопкам сохранения шаблонного расположения окон. 
		Для сохранения шаблона необходимо нажать синюю пиктограмму в виде облака под кнопкой «Рабочий стол». 
		Для возврата к шаблонному расположению окон нужно назвать одно из названий шаблонов.
		</ul>
		`,
		},
		{
		'name':`Основное рабочее пространство`,
		'id':`Main_workspace`,
		'description':`<br><br>
		На <i>Основном рабочем пространстве</i> могут быть расположены все доступные таблицы. 
		Таблицы могут перемещаться по рабочему пространству захватом за верхнюю часть таблицы курсором мыши и менять свой размер. 
		Убрать ненужную таблицу можно крестиком в правом верхнем углу. Вернуть таблицу можно будет через левое боковое меню. 
		По шестеренке в правом верхнем углу содержимое любой таблицы можно отфильтровать по инструментам, дате, времени, отобрать только необходимые столбцы и т.п. 
		В некоторых таблицах есть возможность скачать данные себе на компьютер.
		`,
		"chapters":[
			{
			'name':`Все инструменты`,
			'id':`All_instruments`,
			'description':`<br><br>
			Сводная таблица по всем доступным финансовым инструментам. Ниже основные параметры данной таблицы:
			<ul>
			<li>Имя - краткое название инструмента.
			<li>Тип - отражает к какой группе финансовый инструментов относится.
			<li>Описание - полное название.
			<li>Гарантийное обеспечение. Показывает сколько нужно иметь BTC чтобы иметь возможность купить или продать один полный лот. <br>
				Например, чтобы купить или продать 1 BTC, нужно иметь на счете 0,1 BTC, соответственно, чтобы сделать сделку с 0,001 BTC, нужно на счете иметь 0,0001 BTC. 
			<li>Стоимость шага цены. Стоимость минимального возможного изменения цены для данного инструмента, выраженная в BTC.
			<li>Нижний лимит цены. Цена, ниже которой невозможно выставить никакую заявку. Если заявка была выставлена ранее, когда лимит позволял выставить по этой цене заявку, 
				то заявка остается в системе, но новые не принимаются.
			<li>Верхний лимит цены. Аналогично нижнему лимиту, только с ограничением в выставлении заявок для цены, которые выше лимита.
			<li>Индекс. Значение индекса для данного инструмента.
			<li>Расчетная цена. Расчетное значение цены с учетом текущих bid, offer, цены последней сделки и индекса для данного инструмента.
			<li>Шаг цен. Минимально возможное изменение цены для данного инструмента. 
			<li>Лот. Минимальный лот, с которым можно сделать сделку.
			<li>Ставка фондирования. Ставка Funding rate. Размер списаний и начислений каждый восемь часов для держателей позиции в данном инструмента. Выражена в процентах.
			<li>Минимальная доля видимой части айсберг заявки. Выражена в процентах.
			<li>Тип экспирации.
			</ul>
			`,
			},
			{
			'name':`Новая заявка`,
			'id':`New_order`,
			'description':`<br><br>
			Таблица, отражающая основные параметры отдельного инструмента, включая риск-параметры, и через которую можно ввести заявку на сделку. 
			`,
			},
			{
			'name':`Все сделки`,
			'id':`All_transactions`,
			'description':`<br><br>
			Таблица отражает все сделки по всем инструментам в одной таблице. 
			Через специальное меню в верхнем правом углу можно отфильтровать содержимое по нужным инструментам, а также скачать данные за выбранный период и выбранным инструментам.
			`,
			},
			{
			'name':`Таблица счетов`,
			'id':`Accounts_table`,
			'description':`<br><br>
			Отражает текущие данные по аккаунту участника торгов.<br>
			<b>Доступно</b>: текущий доступный остаток для торгов. <br>
			<b>Баланс</b>: суммарная переоценка всех активов, включая все позиции и свободные остатки а также P/L. <br>
			<b>Зарезервировано</b>: объем BTC зарезервированный для поддержания текущей позиции и активных заявок. <br>
			<b>Управление счетом</b>. Каждый участник может создать еще несколько дополнительных счетов. 
			Они могу использоваться самостоятельно для сегрегирования позиций, а также могут быть переданы другим зарегистрированным участникам. 
			Другому участнику будут доступны только торговые операции (без права ввода и вывода) и только тот лимит, который ему будет выделен.<br>
			<b>Комиссия</b>. В данной таблице отражена статистическая информация по комиссионным сборам и установленному текущему тарифному плану для участника.
			`,
			},
			{
			'name':`Заявки, сделки и позиции`,
			'id':`Orders_transactions_positions`,
			'description':`<br><br>
			Отражает всю торговую историю по счету. Может быть скачана через соответствующую кнопку в верхнем правом углу таблицы 
			(выбор слишком большой глубины истории для скачивания может привести к длительному ожиданию). Также есть фильтр по инструментам и по времени.
			`,
			},
			{
			'name':`Стакан`,
			'id':`Order_book`,
			'description':`<br><br>
			Таблица в минималистическом стиле. Их можно разместить любое количество по всем инструментам одновременно на своем экране. 
			Кликом по любой цене в стакане открывается форма для ввода заявки.
			`,
			},
			{
			'name':`История`,
			'id':`History`,
			'description':`<br><br>
			Таблица содержит всю историю списаний и начислений, включая комиссии, funding rate и пр. действий связанных с аккаунтом.
			`,
			},
			],
		},
		
	],
	},
	{
		'name':`Торговые площадки`,
		'id':`Trading_platforms`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Фьючерсные контракты`,
			'id':`Futures_contracts`,
			'description':`<br><br>
			Фьючерсные контракты (спецификация каждого контракта доступна в таблице Новая заявка - параметры инструмента)<br>
			BTCUSDZ20 25 декабря 2020г.
			`,
			},
			{
			'name':`CFD, бессрочные контракты`,
			'id':`CFD`,
			'description':`<br><br>
			CFD, бессрочные контракты (спецификация каждого контракта доступна в таблице Новая заявка - параметры инструмента)<br>
			BTCUSD бессрочный контракт
			`,
			},
			],
		},
	{
	'name':`Индексы`,
	'id':` Indexes  `,
	'description':`<br> <br>
	Значения индексов рассчитываются на основе данных по ряду площадок, наиболее популярных в соответствующем виде актива.<br>
	Индексы отражают рынок независимо от состояния отдельных активов и бирж. Расчетная база регулярно обновляется.
	`,
	},
	{
	'name':`Доступ к торгам`,
	'id':`Connection_to_bidding`,
	'description':`<br><br>
	Доступ к торгам возможно двумя способами: через сайт viking.trade или с использованием API.
	`,
	"chapters":[
		{
		'name':`Сайт биржи`,
		'id':`Exchange_website`,
		'description':`<br><br>
		После регистрации на сайте пользователь получает весь набор инструментов для торгов. 
		Для регистрации на бирже необходимо предоставить короткий набор основных персональных данных.
		`,
		},
		{
		'name':`API`,
		'id':`Gateway_connection`,
		'description':`<br><br>
		Также, для возможности использования автоматизированных торговых систем есть API. См. документацию.
		`,
		},
		],
		},
		{
		'name':`Тарифы и комиссии`,
		'id':`Fees_and_commissions`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Списания и начисления`,
			'id':`Write_offs_and_accruals`,
			'description':`<br>
			`,
			"chapters":[
				{
				'name':`Биржевая торговая комиссия`,
				'id':`Exchange_Trade_Commission`,
				'description':`<br><br>
				Правилами биржи предусмотрены мейкерские и тейкерские комиссионные сборы за совершение сделок, рассчитываются и списываются в BTC в момент совершения сделки. 
				Фиксированная комиссия также рассчитывается в BTC и составляет фиксированную величину. 
				Обычно используется в индивидуальных тарифных планах с пониженной ставкой тейкера и мейкера.<br><br>
				<table>
				<tr>
				<td>Мейкер</td>
				<td>0,02%</td>
				</tr>
				<tr>
				<td>Тейкер</td>
				<td>0,05%</td>
				</tr>
				<tr>
				<td>Фикс</td>
				<td>0 BTC</td>
				</tr>
				</table>
				<br><br>				
				Комиссия за выход на экспирацию рассчитывается и списывается в BTC. Сумма сбора зависит от объема позиции на момент экспирации.<br><br>
				<table>
				<tr>
				<td>Экспирация</td>
				<td>0,04%</td>
				</tr>
				</table>
				`,
				},
				{
				'name':`Индивидуальный тарифный план`,
				'id':`Individual_tariff_plan`,
				'description':`<br><br>
				Индивидуальный тарифный план дает возможность значительно сократить издержки на биржевую торговую комиссию активным пользователям с большим оборотом. 
				Предложения по индивидуальному тарифу присылайте на наш контактный email.
				`,
				},
			],
		},
		{
		'name':`Funding rate`,
		'id':`Funding_rate`,
		'description':`<br><br>
		Механизм стимулирования участников торгов к сделкам в сторону наиболее справедливой цены, а именно держателей длинной позиции продавать, 
		если цена в моменте завышена относительно “справедливой” и держателей короткой позиции покупать, если цена занижена относительно “справедливой”. 
		Биржа не забирает себе ничего из этого, фактически лонгисты платят шортистам если ставка Funding rate в момент расчетов оказалась положительной и шортисты платят лонгистам, 
		если ставка отрицательная. Текущая ставка Funding rate транслируется в соответствующей таблице. 
		Окончательная сумма списания или начисления зависит от ставки Funding rate и позиции на момент списания. 
		Списания и начисления происходят один раз в час.
		История всех списаний и начислений Funding rate записывается в таблицу History.
		`,
		},
		{
		'name':`Примеры`,
		'id':`Examples`,
		'description':`<br>
		`,
		},
		{
		'name':`Комиссионный сбор при ликвидации`,
		'id':`Commission_fee_on_liquidation`,
		'description':`<br><br>
		По сделкам в случаях принудительного сокращения позиции взимается комиссионный сбор.
		`,
		},
		{
		'name':`Комиссия за ввод и вывод`,
		'id':`Commission_for_depositing_and_withdrawal`,
		'description':`<br><br>
		Комиссия за ввод средств на биржу отсутствует. Комиссия за вывод средств зависит от блокчейна, на который осуществляется вывод. 
		Для некоторых блокчейнов комиссия за вывод средств ограничена комиссией сети, для других блокчейнов в комиссию за вывод средств включены дополнительные расходы биржи, 
		связанные с обработкой входящих и исходящих транзакций.
		В случае, если вывод средств осуществляется на адрес, являющийся адресом пополнения счёта пользователя на бирже (того же или другого), то такая транзакция осуществляется быстро и без комиссии, так как транзакция на блокчейне не создаётся. ID таких транзакций в таблице транзакций пользователя начинается с префикса "internal".
		`,
		},
		{
		'name':`Овердрафт`,
		'id':`Overdraft`,
		'description':`<br><br>
		Овердрафт - технический овердрафт допускающий отрицательный остаток BTC у клиента. 
		Отрицательный остаток по BTC не приводит к маржин-коллу, если у клиента есть положительный остаток по другим валютам, в совокупности достаточный для поддержания открытых позиций.
		На отрицательный остаток BTC может начисляться дополнительная комиссия. Фактически списанная комиссия за овердрафт будет отражена в таблице "История". 
		Если сумма задолжности по BTC будет слишком большой, биржа может без уведомления сконвертировать любую имеющуюся валюту у клиента в BTC для погашения долга.
		`,
		},
		],
	},
	{
	'name':`Типы заявок`,
	'id':`Types_of_order`,
	'description':`<br><br>
	Типы заявок доступные для использования:
	`,
	"chapters":[
		{
		'name':`Лимитная заявка`,
		'id':`Limit order`,
		'description':`<br><br>
		Лимитная заявка используется по умолчанию если специально не выбран другой тип заявки. 
		При использовании данного режима заявка сразу попадает в очередь в биржевой стакан с ценой и количеством, указанными пользователем. <br>
		Типы лимитных заявок:
		<ul>
		<li>GTC (Good Till Cancelled) - Тип заявки, действующий по умолчанию. После выставления действует до тех пор, 
			пока полностью не сведется в сделки либо не будет отменен самим пользователем (кроме случаем маржин-колл, тогда заявка может быть отменена торговой системой).
		<li>FOK (Fill or Kill) - тип лимитной заявки требующий или немедленного и полного исполнения или немедленной отмены. 
		Т.е. данный тип заявки при выставлении будет сведен в сделку только при достаточном количестве встречного объема в стакане. 
		Если требуемого объема не находится, то заявка в полном объеме отменяется. Т.е. частичного исполнения заявки не допускается.
		<li>IOC (Immediate or Cancel) - тип лимитной заявки, которая выставляется в стакан по заданной цене и немедленно отменяется. 
		При выборе IOC заявка будет полностью исполнена, если встречного объема хватит на весь требуемый объем. 
		Будет частично исполнена, если встречного объема меньше требуемого  и остаток заявки немедленно отменен. 
		Если на заданной цене отсутствует встречный объем, то заявка будет немедленно отменена полностью. Данный тип заявки подходит при высокой волатильности на рынке. 
		Отличается от FOK тем, что допускается частичное исполнение.
		<li>GTD (Good Till Date) - тип лимитной заявки, действующий до момента сведения в сделки или до определенной даты. 
		Момент, когда требуется отменить заявку устанавливаем в соответствующем поле, которое активируется при выборе GTD.
		</ul>
		`,
		},
		{
		'name':`Рыночная заявка`,
		'id':`Market_order`,
		'description':`<br><br>
		Пользователь указывает только количество необходимое купить или продать. Цена подставляется автоматически в момент нажатия подтверждения и равна встречной цене в стакане. 
		Если пользователь хочет купить, то автоматически подобранная цена будет равна текущему offer в стакане. 
		Если пользователь хочет продать, то цена в заявке будет равна текущему bid в стакане. 
		Таким образом достигается повышенная вероятность того, что заявка будет сразу сведена в сделку, но не гарантируется этого.
		`,
		},
		{
		'name':`Стоп-заявка`,
		'id':`Stop_order`,
		'description':`<br><br>
		Это вид отложенной биржевой заявки для автоматического совершения сделки по покупке или продаже заданного количества актива при достижении рыночной ценой заданной цены активации.
		`,
		"chapters":[
			{
			'name':`Стоп-рыночная заявка`,
			'id':`Stop_Market_Order`,
			'description':`<br><br>
			Рекомендован при открытых текущих позиций и желании оградить себя от чрезмерного убытка при уходе цены в неблагоприятную сторону. 
			В этом типе заявки указывается цена активации и количество. 
			После подтверждения о выставлении этой заявки она сразу не попадает в очередь в стакан, а только после того, как рынок достигнет цены активации. 
			Фактически вы отдаете приказ бирже выставить лимитную заявку на покупку или продажу по текущей рыночной цене только при достижении определенной цены. 
			Для покупки с использованием этого типа заявки цена на рынке должна стать больше или равна чем указанная цена активации. 
			Для продажи, цена на рынке должна стать меньше или равна чем указанная цена для активации.
			`,
			},
			{
			'name':`Профит-рыночная заяка`,
			'id':`Profit_market_order`,
			'description':`<br><br>
			Рекомендован при открытых текущих позициях и желании выставить закрывающую заявку только при достижении цены активации. 
			Фактически вы отдаете приказ бирже автоматически выставить заявку на продажу по текущем ценам, только если рыночная цена на бирже будет равна или выше, 
			чем указанная в цене активации. В случае покупки, наоборот, заявку на покупку выставить только если цена на бирже будет равна или ниже, чем указанная в сигнальной цене.
			`,
			},
			{
			'name':`Стоп-лимитная заявка`,
			'id':`Stop_limit_order`,
			'description':`<br><br>
			В отличии от стоп-рыночной заявки, при срабатывании цены активации, заявка выставляется на указанную фиксированную цену.
			`,
			},
			{
			'name':`Профит-лимитная заявка`,
			'id':`Profit_limit_order`,
			'description':`<br><br>
			В отличии от профит-рыночной заявки, при срабатывании цены активации, заявка выставляется на указанную цену.
			`,
			},
		],
		},
		{
		'name':`Айсберг-заявка`,
		'id':`Iceberg_Order`,
		'description':`<br><br>
		Специальный тип заявки позволяющий участнику скрыть от остальных участников часть своего объема в выставленной заявке чтобы минимизировать влияние на рыночную цену. 
		В специальном поле “Видимое кол-во” вводится число видимое для всех остальных участников.<br>
		При выставлении айсберг-заявки участник задает общий объем заявки и видимую постоянно всплывающую часть. Видимая часть - та часть объема, которая видна остальным участникам. 
		Когда видимая часть сводится в сделку, всплывает следующая видимая часть, она попадает в конец очереди на своем ценовом уровне и так до тех пор, 
		пока не сведется в сделки весь общий объем заявки. Таким образом устраняется влияние крупных заявок на рыночную цену.<br>
		Торговой системой устанавливается минимальная доля видимой части айсберга от общего объема.
		`,
		},
		],
	},
	{
	'name':`Порядок торгов`,
	'id':`Trading_procedure`,
	'description':`<br><br>
	Торги проводятся в режиме анонимных сделок. 
	Ордера сводятся в сделку с соблюдением принципов классического аукциона: сведение заявок в сделку осуществляется с приоритетом тех заявок, 
	которые раньше остальных предложили наилучшую цену. Отмена совершенной сделки не возможна. 
	Параметры каждой совершенной сделки остаются в истории торгов навсегда и доступны для всех участников в анонимном режиме. 
	Абсолютно всем участникам доступны одинаковые технические решения для доступа к торгам. 
	В личном разделе участнику доступна история по всем его совершенным сделкам.

	`,
	},
	{
	'name':`Режим торгов`,
	'id':`Trading_mode`,
	'description':`<br><br>
	Режим торгов торговой системы круглосуточный и без выходных. 
	Допускаются перерывы на кратковременные плановые технические работы по обслуживанию и обновлению программного обеспечения. 
	О плановых технических работах будут предварительные сообщения. Торги проходят в режиме непрерывного клиринга.
	`,
	},
	{
	'name':`Аккаунт и кошелек`,
	'id':`Account_and_wallet`,
	'description':`<br><br>
	Аккаунт и кошелек создаются автоматически после прохождения процедуры регистрации участника в системе торгов.
	`,
	"chapters":[
		{
		'name':`Аккаунт и субаккаунт`,
		'id':`Account_and_Subaccount`,
		'description':`<br><br>
		Для каждого участника создается автоматически один аккаунт. 
		Каждый участник может создать дополнительно для себя или делегирования (дать в управление другому зарегистрированному участнику) дополнительные субаккаунты. 
		Контроль за движением средств на всех субаккаунтах только у участника создавшего субаккаунты. 

		`,
		},
		{
		'name':`Кошелек`,
		'id':`Wallet `,
		'description':`<br><br>
		Кошелек в торговой системе является мультивалютным. Для доступа к торгам участнику необходимо пополнить свой кошелек любой из валют, для которых открыто пополнение. 
		Любая зачисленная валюта принимается к обеспечению и позволяет совершать сделки со  всеми инструментами доступными в торговой системе. <br>
		В качестве основной валюты для расчетов, начисления дохода, списания комиссий, списания убытков по всем инструментам принята единая криптовалюта BTC. 
		Все другие принимаемые в качестве обеспечения для торгов  валюты пересчитываются в сумму эквивалентную BTC по текущему курсу.<br>
		У участника торгов допускается отрицательное значение остатка BTC только если есть достаточно средств в эквиваленте в других валютах. 
		При отрицательном остатке BTC блокируется для вывода эквивалентное количество в другой валюте.
		`,
		},
	],
	},
	{
	'name':`Реферальная программа`,
	'id':`Referral_Program`,
	'description':`<br><br>
	Реферальная программа предполагает сотрудничество между торговой системой и участниками торгов, 
	где действующие участники получают вознаграждение за новых привлеченных ими клиентов из числа личных знакомых. 
	Вознаграждением является получение части комиссий в размере 25% от уплаченных новым клиентом. 
	Размер вознаграждения может меняться о чем будет дополнительное сообщение. 
	`,
	},
	{
	'name':`Манипулирование ценами`,
	'id':`Prices_Manipulation`,
	'description':`<br><br>
	Торговая система в целях предотвращения и выявления манипулирования рынком осуществляет контроль за операциями с финансовыми инструментами. <br>
	Под манипулированием рынком понимаются действия несущие признаки не рыночного поведения среди участников торгов: <br>
	<ul>
	<li>совершение операций с финансовыми инструментами по предварительному сговору между участниками торгов в целях искажения цены; 
	<li>многократное выставление заявок без целей совершения сделок; 
	<li>многократное выставление заявок и совершение сделок с участниками по предварительному сговору в целях 
		введения в заблуждение остальных участников относительно цены на финансовый инструмент. 
	</ul>
	Торговая система оставляет за собой право заблокировать торговлю любого участника торгов с исчерпывающим количеством признаков вышеописанного поведения 
	и выставить требование о выводе средств. Перед блокировкой участнику торгов направляется уведомление о приостановки торгов с просьбой объяснить экономический смысл проводимых операций.
	`,
	},
	{
	'name':`Безопасность`,
	'id':`Security`,
	'description':`<br><br>
	Безопасность торговой системы, устойчивость к взломам, защита персональных данных и внесенных активов участников торгов является приоритетом в работе нашей команды. 
	Мы работаем с лучшими профессионалами в области безопасности информационных систем, и мы признательны всем исследователям кибербезопасности, 
	которые обращаются к нам с целью сообщить нам о потенциальных уязвимостях, найденных в любых модулях нашей системы.<br>
	Мы будем признательны каждому за сообщение в обнаружении уязвимостей. Если вы считаете, что нашли уязвимость, пожалуйста, сообщите нам с соблюдением следующих правил:
	<ul>
	<li>Не прибегайте сами и не провоцируйте третьи лица к действиям направленных к мошенничеству.
	<li>Не прибегайте к действиям, которые могут навредить торговой системе, замедлить или приостановить ее работу, нанести ущерб ее участникам.
	<li>Не распространяйте и не храните любую информацию, которая может выявить уязвимость в безопасности торговой системы или причинить вред участникам торгов.
	</ul>
	`,
	},
	{
	'name':`Уведомление о рисках`,
	'id':`Risk_notification`,
	'description':`<br><br>
	Перед тем как начать пользоваться нашей системой важно, чтобы вы ознакомились и поняли перечень рисков описанных в данном уведомлении. 
	Перечень рисков не является исчерпывающим и гарантирующим безубыточность в торговле и инвестировании.
	`,
	"chapters":[
		{
		'name':`Рекомендации и советы`,
		'id':`Recommendations_and_tips`,
		'description':`<br><br>
		Торговая система не может рекомендовать те или иные сделки. Не дает советов, связанных с инвестирование в финансовые инструменты. 
		Вся информация, распространяемая торговой системой является лишь одной из множеств точек зрения относительно рынка.
		`,
		},
		{
		'name':`Риск высокой волатильности`,
		'id':`High_volatility_risk`,
		'description':`<br><br>
		Риск высокой волатильности это неотъемлемая часть в трейдинге и описывает уровень колебаний цен на финансовые активы. 
		Различные события могут привести к высокой волатильности и стать причиной резкого изменения цены. 
		В такие моменты наличие открытых позиций требует повышенного внимания к своему обеспечению чтобы не допустить маржин-колла.
		`,
		},
		{
		'name':`Маржинальный риск`,
		'id':`Margin_risks`,
		'description':`<br><br>
		Маржинальная торговля позволяет использовать плечо задействуя только часть своих реальных активов. 
		Такой вид торговли позволяет быстрей зарабатывать, в случае правильно просчитанной стратегии, но и нести большие убытки в случае ошибок.
		`,
		},
		{
		'name':`Правовые и системные риски`,
		'id':`Legal_risks`,
		'description':`<br><br>
		Каждый участник торгов несет самостоятельную ответственность за знание и понимание того, 
		как сделки с определенными финансовыми инструментами регулируются и облагаются налогом той юрисдикции, резидентом которой он является.
		`,
		},
		{
		'name':`Риск не знания инструментов`,
		'id':`Risk_of_not_knowing_the_instruments`,
		'description':`<br><br>
		Комиссионные сборы и понимание спецификации всех инструментов. Перед началом торгов каждый участник торгов 
		должен ознакомиться с предоставленным тарифным планом и разобраться в спецификации тех инструментов, с которыми планирует работать. 
		Иначе это грозит неожиданными прибылями или убытками.
		`,
		},
		{
		'name':`Общее`,
		'id':`General_information`,
		'description':`<br><br>
		<b>Итог:</b> Для успешного трейдинга важно понимать все типы рисков, с которыми можете столкнуться. 
		Понимание многих рисков позволяет защитить ваш торговый портфель в случаях неблагоприятного поведения цены и увеличить ее доходность.
		`,
		},
		],
	},
	{
	'name':`Риск-модуль торговой системы`,
	'id':`Risk_Module_of_Trading_System`,
	'description':`<br><br>
	Основными функциями торговой системы являются исполнение клиринговых обязательств и контроль по исполнению обязательств 
	между контрагентами в совершаемых сделках на площадках торговой системы. <br>
	Для поддержания необходимого уровня надежности торговой системы, риск-модуль осуществляет контроль за риск-параметрами, 
	которые состоят из требований к участникам торгов и различных уровней защиты и процедур предотвращения дефолта у участников торгов.
	`,
	"chapters":[
		{
		'name':`Требования к участникам торгов`,
		'id':`Requirements_to_Traders`,
		'description':`<br><br>
		В качестве обеспечения по сделкам и целях предотвращения дефолтных сценариев участники торгов вносят Гарантийное обеспечение. 
		В качестве Гарантийного обеспечения принимаются BTC и другие крипто-валюты (список принимаемых валют будет пополняться). <br>
		Достаточность обеспечения, внесенного участником торгов,  для поддержания текущей позиции и открытия новой позиции определяется с помощью показателя “Доступно”. 
		В целях расчета данного показателя используется суммарное Гарантийное обеспечение по всем открытым позициям, активным заявкам, а также P/L.
		`,
		},
		{
		'name':`Риск-параметры`,
		'id':`Risk_parameters`,
		'description':`<br>
		`,
		"chapters":[
			{
			'name':`Статические риск-параметры`,
			'id':`Static_risk_parameter`,
			'description':`<br><br>
			К данной категории относятся параметры не меняемые на регулярной основе и которые пересматриваются по мере необходимости.
			<ul>
			<li>Минимальный размер гарантийного обеспечения.
			<li>Ставки овердрафт.
			<li>Ставки дисконтирования в случаях обеспечения не основной валютой принятой торговой системой.
			<li>Спецификация финансовых инструментов.
			</ul>
			`,
			},
			{
			'name':`Динамические риск-параметры`,
			'id':`Dynamic_Risk_Parameters`,
			'description':`<br><br>
			К данной категории относятся параметры:
			<ul>
			<li>Верхняя и нижняя граница ценового коридора. Для исключения ошибочных транзакций и излишней волатильности приняты параметры Верхний и нижний лимиты цен.
			<li>Расчетная стоимость инструмента. Используется для подсчета необходимого минимального уровня средств для поддержания позиции, 
			расчетах funding rate, определения верхних и нижних границ ценового коридора, регулирования процедур дефолта у участников и др.
			<li>прочие параметры, не раскрываются и  являются промежуточными.
			</ul>
			Торговой системой можно быть принято решение об изменении риск-параметров как в режиме приостановки торгов, так и во время торгов.
			`,
			},
		],
		},
		{
		'name':`Маржин-колл или ликвидация`,
		'id':`Margin_Call_or_Liquidation`,
		'description':`<br><br>
		При дефолтном сценарии включается автоматический режим управления позициями и активными заявками участника торгов. 
		Может использоваться только при дефиците собственных средств на кошельке участника после получения отрицательного результата по сделкам 
		или после повышения Гарантийного обеспечения по финансовым инструментам в позициях которого участник находится.<br> 
		При активации данного режима происходит снятие всех активных заявок, в том числе отложенных заявок. 
		Далее, при сохранении дефицита собственных средств на поддержание текущей позиции, происходит автоматическое выставление маржин-колл заявки. 
		Маржин-колл заявка не может быть отменена участником торгов. <br><br>
		Параметры маржин-колл заявки:<br>
		<ul>
		<li>Тип - лимитная заявка.
		<li>Инструмент в заявке - финансовый инструмент из тех, что присутствует в открытых позициях участника и имеет наибольший коэффициент заемных средств. 
		<li>Направление - встречное текущей позиции в соответствующем финансовом инструменте.
		<li>Количество - достаточное чтобы закрыть дефицит собственных средств.
		<li>Цена - лучший bid или лучший offer в зависимости от направления заявки приводящей к немедленному исполнению. Если нет необходимой цены, принимается расчетное значение.
		</ul>
		<br><br>
		* Итоговый объем маржин-колл заявки и цена итоговой сделки могут измениться. 
		Если по цене выставления заявки не нашлось достаточного объема для немедленного исполнения заявки, включается специальный режим пересмотра цены в заявке. 
		В момент перевыставления заявки объем заявки пересчитывается еще раз. 
		По отдельно взятому финансовому инструменту маржин-колл заявка может быть только одна, но может быть несколько маржин-колл заявок по разным инструментам 
		(если в портфеле участника торгов присутствует несколько финансовых инструментов).
		`,
		},
		],
},

	],			
}
