/* eslint-disable eqeqeq */
import React from 'react';
import { strings } from '../localization/strings';
import { Responsive } from "react-grid-layout";
import { withSize } from 'react-sizeme'
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import _ from "lodash";
//import {Link} from 'react-router-dom'
import '../styles/css/grid.css';
import '../styles/css/scrollbar.css';

import MenuLeft from '../components/menu-left'
import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";
import { zipAndBase64, unBase64AndUnZip } from "../utils/utils";
import { layouts, prepare } from '../components/grid-layouts'
import { toast } from 'react-toastify';


class InnerGrid_ extends React.Component {
  static defaultProps = {
    //breakpoints: {llg: 1800, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 240},
    //cols: {llg: 18, lg: 12, md: 10, sm: 6, xs: 3, xxs: 1 },
    //breakpoints: {lg: 1200, xxs: 480},
    //cols: { lg: 18, xxs: 1 },
    breakpoints: {llllg: 3600, lllg: 2800, llg: 2200, lg: 1600, md: 996, sm: 768, xxs: 480},
    cols: {llllg: 38, lllg: 30, llg: 24, lg: 18, md: 12, sm: 8, xxs: 1 },
    rowHeight: 44,
    margin: [15, 15],
    containerPadding: [15, 15],
  };

  onBreakpointChange(newBreakpoint, newCols) {
    console.log("onBreakpointChange " + newBreakpoint);
    //this.breakpoint = newBreakpoint;
    this.props.onLayoutChange(this.props.layout);
  }

  onLayoutChange(ll, allLayouts) {
    //console.log("onLayoutChange");
    //console.log(ll);
    //console.log(allLayouts);
    let layout = _.cloneDeep(this.props.layout);
    for (let l in allLayouts) {
	    layout.items.forEach((item, i)=>{
        allLayouts[l][i].h = ll[i].h;
	    	item[l] = _.cloneDeep(allLayouts[l][i]);
        if (l === 'xxs' || l === 'xs') item[l].static = true;
        /*delete item[l].moved;
        delete item[l].static;
        delete item[l].isResizable;
        delete item[l].isDraggable;
        delete item[l].maxH;
        delete item[l].maxW;
        delete item[l].minH;
        delete item[l].minW;
        */
    	});
    }
    //this.setState();
  	this.props.onLayoutChange(layout);
  }

  onRefreshItem(key) {
    let layout = _.cloneDeep(this.props.layout);
    let n = _.findIndex(layout.items, ['key', key]);
    let item = _.cloneDeep(layout.items[n]);
    item.key = (++layout.key) + "";
    layout.items.push(item);
    layout.items = _.reject(layout.items, { 'key': key});
    this.props.onLayoutChange(layout);
  }

  onCopyItem(key) {//add item to grid
    let layout = _.cloneDeep(this.props.layout);
    let n = _.findIndex(layout.items, ['key', key]);
    let item = _.cloneDeep(layout.items[n]);
    this.props.onAddItem(item);
  }

  onRemoveItem(key) {//remove item from grid
    this.props.onRemoveItem(key);
  }

  onAddItem(layout, component, filter) {//add item to grid
    let item = null;
    layouts[layout].items.some((i)=>{if (i.component_ == component) {item = _.cloneDeep(i); return true;} else return false;});
    if (item) {
      Object.assign(item.filter, filter);
      //console.log("onAddItem", item);
      this.props.onAddItem(item);
    }
  }

  onFilterChanged(key, filter) {
    //console.log("onFilterChanged", key, filter);
    let layout = _.cloneDeep(this.props.layout);
    let n = _.findIndex(layout.items, ['key', key]);
    layout.items[n].filter = _.assign(layout.items[n].filter, filter);
    //console.log("onFilterChanged", layout.items);
    this.props.onLayoutChange(layout);
  }

  onSaveData(key, saved_data) {
    let layout = _.cloneDeep(this.props.layout);
    let n = _.findIndex(layout.items, ['key', key]);
    layout.items[n].saved_data = _.assign(layout.items[n].saved_data, saved_data);
    //console.log("onSaveData", layout.items[n].saved_data);
    this.props.onLayoutChange(layout);
  }

  onResetSavedData(key) {
    let layout = _.cloneDeep(this.props.layout);
    let n = _.findIndex(layout.items, ['key', key]);
    let item = _.cloneDeep(layout.items[n]);
    item.key = (++layout.key) + "";
    item.saved_data = null;
    layout.items.push(item);
    layout.items = _.reject(layout.items, { 'key': key});
    this.props.onLayoutChange(layout);
  }


  render() {
  	let allLayouts = {};
  	let items = this.props.layout.items.map((item)=>{
	    for (var l in this.props.breakpoints) {
	    	if (item[l] === undefined) item[l] = _.cloneDeep(item.lg);
        if (item[l]) {
      		if (!allLayouts.hasOwnProperty(l)) allLayouts[l] = [];
      		item[l].i = item.key;
          //item[l].resizeHandles = ['s', 'se', 'e'];
      		allLayouts[l].push(item[l]);
        }
	    }
			//collapsed: item.collapsed,
	        //onCollapseItem: this.onCollapseItem.bind(this, item.key),
  		//return <div key={item.key} data-grid={_.cloneDeep(item.lg)}>
      if (!item.component) return <div key={item.key}/>;
      return <div key={item.key}>
        {item.component({
          id: item.key,
          component_: item.component_,
          title: item.title,
          last_price: item.last_price,
          total_balance: item.total_balance,
          filter: item.filter,
          saved_data: item.saved_data,
          select: item.select,
          filters: item.filters,
          refreshable: item.refreshable,
          download_trades: item.download_trades,
          download_wallet: item.download_wallet,
          download_account: item.download_account,
          onRefresh: this.onRefreshItem.bind(this, item.key),
	        onCopyItem: this.onCopyItem.bind(this, item.key),
	        onRemoveItem: this.props.layout.static ? null : this.onRemoveItem.bind(this, item.key),
          onAddItem: this.onAddItem.bind(this),
          onFilterChanged: this.onFilterChanged.bind(this, item.key),
          onSaveData: this.onSaveData.bind(this, item.key),
          onResetSavedData: this.onResetSavedData.bind(this, item.key), 
          rowHeight: this.props.rowHeight,
  		})}</div>;
  	});
    //console.log(allLayouts);
    return <Responsive
          width={this.props.size.width}
          draggableCancel=".g-i-no-drag"
          draggableHandle=".g-i-drag"
          layouts={allLayouts}
          breakpoints={this.props.breakpoints} 
          cols={this.props.cols} 
          rowHeight={this.props.rowHeight} 
          margin={this.props.margin}
          containerPadding={this.props.containerPadding}
          onLayoutChange={this.onLayoutChange.bind(this)}
          onBreakpointChange={this.onBreakpointChange.bind(this)}
          isBounded={false}
        >
        {items}
        </Responsive>;
   }
}

const InnerGrid = withSize()(InnerGrid_);

export default class Grid extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        grid_key: 0,
      }
      this.layout_id = props.layout;
      //this.layout_id = "layout_trade";
      this.layout = this.loadLayout(this.layout_id);
      if (props.layout_left_menu) this.layout_left_menu = _.cloneDeep(prepare(layouts[props.layout_left_menu]));
      //console.log(this.layout_left_menu);
      //layouts.layout_left_menu_misk = prepare(layouts.layout_left_menu_misk);
  }

  setLayout(layout_id) {
    //if (layout_id === this.layout_id) return;
    this.saveLayout(this.layout_id, this.layout);
    this.layout_id = layout_id;
    this.layout = this.loadLayout(this.layout_id);
    this.setState({grid_key: this.state.grid_key + 1});
  }

  loadLayout(layout_id) {//load from local storage
      if (this.props.localStorage && localStorage[layout_id]) {
        return prepare(JSON.parse(localStorage[layout_id]));
      } else {
        return _.cloneDeep(prepare(layouts[layout_id]));
      }
  }

  saveLayout(layout_id, layout) {//save to localstorage
    if (this.timeout === undefined) {
      let self = this;
      this.timeout = setTimeout(()=>{
        //console.log("saveLayout", layout);
        localStorage[layout_id] = JSON.stringify(layout);
        self.timeout = undefined;
      }, 300);
    }
  }

  saveLayoutToBackend(layout_id) {//save to backend
    /*setTimeout(()=>{
      console.log("saveLayout", layout_id, this.layout);
      //wspriv.send({"P": "s_str", "D": {"key": "set", "val": ""}});
      wspriv.send({"P": "s_str", "D": {"key": layout_id, "val": zipAndBase64(JSON.stringify(this.layout))}});      
    }, 300);*/
    //let self = this;
    //console.log("loadLayoutFromBackend", layout_id);
    if (!wspriv.connected) {
      toast.info(strings.LayoutMustLogIn, {autoClose: 3000});
      return;
    }

    this.subscribtion = new Subscribtion({
      ws: wspriv,
      smsg: {"P": "s_str", "D": {"key": layout_id, "val": zipAndBase64(JSON.stringify(this.layout))}},
      eternal: false,
      onResult: function(msg) {
        toast.info(strings.LayoutSaved, {autoClose: 3000});
      }, 
    });
  }

  loadLayoutFromBackend(layout_id) {//load from backend
    let self = this;
    //console.log("loadLayoutFromBackend", layout_id);
    this.subscribtion = new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_str", "D": {"key": layout_id}},
      eternal: false,
      onResult: function(msg) {
        if (msg.D.val === "") return;
        try {
          self.layout = prepare(JSON.parse(unBase64AndUnZip(msg.D.val)));
          //console.log("loadLayout", layout_id, JSON.parse(unBase64AndUnZip(msg.D.val)));
          self.saveLayout(self.layout_id, self.layout);
          self.setState({grid_key: self.state.grid_key + 1});
          toast.info(strings.LayoutLoaded, {autoClose: 3000});
        } catch(exception) {
            console.log('[Error] ' + exception);
        }
      }, 
    });
  }

  onResetLayout() {
    this.layout = _.cloneDeep(prepare(layouts[this.layout_id]));
    this.saveLayout(this.layout_id, this.layout);
    this.setState({grid_key: this.state.grid_key + 1});
  }

  onLayoutChange(layout) {
    //console.log("onLayoutChange");
    this.layout = layout;
    this.saveLayout(this.layout_id, this.layout);
    //console.log("onLayoutChange", this.layout);
    this.forceUpdate();
  }

  onRemoveItem(key) {//remove item from layout
    let layout = _.cloneDeep(this.layout);
    layout.items = _.reject(layout.items, { 'key': key});
    this.onLayoutChange(layout);
  } 

  onAddItem(item) {//add item to layout
    let layout = _.cloneDeep(this.layout);
    item.key = (++layout.key) + "";
    layout.items.unshift(item);
    this.onLayoutChange(layout);
  } 

  render() {
    //return <div style={{width: "calc(99.9%)"}}>
    return <React.Fragment>
        {this.layout_left_menu && <div><MenuLeft self={this} layout={this.layout_left_menu}/></div>}
        <div style={{width: "100%"}}>
          <InnerGrid key={this.state.grid_key}
            layout={this.layout} 
            onLayoutChange={this.onLayoutChange.bind(this)}
            onRemoveItem={this.onRemoveItem.bind(this)}
            onAddItem={this.onAddItem.bind(this)}
          />
        </div>
    </React.Fragment>;
  }
}