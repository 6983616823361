import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";

//import {strings} from '../localization/strings';
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import Symbols from "../ws-pub/symbols";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import { sec_id, dir, dec, dt_ns_ms/*, decS, dt_sS*/ } from "../utils/cell-formatters";
import { withFilter, getMinNsecTime } from '../utils/utils'

export default class TradesPub { 
	static getListenSids() {
    let sids = [];
    if (this.listeners['_all_'].length > 0) sids = null;
    else {
      _.each(this.listeners, (listeners, sid_)=>{
        if (listeners.length > 0 && sid_ !== '_all_') sids.push(parseInt(sid_));
      });        
    }
    return sids;
	}

	static addListener(f, sids) {
    //console.log("addListener " + JSON.stringify(sids));
		if (sids === '_all_') sids = [sids];
		sids.forEach((sid)=>{
			if (!this.listeners.hasOwnProperty(sid)) this.listeners[sid] = [];
			this.listeners[sid].push(f);
		});
		this.listenersFs.push(f);
  	//if (this.old_subscribtion) {this.old_subscribtion.delete(); this.old_subscribtion = null;}
    //if (this.subscribtion) this.old_subscribtion = this.subscribtion;
    //console.log("subscribe listeners:");
    //console.log(this.listeners);
    let self = this;
    sids = this.getListenSids();
    if (sids === null || sids.length > 0) {
      if (this.subscribtion) {
      	//console.log("111", sids);
        if (sids === null) this.subscribtion.smsg["D"].sids = null;
        else sids = this.subscribtion.smsg["D"].sids.concat(sids);
        wspub.send({ P: "s_tr", D: {"sids": sids, "wp": false}, "I": this.subscribtion.smsg["I"]});
      } else {
      	//console.log("222", sids);
	      this.subscribtion = new Subscribtion({//subscribe on updates
	        ws: wspub,
	        smsg: { P: "s_tr", D: {"sids": sids, "wp": false}},
	        eternal: true,
	        onResult: function (msg) {self.parse(msg)},
        });
      }
    }
	}

	static removeListener(f, sids) {
    //console.log("removeListener " + JSON.stringify(sids));
		if (sids === '_all_') sids = [sids];
		sids.forEach((sid)=>{
			this.listeners[sid] = _.reject(this.listeners[sid], (l) => l === f);
		});
		this.listenersFs = _.reject(this.listenersFs, (l) => l === f);
		let old = _.cloneDeep(this.subscribtion.smsg["D"].sids);
		if (old == null) old = Symbols.active.map((sym)=>sym.sid);
	  sids = this.getListenSids();
		if (sids != null) {
			if (sids.length === 0) {//unsub from all
        delete this.subscribtion.smsg["D"]["wp"];
				this.subscribtion.unsubscribe();
				this.subscribtion = null;
			} else {
				sids.forEach((sid)=>{old = _.reject(old, (l) => l === sid)});
				if (old.length > 0) wspub.send({ P: "us_tr", D: {"sids": old}});
				this.subscribtion.smsg["D"].sids = sids;
			}
		}
    //console.log(this.listeners);
	}

	static parse(msg) {
    let trs = null;
    if (msg["P"] === "us_tr") {return [];}
    if (msg["D"].hasOwnProperty("trs")) {trs = msg["D"]["trs"];}
    if (msg["D"].hasOwnProperty("tr")) {trs = []; trs.push(msg["D"]["tr"]);};
    if (trs != null) {
      trs.forEach(function (trade) {
          trade.id = trade["sid"] + '_' + trade["tno"] + '_' + trade["d"];
      });
      if (msg["Y"] === "u" || msg["D"].hasOwnProperty("mt")) { //subscr snapshot or updates
        this.listenersFs.forEach((f) => {f({ trs: trs, max_time: msg["D"]["mt"] });}); // update
	    	return true;
      } else return trs; //history
  	}        
  	return [];
  }		
}

TradesPub.listeners = {'_all_':[]};
TradesPub.listenersFs = [];

class TradesPubListenerr extends React.Component {
  constructor(props) {
    super(props);
		this.sids = this.props.sids;
  }
  componentDidMount() {TradesPub.addListener(this.props.onChange, this.sids)}
  componentWillUnmount() {TradesPub.removeListener(this.props.onChange, this.sids)}
  render() {
    if (!_.isEqual(this.props.sids, this.sids)) {
      this.componentWillUnmount();
      this.sids = _.cloneDeep(this.sids);
      this.componentDidMount();
    }
    return null;
  }
}

function tradesPubListener(props) {
  return <TradesPubListenerr {...props}/>
}

export const TradesPubListener = withWSPubKey(tradesPubListener);

class TradesPubTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.filter = _.cloneDeep(this.props.filter);
		this.loadOnScroll = 100;
		this.tab = null;
  }

  initTable(max_time) {
  	let self = this;
    let div = this.tabRef.current;
    div.id = this.props.id;
  	this.max_time = max_time;
  	this.tab = new TableX("#" + div.id, {
      onScrollPromise: function(tab) {
        return new Promise(function(resolve, reject){
		      self.subscribtion = new Subscribtion({
						ws: wspub,
						smsg: {"P": "g_tr", "D": {"sids": self.filter.sids === '_all_' ? null : self.filter.sids, "mt": self.max_time, "lim": self.loadOnScroll}},
					  eternal: false,
					  onResult: function(msg) {
              var trs = TradesPub.parse(msg);
              //console.log("Subscribtion");
              //console.log(trs);
	            self.max_time = getMinNsecTime(trs, "dt", self.max_time);
              if (trs.length > 0) resolve({data:trs});
              else resolve({no_more_data: true, data:trs});
	          }, 
	          onError: function(msg) {
              reject(msg);
	          }
		      });
			  });
			},
			columns:[
			  {title:"Instrument", field:"sid", formatter: sec_id},
			  {title:"Dir", field:"d", formatter: dir},
			  {title:"Price", field:"p", formatter:dec},
			  {title:"Qty", field:"q", formatter:dec},
				{title:"VolBTC", field:"v", formatter:dec},
			  {title:"tNo", field:"tno"},
			  {title:"Time", field:"dt", formatter:dt_ns_ms},
			],
		});
    this.tab.setSort("dt", "desc");
  }

  componentDidMount() {
  	if (this.props.filter.dt > 0) this.initTable(this.props.filter.dt * 1000000000);
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    if (this.subscribtion) this.subscribtion.delete();
    this.tab = null;
  }

  onChange(data) {
    //console.log(data);
    if (this.tab == null && data.max_time) {
	  	this.initTable(data.max_time);
    }
    if (this.tab) {
    	if (this.filter.sids === '_all_') {
    		this.tab.updateOrAddData(data.trs, true);
    	} else {
    		data.trs.forEach((trade)=>{
			  	if (this.filter.sids.indexOf(trade.sid) !== -1) this.tab.updateOrAddData([trade], true);
	    	});
	  	}
    }
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log(this.props.filter);
    return <React.Fragment>
    	{this.props.filter.dt <= 0 && <React.Fragment>
      	<TradesPubListener sids={this.props.filter.sids} onChange={this.onChange} />
      </React.Fragment>}
      <div ref={this.tabRef} />
      </React.Fragment>
  }
}

export const TradesPubTable = withWSPubKey(withFilter(withSize({ monitorHeight: true })(TradesPubTable_)));
