//{'\u00A0'}
//onClick={popupState.close}
//<Link className="p2 col-white-pen-2" to="/api">{strings.APIDocumentation}</Link>
import React from 'react';
import { strings } from '../localization/strings';
import _ from "lodash";

import { wspriv } from "../ws-priv/ws-priv";

//import Grid from '../components/grid'
import Stack, { StackLayout } from '../components/stack'
//import { SelectLanguage } from '../localization/select-language';
import { Theme/*, SelectTheme*/ } from '../styles/select-theme';
import { OnlineIndicator } from '../components/online-indicator';
import { /*AuthFirebase,*/ LogInDialog/*, LogOut*/ } from '../ws-priv/auth-firebase'
import { TwoFaDialog } from '../ws-priv/two-fa';
import { VerifyDeviceDialog } from '../ws-priv/security';
import { 
	AuthFirebase, 
	withAuthFirebaseKey, 
	LogOut,
	VerifyEmailDialog,
} from '../ws-priv/auth-firebase'
import Time from '../ws-pub/exchange-time';

import '../styles/css/page-user.css';

import { DownloadWidget } from "../ws-priv/download"

import { 
	IViking, 
//	IVikingWhite, 	
//	ICopy, 
	IUser, 
	IChevronLeft,
	IMenu,
//	ICross 
} from '../styles/icons';

import { ToggleButton, ToggleButtonGroup } from '../components/toggle-button';

import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import QrCode from "qrcode.react";


function HomeToggles(props) {
	const match = useRouteMatch();
  const history = useHistory();
  let pageName = _.cloneDeep(history.location.pathname.split('/')).pop();
	return <div className="wallet-toggles">
		<br/>
		<ToggleButtonGroup
      value={pageName}
      onChange={(e, v)=>{
				let path = history.location.pathname.split('/');
				path.pop();
				history.push(path.join('/') + '/' + v);        	
      }}
      exclusive
      className="p3 toggle-btn-1"
      >
      {["QuotesMonitor", "Trade", "Accounts", "Wallet"].map((key)=>{
        return <ToggleButton key={key} value={key}>{strings[key]}</ToggleButton>
      })}
    </ToggleButtonGroup>
  </div>
}

export default function PageUserMobile(props) {
	const match = useRouteMatch();
  const history = useHistory();
  let pageName = _.cloneDeep(history.location.pathname.split('/')).pop();
	//console.log("PageUserMobile");
	return <div className="page-user-m">
		<div className="page-user-m-header">
     	<Switch>
        <Route strict path={`${match.path}/Menu`}>
					<div className="page-user-m-header-left col-white-pen-2 cursor-pointer" 
						onClick={()=>{
							let path = history.location.pathname.split('/');
							path.pop();
							history.push(path.join('/'));
						}}
			      onTouchEnd={(e)=>{
			      	e.preventDefault();
							let path = history.location.pathname.split('/');
							path.pop();
							history.push(path.join('/'));
			      }}
						>
						<IChevronLeft className="page-user-m-header-icon" size="21"/>{null/*strings.Home*/}
					</div>
        </Route>
        <Route path={`${match.path}`}>
		      <div className="page-user-m-header-left cursor-pointer"
						onClick={()=>{
							let path = history.location.pathname.split('/');
							path.pop();
							history.push(path.join('/') + '/Menu');
						}}
			      onTouchEnd={(e)=>{
			      	e.preventDefault();
							let path = history.location.pathname.split('/');
							path.pop();
							history.push(path.join('/') + '/Menu');

			      }}
		      ><IUser/></div>
	      </Route>
        <Route path={`${match.path}`}>
					<div className="page-user-m-header-left">
						<Link to="/user"><IViking className="page-user-m-header-icon" size="21"/></Link>
  				</div>
	      </Route>
      </Switch>						
			<div className="page-user-m-header-mid">
				{props.welcome ? <React.Fragment>
						<LogInDialog link className="link p3 cursor-pointer col-white-pen-2">{strings.Login}</LogInDialog>
						<LogInDialog link className="link p3 cursor-pointer col-white-pen-2">{strings.Register}</LogInDialog>
					</React.Fragment> :
					<div className="p2b col-white-pen-2">
						{strings[pageName]}
					</div>
				}
			</div>
			<div className="page-user-m-header-right">
				{props.welcome ? <OnlineIndicator pub/> : <OnlineIndicator pub priv/>}
			</div>
		</div>
		<div className="page-user-m-mid">
     	<Switch>
        <Route path={`${match.path}/Trade`}>
        	<HomeToggles/>
          <StackLayout key="1" layout="layout_mobile_trade"/>
        </Route>
        <Route path={`${match.path}/QuotesMonitor`}>
        	<HomeToggles/>
          <StackLayout key="2" layout="layout_mobile_quotes"/>
        </Route>
        <Route path={`${match.path}/Accounts`}>
        	<HomeToggles/>
          <StackLayout key="3" layout="layout_mobile_accounts"/>
        </Route>
        <Route path={`${match.path}/Wallet`}>
        	<HomeToggles/>
          <StackLayout key="4" layout="layout_mobile_wallet"/>
        </Route>
        <Route path={`${match.path}/Menu`}>
    			<Stack layout="layout_mobile_menu"/>
        </Route>
        <Redirect to={`${match.path}/QuotesMonitor`}/>
      </Switch>		
		</div>
		{null/*<div className="page-user-m-footer">
		</div>*/}
		{!props.welcome && <React.Fragment>
			<TwoFaDialog/>
			<VerifyEmailDialog/>
			<VerifyDeviceDialog/>
			<DownloadWidget/>
		</React.Fragment>}
	</div>
}
