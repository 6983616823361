/* eslint-disable no-sparse-arrays */
import { codes } from './strings_en';

export const api = {
	'name':"API documentation",
	'id':"API_documentation",
	'version':"Version 1.0",
	"chapters":[{
			'name':`Quickstart`,
			'id':`Quickstart`,
			'description':`<br>
			<ul>
			</ul>`,
		"chapters":[{
			'name':`Javascript`,
			'id':`Quickstart_Javascript`,
			'description':`<br>
				Download <a href="https://viking.trade/quickstart/js/quickstart.html">quickstart.html</a> and run it in your browser. It contains example connection to WebSocket public server.
			`,
		},{
			'name':`Python`,
			'id':`Quickstart_Python`,
			'description':`<br>
			<ol>
				<li>Use pip to install asyncio, aiohttp, json, zlib
				<li>Create and run python file from [[Python_inflate_example]]. This will connect you to the WebSocket public server
			</ol>`,
		},{
			'name':`C++`,
			'id':`Quickstart_c++`,
			'description':`<br>
			<ol>
				<li>Use your favorite WebSocket client to connect to wss://api.viking.trade/public
				<li>Use code from [[C++_inflate_example]] to decompress incoming messages
			</ol>`,
		},{
			'name':`.Net C#`,
			'id':`Quickstart_C#`,
			'description':`<br>
				Download <a href="https://viking.trade/quickstart/dotnet/quickstart.zip">quickstart.zip</a> project. 
					It contains example connection to WebSocket public server.
				The project has one dependancy package, that you should install in order for it to work: <a href="https://www.nuget.org/packages/Websocket.Client">Websocket.Client</a>
			`,
		},
		],			
		},{
		'name':`Change log`,
		'id':`Change_log`,
		'description':``,
		'change_log':{
			"1.0":[`December 01, 2020`, `Original version of this document`],
		}
	},{
			'name':`Endpoints`,
			'id':`Endpoints`,
			'description':`<br>
			<ul>
				<li><b>Testnet</b> environment endpoints:
					<table>
						<tr><td>REST public<td><a href="https://apitest.viking.trade/v1/public">https://apitest.viking.trade/v1/public</a>
						<tr><td>WebSocket public<td>wss://apitest.viking.trade/public
						<tr><td>WebSocket private<td>wss://apitest.viking.trade/private
						<tr><td>Site<td><a href="https://art.viking.trade">https://art.viking.trade</a>
						<tr><td>API keys<td><a href="https://art.viking.trade/user/api">https://art.viking.trade/user/api</a>
						<tr><td>Tester<td><a href="https://art.viking.trade/user/api">https://art.viking.trade/user/api</a>
					</table>
				<br/>
				<li><b>Production</b> endpoints:
					<table>
						<tr><td>REST public<td><a href="https://api.viking.trade/v1/public">https://api.viking.trade/v1/public</a>
						<tr><td>WebSocket public<td>wss://api.viking.trade/public
						<tr><td>WebSocket private<td>wss://api.viking.trade/private
						<tr><td>Site<td><a href="https://viking.trade">https://viking.trade</a>
						<tr><td>API keys<td><a href="https://viking.trade/user/api">https://viking.trade/user/api</a>
						<tr><td>Tester<td><a href="https://viking.trade/user/api">https://viking.trade/user/api</a>
					</table>
			</ul>`,
		},{
		'name':"Introduction",
		'id':"Introduction",
		'description':``,
		"chapters":[{
				'name':`Important notes`,
				'id':`Important_notes`,
				'description':`<br>
		<ul>	
		<li>REST public:
			<ul>
			<li>There is a public REST server available. It is not supposed to be used for automated trading. It's purpose is for occational data collection. It has a very strict <a href="#Rate_limits">rate limit</a>.<br/> 
			</ul>	
		<li>Websocket public & private:
			<ul>
			<li>There are two websocket servers available: public and private. Public server is for market data. Private server is for trading.<br/> 
			<li>Both servers compress all sent data with zlib stream deflate algorithm. See <a href="#Decompression">decompression</a>.<br>
			<li>Both servers respond to each request, recieved from the client, with an <a href="#Errors">error</a> or response. 
				<br>In case of the subscribtion request, the server will respond with snapshot and updates.<br>
			<li>If client marks any request with optional <a href="#I">Message ID</a> field, then server will mark all responces to this request with same <a href="#I">Message ID</a>. To mark orders, and some other API requests, there is also <a href="#External_ID">External ID</a> field available.
			<li>Private websocket server always sends <a href="#Automatic_updates_private">automatic updates</a> on orders(with trades), positions, accounts, limits and fees to all opened sessions of the client. Automatic means that you don't have to subscribe on them. You will recieve them anyway.
			<li>Every private websocket session will be closed after 24 hours timeout following authentication.
			<li>Every non authorized private websocket session will be closed after 120 seconds.
			<li>Public websocket server always sends <a href="#Automatic_updates_public">automatic updates</a> on instrument creation/deletion to all opened sessions. Exchange time sync message is sent to public ws upon connection and every 10 seconds.
			<li>When recieving any data that has an update time "ut" (orders, positions, accounts, limits etc.), perform check that it is growing. Because sometimes data can be duplicated in the snapshot, updates and history responses.
			</ul>
		</ul>
			`,
		},{
			'name':`Message format`,
			'id':`Message_format`,
			'description':`Each message from the server will have the 'topic' and 'type' fields, except for time sync message and pong messages.`,
			'reply':['{"P":  "", "Y": "", "D": {}, "I": ""}', '{"T":  123} - time sync', '"7" - pong'],
			'general_params':{
				"P":[`string`, `Message topic. Mandatory field for all messages.`],
				"Y":[`string`, `Message type. Enum: "r" (response), "e" (error), "u" (update). Mandatory field for all server messages`],
				"D":[`object`, `Message data. Optional field`],
				"I":[`string_N`, `Message ID. N = 36. The "I" field is optional for all client requests. If "I" is present in the request, the response and error will also contain the "I" field, which will be set to that of the request "I". If "I" field is NOT present in the request, the response and error will NOT contain the "I" field. Subscribtion updates never have "I" field.`],
				"T":['epoch_sec','Exchange time. Sent to public socket every 10 seconds'],
			}
		},{
			'name':"Errors",
			'id':"Errors",
			'description':`<br>Server sends errors in reply to incorrect requests. The topic "P" field equals to that of the request. The error message format is as follows:`,
			'error':['{"P": "request P", "Y": "e", "D": {"c": "WP0", "p": ["D"] }, "I": "1"}'],
			'params':{
				"P":['string', `Topic of the corresponding request`],
				"c":['str_code', ``],
				"p":['str_params',''],
			},		
		},{
			'description':`All messages from websocket servers are compressed with zlib stream deflate algorithm. The deflate stream is created and maintained for each websocket connection by the server. Client should initialize and maintain inflate stream for each websocket connection.  Deflate/inflate parameter windowBits: -15. Client should add 4 bytes: ['0','0','255','255'] to each incoming message before inflate. Please see <a href="#Decompression_examples">examples</a>.`,
			'name':'Decompression',
			'id':'Decompression',
		},{
			'name':`Ping/pong and disconnect detection`,
			'id':`Ping_pong_and_disconnect_detection`,
			'description':`<br>
			<ul>
				<li>WebSocket Private & Public:<br>
				There are two options for ping/pong and disconnect detection for the client to choose from:<br>
				<ul>
					<li>If there are no messages from the server for more than 5 seconds, client should send a one character string "7" to the server. The server should respond with the same string. If there is no response in 3 seconds, the client should close the connection and try to reconnect.
					<li>If there are no messages from the server for more than 5 seconds, client should send a ping frame message, and wait for the server to respond back with a pong mesage. If there is no pong response in 3 seconds, the client should close the connection and try to reconnect.
				</ul>
				If there are no messages from the client for more than 5 seconds, server will close the connection
			</ul>
			`,
		},{
			'name':"Reconnection scenario",
			'id':"Reconnection_scenario",
			'description':`<br>
			<ul>
				<li>WebSocket Private:<br>
					<ol>
						<li>Establish ws connection
						<li>Send authentication request
						<li>Wait for authentication response
						<li>Wait for orders <a href="#Automatic_updates_private">"or"</a> snapshot message. Snapshot contains data that is not yet written to the database, and thus can not be queried by history 'get' requests yet.
						<li>Wait for trades <a href="#Automatic_updates_private">"tr"</a> snapshot message
						<li>Send <a href="#Get_orders">"g_or"</a> with [[until_time]] = [[snapshot_time]] from 4., to get list of historical orders. See <a href="#Getting_history_data">example</a>. Or you can query orders by ExternalID using <a href="#Get_order">"g_eor"</a> request.
						<li>Send <a href="#Get_trades">"g_tr"</a> with [[until_time]] = [[snapshot_time]] from 5., to get list of historical trades. See <a href="#Getting_history_data">example</a>
						<li>Send <a href="#Get_limits">"g_lim"</a> to get list of your limits, <a href="#Get_positions">"g_pos"</a> to get list of your current positions, <a href="#Get_accounts">"g_acc"</a> to get list of your accounts
						<li>Server will be sending <a href="#Automatic_updates_private">automatic updates</a> on orders (with trades), accounts, positions and limits
						<li><b>When recieving any data that has an update time "ut" (orders, positions, accounts, limits etc.), perform check that it is growing. Because sometimes data can be duplicated in the snapshot, updates and history responses.</b>
					</ol>
				<br>
				<li>WebSocket Public:
					<ol>
					<li>Establish ws connection
					<li>Send subscriptions to all necessary data
					<li>Request historical data with [[until_time]] = [[snapshot_time]] from subscribtion responces. See <a href="#Subscribtion_on_updates_+_history">example</a>
					<li><b>When recieving any data that has an update time "ut", perform check that it is growing. Because sometimes data can be duplicated in the snapshot, updates and history responses.</b>
					</ol>
			</ul>
			<br/>`
		},{
			'name':"Rate limits",
			'id':"Rate_limits",
			'description':`<br>
			<ul>
			<li>REST Public: 
				<ul><li>Rate limit is calculated for every ip address. No more than 10 messages for last 10 seconds. The value is recalculated every second.
				</ul>
			<li>WebSocket Public:
				<ul><li>Rate limit is calculated for every connection. No more than 100 messages for last 10 seconds. The value is recalculated every second.
				</ul>
			<li>WebSocket Private: 
				<ul>
					<li>Use [[Get_rate_limits]] to get the list of your current rate limits.
					<li>Trading messages ([[New_order]], [[Cancel_order]]) - rate limit is calculated for every <a href="#Accounts">trading account</a>.<br>
						By default it is no more than 100 messages for last 10 seconds. The value is recalculated every second.
					<li>Non-trading messages (all messages execept [[New_order]], [[Cancel_order]]) - rate limit is calculated for every connection.<br> 
						By default it is no more than 100 messages for last 10 seconds. The value is recalculated every second.
					<li>Please, contact support if you need to change rate limits.
				</ul>
			</ul>
				`,
		},{
			'name':`Data types`,
			'id':`Data_types`,
			'description':'The types of data fields, used in this documentation.',
			'types': {
				'string': 'String',
				'string_N': "String with maximum length of N symbols.",
				'object': "Object",
				'vector': "Vector of objects. Example: [{}, {}, {}]",
				'vector_int': "Vector of integers. Example: [1, 2, 3]",
				'bool': "Boolean: true, false",
				'dec_8':`8-byte integer representation of decimal multiplied by 1e8. Example: double presicion 5.00010008 represented in dec8 will be 500010008.`,
				'int_4':`4-byte integer.`,
				'int_8':`8-byte integer.`,
				'epoch_sec':'Epoch time in seconds integer representation. Example: 1584629107',
				'epoch_msec':'Epoch time in milliseconds integer representation. Example: 1584629107000',
				'epoch_nsec':'Epoch time in nanoseconds integer representation. Example: 1584629107000000000',
				'float': "Floating point number",
				'uuid':'<a href="https://en.wikipedia.org/wiki/Universally_unique_identifier">Universally unique identifier (UUID)</a> is a 128-bit number represented as string. In its canonical textual representation, the 16 octets of a UUID are represented as 32 hexadecimal (base-16) digits, displayed in five groups separated by hyphens, in the form 8-4-4-4-12 for a total of 36 characters (32 hexadecimal characters and 4 hyphens). Example: "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"',
				'direction':`Order direction`,
				'order_type':`Order type`,
				'activation_type':`Activation type`,
				'time_in_force':`Time in force`,
				'order_status':`Order status`,
				'instrument_status':`Instrument status`,
				'expiry_type':`Expiration type`,
				'cancel_reason':`Cancel reason`,
				'transaction_direction': `Transaction direction`,
				'transaction_state': `Transaction state`,
				'order_flags':`Bit mask`,
				'trade_flags' :`Bit mask`,
				'maker_taker' : 'Boolean: MAKER = true, TAKER = false',
				'str_code':`String. One of the [[String_codes]]. The encoded string may contain format symbols, like '%s', '%d'. See how to read them in [[str_params]] description.`,
				'str_params':`Vector of parameters.<br>String, encoded by [[str_code]] may contain %s, %d, %f, %p, %t, %i, %a format symbols. You should substitute format symbols with corresponding parameters from [[str_params]] vector:
				<br>%s - [[string]]
				<br>%d - [[int_4]]
				<br>%p - [[dec_8]]
				<br>%f - [[float]]
				<br>%t - [[instrument_status]]
				<br>%i - [[epoch_nsec]] date/time
				<br>%a - [[epoch_sec]] date
				<br>For example:
				<br>[[str_code]] = <a href="#WP0">"WP0"</a>, [[str_params]] = ["D"]<br>Message string is "Unexpected field %s"<br>Final string after substitution: "Unexpected field D"`,
				'until_time':`<a href="#epoch_nsec">epoch_nsec</a>. 'until' time for data query`,
				'snapshot_time':`<a href="#epoch_nsec">epoch_nsec</a>. Snapshot time. It is the time of the last database write for the corresponding table. This time should be used as [[until_time]] in history requests. The data consistency between history, snapshot and updates is guaranteed when using this technique. See <a href="#Subscribtion_on_updates_+_history">example</a>
				`,
				'orders':'List of <a href="#order">order</a> objects',
				'order':{
					"aid":['int_8', `Account id`],
					"sid":[`int_4`, `Instrument id`],
					"p":[`dec_8`, `Order price`],
					"q":[`dec_8`, `Order quantity`],
					"d":['direction', ''],
					"eid":["uuid", `<span id="External_ID">External ID</span>`],
					"ll":[`string_N`, `N = 64. Label`],
					"oy":[`order_type`, ``],
					"ay":[`activation_type`, ``],
					"tif":[`time_in_force`, ``],
					"ap":[`dec_8`, `Activation price`],
					"vq":[`dec_8`, `Iceberg visible quantity`],
					"m":[`bool`, `Only maker: cancel order if it becomes taker`],
					"ed":[`epoch_sec`, `Expiration date in seconds since epoch`],
					"ar":[`dec_8`, `Set by the server. Amount rest. Upon cancellation it will be set to cancelled amount.`],
					"vr":[`dec_8`, `Set by the server. Iceberg visible rest.`],
					"own":[`string`, `Set by the server. Email of account owner.`],
					"au":[`string`, `Set by the server. Email of user who created order. Set by the server if "au" differs from account owner.`],
					"cu":[`string`, `Set by the server. Email of user who canceled order. Set by the server if "cu" differs from account owner.`],
					"cc":[`bool`, `Set by the server. Can cross trade. True, if this order can have cross trades.`],
					"ss":[`order_status`, `Set by the server`],
					"cr":[`cancel_reason`, `Set by the server (present only if order was cancelled)`],
					"ct":[`epoch_nsec`, `Set by the server. Creation time.`],
					"ut":[`epoch_nsec`, `Set by the server. Update time.`],
					"ono":['int_8', `Set by the server. Order id.`],
					"f":[`order_flags`,'Set by the server'],
					"trs":[`order_trades`, `Set by the server`],
					
				},
				'order_trades':'List of <a href="#order_trade">order_trade</a> objects',
				'order_trade':{
					"p":['dec_8', `Trade price`],
					"q":['dec_8', `Trade quantity`],
					"v":['dec_8', `Trade volume in BTC`],
					"tno":['int_8', `Trade id`],
					"m":['maker_taker', ``],
					"dt":['epoch_nsec', `Deal time`],
					"cm":['dec_8', `Commission`],
				},
				'full_trades':'List of <a href="#full_trade">full_trade</a> objects',
				'full_trade':{
					"p":["dec_8", `Trade price`],
					"q":["dec_8", `Trade quantity`],
					"v":['dec_8', `Trade volume in BTC`],
					"tno":['int_8', `Trade id`],
					"m":[`maker_taker`, ``],
					"dt":[`epoch_nsec`, `Deal time`],
					"sid":[`int_4`, `Instrument id`],
					"aid":[`int_8`, `Account id`],
					"d":['direction', ''],
					"ono":['int_8', `Order id. Set by the server.`],
					"f":[`order_flags`,'Can be omitted for regular order'],
					"ll":[`string_N`, `N = 64. Order's label if presented in order`],
					"cm":['dec_8', `Commission`],
					"tf":[`trade_flags`,'Can be omitted for regular trade'],
				},
				'chart_trades':'List of <a href="#chart_trade">chart_trade</a> objects',
				'chart_trade':{
					"p":["dec_8", `Trade price`],
					"q":["dec_8", `Trade quantity`],
					"dt":[`epoch_nsec`, `Deal time`],
					"sid":[`int_4`, `Instrument id`],
					"d":['direction', ''],
				},
				'private_trades':'List of <a href="#private_trade">private_trade</a> objects',
				'private_trade':{
					"p":["dec_8", `Trade price`],
					"q":["dec_8", `Trade quantity`],
					"v":['dec_8', `Trade volume in BTC`],
					"tno":['int_8', `Trade id`],
					"d":['direction', ``],
					"dt":[`epoch_nsec`, `Creation time`],
					"sid":[`int_4`, `Instrument id`],
					"m":[`maker_taker`, `If not empty - this is own trade (not cross)`],
					"tf":[`trade_flags`,`If not empty - this is own trade (cross)`],
				},
				'positions':`List of <a href="#position">position</a> objects`,
				'position':{
					"aid":['int_8', `Account id`],
					"sid":[`int_4`, `Instrument id`],
					"pos":[`dec_8`, `Position`],
					"ut":[`epoch_nsec`, `Update time`],
				},
				'limits':`List of <a href="#limit">limit</a> objects`,
				'limit':{
					"lid":['int_8', `Limit id`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"lim":[`dec_8`, "Available balance"],
					"mn":[`bool`, "Main limit for this currency. Deposited coins in this currency will be accumulated on this limit. All main limits are attached to main account."],
					"ut":[`epoch_nsec`, `Update time`],
					"ll":[`string_N`, `N = 64. User label`],
					"own":[`string`, `Owner email`],
					"aid":['int_8', `Account id. Optional. It is present if limit is connected to some account.`],
					"act":['string', `Optional. When limit is deleted from the exchange, you will receive update message with "act":"del"`],					
				},
				'accounts':`List of <a href="#account">account</a> objects`,
				'account':{
					"aid":["int_8", `Account id`],
					"cid":[`string_N`, "N = 32. Currency of an account, always BTC"],
					"fb":[`dec_8`, "Margin balance"],
					"avl":[`dec_8`, "Available balance"],
					"ud":[`dec_8`, "Used deposit"],
					"cw":[`dec_8`, "Can withdraw"],
					"pl":[`dec_8`, "P/L"],
					"own":[`string`, `Owner email`],
					"usrs":[`vector`, `List of users emails, to which this account is delegated`],
					"ut":[`epoch_nsec`, `Update time`],
					"lims":[`vector_int`, `List of limit id's, connected to this account`],
					"mn":[`bool`, "Main account, it is an account with all main limits"],
					"act":['string', `Optional. When account is deleted from the exchange, you will receive update message with "act":"del"`],
					"ll":[`string_N`, `N = 64. User label`],
					"eid":["uuid", `External ID`],
				},
				'rate_limits':`List of <a href="#rate_limit">rate_limit</a> objects`,
				'rate_limit':{
					"aid":["int_8", `Account id, 0 for user's rate limit for non-trading messages (all messages execept [[New_order]], [[Cancel_order]])`],
					"rlp":['int_4', `Computation period`],
					"rlr":['int_4', `Reset period`],
					"rll":['int_4', `Requests count limit`],
				},
				'symbols':`List of <a href="#symbol">symbol</a> objects`,
				'symbol':{
					"sid":[`int_4`, `Instrument id`],
					"sym":[`string`, `Symbol`],
					"mid":[`string_N`, `N = 32. Instrument type`],
					"desc":[`string`, `Description`],
					"pdec":[`int_4`, `Price decimals`],
					"ss":[`instrument_status`, ``],
				},
				'currencies':'List of <a href="#currency">currency</a> objects',
				'currency':{
					"cid":[`string_N`, "N = 32. Currency ID"],
					"short": [`string_N`, `N = 32. Short name`],
					"ll": [`string`, `Label`],
					"bcid":[`string_N`, "N = 32. Blockchain ID"],
					"cw":[`bool`,`Can withdraw`],
					"step": [`float`,`For frontend`],
					"dec":[`int_4`, `Decimals`],
					"mind":[`dec_8`, `Min deposit amount. Lesser transactions will be ignored`],
					"svg": [`string`, `Hexademical string representation of svg image`],
				},
				'blockchains':'List of <a href="#blockchain">blockchain</a> objects',
				'blockchain':{
					"bcid":[`string_N`, "N = 32. Blockchain ID"],
					"ll": [`string`, `Label`],
					"cid": [`string_N`, `N = 32. Blockchain base currency ID`],
				},
				'cmc_contracts':`List of <a href="#cmc_contract">cmc_contract</a> objects`,
				'cmc_contract':{
					"sid":[`int_4`, `Instrument id`],
					"ticker_id":[`string`, `Identifier of a ticker`],
					"base_currency":[`string_N`, "N = 32. Base currency ID"],
					"quote_currency":[`string_N`, "N = 32. Quote currency ID"],
					"last_price":[`float`, `Last transacted price of base currency based on given quote currency`],
					"base_volume":['float', `Last 24 hour trading volume in base currency`],
					"quote_volume":['float', `Last 24 hour trading volume in quote currency`],
					"bid":[`float`, `Current highest bid price`],
					"ask":[`float`, `Current lowest ask price`],
					"high":[`float`, `Rolling 24-hour highest transaction price`],
					"low":[`float`, `Rolling 24-hour lowest transaction price`],
					"product_type":[`string`, `Futures or Perpetual`],
					"open_interest":[`float`, `The number of outstanding derivatives contracts that have not been settled`],
					"index_price":[`float`, `Last calculated index price for underlying of contract`],
					"creation_timestamp":[`epoch_msec`, `Start date of derivative (not specified for perpetual swaps)`],
					"expiry_timestamp":[`epoch_msec`, `End date of derivative (not specified for perpetual swaps)`],
					"funding_rate":[`float`, `Funding rate (in percent)`],
					"next_funding_rate_timestamp":[`epoch_msec`, `Timestamp of the next funding rate change`],
					"maker_fee":[`float`, `Fees for filling a “maker” order (can be negative if rebate is given) (in percent)`],
					"taker_fee":[`float`, `Fees for filling a “taker” order (can be negative if rebate is given) (in percent)`],
					"contract_type":[`string`, `Describes the type of contract - Vanilla, Inverse or Quanto`],
					"contract_price":[`float`, `Describes the price per contract`],
					"contract_price_currency":[`string_N`, `N = 32. Describes the currency which the contract is priced in (e.g. BTC)`],
				},
				'instruments':`List of <a href="#instrument">instrument</a> objects`,
				'instrument':{
					"sid":[`int_4`, `Instrument id`],
					"sym":[`string`, `Symbol`],
					"bcid":[`string_N`, "N = 32. Base currency ID"],
					"qcid":[`string_N`, "N = 32. Quote currency ID"],
					"desc":[`string`, `Description`],
					"mid":[`string_N`, `N = 32. Instrument type`],
					"ms":[`dec_8`, `Min step. Price minimum step`],
					"ls":[`dec_8`, `Lot size. Quantity minimum step`],
					"sp":[`dec_8`, `Step price. BTC price of price minimum step`],
					"fr":[`float`, `Funding rate (in percent)`],
					"im":[`dec_8`, `Initial margin`],
					"lu":[`dec_8`, `Maximum available order price`],
					"ld":[`dec_8`, `Minimum available order price`],
					"ss":[`instrument_status`, ``],
					"pdec":[`int_4`, `Price decimals`],
					"ldec":[`int_4`, `Lot decimals. Quantity decimals`],
					"ir":[`float`, `Iceberg ratio. Minimum iceberg visible amount ratio (in percent)<br>Example: ir = 1%, order quantity = 200<br>if visible quantity = 1, so 1 / 200 * 100% = 0.5%, 0.5% is lower than 1%, so you will receive order adding error<br>if visible quantity = 4, so 4 / 200 * 100% = 5%, 5% is greater than 1%, so order will be successfully added`],
					"ey":[`expiry_type`, ``],
					"at":[`epoch_sec`, `Activation time, zero means not specified`],
					"et":[`epoch_sec`, `Expiration time, zero means no expiration`],
					//"ut":[`epoch_nsec`, `Update time`],
					"act":['string', `Optional. When instrument is deleted from the exchange, you will receive update message with "act":"del", when instrument add to the exchange, you will receive update message with "act":"add"`],
				},
				'instrument_settingss':'List of <a href="#instrument_settings">instrument_settings</a> objects',
				'instrument_settings':{
					"sid":[`int_4`, `Instrument id`],
					"im":[`dec_8`, `Initial margin`],
					"lu":[`dec_8`, `Minimum available order price`],
					"ld":[`dec_8`, `Maximum available order price`],
					"ip":[`dec_8`, `Index price`],
					"ep":[`dec_8`, `Estimated price`],
					"sp":[`dec_8`, `Step price. BTC price of price minimum step`],
					"fr":[`float`, `Funding rate (in percent)`],
				},
				'instrument_statss':'List of <a href="#instrument_stats">instrument_stats</a> objects',
				'instrument_stats':{
					"sid":[`int_4`, `Instrument id`],
					"tc":['int_8', `Trades count for last 24 hours`],
					"tv":['dec_8', `Trades volume BTC sum for last 24 hours`],
					"aors":['int_8', `Current number of active orders`],
					"oi":[`dec_8`, `Opened interest`],
				},
				'instrument_pricess':'List of <a href="#instrument_prices">instrument_prices</a> objects',
				'instrument_prices':{
					"sid":[`int_4`, `Instrument id`],
					"ip":[`dec_8`, `Index price`],
					"ep":[`dec_8`, `Estimated price`],
					"bb":[`dec_8`, `Price buy`],
					"bs":[`dec_8`, `Price sell`],
					"lp":[`dec_8`, `Last deal price`],
					"la":[`dec_8`, `Last deal quantity`],
				},
				'instrument_fieldss':'List of <a href="#instrument_fields">instrument_fields</a> objects',
				'instrument_fields':'All fields from <a href="#instrument">instrument</a>, <a href="#instrument_settings">instrument_settings</a>, <a href="#instrument_stats">instrument_stats</a>, <a href="#top_of_book">top_of_book</a>',
				'instrument_public_tradess':'List of <a href="#instrument_public_trades">instrument_public_trades</a> objects',
				'instrument_public_trades':{
					"sid":[`int_4`, `Instrument id`],
					"trs":[`public_trades`, ``],
				},
				'public_trades':'List of <a href="#public_trade">public_trade</a> objects',
				'public_trade':{
					"p":["dec_8", `Trade price`],
					"q":["dec_8", `Trade quantity`],
					"tno":['int_8', `Trade id`],
					"d":['direction', ``],
					"dt":[`epoch_nsec`, `Creation time`],
					"sid":[`int_4`, `Instrument id`],
				},
				'candles':'List of <a href="#candle">candle</a> objects',
				'candle':`[<a href="#dec_8">dec_8</a> open, <a href="#dec_8">dec_8</a> high, <a href="#dec_8">dec_8</a> low, <a href="#dec_8">dec_8</a> close, <a href="#float">float</a> volume, <a href="#epoch_sec">epoch_sec</a> dt, <a href="#int_4">int_4</a> sid]`,/*{
				},*/
				'top_of_books':'List of <a href="#top_of_book">top_of_book</a> objects',
				'top_of_book':{
					"sid":[`int_4`, `Instrument id`],
					"bb":[`dec_8`, `Buy price`],
					"ab":[`dec_8`, `Buy quantity`],
					"bs":[`dec_8`, `Sell price`],
					"as":[`dec_8`, `Sell quantity`],
					"lp":[`dec_8`, `Last deal price`],
					"la":[`dec_8`, `Last deal quantity`],
					"dl":[`direction`, `Last deal direction`],
				},
				'cmc_orderbook':{
					"ticker_id":[`string`, `Identifier of a ticker`],
					"timestamp":[`epoch_msec`, `Unix timestamp in milliseconds for when the last updated time occurred`],
					"bids":[`vector`, `[[<a href="#float">float</a> price,<a href="#float">float</a> amount],...] An array containing 2 elements. The bid price and quantity for each bid order`],
					"asks":[`vector`, `[[<a href="#float">float</a> price,<a href="#float">float</a> amount],...] An array containing 2 elements. The ask price and quantity for each ask order`],
				},
				'orderbooks':'List of <a href="#orderbook">orderbook</a> objects',
				'orderbook':{
					"sid":[`int_4`, `Instrument id`],
					"b":[`vector`, `[[<a href="#dec_8">dec_8</a> price,<a href="#dec_8">dec_8</a> amount],...]`],
					"s":[`vector`, `[[<a href="#dec_8">dec_8</a> price,<a href="#dec_8">dec_8</a> amount],...]`],
				},
				'idxs':'List of <a href="#idx">idx</a> objects',
				'idx':{
					"iid":[`string_N`, `N = 32. Idx id`],
					"p":["dec_8", `Idx price`],
					"dt":[`epoch_sec`, `Update time`],
				},
				'hidxs':'List of <a href="#hidx">hidx</a> objects',
				'hidx':{
					"iid":[`string_N`, `N = 32. Idx id`],
					"ps":["vector", `[[<a href="#dec_8">dec_8</a> price, <a href="#epoch_sec">epoch_sec</a> dt],...]`],
				},
				'keys': 'List of <a href="#key">key</a> objects',
				'key': {
					"ll": [`string_N`, `N = 64. Key label`],
					"ct":[`epoch_nsec`, `Create time`],
					"pt":['bool', `Key permission: can trade (add/cancel/cancel all orders)`],
					"pm":['bool', `Key permission: can manage (create/remove/delegate/revoke/refuse/transfer/label accounts)`],
					"pw":['bool', `Key permission: can withdraw`],
					"kid": [`string_N`, `Set by the server. N = 36. Key id, used to identify the key`],
					"k": [`string`, `Set by the server. Key secret, returned only in key creation response`],
				},
				'device_info': {
					"br":[`string`, `Browser`],
					"ip":[`string`, `IP-address`],
					"pl": [`string`, `Platform`],
				},
				'devices': 'List of <a href="#device">device</a> objects',
				'device': {
					"data": [`device_info`, ``],
					"ct":[`epoch_sec`, `First login time`],
					"ut":[`epoch_sec`, `Last login time`],
					"vid": [`string_N`, `Set by the server. N = 36. Device id, used to identify the device`],
				},
				'tariffs':`List of [[tariff]] objects`,
                'tariff':{
                    "tid":[`int_4`, `Tariff id`],
					"m_f":[`float`, `Maker deal fee in percent`],
					"t_f":[`float`, `Taker deal fee in percent`],
					"e_f":[`float`, `Expiry deal fee in percent`],
					"f_f":[`dec_8`, `Fixed fee for each 8 hours`],
					"n_pt":[`epoch_sec`, `Next payment date and time or 0`],
					"uid":['string', `User id`],
                },
				'hiss': 'List of [[his]] objects',
				'his': {
					"ct":[`epoch_nsec`, `Create time`],
					"c":['str_code', `History codes start with the letter 'H'`],
					"p":['str_params',''],
				},
				'commissions': 'Dictionary of lists of [[commission]] objects',
				'commission': {
					"ct":[`epoch_nsec`, `Create time`],
					"aid":[`int_8`, `Account id`],
					"cm":['dec_8', `Commission`],
				},
				'transactions': 'List of [[transaction]] objects',
				'transaction':{
					"txid": [`string`, `Transaction ID`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"amnt":['dec_8', `Transaction amount`],
					"cnt":['int_4', `Confirmations`],
					"io":['transaction_direction', ``],
					"stt":['transaction_state', ``],
					"aid":['int_8', `Account id`],
					"tf":['dec_8', `Transaction fee (always 0 for input transactions)`],
					"ct":[`epoch_nsec`, `Set by the server. Creation time.`],
					"ut":[`epoch_nsec`, `Set by the server. Update time.`],
					"ll":['string_N', `N = 64. Comment`],
					"eid":["uuid", `External ID`],
				},
				'account_transactions': 'List of [[account_transaction]] objects',
				'account_transaction': {
					"ct":[`epoch_nsec`, `Create time`],
					"aid":[`int_8`, `Account id`],
					"dif":[`dec_8`, `Delta`],
					"cid":[`string_N`, "N = 32. Delta currency ID"],
					"fb":[`dec_8`, "Account margin balance"],
					//"btc":[`dec_8`, `Account BTC balance`],
					"c":['str_code', ``],
					"p":['str_params',''],
				},
				'transfers': 'List of [[transfer]] objects',
				'transfer':{
					"f_aid":['int_8', `Account id from`],
					"t_aid":['int_8', `Account id to`],
					"amnt":[`dec_8`, `Amount`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"ct":[`epoch_nsec`, `Set by the server. Creation time.`],
					"ll":['string_N', `N = 64. Comment`],
					"eid":["uuid", `External ID`],
				},
				'addresses': 'List of [[address]] objects',
				'address':{
					"ll": [`string_N`, `N = 64. User label. Mandatory for withdrawal addresses`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"ct":[`epoch_nsec`, `Set by the server. Creation time.`],
				},
				'newss': 'List of [[news]] objects',
				'news':{
					//"ut":[`epoch_nsec`, `Update time`],
					"n":['str_code', `News codes start with the letter 'N'`],
					"p":['str_params', ``],
				},
			},
		},{
			'description':`Table of all possible [[str_code]] values</a>. Some of the strings contain format symbols, like '%s', '%d'. See how to read them in [[str_params]] description.`,
			'name':"String codes",
			'id':"String_codes",
			'codes': codes,
		},
		]
	},{
		'name':"REST Public",
		'id':"REST_API_Public",
		'description':`See [[Endpoints]] for information on where to connect to`,
		"chapters":[{
				'description':`Request current time`,
				'name':'Get current time',
				'id':'rest_Get_current_time',
				"request":['/g_t'],
				'reply':['{"T": 1}'],
				'params':{
					"T":[`epoch_sec`, `Exchange time`],
				}
		},{
			'name':'Instruments',
			'id':'rest_Instruments',
			'chapters':[{
				'description':`Request for list of instruments or for current instrument. Instruments with status 'disabled' are not returned.`,
				'name':'Get instruments',
				'id':'rest_Get_all_instruments',
				"request":['/g_ins?sid=1'],
				
				'reply':['{"P":  "g_ins", "Y": "r", "D": {"inss": [instrument, instrument, instrument]}}'],
				'params':{
					"sid":[`int_4`, `Instrument id. Optional. If ommited, then list of all instruments will be returned`],
					"inss":[`instruments`, ``],
				}
			},{
				'description':`Request for list of instruments in Coinmarketcap format. Some values are cached for a 1 second`,
				'name':'Get instruments in Coinmarketcap format',
				'id':'rest_Get_CMC_all_instruments',
				"request":['/contracts'],
				
				'reply':['{"P":  "contracts", "Y": "r", "D": {"contracts": [cmc_contract, cmc_contract, cmc_contract]}}'],
				'params':{
					"contracts":[`cmc_contracts`, ``],
				},
				'footer': '<br>Possible errors: [[EX1]] [[EX2]] [[EX3]] [[EX4]]',
			},{
				'description':'Request for list of disabled(old) instruments',
				'name':'Get disabled instruments',
				'id':'rest_Get_disabled_instruments',
				"request":['/g_dins'],
				
				'reply':['{"P":  "g_dins", "Y": "r", "D": {"inss":[instrument, instrument, instrument]}}'],
				'params':{


					"inss":[`instruments`, ``],
				}
			},{
				'description':'Request for all instruments symbols list (expired + active)',
				'name':'Get symbols',
				'id':'rest_Get_symbols',
				"request":['/g_sym'],
				
				'reply':['{"P":  "g_sym", "Y": "r", "D": {"syms": [symbol, symbol, symbol]}}'],
				'params':{


					"syms":[`symbols`, ``],
				}
			},{
				'description':'',
                'name':'Get currencies',
                'id':'rest_Get_all_currencies',
                "request":['/g_cur'],
				
                'reply':['{"P":  "g_cur", "Y": "r", "D": {"curs": [currency, currency, currency], "bcs": [blockchain, blockchain, blockchain]}}'],
                'params':{
                    "curs":[`currencies`, ``],
					"bcs":[`blockchains`, ``],
                }
			},{
				'description':'Request for status of the instrument',
				'name':'Get status of the instrument',
				'id':'rest_Get_status_of_the_instrument',
				'request':['/g_st?sid=1'],
				
				'reply':['{"P":  "g_st", "Y": "r", "D": {"sts": [{"sid": 1, "ss": 1, "at":1, "et":1}]}}'],

				'params':{
					"sid":[`int_4`, `Instrument id. Optional. If ommited, then list of statuses for all instruments will be returned`],
					"ss":[`instrument_status`, `Instrument trading status`],
					"at":[`epoch_sec`, `Activation time, present if non zero`],
					"et":[`epoch_sec`, `Expiration time, present if non zero`],
				}
			},{
				'description':'Request for settings of the instrument',
				'name':'Get settings of the instrument',
				'id':'rest_Get_settings_of_the_instrument',
				'request':['/g_set?sid=1'],
				
				'reply':['{"P":  "g_set", "Y": "r", "D": {"sets": [instrument_settings, instrument_settings, instrument_settings]}}'],

				'params':{
					"sid":[`int_4`, `Instrument id. Optional. If ommited, then list of settings for all instruments will be returned`],
					"sets":[`instrument_settingss`, ``],
					"set":[`instrument_settings`, ``],
				}
			},{
				'description':'Request for statistics of the instrument',
				'name':'Get statistics of the instrument',
				'id':'rest_Get_statistics_of_the_instrument',
				'request':['/g_sta?sid=1'],
				
				'reply':['{"P":  "g_sta", "Y": "r", "D": {"stas": [instrument_stats, instrument_stats, instrument_stats]}}'],

				'params':{
					"sid":[`int_4`, `Instrument id. Optional. If ommited, then list of settings for all instruments will be returned`],


					"stas":[`instrument_statss`, ``],
					"sta":[`instrument_stats`, ``],
				}
			}
				
			]},{
			'name':'Price levels',
			'id':'rest_Price_levels',
			'chapters':[{
				'description':'Request for top of the book',
				'name':'Get top of the book',
				'id':'rest_Get_top_of_the_book',
				'request':['/g_be?sid=1'],
				
				'reply':['{"P": "g_be", "Y": "r", "D": {"bes": [top_of_book, top_of_book, top_of_book]}}'],

				'params':{
					"sid":[`int_4`, `Instrument id. Optional. If ommited, then top of the book for all instruments will be returned`],
					"bes":[`top_of_books`, ``],
					"be":[`top_of_book`, ``],
				}
			},{
				'description':`Request for orderbook (depth = 20)`,
				'name':'Get orderbook',
				'id':'rest_Get_orderbook',
				'request':['/g_ob20?sid=1'],
				
				'reply':['{"P": "g_ob20", "Y": "r", "D": {"ob": orderbook}}'],

				'params':{
					"sid":[`int_4`, `Instrument id`],
					"ob":[`orderbook`, ``],
				}
			},{
				'description':`Request for Coinmarketcap format orderbook (default depth = 100, i.e. 50 each side), values are cached for a 1 second`,
				'name':'Get orderbook in Coinmarketcap format',
				'id':'rest_Get_CMC_orderbook',
				'request':['/orderbook/TICKER_ID?depth=100'],
				
				'reply':['{"P": "orderbook", "Y": "r", "D": cmc_orderbook}'],

				'params':{
					"TICKER_ID":[`string`, `Identifier of a ticker (from cmc_contract)`],
					"depth":[`int_4`, `Ordebook depth, depth = max(0, min(depth, 200))`],
					"D":[`cmc_orderbook`, ``],
				},
				'footer': '<br>Possible errors: [[EX1]] [[EX2]] [[EX3]] [[EX4]]',
			}]},/*{
			'name':'Candles',
			'id':'rest_Candles',
			'chapters':[{
				'description':`Request candles. '*' = 1s or 1m or 5m or 15m or 30m or 1h or 4h or 6h or 12h or 1d. No more than 5000 candles will be returned`,
				'name':'Get candles',
				'id':'rest_Get_candles',
				"request":['/g_cs*?sid=1&bt=1601645234&et=1606915663'],
				
				'reply':['{"P": "g_cs*", "Y": "r", "D": {"cs": [candle, candle, candle]}}'],
				'params':{
					"sid":[`int_4`, `Instrument id`],
					"bt":['epoch_sec', `Range start date.`],
					"et":['epoch_sec', `Range end date. "bt" <= "et. Optional. Default value is current time.`],
					"cs":[`candles`, ``],
				}
			},]},*//*{
			'name':'Public trades',
			'id':'rest_Public_trades',
			'chapters':[{
				'description':'Request trades. Trades will be received in order "new trades first"',
				'name':'Get public trades',
				'id':'rest_Get_public_trades',
				"request":['/g_tr?sid=1&mt=160164523000000000'],
				'reply':['{"P": "g_tr",  "Y": "r", "D": {"trs": [public_trade, public_trade, public_trade]}}'],
				'params':{
					"sid":[`int_4`, `Instrument id`],
					"mt":[`until_time`, `Optional. Default value is current time.`],
					"lim":[`int_4`, `Limit. Range: [1,100]. If limit is ommited default value 100 is used`],
					"trs":[`public_trades`, ``],
				}
			}]}*/,{
			'name':'Indexes',
			'id':'rest_Indexes',
			'chapters':[{
				'description':`Request indexes list`,
				'name':'Get indexes',
				'id':'rest_Get_all_indexes',
				"request":['/g_idx?iid=csi_btc'],
				'reply':['{"P": "g_idx", "Y": "r", "D": {"idxs": [idx, idx, idx]}}'],
				'params':{
					"iid":[`string_N`, `N = 32. Idx id. Optional. If ommited, then list of all indexes will be returned`],
					"idxs":[`idxs`, ``],
				}
			},/*{
				'description':'Request index 24h secondly history data',
				'name':'Get index 24h history',
				'id':'rest_Get_index_24h_history',
				"request":['/g_hidx?iid=1'],
				
				'reply':['{"P": "g_hidx", "Y": "r", "D": {"hidx": hidx}}'],
				'params':{
					"iid":[`string_N`, `N = 32. Idx id`],
					"hidx":[`hidx`, ``],
				}
			}*/
		]},
			
   				],
	},{
		'name':"WebSocket Public",
		'id':"WebSocket_API_Public",
		'description':`See [[Endpoints]] for information on where to connect to`,
		"chapters":[{
				'description':`Exchange time sync message is sent to public ws upon connection and every 10 seconds`,
				'name':'Time',
				'id':'WSPub_Time',
				'update':['{"T": 1}'],
				'params':{
					"T":[`epoch_sec`, `Exchange time. Sent to public socket every 10 seconds`],
				}
			},{
				'description':`Updates on instrument creation/deletion, index creation. You don't have to request them. Server will always send them to all your public sessions`,
				'name':'Automatic updates',
				'id':'Automatic_updates_public',
				'update':['{"P": "ins", "Y": "u", "D": {"ins": ins}}', '{"P": "idx", "Y": "u", "D": {"idx": idx}}'],
				'params':{
					"ins":[`instrument`, `With "act":"del" on delete and "act":"add" on add`],
					"idx":[`idx`, `With "act":"add" on add`],
				}
			},{
			'name':'Instruments',
			'id':'Instruments',
			'chapters':[{
				'description':`Request for list of instruments. Instruments with status 'disabled' are not returned. <a href="#Automatic_updates_public">Updates</a> on instrument creation/deletion are sent automatically to all public ws sessions`,
				'name':'Get instruments',
				'id':'Get_all_instruments',
				"request":['{"P":  "g_ins"}'],
				
				'reply':['{"P":  "g_ins", "Y": "r", "D": {"inss": [instrument, instrument, instrument]}}'],
				'params':{


					"inss":[`instruments`, ``],
				}
			},{
				'description':'Request for list of disabled(old) instruments',
				'name':'Get disabled instruments',
				'id':'Get_disabled_instruments',
				"request":['{"P":  "g_dins"}'],
				
				'reply':['{"P":  "g_dins", "Y": "r", "D": {"inss":[instrument, instrument, instrument]}}'],
				'params':{


					"inss":[`instruments`, ``],
				}
			},{
				'description':'Request for all instruments symbols list (expired + active)',
				'name':'Get symbols',
				'id':'Get_symbols',
				"request":['{"P":  "g_sym"}'],
				
				'reply':['{"P":  "g_sym", "Y": "r", "D": {"syms": [symbol, symbol, symbol]}}'],
				'params':{


					"syms":[`symbols`, ``],
				}
			},{
				'description':'',
                'name':'Get currencies',
                'id':'Get_all_currencies',
                "request":['{"P":  "g_cur"}'],
				
                'reply':['{"P":  "g_cur", "Y": "r", "D": {"curs": [currency, currency, currency], "bcs": [blockchain, blockchain, blockchain]}}'],
                'params':{
                    "curs":[`currencies`, ``],
					"bcs":[`blockchains`, ``],
                }
			},{
				'description':'only updated fields will be sent (updates will be sent upon any changes)',
				'name':'Subscribe on status change of instruments',
				'id':'Subscribe_on_status_change_of_instruments',
				'subscribe':['{"P":  "s_st", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P":  "s_st", "Y": "r", "D": {"sts": [{"sid": 1, "ss": 1, "at":1, "et":1}]}, "I":  "1"}'],
				'update':['{"P":  "s_st", "Y": "u", "D": {"st": {"sid": 1, "ss": 1, "at":1, "et":1}}}'],
				'unsubscribe':['{"P":  "us_st", "D": {"sids": [1,2,3]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P":  "us_st", "Y": "r", "D": {"sids": [1,2,3]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"ss":[`instrument_status`, `Instrument trading status`],
					"at":[`epoch_sec`, `Activation time, present if non zero`],
					"et":[`epoch_sec`, `Expiration time, present if non zero`],
				}
			},{
				'description':'only updated fields will be sent (updates will be sent not more than every 1 second)',
				'name':'Subscribe on settings of instruments',
				'id':'Subscribe_on_settings_of_instruments',
				'subscribe':['{"P":  "s_set", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P":  "s_set", "Y": "r", "D": {"sets": [instrument_settings, instrument_settings, instrument_settings]}, "I":  "1"}'],
				'update':['{"P":  "s_set", "Y": "u", "D": {"set": instrument_settings}}'],
				'unsubscribe':['{"P":  "us_set", "D": {"sids": [1,2,3]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P":  "us_set", "Y": "r", "D": {"sids": [1,2,3]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"sets":[`instrument_settingss`, ``],
					"set":[`instrument_settings`, ``],
				}
			},{
				'description':'only updated fields will be sent (updates will be sent not more than every 1 second)',
				'name':'Subscribe on statistics of instruments',
				'id':'Subscribe_on_statistics_of_instruments',
				'subscribe':['{"P":  "s_sta", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P":  "s_sta", "Y": "r", "D": {"stas": [instrument_stats, instrument_stats, instrument_stats]}, "I":  "1"}'],
				'update':['{"P":  "s_sta", "Y": "u", "D": {"sta": instrument_stats}}'],
				'unsubscribe':['{"P":  "us_sta", "D": {"sids": [1,2,3]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P":  "us_sta", "Y": "r", "D": {"sids": [1,2,3]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"stas":[`instrument_statss`, ``],
					"sta":[`instrument_stats`, ``],
				}
			},{
				'description':'only updated fields will be sent (updates will be sent not more than every 0.25 second)',
				'name':'Subscribe on prices of instruments',
				'id':'Subscribe_on_prices_of_instruments',
				'subscribe':['{"P":  "s_pr", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P":  "s_pr", "Y": "r", "D": {"prs": [instrument_prices, instrument_prices, instrument_prices]}, "I":  "1"}'],
				'update':['{"P":  "s_pr", "Y": "u", "D": {"pr": instrument_prices}}'],
				'unsubscribe':['{"P":  "us_pr", "D": {"sids": [1,2,3]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P":  "us_pr", "Y": "r", "D": {"sids": [1,2,3]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],
							
							
					"prs":[`instrument_pricess`, ``],
					"pr":[`instrument_prices`, ``],
				}
			},{
				'description':'only updated fields will be sent (updates will be sent not more than every 5 seconds)',
				'name':'Subscribe on all fields of instruments',
				'id':'Subscribe_on_all_fields_of_instruments',
				'subscribe':['{"P":  "s_all", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P":  "s_all", "Y": "r", "D": {"alls": [instrument_fields, instrument_fields, instrument_fields]}, "I":  "1"}'],
				'update':['{"P":  "s_all", "Y": "u", "D": {"all": instrument_fields}}'],
				'unsubscribe':['{"P":  "us_all", "D": {"sids": [1,2,3]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P":  "us_all", "Y": "r", "D": {"sids": [1,2,3]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],
							
							
					"alls":[`instrument_fieldss`, ``],
					"all":[`instrument_fields`, ``],
				}
			}]},{
			'name':'Price levels',
			'id':'Price_levels',
			'chapters':[{
				'description':'Only updated fields will be sent (updates will be sent not more than every 0.1 second)',
				'name':'Subscribe on top of the book',
				'id':'Subscribe_on_top_of_the_book',
				'subscribe':['{"P": "s_be", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_be", "Y": "r", "D": {"bes": [top_of_book, top_of_book, top_of_book]}, "I":  "1"}'],
				'update':['{"P": "s_be", "Y": "u", "D": {"be": top_of_book}}'],
				'unsubscribe':['{"P": "us_be", "D": {"sids": [1]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_be", "Y": "r", "D": {"sids": [1]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"bes":[`top_of_books`, ``],
					"be":[`top_of_book`, ``],
				}
			},{
				'description':`Subscribe on orderbooks (depth = 20). Empty list of bids/offers in update means you should clear bids/offers. Zero amount means you should delete corresponding price level. You can receive deletions of nonexistent price levels on reconnect. Updates will be sent not more than every 0.1 seconds`,
				'name':'Subscribe on orderbooks',
				'id':'Subscribe_on_orderbooks',
				'subscribe':['{"P": "s_ob20", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_ob20", "Y": "r", "D": {"obs": [orderbook, orderbook, orderbook]}, "I":  "1"}'],
				'update':['{"P": "s_ob20", "Y": "u", "D": {"ob": orderbook}}'],
				'unsubscribe':['{"P": "us_ob20", "D": {"sids": [1]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_ob20", "Y": "r", "D": {"sids": [1]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"obs":[`orderbooks`, ``],
					"ob":[`orderbook`, ``],
				}
			},{
				'description':`Subscribe on orderbooks (depth = 20). Empty list of bids/offers in update means you should clear bids/offers. Zero amount means you should delete corresponding price level. You can receive deletions of nonexistent price levels on reconnect. Updates will be sent not more than every 0.3 second`,
				'name':'Subscribe on orderbooks for site',
				'id':'Subscribe_on_orderbooks_site',
				'subscribe':['{"P": "s_ob20s", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_ob20s", "Y": "r", "D": {"obs": [orderbook, orderbook, orderbook]}, "I":  "1"}'],
				'update':['{"P": "s_ob20s", "Y": "u", "D": {"ob": orderbook}}'],
				'unsubscribe':['{"P": "us_ob20s", "D": {"sids": [1]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_ob20s", "Y": "r", "D": {"sids": [1]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],


					"obs":[`orderbooks`, ``],
					"ob":[`orderbook`, ``],
				}
			}]},{
			'name':'Public trades',
			'id':'Public_trades',
			'chapters':[{
				'description':'Request historical trades. Trades will be received in order "new trades first". See <a href="#Subscribtion_on_updates_+_history">example</a>',
				'name':'Get public trades',
				'id':'Get_public_trades',
				"request":['{"P": "g_tr",  "D": {"sids": [1], "mt": 1, "lim": 1}, "I":  "1"}','{"P": "g_tr",  "D": {"mt": 1, "lim": 1 }, "I":  "1"}'],
				
				'reply':['{"P": "g_tr",  "Y": "r", "D": {"trs": [public_trade, public_trade, public_trade]}, "I":  "1"}'],
				'params':{
					"sids":[`vector_int`, `List of instrument id's`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]. If limit is ommited default value 100 is used`],


					"trs":[`public_trades`, ``],
				}
			},{				
				'description':'Updates will be sent not more than every 0.1 second. See <a href="#Subscribtion_on_updates_+_history">example</a>',
				'name':'Subscribe on public trades',
				'id':'Subscribe_on_public_trades',
				'subscribe':['{"P": "s_tr", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_tr", "Y": "r", "D": {"mt": 1, trs": [public_trade, public_trade, public_trade]}, "I":  "1"}'],
				'update':['{"P": "s_tr", "Y": "u", "D": {"trs": [public_trade, public_trade, public_trade]}}'],
				'unsubscribe':['{"P": "us_tr", "D": {"sids": [1]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_tr", "Y": "r", "D": {"sids": [1]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],
					"mt":[`snapshot_time`, ``],


					"trs":[`public_trades`, ``],
				}
			}]},{
			'name':'Indexes',
			'id':'Indexes',
			'chapters':[{
				'description':`Request indexes list`,
				'name':'Get all indexes',
				'id':'Get_all_indexes',
				"request":['{"P": "g_idx"}'],
				
				'reply':['{"P": "g_idx", "Y": "r", "D": {"idxs": [idx, idx, idx]}, "I":  "1"}'],
				'params':{


					"idxs":[`idxs`, ``],
				}
			},{
				'description':'Subscribe on indexes price updates, only updated fields will be sent (updates will be sent not more than every 1 second)',
				'name':'Subscribe on indexes',
				'id':'Subscribe_on_indexes',
				'subscribe':['{"P": "s_idx", "D": {"iids": ["csi_btc"]}, "I":  "1"}','{"P": "s_idx", "D": {"iids": ["csi_btc"],"history": true}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_idx", "Y": "r", "D": {"idxs": [idx]}, "I":  "1"}','{"P": "s_idx", "Y": "r", "D": {"hidxs":[hidx]}, "I":  "1"}'],
				'update':['{"P": "s_idx", "Y": "u", "D": {"idx": idx}}'],
				'unsubscribe':['{"P": "us_idx", "D": {"iids": ["csi_btc"]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_idx", "Y": "r", "D": {"iids": ["csi_btc"]}, "I":  "1"}'],

				'params':{
					"iids":[`vector`, `Index ids list or null, null means "all indexes"`],
					"history":[`bool`, `Request 100 hidx prices in reply.`],


					"hidx":[`hidx`, ``],
					"idx":[`idx`, ``],
				}
			},{
				'description':'Request index 24h secondly history data',
				'name':'Get index 24h history',
				'id':'Get_index_24h_history',
				"request":['{"P": "g_hidx", "D": {"iids": ["csi_btc"]}, "I":  "1"}'],
				
				'reply':['{"P": "g_hidx", "Y": "r", "D": {"hidxs": [hidx, hidx, hidx]}, "I":  "1"}'],
				'params':{
					"iids":[`vector_int`, `Index ids list or null, null means "all indexes"`],


					"hidxs":[`hidxs`, ``],
				}
			}
		]},{
			'name':'Candles',
			'id':'Candles',
			'chapters':[{
				'description':`Request historical candles. '*' = 1s or 1m or 5m or 15m ot 30m or 1h or 4h or 6h or 12h or 1d. No more than 5000 candles will be returned`,
				'name':'Get candles',
				'id':'Get_candles',
				"request":['{"P": "g_cs*", "D": {"sids": [1], "bt": 1, "et": 1}, "I":  "1"}'],
				
				'reply':['{"P": "g_cs*", "Y": "r", "D": {"cs": [candle, candle, candle]}, "I":  "1"}'],
				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],
					"bt":['epoch_sec', `Range start date.`],
					"et":['epoch_sec', `Range end date. "bt" <= "et"`],


					"cs":[`candles`, ``],
				}
			},{
				'description':`Subscribe on candles. '*' = 1s or 1m or 5m or 15m ot 30m or 1h or 4h or 6h or 12h or 1d (updates will be sent not more than every 1 second)`,
				'name':'Subscribe on candles',
				'id':'Subscribe_on_candles',
				'subscribe':['{"P": "s_cs*", "D": {"sids": [1]}, "I":  "1"}'],
				
				'snapshot':['{"P": "s_cs*", "Y": "r", "D": {"mt": 1, "cs": [candle, candle, candle]}, "I":  "1"}'],
				'update':['{"P": "s_cs*", "Y": "u", "D": {"cs": [candle, candle, candle]}}'],
				'unsubscribe':['{"P": "us_cs*", "D": {"sids": [1]}, "I":  "1"}'],
				'unsubscribe-reply':['{"P": "us_cs*", "Y": "r", "D": {"sids": [1]}, "I":  "1"}'],

				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "all instruments"`],
					"mt":[`snapshot_time`, ``],


					"cs":[`candles`, ``],
				}
			}]},
			{
			'name':'News',
			'id':'News',
			'chapters':[{
				'description':`Get current important news`,
				'name':'Get news',
				'id':'Get_news',
				"request":['{"P": "g_n", "I":  "1"}'],
				
				'reply':['{"P": "g_n", "Y": "r", "D": {"nws": [news, news, news]}, "I":  "1"}'],
				'params':{
					"nws":[`newss`, ``],
				}
			}
			]
			},
			/*{
			'name':'?Statistics',
			'chapters':[{
				'description':`Get trading volume for last day, week, month, year, total, daily. Open, close, max, min.`,
				'name':'-Get trading volume',
				"request":['{"P": "g_idx"}'],
				
				'reply':['{"P": "g_idx", "Y": "r", "D": {"idxs": [idx, idx, idx]}, "I":  "1"}'],
				'params':{
					"idxs":[`idxs`, ``],


				}
			},
		]}*/]
	},{
		'name':"WebSocket Private",
		'id':"WebSocket_API_Private",
		'description':`See [[Endpoints]] for information on where to connect to`,
		"chapters":[{
				'description':`Upon ws connection, the authentication request should be sent from the client. In case of successful authentication, server sends response. In other case the client will recieve error and connection will be reset.
		After sending authentication request to the server, you should wait for the authentication response to arrive. If you send other requests before that, server will reset the connection.`,
				'name':"Authentication",
				'id':"Authentication",
				'chapters':[{
					'name':"API key authentication",
					'id':"API_key_authentication",
					'description':'please, see <a href="#Authentication_examples">examples</a>.',
					"request":['{"P": "auth_key", "D": {"kid":"123", "ts":1592556508, "sig":"64bd4e3c116dd54b74a2f405b347ff5750a97d2ed2bb99da8e9b3a02e6d9342cfdb12a17da4d8c17241b07e3176d5e26f03cf2c38a925c60e249a2423dbaf0e8"}, "I": "1"}'],
					
					'reply':['{"P": "auth", "Y": "r", "D": {"auth":"str"}, "I": "1"}'],
					'params':{
						"kid":['string', `API key id`],
						"ts":['epoch_sec', 'Current UTC time'],
						"sig":['string', `Hexademical representation of ECDSA secp256k1 signature of SHA256 hash of "kid" and "ts" concatenated values, separated by space (' '). Please see this <a href="#Authentication_examples">example</a>`],


						"auth":['string', `Some initial user settings for frontend. You can set them with [[Set_settings]] with key "auth"`],
					}},
				]
			},{
				'description':`<br>Immediately after succesful authentication, server sends snapshots for orders and trades. Then server continues sending updates on orders (with trades), positions, accounts, limits and fees.<br>Server will always send them to all your private sessions.
					<br>Snapshots contain the [[snapshot_time]] field. This field is meant to be used when requesting historical data. 
					<br>!!! When recieving any data that has an update time "ut" (orders, positions, accounts, limits), perform check that it is growing. Because sometimes data can be duplicated in the snapshot, updates and history responses.
					<br>
					`,
				'name':'Automatic updates',
				'id':'Automatic_updates_private',
				'snapshot':['{"P": "or", "Y": "r", "D": {"mt": 1, "ors": [order, order, order]}}', '{"P": "tr", "Y": "r", "D": {"mt": 1, "trs": [full_trade, full_trade, full_trade]}}'],
				'update':['{"P": "or", "Y": "u", "D": {"or": order}}','{"P":  "pos", "Y":  "u", "D": {"pos": position}}','{"P":  "acc", "Y":  "u", "D": {"acc": account}}','{"P":  "lim", "Y":  "u", "D": {"lim": limit}}','{"P":  "trf", "Y":  "u", "D": {"trf": tariff}}','{"P": "s_str", "Y": "u", "D": {"key": "K", "val": "V"}, "I": "1"}','{"P": "e_2fa", "Y": "u", "D": {}}','{"P": "d_2fa", "Y": "u", "D": {}}', '{"P": "n", "Y": "u", "D": {"nws": [news, news, news]}, "I":  "1"}'],
				'params':{
					"mt":[`snapshot_time`, ``],
					"ors":[`orders`, ``],
					"trs":[`full_trades`, ``],
					"or":[`order`, ``],
					"pos":[`position`, ``],
					"acc":[`account`, ``],
					"lim":[`limit`, ``],
					"trf":[`tariff`, ``],
					"key":['string', `Settings key, "auth" or "set"`],
					"val":['string', `Settings value string`],
					"nws":[`newss`, ``],
				}
			},{
				'description':'',
				'name':'Get rate limits',
				'id':'Get_rate_limits',
				"request":['{"P": "g_rls", "I": "1"}'],
				
				'reply':['{"P": "g_rls", "Y": "r", "D": {"rls":[rate_limit, rate_limit, rate_limit]}, "I": "1"}'],
				'params':{


					"rls":['rate_limits', ``],
				}
			},{
			'name':'Orders',
			'id':'Orders',
			'chapters':[{
				'description':'New single order request. Please see <a href="#New_order_examples">example new order messages</a>. Order <a href="#Automatic_updates_private">update</a> messages will be sent to all opened private sessions for the account.',
				'name':'New order',
				'id':'New_order',
				"request":['{"P": "n_or", "D": order, "I": "1"}'],
				//'error':['{"P": "n_or", "Y": "e", "D": {"c": "", "p": [] }, "I": "1"}'],
				'reply':['{"P": "n_or", "Y": "r", "D": {"or": order}, "I": "1"}'],
				'params':{
					"or":[`order`, ``],
					//"c":['str_code', `Possible errors: [[WP0]] [[WP1]] [[WP2]] [[WP3]] [[WP4]] [[WP5]] [[WP8]] [[WP9]] [[WP11]] [[WP12]] [[WP14]] [[WP17]] [[WP18]] [[WP19]] [[WP21]] [[WP22]] [[WPM2]] [[AL16]] [[AL23]] [[IF0]] [[IF1]] [[IF2]] [[WS0]] [[EX0]] [[EX1]]`],
					//"p":['str_params',''],
				},
				'footer': '<br/>New order possible errors: [[WP0]] [[WP1]] [[WP2]] [[WP3]] [[WP4]] [[WP5]] [[WP8]] [[WP9]] [[WP11]] [[WP12]] [[WP14]] [[WP17]] [[WP18]] [[WP19]] [[WP21]] [[WP22]] [[WPM2]] [[AL16]] [[AL23]] [[IF0]] [[IF1]] [[IF2]] [[WS0]] [[EX0]] [[EX1]]',
			},{
				'description':'Order cancel request',
				'name':'Cancel order',
				'id':'Cancel_order',
				"request":['{"P": "c_or", "D": {"ono": 1, "sid": 1, "aid": 1}, "I": "1"}'],
				//'error':['{"P": "c_or", "Y": "e", "D": {"c": "", "p": [] }, "I": "1"}'],
				'reply':['{"P": "c_or", "Y": "r", "D": {"or": order}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"sid":[`int_4`, `Instrument id`],
					"or":[`order`, ``],
					//"c":['str_code',`Possible errors: [[WP0]] [[WP1]] [[WP2]] [[O0]] [[WPM0]] [[WPM1]] [[WPM2]] [[AL23]] [[WS0]] [[EX0]] [[EX1]]`],
					//"p":['str_params',''],
				},
				'footer': '<br/>Cancel order possible errors: [[WP0]] [[WP1]] [[WP2]] [[O0]] [[WPM0]] [[WPM1]] [[WPM2]] [[AL23]] [[WS0]] [[EX0]] [[EX1]]',
			},{
				'description':'Cancel multiple orders, filtered by account and instrument. Returns list of accounts and instruments, on which orders are being effected',
				'name':'Cancel multiple orders',
				'id':'Cancel_multiple_orders',
				"request":['{"P":  "c_ors", "D": {"sids": [1], "aids": [1]}, "I": "1"}','{"P":  "c_ors", "I": "1"}'],
				'reply':['{"P":  "c_ors", "Y": "r", "D":  {"sids": [1], "aids": [1]}, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
				}
			},{
				'description':'Find order by Exteranl ID',
				'name':'Get order',
				'id':'Get_order',
				"request":['{"P": "g_eor", "D": {"aid":1, "sid":1, "eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1", "wt": true}, "I": "1"}'],
				'reply':['{"P": "g_eor", "Y": "r", "D": {"or": order}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"sid":[`int_4`, `Instrument id`],
					"eid":["uuid", `External ID`],
					"wt": [`bool`, `Include trades. Trades will be received in order "old trades first". Default value is false`],
					"or":[`order`, ``],
				}
			},{
				'description':`Get orders from datetime. The search is made by creation time. Orders will be received in order "new orders first":`,
				'name':'Get orders',
				'id':'Get_orders',
				"request":['{"P": "g_or", "D": {"aids": [1], "sids": [1], "mt": 1, "lim": 1, "wt": true, "hot": false}, "I": "1"}'],
				'reply':['{"P": "g_or", "Y": "u", "D":  {"ors": [order, order, order] }, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"wt": [`bool`, `Include trades. Trades will be received in order "old trades first". Default value is false`],
					"hot":[`bool`, `Search only in "hot" orders for each account. "hot" means no more than last 1000 orders per account saved in a separate table for fast access. "hot" table does NOT have stop orders. Default value is false`],
					"ors":[`orders`, ``],
				}
			},{
				'description':`Get stop orders from datetime. The search is made by creation time:`,
				'name':'Get stop orders',
				'id':'Get_stop_orders',
				"request":['{"P": "g_sor", "D": {"aids": [1], "sids": [1], "mt": 1, "lim": 1}, "I": "1"}'],
				'reply':['{"P": "g_sor", "Y": "u", "D":  {"ors": [order, order, order] }, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"ors":[`orders`, ``],
				}
			},{
				'description':'Get active orders with/without trades. Orders will be received in order "new orders first":',
				'name':'Get active orders',
				'id':'Get_active_orders',
				"request":['{"P":  "g_aor", "D": {"aids": ["1"], "wt": true}, "I": "1"}'],
				'reply':['{"P":  "g_aor", "Y": "r", "D":  {"ors": [order, order, order] }, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"wt": ['bool','Include trades. Trades will be received in order "old trades first"'],
					"ors":['orders', ``],
				}
			}]},{
			'name':'Trades',
			'id':'Trades',
			'chapters':[{
				'description':'Get trades from datetime. The search is made by creation time. Trades will be received in order "new trades first":',
				'name':'Get trades',
				'id':'Get_trades',
				"request":['{"P":  "g_tr",  "D": {"aids": [1], "sids": [1], "mt": 1, "lim": 1, "hot": false }, "I": "1"}'],
				'reply':['{"P":  "g_tr",  "Y": "u", "D":  {"trs": [full_trade, full_trade, full_trade]}, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"hot":[`bool`, `Search only in "hot" trades for each account. "hot" means no more than last 1000 trades per account saved in a separate table for fast access. Default value is false`],
					"trs":[`full_trades`, ``],
				}
			},{
				'description':'Get trades from datetime and to datetime, not more than 5000. The search is made by creation time. Trades will be received in order "new trades first":',
				'name':'Get trades for chart',
				'id':'Get_trades_for_chart',
				"request":['{"P":  "g_tr2",  "D": {"aids": [1], "sids": [1], "bt": 1, "et": 2, "hot": false }, "I": "1"}'],
				'reply':['{"P":  "g_tr2",  "Y": "u", "D":  {"ctrs": [chart_trade, chart_trade, chart_trade]}, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
					"bt":['epoch_sec', `Range start date.`],
					"et":['epoch_sec', `Range end date. "bt" <= "et"`],
					"hot":[`bool`, `Search only in "hot" trades for each account. "hot" means no more than last 1000 trades per account saved in a separate table for fast access. Default value is false`],
					"ctrs":[`chart_trades`, ``],
				}
			},{
				'description':'Get public trades from datetime. Own trades will be marked by either [[maker_taker]] or [[trade_flags]] fields. The search is made by creation time. Trades will be received in order "new trades first":',
				'name':'Get public trades with own trades marked',
				'id':'Get_public_trades_with_own_trades_marked',
				"request":['{"P":  "g_tr3",  "D": {"sids": [1], "mt": 1, "lim": 1}, "I": "1"}'],
				'reply':['{"P":  "g_tr3",  "Y": "u", "D":  {"trs": [private_trade, private_trade, private_trade]}, "I": "1"}'],
				'params':{
					"sids":[`vector_int`, `Instrument ids list or null, null means "no filter"`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"trs":[`private_trades`, ``],
				}
			},
			]},{
			'name':'Positions',
			'id':'Positions',
			'chapters':[{
				'description':`Request for account's positions`,
				'name':'Get positions',
				'id':'Get_positions',
				"request":['{"P": "g_pos"}','{"P": "g_pos", "D": {"aids": [1]}}'],
				//
				'reply':['{"P": "g_pos", "Y": "r", "D": {"poss": [position, position, position]}, "I": "1"}'],
				'params':{
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"poss":[`positions`, ``],
				}				
			}]},{
			'description':`Account has limits attached to it. Each limit has it's currency (BTC, ETH, e.t.c). You can think of account as a bucket of limits, each of different currency. Account balance is the sum of limits (roughly), attached to it, calculated in bitcoin. Account balance is always in bitcoin.<br>
				When you trade - you trade from account as a whole bucket.<br>
				When you withdraw coins from account - the withdrawal is maid from the attached limit of specified currency.<br>
				When you transfer coins between accounts - the transfer is maid between attached limits of specified currency. If there is no destination limit - it will be created by the server.`,
			'name':'Accounts',
			'id':'Accounts',
			'chapters':[{
				'description':`Find account by External ID`,
				'name':'Get account',
				'id':'Get_account',
				"request":['{"P": "g_eacc", "D": {"eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				'reply':['{"P": "g_eacc", "Y": "r", "D": {"acc": account}, "I": "1"}'],
				'params':{
					"eid":["uuid", `External ID`],
					"acc":[`account`, ``],
				}
			},{
				'description':`Request for client's accounts list`,
				'name':'Get accounts',
				'id':'Get_accounts',
				"request":['{"P": "g_acc", "I": "1"}'],
				'reply':['{"P": "g_acc", "Y": "r", "D": {"accs": [account, account, account]}, "I": "1"}'],
				'params':{
					"accs":[`accounts`, ``],
				}
			},{
				'description':`Request for client's limits list. Limit is where actual coins are stored. Main limits of each currency are attached to main account. Main limit means that all deposited coins of corresponding currency go there. Main account has one main limit of each currency attached to it by default. Each account can have no more than one limit of each currency attached to it.`,
				'name':"Get limits",
				'id':"Get_limits",
				"request":['{"P": "g_lim", "I": "1"}'],
				'reply':['{"P": "g_lim", "Y": "r", "D": {"lims": [limit, limit, limit]}, "I": "1"}'],
				'params':{
					"lims":[`limits`, ``],
				}
			},{
				'description':'Create new account',
				'name':'Create new account',
				'id':'Create_new_account',
				"request":['{"P": "c_acc", "D": {"ll": "test", "eid": "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				
				'reply':['{"P": "c_acc", "Y": "r", "D": {"acc": account}}'],
				'params':{
					"ll":[`string_N`, `N = 32. User label, can be omitted`],
					"eid":["uuid", `External ID, can be omitted`],
					"acc":[`account`, ``],
				}
			},{
				'name':'Transfer balance between accounts',
				'id':'Transfer_balance_between_accounts',
				"request":['{"P": "t_acc", "D": {"f_aid": 14, "t_aid": 1, "amnt": 4900000000, "cid": "BTC", "ll":"qwe", "eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				
				'reply':['{"P": "t_acc", "Y": "r", "D": {"lims": [limit, limit]}, "I": "1"}'],
				'params':{
					"f_aid":['int_8', `Account id from`],
					"t_aid":['int_8', `Account id to`],
					"amnt":[`dec_8`, `Amount to transfer`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"ll": [`string_N`, `N = 32. Transfer label, can be omitted`],
					"eid":["uuid", `External ID, can be omitted`],
					"lims":[`limits`, ``],
				}
			},{
				'description':'Delegate trading rights for this account to another user. This user will be able to place orders, but will NOT be able to withdraw/deposit/transfer funds. Account can be delegated to multiple users',
				'name':'Delegate account to user',
				'id':'Delegate_account_to_user',
				"request":['{"P": "cn_acc", "D": {"aid": 1, "uid": "user@mail.com", "2fa":123456}, "I": "1"}'],
				
				'reply':['{"P": "cn_acc", "Y": "r", "D": {"acc": {"aid": 1, "usrs":["user@mail.ru"]}}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"uid":['string', `User id`],
					"2fa":[`int_4`, `2FA 6-digit key, you should not send this field if you are authorized with an API key`],


					"usrs":[`vector`, `List of users emails, to which this account is delegated`],
					//"acc":[`account`, ``],
				}
			},{
				'description':'Revoke access to the account from user. Or refuse from account yourself, in case it was delegated to you',
				'name':'Revoke delegated account / refuse from account',
				'id':'Revoke_delegated_account___refuse_from_account',
				"request":['{"P": "dcn_acc", "D": {"aid": 1, "uid": "user@mail.com"}, "I": "1"}'],
				
				'reply':['{"P": "dcn_acc", "Y": "r", "D": {"acc": {"aid": 1, "usrs":[]}}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"uid":['string', `User id, can be omitted, default value is your email`],


					"usrs":[`vector`, `List of users emails, to which this account is delegated`],
					//"acc":[`account`, ``],
				}
			},{
				'description':'Label account',
				'name':'Label account',
				'id':'Label_account',
				"request":['{"P": "l_acc", "D": {"aid": 1, "ll": "qwe"}, "I": "1"}'],
				
				'reply':['{"P": "l_acc", "Y": "r", "D": {"acc": {"aid": 1, "ll": "qwe"}}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"ll":[`string_N`, `N = 32. User label`],


					//"acc":[`account`, ``],
				}
			},{
				'description':'Remove account. Account will be deleted with all the limits attached to it. Limit balances should be emptied before this, or error will be returned.',
				'name':'Remove account',
				'id':'Remove_account',
				"request":['{"P": "d_acc", "D": {"aid": 13}, "I": "1"}'],
				
				'reply':['{"P": "d_acc", "Y": "r", "D": {"acc": {"aid": 13, "act": "del"}}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],


					//"acc":[`account`, ``],
				}
			},/*{
			'description':`Limit is where actual coins are stored. Main limits of each currency are attached to main account. Main limit means that all deposited coins of corresponding currency go there. Main account has one main limit of each currency attached to it by default. Each account can have no more than one limit of each currency attached to it.`,
			'name':'Account limits',
			'chapters':[{
				'description':`Request for client's limits list`,
				'name':"Get limits",
				"request":['{"P": "g_lim", "I": "1"}'],
				'reply':['{"P": "g_lim", "Y": "r", "D": {"lims": [limit, limit, limit]}, "I": "1"}'],
				'params':{
					"lims":[`limits`, ``],
				}
			},{
				'description':'Create new limit',
				'name':'Create new limit',
				"request":['{"P": c_lim, "D": {"cid": "BTC", "ll": "test"}, "I": "1"}'],
				
				'reply':['{"P": c_lim, "Y": "r", "D": {"lim": limit}, "I": "1"}'],
				'params':{
					"cid":[`string_N`, "N = 32. Currency ID"],
					"ll":[`string_N`, `N = 32. User label`],


					"lim":[`limit`, ``],
				}
			},{
				'description':'Transfer balance between limits. Limits should be of the same currency',
				'name':'Transfer balance between limits',
				"request":['{"P": "t_lim", "D": {"f_lid": 14, "t_lid": 1, "v": 4900000000}, "I": "1"}'],
				
				'reply':['{"P": "t_lim", "Y": "r", "D": {"lims": [limit, limit, limit]}, "I": "1"}'],
				'params':{
					"f_lid":['int_8', `Limit id from`],
					"t_lid":['int_8', `Limit id to`],
					"amnt":[`dec_8`, `Amount to transfer`],


					"lims":[`limits`, ``],
				}
			},{
				'description':'Attach limit to account. Only one limit of the same currency can be attached to one account',
				'name':'Attach limit to account',
				"request":['{"P": "cn_lim", "D": {"lid": 14, "aid": 11}, "I": "1"}'],
				
				'reply':['{"P": "cn_lim", "Y": "r", "D": {"lim": {"lid": 14, "aid": 14}}, "I": "1"}'],
				'params':{
					"lid":['int_8', `Limit id to attach`],
					"aid":["int_8", `Account id to attach to`],


					//"lim":[`limit`, ``],
				}
			},{
				'description':'Detach limit from account. It is forbidden to detach limits from main account',
				'name':'Detach limit from account',
				"request":['{"P": "dcn_lim", "D": {"lid": 14}, "I": "1"}'],
				
				'reply':['{"P": "dcn_lim", "Y": "r", "D": {"lim": {"lid": 14, "aid": null}}, "I": "1"}'],
				'params':{
					"lid":['int_8', `Limit id to detach, main limit can not be detached`],


					"aid":["int_8", `Account id, limit attached to`],
					//"lim":[`limit`, ``],
				}
			},{
				'name':'Check how much can withdraw from limit',
				"request":['{"P": "cw_lim", "D": {"lid": 14}, "I": "1"}'],
				
				'reply':['{"P": "cw_lim", "Y": "r", "D": {"cw": 123}, "I": "1"}'],
				'params':{
					"lid":['int_8', `Limit id`],


					"cw":[`dec_8`, "Can withdraw"],
				}
			},{
				'description':'Label limit',
				'name':'Label limit',
				"request":['{"P": "l_lim", "D": {"lid": 1, "ll": "qwe"}, "I": "1"}'],
				
				'reply':['{"P": "l_lim", "Y": "r", "D": {"lim": {"lid": 1, "ll": "qwe"}}, "I": "1"}'],
				'params':{
					"lid":['int_8', `Limit id`],
					"ll":[`string_N`, `N = 32. User label`],


					//"lim":[`limit`, ``],
				}
			},{
				'description':`Remove limit. Limit can be deleted only if it is detached from account and it's balance is equal to zero`,
				'name':'Remove limit',
				"request":['{"P": d_lim, "D": {"lid": 1}, "I": "1"}'],
				
				'reply':['{"P": d_lim, "Y": "r", "D": {"lim": {"lid": 1, "act": "del"}}, "I": "1"}'],
				'params':{
					"lid":['int_8', `Limit id`],


					//"lim":[`limit`, ``],
				}
			}]}*/
		]},{
			'name':'Withdraw coins',
			'id':'Withdraw_coins',
			'chapters':[{
         'description':'When withdrawing from the account, account balance should be positive and sum of user accounts balances should be positive (excluding accounts, delegated to the user)',
				'name':'Check how much can withdraw from account',
				'id':'Check_how_much_can_withdraw_from_account',
				"request":['{"P": "cw_acc", "D": {"aid": 1, "cid": "BSC-USD"}, "I": "1"}'],
				
				'reply':['{"P": "cw_acc", "Y": "r", "D": {"cw": 123}, "I": "1"}', '{"P": "cw_acc", "Y": "r", "D": {"cw": 0, "c":"IF4", "p":[0.00001, "BNB"]}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"cid":[`string_N`, "N = 32. Currency ID"],


					"cw":[`dec_8`, "Can withdraw"],
					"c":['str_code', `Code of the message explaining the situation. Can be ommited if there are no messages to user`],
					"p":['str_params','Can be ommited if there are no messages to user'],
				}
			},{
				'description':'',
				'name':'Get withdrawal addresses list',
				'id':'Get_withdrawal_addresses_list',
				"request":['{"P": "g_wadr", "D":{"cids":["BTC"]}, "I": "1"}'],
				'reply':['{"P": "g_wadr", "Y": "r", "D": {"adrs":[address, address, address]}, "I": "1"}'],
				'params':{
					"cids":[`vector`, `Currencies list or null, null means "no filter"`],
					"adrs":['addresses', ``],
				}
			},{
				'description':'',
				'name':'Add withdrawal address',
				'id':'Add_withdrawal_address',
				"request":['{"P": "c_wadr", "D":{"ll":"label", "did":"address_id", "cid":"BTC", "2fa":123456}, "I": "1"}'],
				'reply':['{"P": "c_wadr", "Y": "r", "D": {"adr": address}, "I": "1"}'],
				'params':{
					"ll":[`string_N`, `N = 32. Address label`],
					"2fa":[`int_4`, `2FA 6-digit key, you should not send this field if you are authorized with an API key`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"adr":['address', ``],
				}
			},{
				'description':'',
				'name':'Remove withdrawal address',
				'id':'Remove_withdrawal_address',
				"request":['{"P": "d_wadr", "D":{"did":"address_id", "cid":"BTC"}, "I": "1"}'],
				'reply':['{"P": "d_wadr", "Y": "r", "D": {"did":"address_id", "cid":"BTC"}, "I": "1"}'],
				'params':{
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
				}
			},{
				'description':'',
				'name':'Label withdrawal address',
				'id':'Label_withdrawal_address',
				"request":['{"P": "l_wadr", "D": {"did":"address_id", "cid":"BTC", "ll": "qwe"}, "I": "1"}'],
				'reply':['{"P": "l_wadr", "Y": "r", "D": {"did":"address_id", "cid":"BTC", "ll": "qwe"}, "I": "1"}'],
				'params':{
					"ll":[`string_N`, `N = 32. Address label`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
				}
			},{
				'description':'Rate limit is 1 request per 0.5 seconds',
				'name':'Get transaction fees',
				'id':'Get_transaction_fees',
				"request":['{"P": "g_tfs", "D": {"amnt":10000, "did":"address_id", "cid": "BTC"}, "I": "1"}'],
				'reply':['{"P": "g_tfs", "Y": "r", "D": {"cid": "BTC", "tfs": [200, 200, 210, 210, 230]}, "I": "1"}'],
				'params':{
					"amnt":['dec_8', `Amount to withdraw`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
	        'tfs':['vector_int', 'Length 5. Transaction fees'],
				}
			},{
				'description':'When withdrawing from the account, account balance should be positive and sum of user accounts balances should be positive (excluding accounts, delegated to user)',
				'name':'Withdraw coins',
				'id':'method_Withdraw_coins',
				"request":['{"P": "wthd", "D": {"aid":1, "amnt":10000, "did":"address_id", "cid":"BTC", "tf":1, "ll":"label", "2fa":123456, "eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				'reply':['{"P": "wthd", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"amnt":['dec_8', `Amount to withdraw`],
					"did":['string', `Address ID`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"tf":['dec_8', `Transaction fee in base blockchain currency. For example for BSC base blockchain currency is BNB`],
					"ll":['string_N', `N = 32. Comment`],
					"2fa":[`int_4`, `2FA 6-digit key, you should not send this field if you are authorized with an API key`],
					"eid":["uuid", `External ID, can be omitted`],
				}
			},{
				'description':'Find withdraw by Exteranl ID',
				'name':'Get withdraw',
				'id':'Get_withdraw',
				"request":['{"P": "g_ewthd", "D": {"aid":1, "cid":"BTC", "eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				'reply':['{"P": "g_ewthd", "Y": "r", "D": {"wthd": transaction}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"cid":[`string_N`, "N = 32. Currency ID"],
					"eid":["uuid", `External ID`],
					"wthd":[`transaction`, ``],
				}
			},
		]},{
			'name':'Deposit coins',
			'id':'Deposit_coins',
			'chapters':[{
				'description':'',
				'name':'Get deposit addresses list',
				'id':'Get_deposit_addresses_list',
				"request":['{"P": "g_dadr", "D":{"cids":["BTC"], "mt": 1, "lim": 1}, "I": "1"}'],
				'reply':['{"P": "g_dadr", "Y": "r", "D": {"adrs":["address", "address", "address"]}, "I": "1"}'],
				'params':{
					"cids":[`vector`, `Currencies list or null, null means "no filter"`],
					"mt":[`until_time`, ``],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"adrs":['addresses', ``],
				}
			},{
				'description':'Request new deposit address to deposit coins on',
				'name':'Request new deposit address',
				'id':'Request_new_deposit_address',
				"request":['{"P": "n_dadr", "D":{"cid":"BTC"}, "I": "1"}'],
				'reply':['{"P": "n_dadr", "Y": "r", "D": {"adr": address}, "I": "1"}'],
				'params':{
					"cid":[`string_N`, "N = 32. Currency ID"],
					"adr":['address', ``],
				}
			}
			]},{
			'name':'History',
			'id':'History',
			'chapters':[{
				'description':`Request for user's accounts, limits, withdrawals, deposits and commissions history`,
				'name':'Get user account history',
				'id':'Get_user_account_history',
				"request":['{"P": "g_his", "D": {"mt": 1, "cs":["H00", "H01", "H02"], "lim": 1}}'],
				'reply':['{"P": "g_his", "Y": "r", "D": {"hiss": [history, history, history]}, "I": "1"}'],
				'params':{
					"mt":[`until_time`, ``],
					"cs":[`vector`, `[[String_codes]] list or null, null means "no filter". All available history codes start with letter 'H'.`],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"hiss":[`hiss`, ``],
				}
			},{
				'description':`Request for user's withdrawals/deposits history`,
				'name':'Get withdrawals/deposits history',
				'id':'Get_withdrawals_deposits_history',
				"request":['{"P": "g_trns", "D": {"mt": 1, "cids":["BTC"], "io":0, "lim": 1}}'],
				'reply':['{"P": "g_trns", "Y": "r", "D": {"trns": [transaction, transaction, transaction]}, "I": "1"}'],
				'params':{
					"mt":[`until_time`, ``],
					"cids":[`vector`, `Currencies list or null, null means "no filter"`],
					"io":['transaction_direction', `both directions if parameter is omitted`],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"trns":[`transactions`, ``],
				}
			},{
				'description':``,
				'name':'Get account statement',
				'id':'Get_account_statement',
				"request":['{"P": "g_ast", "D": {"mt": 1, "aids": [1], "lim": 1}}'],
				'reply':['{"P": "g_ast", "Y": "r", "D": {"atrs": [account_transaction, account_transaction, account_transaction]}, "I": "1"}'],
				'params':{
					"mt":[`until_time`, ``],
					"aids":[`vector_int`, `Account ids list or null, null means "no filter"`],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"atrs":[`account_transactions`, ``],
				}
			},{
				'description':`Request for user's transfers history`,
				'name':'Get transfers history',
				'id':'Get_transfers_history',
				"request":['{"P": "g_tfrs", "D": {"mt": 1, "cids":["BTC"], "lim": 1}}'],
				'reply':['{"P": "g_tfrs", "Y": "r", "D": {"tfrs": [transfer, transfer, transfer]}, "I": "1"}'],
				'params':{
					"mt":[`until_time`, ``],
					"cids":[`vector`, `Currencies list or null, null means "no filter"`],
					"lim":[`int_4`, `Limit. Range: [1,1000]`],
					"tfrs":[`transfers`, ``],
				}
			},{
				'description':'Find transfer by Exteranl ID',
				'name':'Get transfer',
				'id':'Get_transfer',
				"request":['{"P": "g_etfr", "D": {"cid":"BTC", "eid":"40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1"}, "I": "1"}'],
				'reply':['{"P": "g_etfr", "Y": "r", "D": {"tfr": transfer}, "I": "1"}'],
				'params':{
					"cid":[`string_N`, "N = 32. Currency ID"],
					"eid":["uuid", `External ID`],
					"tfr":[`transfer`, ``],
				}
			},]},/*{
			'name':'?Statistics',
			'chapters':[{
				'description':`Get trading volume for last day, week, month, year, total, daily. Open, close, max, min.`,
				'name':'-Get trading volume',
				"request":['{"P": "g_idx"}'],
				
				'reply':['{"P": "g_idx", "Y": "r", "D": {"idxs": [idx, idx, idx]}, "I":  "1"}'],
				'params':{


					"idxs":[`idxs`, ``],
				}
			},
		]}*/,{
			'name':'Fees',
			'id':'Fees',
			'chapters':[{
				'description':`Get user's tariff plan and tariff plans for managed accounts`,
				'name':'Get tariff plans',
				'id':'Get_tariff_plans',
				"request":['{"P": "g_trf"}'],
				//
				'reply':['{"P": "g_trf", "Y": "r", "D": {"trfs": [tariff, tariff, tariff]}, "I": "1"}'],
				'params':{
					"trfs":[`tariffs`, ``],
				}
			},{
				'description':`Request for user's last commission and/or referral payments`,
				'name':'Get last commission and/or referral payments',
				'id':'Get_last_commission_and_or_referral_payments',
				"request":['{"P": "g_cmss", "D": {"aid": [1], "cs":["H21", "H22", "H23", "H24"]}}'],
				
				'reply':['{"P": "g_cmss", "Y": "r", "D": {"cmss": {"H21":[commission], "H22":[commission, commission], "H23":[commission], "H24":[commission, commission]}}, "I": "1"}'],
				'params':{
					"aid":['int_8', `Account id`],
					"cs":[`vector`, `[[String_codes]] list or null, null means "no filter". Available codes: '[[H21]]', '[[H22]]', '[[H23]]', '[[H24]]'.`],


					"cmss":[`commissions`, ``],
				}
			},
			]},{			
			'description': ``,
			'name':'Referral program',
			'id':'Referral_program',
			'chapters':[{
				'description':`Get referral link<br/>
			<ul>
			<li>In order to get referral payments history, please use [[Get_user_account_history]] request with '[[H24]]' string code
			<li>In order to see last referral payments please use [[Get_last_commission_and_or_referral_payments]] request with '[[H24]]' string code
			</ul>`,
				'name': 'Get referral link',
				'id':'Get_referral_link',
				"request":['{"P": "g_rfr", "I": "1"}'],
				//
				'reply':['{"P": "g_rfr", "Y": "r", "D": {"rfl": "https://..."}, "I": "1"}'],
				'params':{
					"rfl":[`string`, `Referral link`],
				}
			},]},{
			'name':'API keys',
			'id':'API_keys',
			'chapters':[{

				'description':'Get list of API keys',
				'name':'Get API keys list',
				'id':'Get_API_keys_list',
				"request":['{"P":  "g_key", "I": "1"}'],
				
				'reply':['{"P":  "g_key", "Y": "r", "D": {"keys": [key, key, key]}, "I": "1"}'],
				'params':{


					"keys":[`keys`, ``],
				}
			},{
				'description':'API key creation',
				'name':'Create new API key',
				'id':'Create_new_API_key',
				"request":['{"P":  "c_key", "D": {"pt":true, "pm":true, "pw":true, "ll":"", "2fa":123456}, "I": "1"}'],
				
				'reply':['{"P":  "c_key", "Y": "r", "D": {"key": key}, "I": "1"}'],
				'params':{
					"pt":['bool', `Key permission: can trade`],
					"pm":['bool', `Key permission: can manage (accounts create/remove/transfer)`],
					"pw":['bool', `Key permission: can withdraw`],
					"ll": [`string_N`, `N = 32. Key label, can be omitted`],
					"2fa":[`int_4`, `2FA 6-digit key`],


					"key":[`key`, ``],
				}
			},{
				'description':'API key deletion',
				'name':'Remove API key',
				'id':'Remove_API_key',
				"request":['{"P":  "d_key", "D": {"kid": "key_id"}, "I": "1"}'],
				
				'reply':['{"P":  "d_key", "Y": "r", "D": {"key": key}, "I": "1"}'],
				'params':{
					"kid": [`string_N`, `Set by the server. N = 36. Key id, used to identify the key`],


					"key":[`key`, ``],
				}
			}]},{
			'name':'Settings',
			'id':'Settings',
			'chapters':[{
				'description':'Get settings from storage by key.',
				'name':'Get settings',
				'id':'Get_settings',
				"request":['{"P": "g_str", "D": {"key": "K"}, "I": "1"}'],
				
				'reply':['{"P": "g_str", "Y": "r", "D": {"key": "K", "val": "V"}, "I": "1"}'],
				'params':{
					"key":['string', `Settings key, should be "auth" or "set"`],


					"val":['string', `Settings value string`],
				}
			},{
				'description':'Set settings in storage by key.',
				'name':'Set settings',
				'id':'Set_settings',
				"request":['{"P": "s_str", "D": {"key": "K", "val": "V"}, "I": "1"}'],
				
				'reply':['{"P": "s_str", "Y": "r", "D": {"key": "K"}, "I": "1"}'],
				'params':{
					"key":['string', `Settings key, should be "auth", "set", "layout1", "layout2" or "layout3". Updates on "auth" and "set" will be sent to all user's sessions`],


					"val":['string', `Settings value string. Max allowed length for "auth" and "set" is 2048, for "layout1", "layout2", "layout3" is 16000`],
				}
			},/*{
				'description':`<br>Notifications on:<br><br>
				-Margin call email<br>
				-Login email (alwais on)<br>
				-On-page trade Notifications<br>
				-sounds on login from an unknnown device<br>
				-sounds on margin call<br>
				-maybe to telegram
				`,
				'name':'-Notifications',
				"request":['{"P": "s_2fa", "I": "1"}'],
				'reply':['{"P": "s_2fa", "Y": "r", "D": {"kid": "key_id"}, "I": "1"}'],
				'params':{


					"kid":[`string_N`, `N=32. 2FA key internal ID`],
				}
			},*/]},{
			'name':'Security',
			'id':'Security',
			'chapters':[{
					'name':'2FA',
					'id':'2FA_chapter',
					'chapters':[
			{				
				'description':'Check if 2FA is enabled',
				'name':'Check if 2FA is enabled',
				'id':'Check_if_2FA_is_enabled',
				"request":['{"P": "g_2fa", "I": "1"}'],
				'reply':['{"P": "g_2fa", "Y": "r", "D": {"e": true}, "I": "1"}'],
				'params':{
					"e":[`bool`, `Enabled or not`],
				}
			},{
				'description':'Enable 2FA',
				'name':'Enable 2FA',
				'id':'Enable_2FA',
				"request":['{"P": "e_2fa", "D":{"2fa":123456}, "I": "1"}'],
				'reply':['{"P": "e_2fa", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
					"2fa":[`int_4`, `2FA 6-digit key`],
				}
			},{
				'description':'Disable 2FA',
				'name':'Disable 2FA',
				'id':'Disable_2FA',
				"request":['{"P": "d_2fa", "D":{"2fa":123456}, "I": "1"}'],
				'reply':['{"P": "d_2fa", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
					"2fa":[`int_4`, `2FA 6-digit key`],
				}
			},{
				'description':'Generate new 2FA secret key',
				'name':'Generate 2FA secret key',
				'id':'Generate_2FA_secret_key',
				"request":['{"P": "n_2fa", "I": "1"}'],
				'reply':['{"P": "n_2fa", "Y": "r", "D": {"2fa":{"secret": "secret", "url":"URL"}}, "I": "1"}'],
				'params':{
					"secret":[`string_N`, `N=32. 2FA secret key`],
					"url":[`string`, `OTP url`],
				}
			},{
				'description':'User will immidiately recieve email with notification, that 2FA reset has been requested. In 3 days user will recieve email with 2FA reset link.',
				'name':'Request 2FA reset',
				'id':'Request_2FA_reset',
				"request":['{"P": "rr_2fa", "D":{}, "I": "1"}'],
				'reply':['{"P": "rr_2fa", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
				}
			}]},{
			'name':'Device confirmation',
			'id':'Device_confirmation',
			'chapters':[{
				'description':'',
				'name':'Check if device confirmation is enabled',
				'id':'Check_device_confirmation_state',
				"request":['{"P": "g_dcf", "I": "1"}'],
				'reply':['{"P": "g_dcf", "Y": "r", "D": {"e": true}, "I": "1"}'],
				'params':{
					"e":[`bool`, `Enabled or not`],
				}
			},{
				'description':'',
				'name':'Enable device confirmation',
				'id':'Enable_device_confirmation',
				"request":['{"P": "e_dcf", "D":{}, "I": "1"}'],
				'reply':['{"P": "e_dcf", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
				}
			},{
				'description':'',
				'name':'Disable device confirmation',
				'id':'Disable_device_confirmation',
				"request":['{"P": "d_dcf", "D":{}, "I": "1"}'],
				'reply':['{"P": "d_dcf", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
				}
			},{
				'description':'',
				'name':'-Resend device confirmation email',
				'id':'Resend_device_confirmation_email',
				"request":['{"P": "cnf_dev", "D":{}, "I": "1"}'],
				'reply':['{"P": "cnf_dev", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
				}
			},{
				'description':'',
				'name':`Request for user's devices list`,
				'id':'Request_user_devices',
				"request":['{"P": "g_dev", "D":{}, "I": "1"}'],
				'reply':['{"P": "g_dev", "Y": "r", "D": {devs:[device, device, device]}, "I": "1"}'],
				'params':{
					"devs":[`devices`, ``],
				}
			},{
				'description':'',
				'name':'Device logout',
				'id':'Device_logout',
				"request":['{"P": "lgt_dev", "D":{"vid": "123"}, "I": "1"}'],
				'reply':['{"P": "lgt_dev", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
					"vid":[`string`, `Device id`],
				}
			},{
				'description':'',
				'name':'Device remove',
				'id':'Device_remove',
				"request":['{"P": "r_dev", "D":{"vid": "123"}, "I": "1"}'],
				'reply':['{"P": "r_dev", "Y": "r", "D": {}, "I": "1"}'],
				'params':{
					"vid":[`string`, `Device id`],
				}
			}]}/*,{
				'description':'',
				'name':'-Enable safe session duration',
				"request":['{"P": "lang", "D": {"lang":"en"}, "I": "1"}'],
				
				'reply':['{"P": "lang", "Y": "r", "D": {"lang": "en"}, "I": "1"}'],
				'params':{
					"lang":['str', `Language`],
				}
			}*/]},
		]
	},{
			'name':"Examples (WebSocket)",
			'id':"Examples",
			'chapters':[{
				'name':'New order examples',
				'id':'New_order_examples',
				'description':`<br>
See [[order]] for fields description.
<ul>
	<li><b>Limit buy GTC</b> order on instrument 1 from account 1 with price 5000 and quantity 0.001: {"P": "n_or", "D": {"aid": 1, "sid": 1, "p": 500000000000, "q": 100000, "d": 1, "eid": "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1", "oy": 0, "ay": 0, "tif": 0}}
	<li><b>Limit buy SL GTC</b> order on instrument 1 from account 1 with price 5000, quantity 0.001 and activation price 5000: {"P": "n_or", "D": {"aid": 1, "sid": 1, "p": 500000000000, "q": 100000, "d": 1, "eid": "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1", "oy": 0, "ay": 1, "tif": 0, "ap": 500000000000}}
	<li><b>Limit buy GTC iceberg</b> order on instrument 1 from account 1 with price 5000, quantity 0.01 and visible quantity 0.001: {"P": "n_or", "D": {"aid": 1, "sid": 1, "p": 500000000000, "q": 1000000, "vq": 100000, "d": 1, "eid": "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1", "oy": 0, "ay": 0, "tif": 0}}
	<li><b>Market sell GTC</b> order on instrument 1 from account 1 with quantity 0.001: {"P": "n_or", "D": {"aid": 1, "sid": 1, "q": 100000, "d": 2, "eid": "40b3f6be-7ce9-46eb-b623-bc6f6a9aeec1", "oy": 1, "ay": 0}}
</ul>
				`,
			},{
				'name':'Getting history data',
				'id':'Getting_history_data',
				'description':`<br>
			Example of how to get history data after recieving automatic snapshot on private trades:<br>
	<br>Server sends automatic trades snapshot upon connection:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "tr", "Y": "r", "D": {"mt": 1603184758966246912, "trs": [...]}}
	<br>Client takes "mt" [[snapshot_time]] and uses it as [[until_time]] to request 100 history rows:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "D": {"sids": [1], "mt": 1603184758966246912, "lim": 100}, "I": "1"}
	<br>Server:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "Y": "r", "D": {"trs": [...]}, "I": "1"}
	<br>Client takes create_time "ct" from oldest trade and substructs 1 from it. Then uses it as [[until_time]] for next history query:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "D": {"sids": [1], "mt": "ct" - 1, "lim": 100}, "I": "2"}
	<br>...
	`, 
			},{
				'name':'Subscribtion on updates + history',
				'id':'Subscribtion_on_updates_+_history',
				'description':`<br>
			Example subscribtion on public trades updates + history:<br>
	<br>Client subscribes on updates:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "s_tr", "D": {"sids": [1]}, "I": "1"}
	<br>Server:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "s_tr", "Y": "r", "D": {"mt": 1603184758966246912, "trs": [...]}, "I": "1"}
	<br>Client takes "mt" [[snapshot_time]] and uses it as [[until_time]] to request 100 history rows:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "D": {"sids": [1], "mt": 1603184758966246912, "lim": 100}, "I": "2"}
	<br>Server:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "Y": "r", "D": {"trs": [...]}, "I": "2"}
	<br>Client takes create_time "ct" from oldest trade and substructs 1 from it. Then uses it as [[until_time]] for next history query:
	<br>&nbsp&nbsp&nbsp&nbsp{"P": "g_tr", "D": {"sids": [1], "mt": "ct" - 1, "lim": 100}, "I": "3"}
	<br>...
	`, 
			},{
				'name':'Decompression examples',
				'id':'Decompression_examples',
				'chapters':[{
				'name':'Javascript inflate example',
				'id':'Javascript_inflate_example',
				'description':`Download <a href="https://viking.trade/quickstart/js/quickstart.html">quickstart.html</a> and run it in your browser. It contains example connection to WebSocket public server and decompression <a href="https://github.com/nodeca/pako">pako library</a> code. We use a bit customized pako version with stream support added`,
				/*'description':`<br>Javascript inflate example using <a href="https://github.com/nodeca/pako">pako</a> library. Pako version 1.0.11 should be used, because it supports streaming inflate (Z_SYNC_FLUSH mode):<br><br>
<code>
import pako from 'pako';<br/><br/>

static convert_nsec_to_string(str) {//for bigint to be readable<br/>
&nbsp;&nbsp;return str.replace(/"(at|dt|mt|ct|ut|bt|et|t)":s*(d+)/g,'"$1":"$2"'); <br/>
}<br/><br/>

var inflate;<br/>
var msg_queue;<br/><br/>

websocket.onconnect() {<br/>
&nbsp;&nbsp;inflate = new pako.Inflate({ to: 'string', windowBits: -15 });//new inflate stream for each ws session<br/>
&nbsp;&nbsp;msg_queue = [];//for async FileReader not to mess with messages order<br/>
}<br/><br/>

websocket.onmessage(msg) {<br/>
&nbsp;&nbsp;if (msg.data instanceof Blob) {// Recieved binary data - should decompress<br/>
&nbsp;&nbsp;&nbsp;&nbsp;if (msg.data.size === 0) return;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;let reader = new FileReader();<br/>
&nbsp;&nbsp;&nbsp;&nbsp;reader.ref = {arrayBuffer: null};<br/>
&nbsp;&nbsp;&nbsp;&nbsp;msg_queue.push(reader.ref);<br/>
&nbsp;&nbsp;&nbsp;&nbsp;reader.addEventListener("loadend", function(e) {<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;this.ref.arrayBuffer = e.target.result;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while(msg_queue.length > 0 && msg_queue[0].arrayBuffer != null) {<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inflate.push(self.msg_queue.shift().arrayBuffer, 2) //2 = Z_SYNC_FLUSH<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (inflate.err) { <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;console.error("inflate: " + inflate.err);<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (inflate.result) {<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;let parsed = JSON.parse(convert_nsec_to_string(inflate.result), function (key, value) {<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (value === 'NaN') return NaN;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (value === 'Infinity') return Infinity;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (value === '-Infinity') return -Infinity;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return value;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;})<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;YOUR_MESSAGE_HANDLER(parsed);<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br/>
&nbsp;&nbsp;&nbsp;&nbsp;});<br/>
&nbsp;&nbsp;&nbsp;&nbsp;reader.readAsArrayBuffer(new Blob([msg.data, new Uint8Array(('0,0,255,255').split(','))]));<br/>
&nbsp;&nbsp;&nbsp;&nbsp;return;<br/>
&nbsp;&nbsp;}<br/>
&nbsp;&nbsp;//not compressed message<br/>
&nbsp;&nbsp;let parsed = JSON.parse(convert_nsec_to_string(msg.data), function (key, value) {<br/>
&nbsp;&nbsp;&nbsp;&nbsp;if (value === 'NaN') return NaN;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;if (value === 'Infinity') return Infinity;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;if (value === '-Infinity') return -Infinity;<br/>
&nbsp;&nbsp;&nbsp;&nbsp;return value;<br/>
&nbsp;&nbsp;})<br/>
&nbsp;&nbsp;YOUR_MESSAGE_HANDLER(parsed);<br/>
}<br/>
<br/>
</code>
				`,*/
			},{
				'name':'Python inflate example',
				'id':'Python_inflate_example',
				'description':`<br>Python inflate example:<br><br>
<code>
#!/bin/env python3<br>
<br>
import asyncio<br>
import aiohttp<br>
import json<br>
import zlib<br>
<br>
async def client():<br>
&nbsp;&nbsp;async with aiohttp.ClientSession() as session:<br>
&nbsp;&nbsp;&nbsp;&nbsp;decompress = zlib.decompressobj(wbits=-15)<br>
&nbsp;&nbsp;&nbsp;&nbsp;async with session.ws_connect('wss://api.viking.trade/public') as ws:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;await ws.send_json({'P':'g_sym'})<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;async for msg in ws:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if msg.type == aiohttp.WSMsgType.TEXT:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data = json.loads(msg.data)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('Recv text msg:', data)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elif msg.type == aiohttp.WSMsgType.BINARY:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data = json.loads(decompress.decompress(msg.data + bytes([0x00, 0x00, 0xFF, 0xFF])))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('Recv binary msg:', data)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elif msg.type == aiohttp.WSMsgType.ERROR:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break<br>
<br>
loop = asyncio.get_event_loop()<br>
loop.run_until_complete(client())<br>
</code>
				`,
			},{
				'name':'C++ inflate example',
				'id':'C++_inflate_example',
				'description':`<br>Inflate example using C++. In order for it to compile, you should have <a href="https://zlib.net/">zlib</a> development package installed:<ul>
				<li><b>Fedora/Centos</b> sudo dnf install zlib-devel
				<li><b>Ubuntu/Debian</b> sudo apt install zlib1g-dev
				</ul><br><br>
<code>
#include &lt;zlib.h&gt;<br>
<br>
class viking<br>
{<br>
public:<br>
&nbsp;&nbsp;viking();<br>
&nbsp;&nbsp;virtual ~viking();<br>
<br>
&nbsp;&nbsp;void on_msg_binary(const vector&lt;uint8_t&gt; &msg);<br>
<br>
private:<br>
&nbsp;&nbsp;z_stream zs;<br>
}<br>
<br>
<br>
viking::viking()<br>
{<br>
&nbsp;&nbsp;memset(&zs, 0, sizeof(zs));<br>
&nbsp;&nbsp;if (inflateInit2(&zs, -MAX_WBITS) != Z_OK)<br>
&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;printf("error");<br>
&nbsp;&nbsp;}<br>
}<br>
<br>
viking::~viking()<br>
{<br>
&nbsp;&nbsp;inflateEnd(&zs);<br>
}<br>
<br>
void viking::on_msg_binary(const vector&lt;uint8_t&gt; &msg)<br>
{<br>
&nbsp;&nbsp;int l = msg.size();<br>
&nbsp;&nbsp;char * tmp = new char [l + 4];<br>
&nbsp;&nbsp;char end[4] = {(char)0, (char)0, (char)255, (char)255};<br>
&nbsp;&nbsp;memcpy(tmp, &msg[0], l);<br>
&nbsp;&nbsp;memcpy(tmp + l, end, 4);<br>
<br>
&nbsp;&nbsp;zs.next_in = (Bytef*)(&tmp[0]);<br>
&nbsp;&nbsp;zs.avail_in = l + 4;<br>
&nbsp;&nbsp;int ret;<br>
&nbsp;&nbsp;char outbuffer[32768];<br>
&nbsp;&nbsp;std::string outstring;<br>
&nbsp;&nbsp;int old_out = zs.total_out;<br>
&nbsp;&nbsp;do<br>
&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;zs.next_out = reinterpret_cast&lt;Bytef*&gt;(outbuffer);<br>
&nbsp;&nbsp;&nbsp;&nbsp;zs.avail_out = sizeof(outbuffer);<br>
<br>
&nbsp;&nbsp;&nbsp;&nbsp;ret = inflate(&zs, Z_SYNC_FLUSH);<br>
<br>
&nbsp;&nbsp;&nbsp;&nbsp;if (old_out + outstring.size() < zs.total_out)<br>
&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;outstring.append(outbuffer, zs.total_out - old_out - outstring.size());<br>
&nbsp;&nbsp;&nbsp;&nbsp;}<br>
<br>
&nbsp;&nbsp;}<br>
&nbsp;&nbsp;while (ret == Z_OK && zs.avail_out == 0);<br>
&nbsp;&nbsp;delete[] tmp;<br>
&nbsp;&nbsp;YOUR_MESSAGE_HANDLER(outstring);<br>
}<br>
</code>
				`,
			},{
			'name':`.Net C# inflate example`,
			'id':`Inflate_C#`,
			'description':`<br>
				Download <a href="https://viking.trade/quickstart/dotnet/quickstart.zip">quickstart.zip</a> project. 
					It contains example connection to WebSocket public server.
				The project has one dependancy package: <a href="https://www.nuget.org/packages/Websocket.Client">Websocket.Client</a>
			`,
		},],
			},{
				'name':'Authentication examples',
				'id':'Authentication_examples',
				'chapters':[{
				'name':'Javascript authentication example',
				'id':'Javascript_authentication_example',
				'description':`<br>API key authentication example using javascript <a href="https://github.com/indutny/elliptic">elliptic</a> library:<br><br>

<code>
import elliptic from 'elliptic';<br><br>

function buf2hex(buffer) { // buffer is an ArrayBuffer<br>
&nbsp;&nbsp;return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');<br>
};<br><br>

async function authenticate() {<br>
&nbsp;&nbsp;let kid = YOUR_API_KEY_ID_STRING;<br>
&nbsp;&nbsp;let ec = new elliptic.ec('secp256k1');<br>
&nbsp;&nbsp;let k = ec.keyFromPrivate('YOUR_API_KEY_SECRET_64_SYMBOLS_STRING');<br>
&nbsp;&nbsp;let ts = EXCHANGE_TIME_IN_SECONDS_FROM_PUBLIC_WS;<br>
&nbsp;&nbsp;let h  = buf2hex(await crypto.subtle.digest('SHA-256', new TextEncoder().encode(kid + ' ' + ts.toString())));<br>
&nbsp;&nbsp;let s = k.sign(h);<br>
&nbsp;&nbsp;let sig = s.r.toString(16, 64) + s.s.toString(16, 64);<br>
&nbsp;&nbsp;websocket.send({"P": "auth_key", "D": {"kid": kid, "ts": ts, "sig": sig}});<br>
}<br>
</code>
				`,
			},{
				'name':'Python authentication example',
				'id':'Python_authentication_example',
				'description':`<br>API key authentication example using python:<br><br>
<code>
#!/bin/env python3<br>
<br>
import asyncio<br>
import aiohttp<br>
import json<br>
import zlib<br>
import binascii<br>
import time<br>
import hashlib<br>
from ecdsa import SigningKey, SECP256k1<br>
<br>
async def client():<br>
&nbsp;&nbsp;async with aiohttp.ClientSession() as session:<br>
&nbsp;&nbsp;&nbsp;&nbsp;decompress = zlib.decompressobj(wbits=-15)<br>
&nbsp;&nbsp;&nbsp;&nbsp;async with session.ws_connect('wss://art.viking.trade/private') as ws:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;kid = 'key-id'# your key ID<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;secret = b'E06CC2494EE45335AD9FA368B729141194E234FA341AAC5E4E7BCA953E8A36FF'# your key secret<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t = int(time.time())# or exchange time from public WS<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sk = SigningKey.from_string(binascii.unhexlify(secret), curve=SECP256k1)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sig = binascii.hexlify(sk.sign((kid + ' ' + str(t)).encode('utf-8'), hashfunc=hashlib.sha256)).decode('utf-8')<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;await ws.send_json({'P':'auth_key', 'D':{'kid':kid, 'ts':t, 'sig':sig}})<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;authorized = False<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;async for msg in ws:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if msg.type == aiohttp.WSMsgType.BINARY:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data = json.loads(decompress.decompress(msg.data + bytes([0x00, 0x00, 0xFF, 0xFF])))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('Recv msg:', data)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if not authorized and data.get('P') == 'auth' and data.get('Y') == 'r':<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;authorized = True<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print('OK')<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;elif msg.type == aiohttp.WSMsgType.ERROR:<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;break<br>
<br>
loop = asyncio.get_event_loop()<br>
loop.run_until_complete(client())<br>
</code>
				`,
			},{
				'name':'C++ authentication example',
				'id':'C++_authentication_example',
				'description':`<br>API key authentication example using C++. In order for it to compile, you should have openssl development package installed:<ul>
				<li><b>Fedora/Centos</b> sudo dnf install openssl-devel
				<li><b>Ubuntu/Debian</b> sudo apt install libssl-dev
				</ul><br><br>
<code>
#include &lt;openssl/buffer.h&gt;<br>
#include &lt;openssl/sha.h&gt;<br>
#include &lt;openssl/hmac.h&gt;<br>
#include &lt;openssl/bio.h&gt;<br>
#include &lt;openssl/md5.h&gt;<br>
#include &lt;openssl/ec.h&gt;<br>
#include &lt;openssl/ecdsa.h&gt;<br>
#include &lt;sstream&gt;<br>
<br>
std::vector&lt;unsigned char&gt; sha256(const std::string& data)<br>
{<br>
&nbsp;&nbsp;std::vector&lt;unsigned char&gt; digest(SHA256_DIGEST_LENGTH);<br>
<br>
&nbsp;&nbsp;SHA256_CTX ctx;<br>
&nbsp;&nbsp;SHA256_Init(&ctx);<br>
&nbsp;&nbsp;SHA256_Update(&ctx, data.c_str(), data.length());<br>
&nbsp;&nbsp;SHA256_Final(digest.data(), &ctx);<br>
<br>
&nbsp;&nbsp;return digest;<br>
}<br>
<br>
std::pair&lt;std::string, std::string&gt; ecdsa_hex(const std::string& secret, const std::vector&lt;unsigned char&gt;& data)<br>
{<br>
&nbsp;&nbsp;std::pair&lt;std::string, std::string&gt; res;<br>
<br>
&nbsp;&nbsp;EC_KEY* key = EC_KEY_new_by_curve_name(NID_secp256k1);<br>
&nbsp;&nbsp;if (key != NULL)<br>
&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;BIGNUM *priv = NULL;<br>
&nbsp;&nbsp;&nbsp;&nbsp;if (BN_hex2bn(&priv, secret.c_str()))<br>
&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (EC_KEY_set_private_key(key, priv))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EC_POINT* pub = NULL;<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;const EC_GROUP* group = EC_KEY_get0_group(key);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;pub = EC_POINT_new(group);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (EC_POINT_mul(group, pub, priv, NULL, NULL, NULL))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (EC_KEY_set_public_key(key, pub))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (EC_KEY_check_key(key))<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ECDSA_SIG* sig = ECDSA_do_sign(data.data(), data.size(), key);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (sig != NULL)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;char* r = BN_bn2hex(sig-&gt;r);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.first = r;<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OPENSSL_free(r);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while (res.first.size() % 64 != 0)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.first = "0" + res.first;<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;char* s = BN_bn2hex(sig-&gt;s);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.second = s;<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OPENSSL_free(s);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while (res.second.size() % 64 != 0)<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;res.second = "0" + res.second;<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ECDSA_SIG_free(sig);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EC_POINT_free(pub);<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BN_free(priv);<br>
&nbsp;&nbsp;&nbsp;&nbsp;}<br>
&nbsp;&nbsp;&nbsp;&nbsp;EC_KEY_free(key);<br>
&nbsp;&nbsp;}<br>
<br>
&nbsp;&nbsp;return res;<br>
}<br>
<br>
void send_auth(const std::string& api_key, const std::string& secret_key)<br>
{<br>
&nbsp;&nbsp;std::stringstream to_str;<br>
<br>
&nbsp;&nbsp;struct timespec _ts;<br>
&nbsp;&nbsp;if (clock_gettime(CLOCK_REALTIME, &_ts) == -1)<br>
&nbsp;&nbsp;{<br>
&nbsp;&nbsp;&nbsp;&nbsp;perror ("clock gettime");<br>
&nbsp;&nbsp;}<br>
<br>
&nbsp;&nbsp;long long ts = _ts.tv_sec;<br>
&nbsp;&nbsp;to_str << api_key << " " << ts;<br>
&nbsp;&nbsp;std::string to_sig = to_str.c_str();<br>
&nbsp;&nbsp;std::pair&lt;std::string, std::string&gt; sig = ecdsa_hex(secret_key, sha256(to_sig));<br>
&nbsp;&nbsp;to_str.str("");<br>
&nbsp;&nbsp;to_str << "{\\"P":\\"auth_key\\",\\"D\\":{\\"kid\\":\\"" << api_key << "\\",\\"ts\\":" << ts << ",\\"sig\\":\\""<< sig.first << sig.second << "\\"}}";<br>
&nbsp;&nbsp;WEB_SOCKET.send(to_str.str());// WebSocket send auth message<br>
}<br>
</code>
				`,
			},{
			'name':`.Net C# authentication example`,
			'id':`C#_authentication_example`,
			'description':`<br>
				Download <a href="https://viking.trade/quickstart/dotnet/private.zip">private.zip</a> project. 
					It contains example connection to WebSocket private server.
				The project has three dependancy packages, that you should install in order for it to work: <a href="https://www.nuget.org/packages/Websocket.Client">Websocket.Client</a>, <a href="https://www.nuget.org/packages/Json.Net">Json.Net</a>, <a href="https://www.nuget.org/packages/Cryptography.ECDSA.Secp256k1">Cryptography.ECDSA.Secp256k1</a>
			`,
		}],
		},],			
	},]
}
