/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { withSize } from "react-sizeme";

import {strings} from '../localization/strings';
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import Symbols from "../ws-pub/symbols";
import Subscribtion from "../utils/subscribtion";
import TableX from "../utils/table-x";
import {
  dec, decS,
  ins_status, ins_statusS,
  ins_expiry_type, ins_expiry_typeS,
  dt_s_m, dt_s_mS,
} from "../utils/cell-formatters";
import {withFilter, copyObject} from '../utils/utils'

import '../styles/css/instruments.css';

import { isMobile } from "react-device-detect";

import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

export default class Instruments {
  static getListenSids(prefix) {
    let listeners = this.listeners[prefix];
    let sids = [];
    if (listeners['_all_'].length > 0) sids = null;
    else {
      _.each(listeners, (listeners_, sid_)=>{
        if (listeners_.length > 0 && sid_ !== '_all_') sids.push(parseInt(sid_));
      });        
    }
    return sids;
  }

  static addListener(Fs, sids) {
    _.each(Fs, (f, prefix)=>{
      if (f) this.addListenerInner(prefix, f, sids);
    });
    let fs = [];
    _.each(Fs, (f) => {if (fs.indexOf(f) === -1) fs.push(f);});
    fs.forEach((f) => {if (f) f(this.arr)}); //snapshot
  }

  static removeListener(Fs, sids) {
    _.each(Fs, (f, prefix)=>{
      if (f) this.removeListenerInner(prefix, f, sids);
    });
  }

  //prefix: "be", "st", "set", "sta"
  static addListenerInner(prefix, f, sids) {
    //console.log("addListener", prefix, sids);
    let listeners = this.listeners[prefix];
    let listenersFs = this.listenersFs[prefix];
    listenersFs.push(f);
    let sids_listening = this.getListenSids(prefix);
    let sids_to_listen = [];
    if (sids_listening !== null) {//null means _all_
      if (sids === '_all_') sids_to_listen = null;
      else {
        sids_to_listen = _.cloneDeep(sids);
        sids.forEach((sid)=>{if (sids_listening.indexOf(sid) !== -1) sids_to_listen = _.reject(sids_to_listen, (sid_) => sid_ === sid);});
      }
    } 
    //console.log("sids_to_listen", sids_to_listen);
    if (sids_to_listen === null || sids_to_listen.length > 0) this.subscribe(prefix, sids_to_listen);
    if (sids === '_all_') sids = [sids];
    sids.forEach((sid)=>{
      if (!listeners.hasOwnProperty(sid)) listeners[sid] = [];
      listeners[sid].push(f);
    });
  }

  static removeListenerInner(prefix, f, sids) {
    //console.log("removeListener ", prefix, sids);
    let listeners = this.listeners[prefix];
    let listenersFs = this.listenersFs[prefix];
    let subscribtion = this.subscribtion[prefix];
    if (sids === '_all_') sids = [sids];
    sids.forEach((sid)=>{
      listeners[sid] = _.reject(listeners[sid], (l) => l === f);
    });
    this.listenersFs[prefix] = _.reject(listenersFs, (l) => l === f);
    let old = _.cloneDeep(subscribtion.smsg["D"].sids);
    if (old == null) old = Symbols.active.map((sym)=>sym.sid);
    sids = this.getListenSids(prefix);
    if (sids != null) {
      if (sids.length === 0) {//unsub from all
        subscribtion.unsubscribe();
        this.subscribtion[prefix] = null;
      } else {
        sids.forEach((sid)=>{old = _.reject(old, (l) => l === sid)});
        if (wspub.connected && old.length > 0) wspub.send({ P: "us_" + prefix, D: {"sids": old}});
        subscribtion.smsg["D"].sids = sids;
      }
    }
    //console.log(this.listeners);
  }

  static request() {
    this.got_definitions = false;
    wspub.send({ P: "g_ins" }); //request instruments
  }

  static subscribe(prefix, sids) {
    let self = this;
    let subscribtion = this.subscribtion[prefix];
    if (sids === null || sids.length > 0) {
      if (subscribtion) {
        if (sids === null) subscribtion.smsg["D"].sids = null;
        else subscribtion.smsg["D"].sids = subscribtion.smsg["D"].sids.concat(sids);
        wspub.send({ P: "s_" + prefix, D: {"sids": sids}, "I": subscribtion.smsg["I"]});
      } else {
        this.subscribtion[prefix] = new Subscribtion({//subscribe on updates
          ws: wspub,
          smsg: { P: "s_" + prefix, D: {"sids": sids}},
          eternal: true,
          onResult: function (msg) {self.parseUpdate(prefix, msg)},
        });
      }
    }  
    //console.log(prefix, this.subscribtion);  
  }

  static parse(msg) {
    let secs = [];
    if (msg["D"].hasOwnProperty("inss")) {
      Instruments.arr = [];
      Instruments.all = {};
      secs = msg["D"]["inss"];
    }
    if (msg["D"].hasOwnProperty("ins")) secs.push(msg["D"]["ins"]);
    if (secs.length > 0) {
      let secsToAdd = [];
      let secsToUpdate = [];
      let secsToDel = [];
      secs.forEach(function (sec) {
        sec.id = sec["sid"];
        if (!Instruments.hasOwnProperty(sec["sid"])) {
          if (sec["act"] !== "del") {
            secsToAdd.push(sec); //add instrument
          }
        } else {
          if (sec["act"] === "del") {
            secsToDel.push(sec["sid"]); //delete instrument
            delete Instruments.all[sec["sid"]];
          } else {
            secsToUpdate.push(sec); //update instrument
          }
        }
      });

      if (secsToAdd.length > 0) {//add instruments
        secs = secsToAdd;
        secs.forEach((sec)=>{
          let sid = sec["sid"];
          Instruments.all[sid] = _.cloneDeep(sec);
          Instruments.arr.push(Instruments.all[sid]);
        });
        Instruments.arr.sort((a,b)=>a.sid < b.sid ? 1 : -1);
        secs.sort((a,b)=>a.sid < b.sid ? 1 : -1);
      }
      if (secsToUpdate.length > 0) {//update instruments
        secs.forEach((sec)=>{
          let sid = sec["sid"];
          copyObject(Instruments.all[sid], sec);
        });
      }
      if (secsToDel.length > 0) {//del instruments
      }
      // notify all listeners
      let fs = [];
      _.each(this.listenersFs, (Fs)=>{Fs.forEach((f) => {if (fs.indexOf(f) === -1) fs.push(f);})});
      fs.forEach((f) => {f(secs);});
      if (!this.got_definitions) {
        this.got_definitions = true;
        this.listenersDefinitions.forEach((f) => {f();});
      }
    }
    let prefix = msg["P"].split('');
    prefix.splice(0, 2);
    prefix = prefix.join('');
    if (msg["Y"] === "u" &&  Instruments.listeners.hasOwnProperty(prefix)) {
      Instruments.parseUpdate(prefix, msg);
    }
  }

  static parseUpdate(prefix, msg) {
    let secs = [];
    if (msg["D"].hasOwnProperty(prefix)) secs.push(msg["D"][prefix]);
    else if (msg["D"].hasOwnProperty(prefix + "s")) secs = msg["D"][prefix + "s"];
    if (secs.length > 0) {
      secs.forEach(function (sec) {
        sec.id = sec["sid"];
      });
      Instruments.update(prefix, secs);
    }
  }

  static update(prefix, secs) {
    secs.forEach((sec)=>{
      let sid = sec["sid"];
      copyObject(Instruments.all[sid], sec);
    });
    this.listenersFs[prefix].forEach((f) => {f(secs);}); // update    
    if (prefix === "sta") Symbols.updateStatuses(secs);
  }

  static addListenerDefinitions(f) {
    this.listenersDefinitions.push(f);
  }
  static removeListenerDefinitions(f) {
    this.listenersDefinitions = _.reject(this.listenersDefinitions, (l) => l === f);
  }  
}

Instruments.listeners = {
  "be": { _all_: [] }, //top of book
  "set": { _all_: [] }, //settings
  "sta": { _all_: [] }, //stats
  "st": { _all_: [] }, //statuses
  "pr": { _all_: [] }, //prices
  "all": { _all_: [] }, //all
};
Instruments.listenersFs = {
  "be": [],
  "set": [],
  "sta": [],
  "st": [],
  "pr": [],
  "all": [],
}
Instruments.listenersDefinitions = [];
Instruments.subscribtion = {};
Instruments.arr = [];
Instruments.all = {};

Instruments.columns = [
  { title: "InsID", field: "sid" , minWidth: 50},
  { title: "Symbol", field: "sym" , minWidth: 110},
  { title: "Type", field: "mid" , minWidth: 50},
  { title: "Description", field: "desc" , minWidth: 100},
  { title: "InitialMargin", field: "im", formatter: dec , minWidth: 100},
  { title: "StepPrice", field: "sp", formatter: dec , minWidth: 100},
  { title: "LimDown", field: "ld", formatter: dec , minWidth: 100},
  { title: "LimUp", field: "lu", formatter: dec , minWidth: 100},
  { title: "Index", field: "ip", formatter: dec , minWidth: 100},
  { title: "Estimate", field: "ep", formatter: dec , minWidth: 100},
  { title: "BestBuy", field: "bb", formatter: dec , minWidth: 100},
  //{ title: "QuantityBuy", field: "ab", formatter: dec , minWidth: 100},
  { title: "BestSell", field: "bs", formatter: dec , minWidth: 100},
  //{ title: "QuantitySell", field: "as", formatter: dec , minWidth: 100},
  { title: "LastPrice", field: "lp", formatter: dec , minWidth: 100},
  { title: "LastQuantity", field: "la", formatter: dec , minWidth: 100},
  { title: "Status", field: "ss", formatter: ins_status , minWidth: 100},
  { title: "MinStep", field: "ms", formatter: dec , minWidth: 100},
  { title: "LotSize", field: "ls", formatter: dec , minWidth: 100},
  { title: "PriceDecimals", field: "pdec" , minWidth: 100},
  { title: "LotDecimals", field: "ldec" , minWidth: 100},
  { title: "FundingRate", field: "fr" , minWidth: 100},
  { title: "IcebergRatio", field: "ir" , minWidth: 100},
  { title: "ExpiryType", field: "ey", formatter: ins_expiry_type , minWidth: 100},
  { title: "Activation", field: "at", formatter: dt_s_m , minWidth: 100},
  { title: "Expiration", field: "et", formatter: dt_s_m , minWidth: 140},
  { title: "TradesQty", field: "tc" , minWidth: 100},
  { title: "TradesVol", field: "tv", formatter: dec , minWidth: 100},
  { title: "ActiveOrders", field: "aors" , minWidth: 100},
  { title: "OpenedInterest", field: "oi", formatter: dec , minWidth: 100},
];

Instruments.colsByTitle = {};
Object.keys(Instruments.columns).map((i)=>{
  let col = Instruments.columns[i];
  Instruments.colsByTitle[col.title] = Instruments.columns[i];
  return null;
});

Instruments.colsByField = {};
Object.keys(Instruments.columns).map((i)=>{
  let col = Instruments.columns[i];
  Instruments.colsByField[col.field] = Instruments.columns[i];
  return null;
});

export class InstrumentsListener extends React.Component {
  constructor(props) {
    super(props);
    this.sids = this.props.sids;
  }
  componentDidMount() {Instruments.addListener({
    //"be": this.props.onChangeBest, 
    //"set": this.props.onChangeSettings, 
    //"st": this.props.onChangeStatuses, 
    //"sta": this.props.onChangeStatistics, 
    "pr": this.props.onChangePrices, 
    "all": this.props.onChangeAll}, 
    this.sids
  );}
  componentWillUnmount() {Instruments.removeListener({
    //"be": this.props.onChangeBest, 
    //"set": this.props.onChangeSettings, 
    //"st": this.props.onChangeStatuses, 
    //"sta": this.props.onChangeStatistics, 
    "pr": this.props.onChangePrices, 
    "all": this.props.onChangeAll}, 
    this.sids
  );}
  render() {
    if (!_.isEqual(this.props.sids, this.sids)) {
      this.componentWillUnmount();
      this.sids = _.cloneDeep(this.sids);
      this.componentDidMount();
    }
    return null;
  }
}

function contract_sizeS(val, row) {
  if (row.ip && row.sp && row.ms) return strings.formatString(strings.ContractSizeStr, decS(parseInt(row.ip * row.sp / row.ms)), decS(parseInt(1e8 * row.sp / row.ms)))
  else return null;
}

class InstrumentInfo_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.forceUpdate();
  }

  render() {
    let sec = Instruments.all[this.props.filter.sid];
    return <div className="default-column">
        <InstrumentsListener
        sids={[this.props.filter.sid]}
        //onChangeBest={this.onChange}
        //onChangeSettings={this.onChange}
        //onChangeStatuses={this.onChange}
        //onChangeStatistics={this.onChange}
        onChangeAll={this.onChange}
        onChangePrices={this.onChange}
      />
      {[
        { title: "Status", field: "ss", formatter: ins_statusS },
        { title: "Symbol", field: "sym" }, 
        { title: "Type", field: "mid" }, 
        { title: "Description", field: "desc" },
        { title: "InitialMargin", field: "im", formatter: decS },
        { title: "ContractSize", field: "id", formatter: contract_sizeS },
        { title: "StepPrice", field: "sp", formatter: decS },
        { title: "MinStep", field: "ms", formatter: decS },
        { title: "LotSize", field: "ls", formatter: decS },
        { title: "PriceDecimals", field: "pdec" },
        { title: "LotDecimals", field: "ldec" },
        { title: "FundingRate", field: "fr" },
        { title: "IcebergRatio", field: "ir" },
        { title: "ExpiryType", field: "ey", formatter: ins_expiry_typeS },
        { title: "Activation", field: "at", formatter: dt_s_mS },
        { title: "Expiration", field: "et", formatter: dt_s_mS },
        { title: "TradesQty", field: "tc" },
        { title: "TradesVol", field: "tv", formatter: decS },
        { title: "ActiveOrders", field: "aors" },
        { title: "OpenedInterest", field: "oi", formatter: decS },
        { title: "Index", field: "ip", formatter: decS },
        { title: "Estimate", field: "ep", formatter: decS },
      ].map((col)=>
        <div className="default-info" key={col.field}>
          <div className="p4">{strings[col.title]}:</div>
          {sec.hasOwnProperty(col.field) && <div className="p4b">{col.formatter ? col.formatter(sec[col.field], sec) : sec[col.field]}</div>}
        </div>)}
    </div>
  }
}

export const InstrumentInfo = withInsDefinitions(InstrumentInfo_);


function InstrumentsList_(props) {
  const match = useRouteMatch();
  const history = useHistory();
  let path = history.location.pathname.split('/');
  let sym = path[path.length - 1];
  let ins = null;
  return <Switch>
    <Route strict path={`${match.path}/`}>
      <InstrumentSpecification sym={sym}/>
    </Route>
    <Redirect strict path={`${match.path}/`} to={match.path}/>
    <Route path={`${match.path}`}>
      <ul>{Instruments.arr.sort((a,b)=>a.sym > b.sym ? 1 : -1).map((ins)=><li key={ins.sym}><Link className="p3" to={"/user/contract_specs/" + ins.sym}>{ins.sym}</Link></li>)}</ul>
    </Route>
  </Switch>
}

export const InstrumentsList = withInsDefinitions(InstrumentsList_);

class InstrumentSpecification_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    Instruments.arr.some((i)=>{
      if (i.sym === props.sym) {
        this.sec = i;
        return true;
      } else return false;
    });    
  }

  onChange() {
    this.forceUpdate();
  }

  render() {
    let sec = this.sec;
    if (!sec) return <p>No such contract<br/></p>;
    return <div className="default-column">
      <InstrumentsListener
        sids={[sec.sid]}
        onChangeAll={this.onChange}
        onChangePrices={this.onChange}
      />
      <div><span className="p2">{strings.ContractSpecs + ":\u00A0\u00A0"}</span><span className="p2b">{sec.sym}</span></div>
      {[
        { title: "Status", field: "ss", formatter: ins_statusS },
        //{ title: "Symbol", field: "sym" }, 
        { title: "Type", field: "mid" }, 
        { title: "Description", field: "desc" },
        { title: "InitialMargin", field: "im", formatter: decS },
        { title: "ContractSize", field: "id", formatter: contract_sizeS },
        { title: "StepPrice", field: "sp", formatter: decS },
        { title: "MinStep", field: "ms", formatter: decS },
        { title: "LotSize", field: "ls", formatter: decS },
        { title: "PriceDecimals", field: "pdec" },
        { title: "LotDecimals", field: "ldec" },
        { title: "FundingRate", field: "fr" },
        { title: "IcebergRatio", field: "ir" },
        { title: "ExpiryType", field: "ey", formatter: ins_expiry_typeS },
        { title: "Activation", field: "at", formatter: dt_s_mS },
        { title: "Expiration", field: "et", formatter: dt_s_mS },
        { title: "TradesQty", field: "tc" },
        { title: "TradesVol", field: "tv", formatter: decS },
        { title: "ActiveOrders", field: "aors" },
        { title: "OpenedInterest", field: "oi", formatter: decS },
        { title: "Index", field: "ip", formatter: decS },
        { title: "Estimate", field: "ep", formatter: decS },
      ].map((col)=>
        <div className="default-info" key={col.field}>
          <div className="p4">{strings[col.title]}:</div>
          {sec.hasOwnProperty(col.field) && <div className="p4b">{col.formatter ? col.formatter(sec[col.field], sec) : sec[col.field]}</div>}
        </div>)}
      <br/>
    </div>
  }
}

export const InstrumentSpecification = withInsDefinitions(InstrumentSpecification_);

class InstrumentsTable_ extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.tabRef = React.createRef();
    this.filter = _.cloneDeep(this.props.filter);
  }

  componentDidMount() {
    let div = this.tabRef.current;
    div.id = "quotes_" + this.props.id;
    let cols = this.props.filter.cols;
    //console.log(cols);
    if (cols === '_all_') cols = Instruments.columns;
    else {
      cols = cols.map((title)=>_.find(Instruments.columns, { 'title': title }));
    }
    //console.log(cols);
    let self = this;
    this.tab = new TableX("#" + div.id, {
      columns: cols,
      rowClick: (e, row)=>{
        //console.log(row.getData());
        if (isMobile) self.history.push('/user/Trade#sid=' + row.getData().sid);
        else {
          this.props.onAddItem("layout_trade_menu", "Orderbook", {sid: row.getData().sid});
        }
      },
    });
    this.tab.setSort("sid", "asc");
    //console.log("init " + this.props.id);
    if (this.secs) this.onChange(this.secs);
  }

  componentWillUnmount() {
    if (this.tab) this.tab.destroy();
    this.tab = null;
  }

  onChange(secs) {
    let self = this;
    if (this.tab) {
      if (self.filter.sids === "_all_") {
        //console.log("onChange", this.props.id, secs);
        this.tab.updateOrAddData(_.cloneDeep(secs), true);
      } else {
        secs.forEach(function (sec) {
          if (self.filter.sids.indexOf(sec.sid) !== -1)
            self.tab.updateOrAddData([_.cloneDeep(sec)], true);
        });
      }
    }
    this.secs = secs;
  }

  render() {
    if (this.tab) this.tab.render();
    //console.log("render", this.props.id, this.props.filter);
    return (
      <React.Fragment>
        <History self={this}/>
        <InstrumentsListener
          sids={this.props.filter.sids}
          //onChangeBest={this.onChange}
          //onChangeSettings={this.onChange}
          //onChangeStatuses={this.onChange}
          //onChangeStatistics={this.onChange}
          onChangeAll={this.onChange}
          onChangePrices={this.onChange}
        />
        <div ref={this.tabRef} />
      </React.Fragment>
    );
  }
}

function History(props) {
  props.self.history = useHistory();
  return null;
}

export const InstrumentsTable = withWSPubKey(withFilter(withSize({ monitorHeight: true })(InstrumentsTable_))
);

//HOC
function withInsDefinitions_(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {Instruments.addListenerDefinitions(this.onChange)}
    componentWillUnmount() {Instruments.removeListenerDefinitions(this.onChange)}
    onChange() {
      this.forceUpdate();
    }
    render() {
      return <React.Fragment>
        {Instruments.got_definitions && <WrappedComponent {...this.props} />}
      </React.Fragment>;
    }
  };
}

export function withInsDefinitions(WrappedComponent) {
  return withInsDefinitions_(WrappedComponent)
}

//HOC
/*export function withSecSettings(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        sec: {},
      }
    }

    componentDidMount() {Instruments.addListener({"set": this.onChange}, [this.props.sid]);}
    componentWillUnmount() {Instruments.removeListener({"set": this.onChange}, [this.props.sid]);}
    onChange(secs) {
      //console.log("SecSet onChange", secs);
      let self = this;      
      secs.forEach(function (sec) {
        if (sec.sid === self.props.sid)
          self.setState({sec: sec});
      });
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}*/

//HOC
function withSecPrices_(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        sec: {},
      }
    }

    componentDidMount() {Instruments.addListener({"pr": this.onChange}, [this.props.sid]);}
    componentWillUnmount() {Instruments.removeListener({"pr": this.onChange}, [this.props.sid]);}
    onChange(secs) {
      //console.log("SecSet onChange", secs);
      let self = this;      
      secs.forEach(function (sec) {
        if (sec.sid === self.props.sid)
          self.setState({sec: sec});
      });
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

export function withSecPrices(WrappedComponent) {
  return withWSPubKey(withSecPrices_(WrappedComponent))
}

//HOC
/*export function withTopOfBook(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        sec: {},
      }
    }

    componentDidMount() {Instruments.addListener({"pr": this.onChange}, [this.props.sid]);}
    componentWillUnmount() {Instruments.removeListener({"pr": this.onChange}, [this.props.sid]);}
    onChange(secs) {
      //console.log("SecSet onChange", secs);
      let self = this;      
      secs.forEach(function (sec) {
        if (sec.sid === self.props.sid)
          self.setState({sec: sec});
      });
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}*/

function BestBid_(props) {
  if (!props.sec) return null;
  let sec = Instruments.all[props.sec.sid];
  return sec ? (sec.bb ? decS(sec.bb) : null) : null;
}

export const BestBid = withSecPrices(BestBid_);

function BestOffer_(props) {
  if (!props.sec) return null;
  let sec = Instruments.all[props.sec.sid];
  return sec ? (sec.bs ? decS(sec.bs) : null) : null;
}

export const BestOffer = withSecPrices(BestOffer_);

function LastPrice_(props) {
  //console.log(props);
  if (!props.sec) return null;
  let sec = Instruments.all[props.sec.sid];
  return sec ? (sec.lp ? <span className={sec.dl == 1 ? "col-green" : "col-red"}>{decS(sec.lp)}</span> : (sec.ip ? decS(sec.ip) : null)) : null;
}

export const LastPrice = withSecPrices(LastPrice_);
