/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import Checkbox from '../components/checkbox';

import { Button } from "../components/button";

import { strings } from '../localization/strings';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { IChevronDown, ISelectTriangle } from '../styles/icons';

import { isMobile } from "react-device-detect";

import '../styles/css/select-multiple.css';

export default function SelectMultiple(props) {
  return <PopupState variant="popover">
    {(popupState) => (
      <div>
        <div {...bindTrigger(popupState)} className="cursor-pointer multy-select-label">
          <div className="p4">{props.label}{':\u00A0'}</div>
          <div className="p4b">{props.renderValue(props.value)}</div>
          <div><IChevronDown size="18"/></div>
        </div>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
          transformOrigin={{vertical: 'top',horizontal: 'center',}}>
            <div className="default-popup-title multy-select-buttons">
              <div><Button className="bg-blue" 
                onClick={()=>{props.onChange(props.options.map((opt)=>opt.value))}}>
                {strings.SelectAll}</Button></div>
              <div><Button className="bg-blue"
                onClick={()=>{props.onChange([])}}>
                {strings.ClearAll}</Button></div>
            </div>
          <div className="default-popup p4">
            <div>
  	          {props.options.map((opt, i)=><Checkbox 
                key={i}
  	           	label={opt.label} 
  	           	checked={props.value.indexOf(opt.value) > -1 ? true : false} 
  	           	onChange={()=>{props.onChange(_.xor(props.value, [opt.value]))}}/>
  	          )}
            </div>
          </div>
        </Popover>
      </div>
    )}
  </PopupState>; 
}

export function Select(props) {
  //console.log(props.options);
  if (isMobile) return <select 
    value={props.value} 
    onChange={(e)=>props.onChange(e.target.value)}>
    {
        props.options.map((opt, i)=><option key={i} value={opt.value}>{
                opt.label2 ? 
                  opt.label + '\u00A0\u00A0' + opt.label2 
                : opt.label
              }</option>)
    }
  </select>
  let label = "";
  props.options.some((opt)=>{if (opt.value == props.value) {label = opt.label; return true;} return false;});
  return <PopupState variant="popover">
    {(popupState) => (
      <div>
        <div {...bindTrigger(popupState)} className={"cursor-pointer select-single p3b col-night-sky"}>{label}<ISelectTriangle size="18"/></div>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          <div className="p4 select-single-options">
            {props.options.map((opt, i)=><div className="cursor-pointer"
              key={i}
              onClick={()=>{
                popupState.close();
                props.onChange(opt.value);
              }}>{
                opt.label2 ? 
                  <><b>{opt.label}</b>{'\u00A0\u00A0'}{opt.label2}</> 
                : opt.label
              }</div>
            )}
          </div>
        </Popover>
      </div>
    )}
  </PopupState>; 
}