import React from 'react';
import { strings } from '../localization/strings';
//import _ from "lodash";

import { wspub } from '../ws-pub/ws-pub';
import { wspriv } from '../ws-priv/ws-priv';
import { ExchangeTime } from '../ws-pub/exchange-time'

import { ICircle } from '../styles/icons'

import Popover from '@material-ui/core/Popover';
//import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import '../styles/css/accounts.css';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
}));

function MouseOverPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
      	className="cursor-pointer"
        onClick={(event) => {
          if (!anchorEl) handlePopoverOpen(event);
          else handlePopoverClose();
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {(!props.priv || (props.priv && wspriv.authorized)) && (!props.pub || (props.pub && wspub.connected)) ? <ICircle color="#3BC471" style={{marginTop: "3px", padding: "4px"}}/> : <ICircle color="#EE3F34" style={{marginTop: "3px"}}/>}
      </div>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{margin: "8px"}}>
          <div className="t-accs">
            <table>
              <tbody className="p3 col-grey">
              {props.pub ? <tr><td>{strings.WSPublic}</td><td><ICircle color={wspub.connected ? "#3BC471" : "#EE3F34"}/></td><td>{wspub.connected ? strings.Online : strings.Offline }</td></tr> : null }
              {props.priv ? <tr><td>{strings.WSPrivate}</td><td><ICircle color={wspriv.authorized ? "#3BC471" : "#EE3F34"}/></td><td>{wspriv.authorized ? strings.Online : strings.Offline }</td></tr> : null }
              </tbody>
            </table>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export class OnlineIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
  }

  componentDidMount() {
  	let props = this.props;
    if (props.priv) {
	    ExchangeTime.addListener(this.onTimeChange)
    	wspriv.addListener(this.onChange);
    }
    if (props.pub) wspub.addListener(this.onChange);
  }
  componentWillUnmount() {
    this.will_unmount = true;
  	let props = this.props;
    if (props.priv) {
	    ExchangeTime.removeListener(this.onTimeChange)
    	wspriv.addListener(this.onChange);
    }
    if (props.pub) wspub.addListener(this.onChange);
  }  
  onChange() {if (!this.will_unmount) this.forceUpdate()}
  onTimeChange(sync) {
    if (sync) wspriv.checkState(wspriv);
  }

  render() {
  	let props = this.props;
    return <React.Fragment>
    	<MouseOverPopover pub={props.pub} priv={props.priv}/>
    </React.Fragment>;
  }
}
