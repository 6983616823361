import React from "react";
import '../styles/css/checkbox.css';
//import Checkbox from '@material-ui/core/Checkbox';

export default function Checkbox(props) {
	return <div 
		onClick={props.onChange} 
    onTouchEnd={(e)=>{
      e.preventDefault();
      props.onChange();
    }}
		className="ch-b-container">
		{!props.right && <div className="ch-b-label">{props.label}:{'\u00A0'}</div>}
		<div className="ch-b">
		  <input type="checkbox" checked={props.checked} onChange={()=>{}}/>
		  <span className="checkmark bc-grey-40 col-white-pen"></span>
		</div>
		{props.right && <div>{'\u00A0'}{props.label}</div>}
	</div>;
}