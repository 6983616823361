/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";

import { strings } from '../localization/strings';
import { Theme } from '../styles/select-theme';

import { wspriv, withWSPrivKey, WSPrivListener } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import QrCode from "qrcode.react";

import '../styles/css/two-fa.css';
import '../styles/css/button-confirm.css';

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';
import Dialog from '@material-ui/core/Dialog';

//import { LoginOrRegister } from "../ws-priv/login-or-register";

import { 
  ICheck, 
} from '../styles/icons';

import { Button, ButtonConfirm } from "../components/button";

import { Log } from '../components/log';
import { toast } from 'react-toastify';

import { LogOut } from '../ws-priv/auth-firebase'

export class TwoFAState {
  static request() {
    //let self = this;
    new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_2fa"},
      eternal: false,
      onResult: function(msg) {
        TwoFAState.setState(msg["D"]["e"]);
      }, 
    });    
  }

  static setState(state) {
    TwoFAState.current = state;
    this.listeners.forEach((f) => f(TwoFAState.current)); // update
  }

  static parse(msg) {
    if (msg["Y"] === "u") {
      if (msg["P"] ===  "e_2fa") {
        Log.info(strings.TwoFA_.Enabled);
        TwoFAState.setState(true);
        return true;
      } else if (msg["P"] ===  "d_2fa") {
        Log.info(strings.TwoFA_.Disabled);
        TwoFAState.setState(false);
      }
    }
  }

  static addListener(f) {
    this.listeners.push(f);
    f(TwoFAState.current); //snapshot
  }

  static removeListener(f, iid) {
    this.listeners = _.reject(this.listeners, (l) => l === f);
  }
}

TwoFAState.current = null;
TwoFAState.listeners = [];

//HOC
export function withTwoFAState(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        state2fa: TwoFAState.current,
        key: 0,
      };
    }

    componentDidMount() {TwoFAState.addListener(this.onChange)}
    componentWillUnmount() {TwoFAState.removeListener(this.onChange)}
    onChange(state) {this.setState({
      state2fa: state,
      key: state != this.state.state2fa ? this.state.key + 1 : this.state.key,
    })}

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

/*export function ButtonConfirm2FA(props) {
  const [key, setKey] = React.useState('');
  const [inputRef, setInputRef] = React.useState(null);
  const onVerify = (key) => {
    if (key.length === 6 && parseInt(key) == key) {
      props.onClick(parseInt(key));
    } else {
      toast.error(strings.TwoFA_.WrongFormat);
    }
  }
  if (TwoFAState.current === null) return null;
  if (inputRef) setTimeout(()=>inputRef.focus(), 300);
  return <PopupState variant="popover">
    {(popupState) => (
      <React.Fragment>
        <button {...bindTrigger(popupState)} className="p4b bg-blue">{props.children}</button>
        <Popover
          onEnter={()=>setKey('')}
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          {!TwoFAState.current && !props.enablingTwoFA && <TwoFA/>}
          {(TwoFAState.current || props.enablingTwoFA) && <div className="default-popup popup-confirm">
            <div>
              <div className="p4b">{strings.TwoFA_.EnterKey}:</div>
              <input type="text" value={key} 
                onChange={(e)=>setKey(e.target.value)} 
                ref={(ref) => { if (inputRef != ref && ref != null) setInputRef(ref); }}
                onKeyPress={(e)=>{
                  if (e.keyCode === 13 || e.key === "Enter") {
                    onVerify(key);
                    popupState.close();
                  }
                }}
              />
            </div>
            <div className="confirm-buttons">
              <Button onClick={()=>{
                  onVerify(key);
                  popupState.close();
                }}
                className="p4b bg-blue">
                {strings.TwoFA_.Verify}
              </Button>
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </div>}        
        </Popover>
      </React.Fragment>
    )}
  </PopupState>; 
}*/


export function ButtonConfirm2FADialog(props) {
  const [key, setKey] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [inputRef, setInputRef] = React.useState(null);
  const onVerify = (key) => {
    if (key.length === 6 && parseInt(key) == key) {
      props.onClick(parseInt(key));
    } else {
      toast.error(strings.TwoFA_.WrongFormat);
    }
  }
  if (TwoFAState.current === null) return null;
  if (inputRef) setTimeout(()=>inputRef.focus(), 300);
  return <React.Fragment>
    <Button onClick={()=>setOpen(!open)} className="p4b bg-blue">{props.children}</Button>
    {open && <Dialog 
      open={open}
      TransitionProps={{
        onEnter: ()=>setKey(""),
      }}
      >
      <div className="default-popup-title p4b">
        {strings.TwoFA}
      </div>
      <div className="default-popup popup-confirm">
        <div>
          <div className="p4b">{strings.TwoFA_.EnterKey}:</div>
          <input type="text" value={key} 
            ref={(ref) => { if (inputRef != ref && ref != null) setInputRef(ref); }}
            onChange={(e)=>setKey(e.target.value)} 
            onKeyPress={(e)=>{
              if (e.keyCode === 13 || e.key === "Enter") {
                onVerify(key);
              }
            }}
          />
        </div>
        <div className="confirm-buttons-wrap">
          <Button onClick={()=>onVerify(key)} className="p4b bg-blue">
            {strings.TwoFA_.Verify}
          </Button>
          <Button 
            onClick={()=>setOpen(false)} 
            className="p4b bg-blue">
            {strings.Cancel}
          </Button>
        </div>
      </div>
    </Dialog>}
  </React.Fragment>
}

function Secret(props) {
    let s0 = "";
    let s1 = "";
    for(let i = 0; i < 16; ++i) {
      if (i % 4 === 0) s0 += ' ';
      s0 += props.secret[i];
    }
    for(let i = 16; i < props.secret.length; ++i) {
      if (i % 4 === 0) s1 += ' ';
      s1 += props.secret[i];
    }
    return <React.Fragment><div>{s0}</div><div>{s1}</div></React.Fragment>;

    /*let s0 = [];
    let s1 = [];
    for(let i = 0; i < 4; ++i) {
      s0.push(props.secret.slice(i * 4, i * 4 + 4));
    }
    for(let i = 4; i < 8; ++i) {
      s1.push(props.secret.slice(i * 4, i * 4 + 4));
    }
    let i = 0;
    return <div className="two-fa-setup-codes-secret">
      {s0.map((k)=><div key={++i}>{k}</div>)}
      {s1.map((k)=><div key={++i}>{k}</div>)}
    </div>;*/

}

export class TwoFA_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrcode: null,
      secret: "",
      show: false,
    }
    this.anchorRef = React.createRef();
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    let self = this;
    if (TwoFAState.current === null) return null;
    return <div className={this.props.not_standalone ? '' : "default-column"} ref={this.anchorRef}>
      <div className="default-info">
        <div className="p4">{strings.TwoFA}:</div>
        {this.props.state2fa ? <ICheck color="#3BC471"/> : null}{'\u00A0'}
        {this.props.state2fa ? <div className="p4b">{strings.Enabled}</div> : <div className="p4b col-red">{strings.Disabled}</div>} 
      </div>
      <div className="default-info">
        {!this.props.state2fa && <Button onClick={()=>{
            new Subscribtion({
              ws: wspriv,
              smsg: {"P": "n_2fa"},
              eternal: false,
              onResult: function(msg) {
                self.setState({qrcode: msg["D"]["2fa"]["url"], secret: msg["D"]["2fa"]["secret"], show: true});
              }, 
              onError: function(msg) {
              }
            });
          }} className="p4b bg-blue">{strings.TwoFA_.Enable}</Button>}
        {this.props.state2fa && <React.Fragment>{'\u00A0'}{'\u00A0'}<ButtonConfirm2FADialog 
          onClick={(key)=>wspriv.send({"P": "d_2fa", "D":{"2fa":key}})}>{strings.TwoFA_.Disable}</ButtonConfirm2FADialog></React.Fragment>}
        {this.props.state2fa && <React.Fragment>{'\u00A0'}{'\u00A0'}<ButtonConfirm text={strings.TwoFA_.ResetText} 
          onClick={()=>wspriv.send({"P": "rr_2fa", "D":{}})}>{strings.TwoFA_.Reset}</ButtonConfirm></React.Fragment>}
      </div>
      <Popover
        open={this.state.show}
        onClose={()=>this.setState({show: false})}
        anchorEl={this.anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="two-fa-setup-title p3b">{strings.TwoFA_.Setup}</div>
        <div className="two-fa-setup">
          <div className="p4">{strings.TwoFA_.QRCodeText}</div>
          <div className="two-fa-setup-codes">
            <QrCode
                value={this.state.qrcode}
                size={256}
                bgColor={Theme.current === "dark" ? "#000000" : "#FFFFFF"}
                fgColor={Theme.current === "dark" ? "#FFFFFF" : "#000000"}
                /*imageSettings={{
                  src: "https://art.viking.trade/images/favicon.ico",
                  excavate: true
                }}*/
            />
            <div>
              <div className="p4">{strings.TwoFA_.QRCodeText2}:</div><br/>
              <div className="p4b"><Secret secret={this.state.secret}/></div>
            </div>
          </div>
          <div>
            <ButtonConfirm2FADialog 
              enablingTwoFA={true}
              onClick={(key)=>wspriv.send({"P": "e_2fa", "D":{"2fa": key}})}>{strings.Next}</ButtonConfirm2FADialog>        
            <Button 
                onClick={()=>this.setState({show: false})}
                className="p4b bg-blue">
                {strings.Cancel}
            </Button>              
          </div>
        </div>
      </Popover>    
    </div>;
  }
}

export const TwoFA = withTwoFAState(withWSPrivKey(TwoFA_));

export class TwoFaDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onWSStateChange = this.onWSStateChange.bind(this);
    this.state = {
      open: wspriv.two_fa,
      key: "",
      inputRef: null,
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onWSStateChange() {
    this.setState({open: wspriv.two_fa});
  }

  send() {
    let key = this.state.key;
    if (key.length === 6 && parseInt(key) == key) {
      wspriv.send({"P": "auth_2fa", "D":{"2fa": parseInt(key)}});
    } else {
      toast.error(strings.TwoFA_.WrongFormat);
    }
  }

  render() {
    if (this.state.inputRef) setTimeout(()=>this.state.inputRef.focus(), 300);
    return <div>
      <WSPrivListener onChange={this.onWSStateChange}/>
      {this.state.open && <Dialog 
        open={this.state.open}
        TransitionProps={{
          onEnter: ()=>this.setState({key: ""}),
        }}
        >
        <div className="default-popup-title p4b">
          {strings.TwoFA}
        </div>
        <div className="default-popup popup-confirm">
          <div>
            <div className="p4b">{strings.TwoFA_.EnterKey}:</div>
            <input type="text" value={this.state.key} 
              ref={(ref)=>{ if (this.state.inputRef != ref && ref != null) this.setState({inputRef: ref});}}
              onChange={(e)=>this.setState({key: e.target.value})} 
              onKeyPress={(e)=>{
                if (e.keyCode === 13 || e.key === "Enter") {
                  this.send();
                }
              }}
            />
          </div>
          <div className="confirm-buttons-wrap">
            <Button onClick={()=>this.send()} className="p4b bg-blue">
              {strings.TwoFA_.Verify}
            </Button>
            <LogOut>{strings.Cancel}</LogOut>
            <ButtonConfirm text={strings.TwoFA_.ResetText} 
              onClick={()=>wspriv.send({"P": "rr_2fa", "D":{}})}>
              {strings.TwoFA_.Reset}
            </ButtonConfirm>
          </div>
        </div>
      </Dialog>}
    </div>
  }
};
