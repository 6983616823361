import React from "react";
//import { strings } from '../localization/strings';
import { getMinNsecTime } from '../utils/utils'

import TableX from "../utils/table-x";

//import { Button } from "../components/button";

import { wspriv/*, withWSPrivKey*/ } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import {
  dec,
  decS, 
  dt_ns_s,
  dt_ns_sS,
  string_code,
  string_codeS,
} from "../utils/cell-formatters";


export class Statement {
  static parse(msg) {
    return msg["D"]["atrs"];
  }
}

Statement.columns = [
  {title:"Account", field:"aid"},
  {title:"MarginBalance", field:"fb", formatter: dec, formatterS: decS},
//  {title:"BTCBalance", field:"btc", formatter: dec, formatterS: decS},
  {title:"", field:"dif", formatter: dec, formatterS: decS},
  {title:"Currency", field:"cid"},
  {title:"Time", field:"ct", formatter: dt_ns_s, formatterS: dt_ns_sS},
  {title:"Message", field:"c", formatter: string_code, formatterS: string_codeS},
];

export class ShowStatement extends React.Component {
  constructor(props) {
    super(props);
    this.tabRef = React.createRef();
  }

  render() {
    let self = this;
    return <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <button {...bindTrigger(popupState)} className="p4b bg-blue">{this.props.children}</button>
          <Popover
            TransitionProps={{
              onEnter: ()=>{
  					    this.max_time = "18446744073709551615";
                let div = self.tabRef.current;
                div.id = "atrans_" + this.props.id;
                self.tab = new TableX("#" + div.id, {
                  onScrollPromise: function(tab) {
                    return new Promise(function(resolve, reject){
                      self.subscribtion = new Subscribtion({
                        ws: wspriv,
                        smsg: {"P": "g_ast", "D":{"aids": self.props.aids, "mt": self.max_time, "lim": 100}},
                        eternal: false,
                        onResult: function(msg) {
                          let atrs = Statement.parse(msg);
                          //console.log("Subscribtion", self.max_time, atrs);
                          self.max_time = getMinNsecTime(atrs, "ct", self.max_time);
                          if (atrs.length > 0) resolve({data: atrs});
                          else resolve({no_more_data: true, data: atrs});
                        }, 
                        onError: function(msg) {
                          reject(msg);
                        }
                      });
                    });
                  },
                  columns: Statement.columns,
                });
              },
              onExited: ()=>{
                if (self.tab) self.tab.destroy();
                self.tab = null;
              },              
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
            transformOrigin={{vertical: 'top',horizontal: 'left',}}>
            <div className="default-table-popup">
              <div ref={self.tabRef}/>
            </div>       
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  }
}
