/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";

import withTitlebar from "../components/grid-titlebar";
import { LogTable } from "../components/log";
import { OBTable } from "../ws-priv/orderbook";
import { IndexesTable } from "../ws-pub/indexes";
import { TradesPubOwnTable } from "../ws-priv/trades-pub-own";
import { TradesPubTable } from "../ws-pub/trades-pub";
import { InstrumentsTable } from "../ws-pub/instruments";
import { Chart as ChartTrades } from "../ws-pub/chart-trades";
import { ActiveTable } from "../ws-priv/active";
import { OrdersTable } from "../ws-priv/orders";
import { TradesTable } from "../ws-priv/trades";
import { PositionsTable } from "../ws-priv/positions";
import { OrdersAndTrades } from "../ws-priv/orders-and-trades";
import { InstrumentControl } from "../ws-priv/instrument-control";
import { NewOrder } from "../ws-priv/new-order";
import { AccountControl } from "../ws-priv/account-control";
import { AccountControlGrid } from "../ws-priv/account-control";
import { Transfer } from '../ws-priv/transfer';
import { AccountsTable } from "../ws-priv/accounts";

import { HistoryTable } from "../ws-priv/history";
import { Wallet } from "../ws-priv/wallet";
import { Referral } from "../ws-priv/referral";

import { APIKeys } from "../ws-priv/api-keys";
import { APITester, APIChooseVersion } from "../components/api-tester";
import { APIDocumentation } from "../components/api-documentation";

import { Security, Password, Devices } from "../ws-priv/security";
import { TwoFA } from "../ws-priv/two-fa";

import { end_of_day } from '../utils/utils'

import { api, description } from '../localization/strings';

import { 
	ICode,
	IInstrument, 
	ITrades, 
	IAllTrades, 
	INewOrder, 
	IIndexes,
	IWallet,
	IHistory,
	IChart,
	IAccount,
	IAccounts,
	IOrderbook,
	ISecurity,
	IKeys,
	IReferral,
	IQuotes,
	IQuestion,
//	IEmpty,
} from '../styles/icons';

const WTLogTable = withTitlebar(LogTable);
const WTOBTable = withTitlebar(OBTable);
const WTIndexesTable = withTitlebar(IndexesTable);
const WTTradesPubOwnTable = withTitlebar(TradesPubOwnTable);
const WTTradesPubTable = withTitlebar(TradesPubTable);
const WTInstrumentsTable = withTitlebar(InstrumentsTable);
const WTChartTrades = withTitlebar(ChartTrades);
const WTActiveTable = withTitlebar(ActiveTable);
const WTOrdersTable = withTitlebar(OrdersTable);
const WTTradesTable = withTitlebar(TradesTable);
const WTPositionsTable = withTitlebar(PositionsTable);
const WTAccountsTable = withTitlebar(AccountsTable);
const WTAccountControl = withTitlebar(AccountControl);
const WTAccountControlGrid = withTitlebar(AccountControlGrid);
const WTTransfer = withTitlebar(Transfer);
const WTNewOrder = withTitlebar(NewOrder);
const WTOrdersAndTrades = withTitlebar(OrdersAndTrades);
const WTInstrumentControl = withTitlebar(InstrumentControl);
const WTWallet = withTitlebar(Wallet);
const WTReferral = withTitlebar(Referral);
const WTHistoryTable = withTitlebar(HistoryTable);
const WTAPIKeys = withTitlebar(APIKeys);
const WTAPITester = withTitlebar(APITester);
const WTAPIChooseVersion = withTitlebar(APIChooseVersion);
const WTAPIDocumentation = withTitlebar(APIDocumentation);
const WTSecurity = withTitlebar(Security);
const WTPassword = withTitlebar(Password);
const WTDevices = withTitlebar(Devices);
const WTTwoFa = withTitlebar(TwoFA);

//const WTTariffs = withTitlebar(Tariffs);

export const components = {
	LogTable: (props) => <WTLogTable {...props} />,
	OrderbookTable: (props) => <WTOBTable {...props} />,
	Orderbook: (props) => <WTOBTable {...props} />,
	IndexesTable: (props) => <WTIndexesTable {...props} />,
	TradesPubOwnTable: (props) => <WTTradesPubOwnTable {...props} />,
	TradesPubTable: (props) => <WTTradesPubTable {...props} />,
	InstrumentsTable: (props) => <WTInstrumentsTable {...props} />,
	QuotesTable: (props) => <WTInstrumentsTable {...props} />,
	ChartTrades: (props) => <WTChartTrades {...props} />,
	ActiveTable: (props) => <WTActiveTable {...props} />,
	OrdersTable: (props) => <WTOrdersTable {...props} />,
	TradesTable: (props) => <WTTradesTable {...props} />,
	PositionsTable: (props) => <WTPositionsTable {...props} />,
	AccountsTable: (props) => <WTAccountsTable {...props} />,
	AccountControl: (props) => <WTAccountControl {...props} />,
	AccountControlGrid: (props) => <WTAccountControlGrid {...props} />,
	Transfer: (props) => <WTTransfer {...props} />,
	NewOrder: (props) => <WTNewOrder {...props} />,
	OrdersAndTrades: (props) => <WTOrdersAndTrades {...props} />,
	InstrumentControl: (props) => <WTInstrumentControl {...props} />,
	Wallet: (props) => <WTWallet {...props} />,
	HistoryTable: (props) => <WTHistoryTable {...props} />,
	Referral: (props) => <WTReferral {...props} />,
	APIKeys: (props) => <WTAPIKeys {...props} />,
	APITester: (props) => <WTAPITester {...props} no_title/>,
	APIChooseVersion: (props) => <WTAPIChooseVersion {...props} no_title/>,
	APIDocumentation: (props) => <WTAPIDocumentation {...props} api={api}/>,
	Description: (props) => <WTAPIDocumentation {...props} api={description}/>,
	Security: (props) => <WTSecurity {...props} />,
	Password: (props) => <WTPassword {...props} />,
	Devices: (props) => <WTDevices {...props} />,
	TwoFA: (props) => <WTTwoFa {...props} />,
};

export const layouts = {
	layout_mobile_trade: {
		key: 0,
		//static: true,
		items: [
			{
				id_: "mob_InstrumentControl",
				icon: INewOrder,
				component_: "InstrumentControl",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true, sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				last_price: true,
				title: "Trade",
				select: ["sid_active"],
				lg: { x: 0, y: 0, w: 3, h: 10, minW: 1, minH: 10 },
				height: "600px",
			},
			{
				id_: "mob_OrdersAndTrades",
				icon: ITrades,
				component_: "OrdersAndTrades",
				filter: { priv_table: "Orders", sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt"],
				select: ["priv_table"],
				download_trades: true,
				/*title: "OrdersAndTrades",*/
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},
		],
	},
	layout_mobile_quotes: {
		key: 0,
		//static: true,
		items: [
			{
				id_: "mob_QuotesTable",
				icon: IQuotes,
				component_: "QuotesTable",
				filter: { sids: "_all_", "cols": ["Symbol", "BestBuy", "BestSell"]},
				filters: ["sids_active", "cols"],
				title: "QuotesMonitor",
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
			},
		],
	},
	layout_mobile_accounts: {
		key: 0,
		//static: true,
		items: [
			{
				id_: "mob_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
				height: "400px",
			},
		],
	},
	layout_mobile_wallet: {
		key: 0,
		//static: true,
		items: [
			{
				id_: "mob_Wallet",
				icon: IWallet,
				component_: "Wallet",
				filter: { "action": "Deposit", "cid": "BTC"},
				title: "Wallet",
				refreshable: true,
				download_wallet: true,
				total_balance: true,
				lg: { x: 0, y: 0, w: 6, h: 9, minW: 1, minH: 1 },
				height: "600px",
			},
		],
	},
	layout_mobile_menu: {
		key: 0,
		items: [
			/*{
				id_: "mob_InstrumentControl",
				icon: INewOrder,
				component_: "InstrumentControl",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true, sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				last_price: true,
				title: "Trade",
				select: ["sid_active"],
				lg: { x: 0, y: 0, w: 3, h: 10, minW: 1, minH: 10 },
				height: "600px",
			},
			{
				id_: "mob_QuotesTable",
				icon: IQuotes,
				component_: "QuotesTable",
				filter: { sids: "_all_", "cols": ["Symbol", "BestBuy", "BestSell"]},
				filters: ["sids_active", "cols"],
				title: "QuotesMonitor",
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "mob_Wallet",
				icon: IWallet,
				component_: "Wallet",
				filter: { "action": "Deposit", "cid": "BTC"},
				title: "Wallet",
				refreshable: true,
				download_wallet: true,
				total_balance: true,
				lg: { x: 0, y: 0, w: 6, h: 9, minW: 1, minH: 1 },
				height: "600px",
			},
			{
				id_: "mob_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
				height: "400px",
			},*/
			{
				id_: "mob_Referral",
				icon: IReferral,
				component_: "Referral",
				title: "Referral",
				refreshable: true,
				lg: { x: 10, y: 0, w: 8, h: 3, minW: 1, minH: 1 },
				height: "300px",
			},		
			/*{
				id_: "mob_OrdersAndTrades",
				icon: ITrades,
				component_: "OrdersAndTrades",
				filter: { priv_table: "Orders", sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt"],
				select: ["priv_table"],
				download_trades: true,
				//title: "OrdersAndTrades",
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},*/
			/*{
				id_: "mob_ChartTrades",
				icon: IChart,
				component_: "ChartTrades",
				filter: { sid: 4, left_toolbar: true, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				select: ["sid_all"],
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},			
			{
				id_: "mob_Orderbook",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 0, y: 0, w: 2, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},*/
			{
				id_: "mob_IndexesTable",
				icon: IIndexes,
				component_: "IndexesTable",
				filter: { iid: "_all_" },
				//title: "Indexes",
				in_menu: true,
				select: ["iid"],
				lg: { x: 0, y: 0, w: 3, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},
			/*{
				id_: "mob_TradesPubOwnTable",
				icon: IAllTrades,
				component_: "TradesPubOwnTable",
				filter: { sids: "_all_", dt: -end_of_day.unix() },
				filters: ["sids_all", "dt"],
				title: "AllTrades",
				lg: { x: 0, y: 0, w: 7, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},*/
			{
				id_: "mob_InstrumentsTable",
				icon: IInstrument,
				component_: "InstrumentsTable",
				filter: { sids: "_all_", "cols": "_all_"},
				filters: ["sids_active", "cols"],
				title: "AllInstruments",
				lg: { x: 0, y: 0, w: 9, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},
			/*{
				id_: "mob_AccountsTable",
				icon: IAccounts,
				component_: "AccountsTable",
				filter: { "cols": "_all_" },
				title: "AccountsTable",
				lg: { x: 0, y: 0, w: 8, h: 5, minW: 1, minH: 1 },
				height: "600px",
			},*/
			{
				id_: "mob_HistoryTable",
				icon: IHistory,
				component_: "HistoryTable",
				filter: { dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["dt", "cols"],
				title: "History",
				refreshable: true,
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
				height: "600px",
			},
			{
				id_: "mob_Security",
				icon: ISecurity,
				component_: "Security",
				title: "Security",
				lg: { x: 6, y: 0, w: 4, h: 3 },
				height: "600px",
			},
			{
				id_: "mob_APIKeys",
				icon: IKeys,
				component_: "APIKeys",
				title: "APIKeys",
				refreshable: true,
				lg: { x: 10, y: 0, w: 8, h: 3, minW: 1, minH: 1 },
				height: "400px",
			},
			{
				id_: "mob_APIDocumentation",
				icon: ICode,
				component_: "APIDocumentation",
				title: "APIDoc",
				refreshable: true,
				lg: { x: 9, y: 0, w: 9, h: 5, minW: 1, minH: 1 },
				link: "/doc/api"
			},
			{
				id_: "mob_Description",
				icon: IQuestion,
				component_: "Description",
				title: "Description",
				refreshable: true,
				lg: { x: 9, y: 0, w: 9, h: 5, minW: 1, minH: 1 },
				link: "/doc/description"
			},
		]
	},
	layout_trade_menu: {
		key: 0,
		items: [
			{
				id_: "trm_InstrumentControl",
				icon: INewOrder,
				component_: "InstrumentControl",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true, sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				last_price: true,
				select: ["sid_active"],
				lg: { x: 0, y: 0, w: 3, h: 10, minW: 1, minH: 10 },
			},
			{
				id_: "trm_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "trm_OrdersAndTrades",
				icon: ITrades,
				component_: "OrdersAndTrades",
				filter: { priv_table: "Orders", sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt"],
				select: ["priv_table"],
				download_trades: true,
				/*title: "OrdersAndTrades",*/
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
			},
			/*{
				id_: "trm_NewOrder",
				icon: INewOrder,
				component_: "NewOrder",
				select: ["sid_active"],
				filter: { sid: 4 },
				lg: { x: 0, y: 0, w: 4, h: 8, minW: 1, minH: 1 },
			},*/
			{
				id_: "trm_QuotesTable",
				icon: IQuotes,
				component_: "QuotesTable",
				filter: { sids: "_all_", "cols": ["Symbol", "BestBuy", "BestSell"]},
				filters: ["sids_active", "cols"],
				title: "QuotesMonitor",
				lg: { x: 0, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "trm_ChartTrades",
				icon: IChart,
				component_: "ChartTrades",
				filter: { sid: 4, left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				select: ["sid_active"],
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
			},			
			{
				id_: "trm_Orderbook",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 0, y: 0, w: 2, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "trm_IndexesTable",
				icon: IIndexes,
				component_: "IndexesTable",
				filter: { iid: "_all_" },
				//title: "Indexes",
				select: ["iid"],
				lg: { x: 0, y: 0, w: 3, h: 6, minW: 1, minH: 1 },
			},
			/*{
				id_: "trm_TradesPubTable",
				icon: IAllTrades,
				component_: "TradesPubTable",
				filter: { sids: "_all_", dt: -end_of_day.unix() },
				filters: ["sids_all", "dt"],
				title: "AllTrades",
				lg: { x: 0, y: 0, w: 7, h: 6, minW: 1, minH: 1 },
			},*/
			/*{
				id_: "trm_LogTable",
				icon: IInstrument,
				component_: "LogTable",
				title: "Log",
				filter: {},
				lg: { x: 0, y: 0, w: 8, h: 5, minW: 1, minH: 1 },
			},*/
			/*
			{
				component_: "LogTable",
				title: "Log",
				filter: {},
				lg: { x: 5, y: 0, w: 4, h: 6 },
			},*/
			/*
			{
				component_: "PositionsTable",
				filter: { sids: "_all_", aids: "_all_", "cols": "_all_"},
				filters: ["aids", "cols"],
				title: "Positions",
				lg: { x: 8, y: 20, w: 4, h: 6 },
			},				
			{
				component_: "TradesTable",
				filter: { sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt", "cols"],
				title: "MyTrades",
				lg: { x: 12, y: 0, w: 6, h: 6 },
			},		
			{
				component_: "OrdersTable",
				filter: { sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt", "cols"],
				title: "Orders",
				lg: { x: 12, y: 10, w: 6, h: 6 },
			},	
			{
				component_: "ActiveTable",
				filter: { sids: "_all_", aids: "_all_", "cols": "_all_"},
				filters: ["sids_all", "aids", "cols"],
				title: "ActiveOrders",
				lg: { x: 12, y: 20, w: 6, h: 6 },
			},*/
			{
				id_: "trm_TradesPubOwnTable",
				icon: IAllTrades,
				component_: "TradesPubOwnTable",
				filter: { sids: "_all_", dt: -end_of_day.unix() },
				filters: ["sids_all", "dt"],
				title: "AllTrades",
				lg: { x: 0, y: 0, w: 7, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "trm_AccountsTable",
				icon: IAccounts,
				component_: "AccountsTable",
				filter: { "cols": "_all_" },
				title: "AccountsTable",
				lg: { x: 0, y: 0, w: 8, h: 5, minW: 1, minH: 1 },
			},
			{
				id_: "trm_HistoryTable",
				icon: IHistory,
				component_: "HistoryTable",
				filter: { dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["dt", "cols"],
				title: "History",
				refreshable: true,
				lg: { x: 0, y: 0, w: 8, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "trm_InstrumentsTable",
				icon: IInstrument,
				component_: "InstrumentsTable",
				filter: { sids: "_all_", "cols": "_all_"},
				filters: ["sids_active", "cols"],
				title: "AllInstruments",
				lg: { x: 0, y: 0, w: 9, h: 6, minW: 1, minH: 1 },
			},
		]
	},
	layout_trade: {
		key: 0,
		items: [
			{
				id_: "tr_InstrumentControl",
				icon: INewOrder,
				component_: "InstrumentControl",
				filter: { sid: 4, "cols": "_all_", "show_index_price": true, "show_mark_price": true, sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				last_price: true,
				select: ["sid_active"],
				lg: { x: 0, y: 0, w: 3, h: 12, minW: 1, minH: 10 },
			},
			{
				id_: "tr_Orderbook0",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 3, "cols": "_all_", "show_index_price": false, "show_mark_price": true },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 3, y: 0, w: 2, h: 6, minW: 1, minH: 1 },
				sm: { x: 3, y: 0, w: 2, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_Orderbook1",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 5, "cols": ["Price","Size"], "show_index_price": true, "show_mark_price": false },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 5, y: 0, w: 2, h: 6, minW: 1, minH: 1 },
				sm: { x: 0, y: 12, w: 2, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_Orderbook2",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 7, "cols": "_all_", "show_index_price": true, "show_mark_price": false },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 3, y: 6, w: 2, h: 6, minW: 1, minH: 1 },
				sm: { x: 2, y: 12, w: 2, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_Orderbook3",
				icon: IOrderbook,
				component_: "Orderbook",
				filter: { sid: 2, "cols": "_all_", "show_index_price": true, "show_mark_price": false },
				select: ["sid_active"],
				filters: ["cols", "show_index_price", "show_mark_price"],
				lg: { x: 5, y: 6, w: 2, h: 6, minW: 1, minH: 1 },
				sm: { x: 0, y: 18, w: 2, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_ChartTrades",
				icon: IChart,
				component_: "ChartTrades",
				filter: { sid: 4, left_toolbar: false, top_toolbar: true, show_trades: true },
				filters: ["left_toolbar", "top_toolbar", "show_trades", "reset_chart"],
				select: ["sid_active"],
				lg: { x: 7, y: 0, w: 7, h: 6, minW: 1, minH: 1 },
				md: { x: 7, y: 0, w: 5, h: 6, minW: 1, minH: 1 },
				sm: { x: 3, y: 6, w: 5, h: 6, minW: 1, minH: 1 },
			},			
			{
				id_: "tr_QuotesTable",
				icon: IQuotes,
				component_: "QuotesTable",
				filter: { sids: "_all_", "cols": ["Symbol", "BestBuy", "BestSell"]},
				filters: ["sids_active", "cols"],
				title: "QuotesMonitor",
				lg: { x: 14, y: 0, w: 4, h: 6, minW: 1, minH: 1 },
				md: { x: 12, y: 0, w: 5, h: 6, minW: 1, minH: 1 },
				/*sm: { x: 13, y: 12, w: 4, h: 6, minW: 1, minH: 1 },*/
				sm: { x: 2, y: 18, w: 6, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 7, y: 6, w: 4, h: 6, minW: 1, minH: 1 },
				md: { x: 0, y: 12, w: 5, h: 6, minW: 1, minH: 1 },
				sm: { x: 11, y: 0, w: 3, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_TradesPubOwnTable",
				icon: IAllTrades,
				component_: "TradesPubOwnTable",
				filter: { sids: "_all_", dt: -end_of_day.unix() },
				filters: ["sids_all", "dt"],
				title: "AllTrades",
				lg: { x: 11, y: 6, w: 7, h: 6, minW: 1, minH: 1 },
				sm: { x: 6, y: 12, w: 4, h: 6, minW: 1, minH: 1 },
			},
			{
				id_: "tr_OrdersAndTrades",
				icon: ITrades,
				component_: "OrdersAndTrades",
				filter: { priv_table: "Orders", sids: "_all_", aids: "_all_", dt: -end_of_day.unix(), "cols": "_all_"},
				filters: ["sids_all", "aids", "dt"],
				select: ["priv_table"],
				download_trades: true,
				/*title: "OrdersAndTrades",*/
				lg: { x: 0, y: 12, w: 18, h: 6, minW: 1, minH: 1 },
				sm: { x: 0, y: 24, w: 18, h: 6, minW: 1, minH: 1 },
			},
		],
	},
	/*layout_account: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_Wallet",
				icon: IWallet,
				component_: "Wallet",
				filter: { "action": "Deposit", "cid": "BTC"},
				title: "Wallet",
				refreshable: true,
				download_wallet: true,
				total_balance: true,
				lg: { x: 0, y: 0, w: 6, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 6, y: 0, w: 4, h: 7, minW: 1, minH: 1 },
				md: { x: 0, y: 7, w: 4, h: 7, minW: 1, minH: 1 },
				sm: { x: 0, y: 0, w: 4, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_Referral",
				icon: IReferral,
				component_: "Referral",
				title: "Referral",
				refreshable: true,
				lg: { x: 10, y: 0, w: 8, h: 7, minW: 1, minH: 1 },
				md: { x: 6, y: 0, w: 6, h: 7, minW: 1, minH: 1 },
				sm: { x: 0, y: 0, w: 6, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_HistoryTable",
				icon: IHistory,
				component_: "HistoryTable",
				filter: { dt: -end_of_day.unix(), "hiss": "_all_"},
				filters: ["dt", "hiss"],
				title: "History",
				refreshable: true,
				lg: { x: 0, y: 7, w: 18, h: 7, minW: 1, minH: 1 },
				md: { x: 4, y: 7, w: 8, h: 7, minW: 1, minH: 1 },
				sm: { x: 0, y: 0, w: 6, h: 7, minW: 1, minH: 1 },
			},
		]
	},*/
	layout_wallet: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_Wallet",
				icon: IWallet,
				component_: "Wallet",
				filter: { "action": "Deposit", "cid": "BTC"},
				//title: "Wallet",
				refreshable: true,
				download_wallet: true,
				total_balance: true,
				lg: { x: 0, y: 0, w: 8, h: 8, minW: 1, minH: 1 },
			},
			/*{
				id_: "acc_AccountControl",
				icon: IAccount,
				component_: "AccountControl",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 6, y: 0, w: 4, h: 7, minW: 1, minH: 1 },
				md: { x: 0, y: 7, w: 4, h: 7, minW: 1, minH: 1 },
				sm: { x: 0, y: 0, w: 4, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_HistoryTable",
				icon: IHistory,
				component_: "HistoryTable",
				filter: { dt: -end_of_day.unix(), "hiss": "_all_"},
				filters: ["dt", "hiss"],
				title: "History",
				refreshable: true,
				lg: { x: 0, y: 0, w: 10, h: 7, minW: 1, minH: 1 },
			},*/
		]
	},
	layout_accounts: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_AccountControlGrid",
				icon: IAccount,
				component_: "AccountControlGrid",
				filter: { "aid": null },
				select: ["aid"],
				download_account: true,
				lg: { x: 0, y: 0, w: 7, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_Transfer",
				icon: IAccount,
				component_: "Transfer",
				filter: {},
				select: [],
				title: "Transfer",
				lg: { x: 7, y: 0, w: 3, h: 7, minW: 1, minH: 1 },
			},
			{
				id_: "acc_HistoryTable",
				icon: IHistory,
				component_: "HistoryTable",
				filter: { dt: -end_of_day.unix(), "hiss": "_all_"},
				filters: ["dt", "hiss"],
				title: "History",
				refreshable: true,
				lg: { x: 0, y: 7, w: 10, h: 7, minW: 1, minH: 1 },
			},
		]
	},
	layout_referral: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_Referral",
				icon: IReferral,
				component_: "Referral",
				title: "Referral",
				refreshable: true,
				lg: { x: 0, y: 0, w: 7, h: 7, minW: 1, minH: 1 },
			},
		]
	},
	layout_api: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_APIKeys",
				icon: IKeys,
				component_: "APIKeys",
				title: "APIKeys",
				refreshable: true,
				lg: { x: 0, y: 0, w: 9, h: 5, minW: 1, minH: 1 },
			},
			{
				id_: "acc_APIChooseVersion",
				icon: ICode,
				component_: "APIChooseVersion",
				title: "APIDoc",
				refreshable: true,
				lg: { x: 0, y: 0, w: 9, h: 5, minW: 1, minH: 1 },
			},
			{
				id_: "acc_APITester",
				icon: ICode,
				component_: "APITester",
				title: "APITester",
				refreshable: true,
				lg: { x: 9, y: 0, w: 9, h: 10, minW: 1, minH: 1 },
				md: { x: 0, y: 0, w: 9, h: 10, minW: 1, minH: 1 },
			},
		],
	},
	layout_security: {
		key: 0,
		static: true,
		items: [
			{
				id_: "acc_Security",
				icon: ISecurity,
				component_: "Security",
				title: "Security",
				lg: { x: 0, y: 0, w: 8, h: 12 },
			},
		],
	},
};

let items_by_id = {};
_.forEach(layouts, (layout)=>{
	layout.items.forEach((item)=>{
		items_by_id[item.id_] = item;
	});
})

export function prepare(layout) {
	layout.items.forEach((item) => prepareItem(item));
	layout.items = _.reject(layout.items, {component: undefined});
	if (layout.key === 0)
		layout.items.forEach((item) => (item.key = ++layout.key + ""));
	return layout;
}

export function prepareItem(item) {
	//console.log("prepareItem", item)
	if (items_by_id.hasOwnProperty(item.id_)) {
		//console.log("!!!");
		_.forEach(items_by_id[item.id_], (v, k)=>{
			if (k != "llllg" && k != "lllg" && k != "llg" && k != "lg" && k != "md" && k != "sm" && k != "xxs") {
				if (k == "filter" && item.filter != null) {
					let filter = {};
					//console.log(v);
					//console.log(item.filter);
					_.forEach(v, (v1, k1)=>{
						if (item.filter.hasOwnProperty(k1)) filter[k1] = item.filter[k1];
						else filter[k1] = v1;
					})
					item.filter = filter;
				} else {
					item[k] = v;
				}
			}
		})
	}/* else {
		item.id_ = "trm_ChartTrades";
	}*/
	item.component = components[item.component_]
}

