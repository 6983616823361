import React from 'react';
import _ from "lodash";
import { strings } from '../localization/strings';

import { layouts, prepare, prepareItem } from '../components/grid-layouts'

import { 
//	ICopy, 
	IChevronRight,
//	ICross 
} from '../styles/icons';

import '../styles/css/stack.css';

import { SelectLanguage } from '../localization/select-language';
import { Theme, SelectTheme } from '../styles/select-theme';
import { col } from '../styles/colors';

import {
  Switch,
  Route,
  //Link,
  useRouteMatch,
  useHistory,
  Redirect,
} from "react-router-dom";

import { 
  AuthFirebase, 
  LogOut,
} from '../ws-priv/auth-firebase'

class StackItem extends React.Component {
  constructor(props) {
    super(props);
    this.item_id = this.props.item.component_;
    this.state = { 
    	item: this.loadItem(this.item_id),
    	key: 0,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

	onRefreshItem(item_id) {
    this.setState({key: this.state.key + 1});
    //console.log("onRefreshItem");
  }

	onFilterChanged(item_id, filter) {
    let item = _.cloneDeep(this.state.item);
    item.filter = _.assign(item.filter, filter);
    //console.log("onFilterChanged", item);
    this.saveItem(item_id, item);
    this.setState({item: item});
  }

  onSaveData(item_id, saved_data) {
    let item = _.cloneDeep(this.state.item);
    //let saved_data = _.cloneDeep(this.state.item);
    item.saved_data = _.assign(item.saved_data, saved_data);
    //console.log("onSaveData", item);
    this.saveItem(item_id, item);
    this.setState({item: item});
  }

  onResetSavedData(item_id) {
    let item = _.cloneDeep(this.state.item);
    item.saved_data = null;
    //console.log("onResetSavedData", item);
    this.saveItem(item_id, item);
    this.setState({item: item, key: this.state.key + 1});
  }

  loadItem(item_id) {//load from local storage
		let item;
    if (localStorage["layout_mobile_" + item_id]) {
      item = JSON.parse(localStorage["layout_mobile_" + item_id]);
      item.height = this.props.item.height;
      //item.download_trades = this.props.item.download_trades;
    } else {
      item = _.cloneDeep(this.props.item);
    }
    //item = _.cloneDeep(this.props.item);
    prepareItem(item);
    item.download_trades = false;
    return item;
  }

  saveItem(item_id, item) {//save to localstorage
  	//console.log("saveItem", item_id, item.saved_data);
    localStorage["layout_mobile_" + item_id] = JSON.stringify(item);
  }

  render() {
  	let item = this.state.item;
    //console.log("StackItem render", item);
  	return <div key={this.state.key} style={{width: "100%", height: item.height ? item.height : "600px", position: "relative"}}>
  		{item.component({
	      id: item.component_,
	      component_: item.component_,
	      title: item.title,
	      last_price: item.last_price,
	      filter: item.filter,
	      saved_data: item.saved_data,
	      select: item.select,
	      filters: item.filters,
	      onCopyItem: null,
	      onRemoveItem: null,
	      refreshable: item.refreshable,
        download_trades: item.download_trades,
	      onRefresh: this.onRefreshItem.bind(this, this.item_id),
	      onFilterChanged: this.onFilterChanged.bind(this, this.item_id),
	      onSaveData: this.onSaveData.bind(this, this.item_id),
	      onResetSavedData: this.onResetSavedData.bind(this, this.item_id),
	      rowHeight: 44,
			})}
		</div>
  }
}

export function StackLayout(props) {
  //console.log(layouts[props.layout]);
  let layout = prepare(layouts[props.layout]);
  //console.log(layout.items);
  return <React.Fragment>
    {layout.items.map((item)=>{
      return <React.Fragment key={item.component_}>
        <br/>
        <StackItem key={item.component_} item={item}/>
      </React.Fragment>
    })}
  </React.Fragment>
}

export default function Stack(props) {
	const match = useRouteMatch();
  const history = useHistory();
  let layout = prepare(layouts[props.layout]);
	let path = history.location.pathname.split('/');
	let component_ = path[path.length - 1];
	let item = null;
	layout.items.some((i)=>{
		if (i.component_ === component_) {
			item = i;
			return true;
		} else return false;
	});
	//console.log(history);
	//if (!item) history.replace(`${match.path}`);
  //console.log("Stack render", item);
  return <Switch>
      {item && <Route strict path={`${match.path}/`}>
					<StackItem key={component_} item={item}/>
      </Route>}
      <Redirect strict path={`${match.path}/`} to={match.path}/>
      <Route path={`${match.path}`}>
        <div className="default-popup">
          <div>{AuthFirebase.email}</div>
        </div>
				<div className="stack">
			  	{layout.items.map((item)=>{
			  		return <div key={item.key} className="stack-item  cursor-pointer" 
            onClick={()=>{
              if (item.link) history.push(item.link);
			  			else history.push(match.path + "/" + item.component_);
			  		}}>
              <div className="stack-item-left">
                {item.icon && item.icon({size: "38", color: Theme.current === "light" ? col.grey_5 : col.grey_light})}
				        <div className="p3 col-grey">{item.title ? strings[item.title] : strings[item.component_]}</div>
              </div>
				      {!item.link && <IChevronRight size="20"/>}
			  		</div>
			  	})}
			  </div>
        <div className="default-popup">
          <div><SelectLanguage/>{'\u00A0'}<SelectTheme/></div>
        </div>        
        <div className="default-popup">
          <LogOut>{strings.Logout}</LogOut>
        </div>        
        {null /*<div className="stack-grid">
          {layout.items.map((item)=>{
            return <div key={item.key} className="cursor-pointer" onClick={()=>{
              history.push(match.path + "/" + item.component_);
            }}>
              <div className="p3 col-grey">{item.title ? strings[item.title] : strings[item.component_]}</div>
            </div>
          })}
        </div>*/}        
      </Route>
    </Switch>						
}
