/* eslint-disable eqeqeq */
import React from 'react';
import _ from "lodash";
import { wspub } from "../ws-pub/ws-pub";

import { strings } from '../localization/strings';
//import Select from '@material-ui/core/Select';
import SelectMultiple, { Select } from '../components/select-multiple';
/*import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';*/

//Parser
export default class Symbols {
    static parse(msg) {
        if (msg["D"].hasOwnProperty("syms")) {
            Symbols.ready = true;
            Symbols.all = [];
            Symbols.active = [];
            Symbols.add(msg["D"]["syms"]);
            return true;
        }
        if (msg["D"].hasOwnProperty("ins")) {
            var sec = msg["D"]["ins"];
            if (!Symbols.hasOwnProperty(sec["sid"])) Symbols.add([sec]);
            return true;
        }
        return false;
    }

    static request() {
	    wspub.send({"P":  "g_sym"}); //request symbols
    }

    static isActive(sym) {
        if (sym.ss !== 2 && sym.ss !== 4) return true;
        else return false;
    }

    static add(syms) {
        syms.forEach(function (sym) {
	        Symbols[sym["sid"]] = _.cloneDeep(sym);
            Symbols.all.push(Symbols[sym["sid"]]);
            if (Symbols.isActive(sym)) Symbols.active.push(Symbols[sym["sid"]]);
        });
        Symbols.all.sort((a,b)=>a.sym > b.sym ? 1 : -1);
        Symbols.active.sort((a,b)=>a.sym > b.sym ? 1 : -1);
        this.listeners.forEach((f)=>{f()});
    }

    static updateStatuses(syms) {
        syms.forEach(function (sym) {
            Symbols[sym["sid"]]["ss"] = sym["ss"];
        });
        this.listeners.forEach((f)=>{f()});
    }

  static addListener(f) {this.listeners.push(f);}
  static removeListener(f) {this.listeners = _.reject(this.listeners, (l) => l === f);}  
}

Symbols.ready = false;
Symbols.all = [];
Symbols.active = [];
Symbols.listeners = [];

//HOC
export function withSymbols(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.state = {
        symbols: _.cloneDeep(this.props.all ? Symbols.all : Symbols.active)
      };
    }

    componentDidMount() {Symbols.addListener(this.onChange)}
    componentWillUnmount() {Symbols.removeListener(this.onChange)}
    onChange() {this.setState({symbols: _.cloneDeep(this.props.all ? Symbols.all : Symbols.active)})}

    render() {
      return <WrappedComponent symbols={this.state.symbols} {...this.props} />;
    }
  };
}

export class SymbolsListener extends React.Component {
  componentDidMount() {Symbols.addListener(this.props.onChange)}
  componentWillUnmount() {Symbols.removeListener(this.props.onChange)}  
  render() {return null;}
}

//Component
function SelectSym_(props) {
    if (props.symbols.length > 0 && !props.symbols.some((sym)=>sym.sid == props.value)) {
        setTimeout(()=>props.onChange({sid: props.symbols[0].sid}), 0);
    }
    if (props.symbols.length === 0) return null;
    return <select value={!Symbols.hasOwnProperty(props.value) ? props.symbols[0].sid : props.value} onChange={(e)=>props.onChange({sid: parseInt(e.target.value)})}>
        {
            props.symbols.map((sym)=><option key={sym.sym} value={sym.sid}>{sym.sym}</option>)
        }
    </select>;
}

export const SelectSym = withSymbols(SelectSym_)

//Component
function SelectSym2_(props) {
    if (props.symbols.length > 0 && !props.symbols.some((sym)=>sym.sid == props.value)) {
        setTimeout(()=>props.onChange({sid: props.symbols[0].sid}), 0);
    }
    if (props.symbols.length === 0) return null;
    return <Select 
        className={props.className}
        value={Symbols.hasOwnProperty(props.value) ? props.value: props.symbols[0].sid} 
        onChange={(v)=>props.onChange({sid: parseInt(v)})}
        options={props.symbols.map((sym)=>{return {value: sym.sid, label: sym.sym}})}
        >
    </Select>;
}

export const SelectSym2 = withSymbols(SelectSym2_)

/*function SelectSyms_(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <Select
            labelId="select-syms"
            //id="demo-simple-select"
            multiple
            value={value}
            renderValue={(selected) => selected.map((s)=>Symbols[s].sym).join(', ')}
            onChange={(e)=>props.onChange({sids: e.target.value.length === 0 ? '_all_' : e.target.value.map((sid)=>parseInt(sid))})}
        >
        {props.symbols.map((sym) => (
            <MenuItem key={sym.sid} value={sym.sid}>
                <Checkbox checked={value.indexOf(sym.sid) > -1} />
                <ListItemText primary={sym.sym} />
            </MenuItem>
        ))}
    </Select>
}

export const SelectSyms = withSymbols(SelectSyms_) */          

//Component
/*function SelectSyms_(props) {
    return <Select
        className={"react-select"} 
        classNamePrefix={"react-select"} 
        isMulti={true}
        value={props.value == null ? "" : props.value} 
        onChange={(e)=>console.log(e)}
        options={props.symbols.map((sym)=>{return {label: sym.sym, value: sym.sid}})}
        />
}

export const SelectSyms = withSymbols(SelectSyms_)*/

function SelectSyms_(props) {
    let value = (props.value === '_all_' ? [] : props.value);
    return <SelectMultiple
            multiple
            label={props.label ? props.label : strings.SelectInstruments}
            value={value}
            renderValue={(selected) => value.length === 0 ? strings.AllInstruments : selected.map((s)=>Symbols[s].sym).join(', ')}
            options={props.symbols.map((sym)=>{return {label: sym.sym, value: sym.sid}})}
            onChange={(value)=>props.onChange({sids: value.length === 0 ? '_all_' : value.map((sid)=>parseInt(sid))})}
        />
}

export const SelectSyms = withSymbols(SelectSyms_)