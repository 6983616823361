/* eslint-disable eqeqeq */
import React/*, { useRef }*/ from "react";
//import _ from "lodash";

import { Button } from "../components/button";

import { strings } from '../localization/strings';
import { Theme } from '../styles/select-theme';

import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { Accounts, withAccountsList } from "../ws-priv/accounts";

import '../styles/css/referral.css';
//import '../styles/css/button-confirm.css';

import { LoginOrRegister } from "../ws-priv/login-or-register";


import copy from 'copy-to-clipboard';
import QrCode from "qrcode.react";
import Popover from '@material-ui/core/Popover';
//import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

import { 
  IBtnCopyClipboard,
} from '../styles/icons';

import { toast } from 'react-toastify';

import { decS, iTextCidS} from "../utils/cell-formatters";

export class Referral_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null,
      show: false,
      //hiss: null,
      payments_today: null,
      payments_yesterday: null,
    }
    this.anchorRef = React.createRef();
  }

  componentDidMount() {
    let self = this;
    new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_rfr"},
      eternal: false,
      onResult: function(msg) {
        if (!self.will_unmount) self.setState({link: msg["D"]["rfl"]});
      }, 
    });        
    new Subscribtion({
      ws: wspriv,
      smsg: {"P": "g_cmss", "D": {"aid": Accounts.main.aid, "cs": ["H24"]}},
      eternal: false,
      onResult: function(msg) {
        if (!self.will_unmount) self.setState({
          payments_today: msg["D"]["cmss"]["H24"][0]["cm"],
          payments_yesterday: msg["D"]["cmss"]["H24"][1]["cm"],
        });
      }, 
    });
  }

  componentWillUnmount() {
    this.will_unmount = true;
  }

  render() {
    let self = this;
    if (this.state.link === null) return <LoginOrRegister/>;
    return <div className="default-column" ref={this.anchorRef}>
      <div className="p4">{strings.Referral_.Text}</div>
      <div className="referral-info">
        <div className="p4">{strings.Referral_.Link}:</div>
        <Button onClick={()=>{
            self.setState({show: true});
          }} className="p4b bg-blue">
            {strings.QRCode}
        </Button>{'\u00A0\u00A0'}
        <Button onClick={()=>{
            copy(this.state.link);
            toast.info(strings.CopiedToCB, {autoClose: 3000});
          }} className="p4b bg-blue">
          <IBtnCopyClipboard size="13"/>
        </Button>
        <div className="p4b">
          {'\u00A0\u00A0' + this.state.link}
        </div>
      </div>
      <br/>
      <div>
          <div className="p4b">{strings.Referral_.Payments}</div>
      </div>
      <div className="default-info-column">
        {this.state.payments_today != null && <React.Fragment><div>
            <div className="p4">{strings.Referral_.Today}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.payments_today))}</div>
          </div>
          <div>
            <div className="p4">{strings.Referral_.Yesterday}:</div>
            <div className="p4b">{iTextCidS(decS(this.state.payments_yesterday))}</div>
        </div></React.Fragment>}
      </div>
      <Popover
        open={this.state.show}
        onClose={()=>this.setState({show: false})}
        anchorEl={this.anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="cursor-pointer"
            onClick={()=>this.setState({show: false})}>
          <div className="deposit-qr-title p3b">{strings.Referral_.Link}</div>
          <div className="deposit-qr">
            <div>
              <QrCode
                  value={this.state.link}
                  size={256}
                  bgColor={Theme.current === "dark" ? "#000000" : "#FFFFFF"}
                  fgColor={Theme.current === "dark" ? "#FFFFFF" : "#000000"}
                  imageSettings={{
                    //src: "/favicon.ico",
                    src: "/favicon.png",
                    x: null,
                    y: null,
                    height: 32,
                    width: 32,
                    excavate: true,
                  }}                  
              />
            </div>
          </div>
        </div>
      </Popover>      
      <LoginOrRegister/>
    </div>;
  }
}

export const Referral = withAccountsList(withWSPrivKey(Referral_));
