/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";

import '../styles/css/chart-trades.css';
import { widget } from '../charting_library/charting_library';
import { Language, withLanguageKey } from '../localization/select-language';
import { Theme, withThemeKey } from '../styles/select-theme';
//import {strings} from '../localization/strings';
import { wspub, withWSPubKey } from "../ws-pub/ws-pub";
import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
import { Candles } from "../ws-pub/candles";
//import Subscribtion from "../utils/subscribtion";
import Symbols, { SymbolsListener } from "../ws-pub/symbols";
import { ExchangeTime } from "../ws-pub/exchange-time";
import { withFilter/*, getMinNsecTime*/ } from '../utils/utils'
import { TrsForChart } from "../ws-priv/trades-for-chart";
import BigNumber from "bignumber.js";
import { decS } from "../utils/cell-formatters";
import { col } from "../styles/colors.js"

function getSubscrResolution(r) {
    var res = r;
    if (r == "D" || r == "1D") res = "1d";
    else if (r == "S" || r == "1S") res = "1s";
    else if (r == "1") res = "1m";    
    else if (r == "5") res = "5m";    
    else if (r == "15") res = "15m";    
    else if (r == "30") res = "30m";    
    else if (r == "60") res = "1h";    
    else if (r == "240") res = "4h";    
    else if (r == "360") res = "6h";    
    else if (r == "720") res = "12h";    
    console.log("getSubscrResolution", r, "->", res);
    return res;
}

function getResolutionInSecs(r) {
      var res = 1;
      if (r === '1S') res = 1;
      else if (r === '1'  || r === '1m') res = 60;
      else if (r === '5'  || r === '5m') res = 5 * 60;
      else if (r === '15' || r === '15m') res = 15 * 60;
      else if (r === '30' || r === '30m') res = 30 * 60;
      else if (r === '60' || r === '60m' || r === '1h' || r === '1H' || r === 'H') res = 60 * 60;
      else if (r === '2h' || r === '2H') res = 2 * 60 * 60;
      else if (r === '4h' || r === '4H') res = 4 * 60 * 60;
      else if (r === "D"  || r === '1d'  || r === '1D') res = 24 * 60 * 60;
      else if (r === "W"  || r === '1w'  || r === '1W') res = 24 * 60 * 60;
      else if (r === "M"  || r === '1m'  || r === '1M') res = 24 * 60 * 60;
      return res;
}

export class DataFeed {    
    constructor(chart) {
        this.chart = chart;
        this.subscrs = {};
    }

    onReady(callback) {
        //console.log("onReady");
        setTimeout(function(){ 
            callback({
                exchanges:['Viking'],
                symbols_types: ['futures','cfd'],
                //supported_resolutions: ["1S", "1", "60", "D"],
                supported_resolutions: ['1S', '1', '5', '15', '30', '1H', '4H', '6H', '12H', '1D', '1W'],
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
            });
        }, 0);
    };

    searchSymbols(userInput, exchange, symbolType, callback) {
        //console.log("searchSymbols");
        setTimeout(function(){ 
            let secs = [];
            Symbols.active.forEach(function(sec, k) {
                if (sec.sym.indexOf(userInput) !== -1)
                    secs.push({
                        symbol: sec.sym,
                        full_name: sec.sym,
                        ticker: sec.sid.toString(),
                        description: sec.desc,
                        exchange: 'Viking',
                        type: (sec.mid === "fut" ? "futures" : sec.mid),
                });
            });
            //console.log(secs);
            callback(secs);
        }, 0);
    };
    resolveSymbol(symbolName, callback, onError) {
        //console.log("resolveSymbol " + symbolName, Symbols.active);
        setTimeout(function(){ 
            Symbols.active.forEach(function(sec, k) {
                //console.log(sec.sid + " " + sec.sym);
                if (sec.sid.toString() === symbolName || sec.sym === symbolName) {
                    let sym = {
                        name: sec["sym"],
                        ticker: sec["sid"].toString(),
                        description: sec["desc"],
                        format: "price",
                        //exchange: 'Viking',
                        type: (sec.mid === "fut" ? "futures" : sec.mid),
                        session: '24x7',
                        //listed_exchange: 'Viking',
                        timezone: 'Etc/UTC',
                        minmov: 1,
                        pricescale: Math.pow(10, sec["pdec"]),
                        //minmove2: 0,
                        has_seconds: true,
                        //seconds_multipliers: ['1'],
                        has_intraday: true,
                        //intraday_multipliers: ['1', '60'],
                        has_daily: true,
                        supported_resolutions: ['1S', '1', '5', '15', '30', '1H', '4H', '6H', '12H', '1D', '1W'],
                        //supported_resolutions: ['1S', '1', '60', '1D'],
                        data_status: 'streaming',
                        //has_no_volume: true,
                        has_empty_bars: true,
                    }
                    callback(sym);
                }
            });
        }, 0);
    };

    getBars(symbolInfo, resolution, params, callback, onError) {
        let from = params.from;
        let to = params.to;
    	let chart = this.chart;
        console.log("getBars resolution " + resolution);
        //console.log("getBars from " + from + " " + moment(from * 1000).format('YYYY-MM-DD HH:mm:ss'));
        //console.log("getBars to   " + to + " " + moment(to * 1000).format('YYYY-MM-DD HH:mm:ss'));
        if (params.firstDataRequest) to += 10000000;
        let sid = Number(symbolInfo.ticker);
        Candles.get(
            sid, 
            getSubscrResolution(resolution), 
            from, 
            to,
            function(cs) {
                let rows = [];
                cs.forEach(function(c) {
                    //console.log("t " + c[5] + " " + moment(c[5] * 1000).format('YYYY-MM-DD HH:mm:ss'));
                    rows.push({time:c[5] * 1000, open:c[0]/1e8, high:c[1]/1e8, low:c[2]/1e8, close:c[3]/1e8, volume:c[4]});
                });
                rows.sort(function(a, b){return a.time - b.time});
                //console.log(rows);
                if (to > chart.last_bar) chart.last_bar = to;
                if (rows.length > 0) {
                    /*let step = getResolutionInSecs(resolution);
                    for (let i = from; i < to; i += step) {//подписка на свечи с пропусками
                        //console.log(from, to, i);
                    }*/
                    callback(rows);
                } else {
                    callback([], {noData: true, nextTime: 0});
                }
            }, 
            function(msg) {/*onError(msg)*/},
        );
        //if (chart.show_trades) TrsForChart.getTrs(sid, rangeStartDate, to);
    };
    subscribeBars(symbolInfo, resolution, onTick, listenerGuid, onResetCacheNeededCallback) {
    		let chart = this.chart;
        //console.log("subscribeBars");
        let sid = Number(symbolInfo.ticker);
        let subscr = this.subscrs[listenerGuid] = {
            sid: sid, 
            ticker: symbolInfo.ticker, 
            resolution: getSubscrResolution(resolution), 
            onResetCacheNeededCallback: onResetCacheNeededCallback,
            f: function(cs) {
              //console.log("onTick", resolution, cs);
              cs.forEach(function(c) {
                  //console.log("t " + c[5] + " " + moment(c[5] * 1000).format('YYYY-MM-DD HH:mm:ss'));
                  onTick({time:c[5] * 1000, open:c[0]/1e8, high:c[1]/1e8, low:c[2]/1e8, close:c[3]/1e8, volume:c[4]});
                  if (c[5] > chart.last_bar) {
                      chart.last_bar = c[5];
                      setTimeout(function() {chart.drawTrs();}, 500);
                  }
              });
            }
        };

        Candles.addListener(subscr.f, subscr.sid, subscr.resolution);
    };
    unsubscribeBars(listenerGuid) {
        //console.log("unsubscribeBars");
        let subscr = this.subscrs[listenerGuid];
        if (subscr == null) return undefined;
        Candles.removeListener(subscr.f, subscr.sid, subscr.resolution);
    };

    calculateHistoryDepth(resolution, resolutionBack, intervalBack) {
        //console.log("calculateHistoryDepth");
        return undefined;
    };
    getMarks(symbolInfo, from, to, onDataCallback, resolution) {
        //console.log("getMarks");
        return undefined;
    };
    getTimescaleMarks(symbolInfo, from, to, onDataCallback, resolution) {
        //console.log("getTimescaleMarks");
        return undefined;
    };
    getServerTime(callback) {
        //console.log("getServerTime");
        setTimeout(function(){ 
            callback(ExchangeTime.now);
        }, 0);
    };
}

export class Chart_ extends React.Component {
  constructor(props) {
    super(props);
    this.onDrawTrs = this.onDrawTrs.bind(this);
    this.onSymbolsChange = this.onSymbolsChange.bind(this);
    this.onChangeTheme = this.onChangeTheme.bind(this);
    this.tabRef = React.createRef();
    this.show_trades = wspriv.welcome ? false : this.props.filter.show_trades;
  }

  onClearTrs() {
    //console.log("clearTrs ------------------------------------------------");
    _.each(this.shapes, function(s, step) {
      if (s[1].shape != null) s[1].shape.remove();
      if (s[2].shape != null) s[2].shape.remove();
    });
    this.shapes = {};
    let id = this.props.id;
    _.each(TrsForChart.trs, function(trs, sid) {
        trs.forEach(function(tr) {
            if (tr.shown.hasOwnProperty(id)) delete tr.shown[id];
        });
    });     
  }

  onDrawTrs(isSnapshot) {
    try {
      //console.log("onDrawTrs", isSnapshot);
      if (!this.show_trades) return;
    	if (isSnapshot) {
        this.componentDidMount();
        this.onClearTrs();
    	}

    	//draw trades
      if (this.widget == null) return; // chart not initialized yet
      if (!this.widget._ready) return;
      let sid = this.props.filter.sid;
      let r = this.widget.chart().getVisibleRange();
      if (r.from === r.to) return;
      TrsForChart.getTrs(sid, r.from);
      let trs = TrsForChart.trs[sid];
      if (trs === undefined) return;
      //console.log(TrsForChart.trs);
      //compare to stored trades            
      if (BigNumber(r.from + '000000000').comparedTo(TrsForChart.max_time[sid]) < 0 || trs.length === 0) return; //didn't recieved these trs yet
      if (r.to === 0) r.to = 99999999999;
      let resolution = getResolutionInSecs(this.widget.chart().resolution());
   
      let tr, step, dt;
      let id = this.props.id;
      //console.log("isNewTrades",isNewTrades, trs);
      for (let i = trs.length - 1; i >= 0; i--) {
          tr = trs[i];
          step = parseInt(tr.dt * 1e-9 / resolution);
          dt = resolution * step;
          /*if (debug && dt >= r.from && !tr.shown) {
              console.log("+ " + (tr.d == 1 ? "buy " : "sell ") + dt + " " + tr.dt + " " + moment.utc(dt * 1000).format('DD HH:mm:ss'));
          }*/
          if (tr.shown[id]) break;
          if (dt < r.from || dt > r.to || dt > this.last_bar) continue;
          tr.shown[id] = true;
          this.drawTrade(tr, step, dt);
      }
      for (let i = 0; i < trs.length; ++i) {
          tr = trs[i];
          step = parseInt(tr.dt * 1e-9 / resolution);
          dt = resolution * step;
          if (tr.shown[id]) break;
          if (dt < r.from || dt > r.to || dt > this.last_bar) continue;
          tr.shown[id] = true;
          this.drawTrade(tr, step, dt);
      }      
    } catch (err) {}
  }

  drawTrade(tr, step, dt) {
    if (this.shapes[step] === undefined) {
        //if (debug) console.log(0);            
        this.shapes[step] = [null, {shape: null, tt: '', count: 0}, {shape: null, tt: '', count: 0}]
    };
    let s = this.shapes[step][tr["d"]];
    if (s.shape == null) {//create shape
        //if (debug) console.log(1);            
        s.tt = "@" + decS(tr.p) + (tr.d === 1 ? " Buy " : " Sell ") + decS(tr.q) + "\n"; 
        s.count = 1;
        s.shape = this.widget.chart().createExecutionShape()
                .setTextColor((tr.d === 1 ? col.green : col.red))
                .setTooltip(s.tt)
                .setArrowColor((tr.d === 1 ? col.green : col.red))
                .setDirection((tr.d === 1 ? "buy" : "sell"))
                .setTime(dt)
                .setPrice(tr.p * 1e-8);
    } else {//update shape
        //if (debug) console.log(2);            
        ++s.count;
        if (s.count < 6) {
            s.tt += "@" + decS(tr.p) + (tr.d === 1 ? " Buy " : " Sell ") + decS(tr.q) + "\n";
            s.shape.setTooltip(s.tt).setText(s.count);
        } else {
            s.shape.setTooltip(s.tt + (s.count - 5) + " more...").setText(s.count);
        }
    }

  }

  componentDidMount() {
      //console.log("componentDidMount", wspub.connected, Symbols.ready, wspriv.connected, TrsForChart.ready, this.show_trades);
      if (!Symbols[this.props.filter.sid]) return;
      if (this.show_trades && !wspriv.authorized) return;
      if (this.show_trades && !this.listener) {
        this.listener = true;
        TrsForChart.addListener(this.onDrawTrs);
      }
      if (this.widget === undefined && wspub.connected && this.tabRef.current && Symbols.ready && ((this.show_trades && TrsForChart.ready && wspriv.connected) || !this.show_trades)) {
      let div = this.tabRef.current;
      //console.log(div);
      div.id = this.props.id;
      div.classList.add("twchart");
      this.data_feed = new DataFeed(this)

			this.symbol = Symbols[this.props.filter.sid].sym;

      //for trades
      this.wspriv = null;
      this.last_bar = 0;
			this.shapes = {};

      let disabled_features = ["use_localstorage_for_settings", "header_symbol_search"];
      let enabled_features = [/*"study_templates"*/];
      if (!this.props.filter.left_toolbar) disabled_features.push("left_toolbar");
      else enabled_features.push("side_toolbar_in_fullscreen_mode");
      if (!this.props.filter.top_toolbar) disabled_features.push("header_widget");
      //console.log("saved_data", saved_data);
      let text_color = Theme.current === 'dark' ? col.grey_light : col.grey_dark;
      let bg_color = Theme.current === 'dark' ? col.grey_dark : "white";
      let line_color = Theme.current === 'dark' ? "#363c4e" : "#e1ecf2";
      if (this.props.saved_data && this.props.saved_data.chart) {
        //console.log(this.props.saved_data.chart);
        let chartProperties = this.props.saved_data.chart.charts[0].chartProperties;
        chartProperties.paneProperties.background = bg_color;
        chartProperties.paneProperties.horzGridProperties.color = line_color;
        chartProperties.paneProperties.vertGridProperties.color = line_color;
        chartProperties.scalesProperties.textColor = text_color;
        chartProperties.scalesProperties.backgroundColor = bg_color;
      }
      let sd = this.props.saved_data;
      //console.log(this.props.saved_data.chart);
      this.widget = new widget({
        //debug: true, // uncomment this line to see Library errors and warnings in the console
        autosize: true,
        fullscreen: false,
        symbol: this.symbol ? this.symbol : 'BTCUSD',
        //ticker: 1,
        interval: sd && sd.interval ? sd.interval : '1',
        timeframe: sd && sd.timeframe ? sd.timeframe : 'D',
        container_id: div.id,
        datafeed: this.data_feed,
        library_path: "/charting_library/",
        locale: Language.current,//getParameterByName('lang') || "en",
        disabled_features: disabled_features,
        enabled_features: enabled_features,
        //charts_storage_url: 'http://saveload.tradingview.com',
        //charts_storage_api_version: "1.1",
        //client_id: 'tradingview.com',
        //user_id: 'public_user_id',
        theme: Theme.current,//getParameterByName('theme'),
        //save_load_adapter: save_load_adapter,
        saved_data: this.props.saved_data ? this.props.saved_data.chart : undefined,
        custom_css_url: '/tradingview.css',
        overrides: {
          "paneProperties.background": bg_color,
          "paneProperties.backgroundType": "solid",
          "scalesProperties.backgroundColor" : bg_color,
          "mainSeriesProperties.candleStyle.upColor": col.green,
          "mainSeriesProperties.candleStyle.downColor": col.red,
          "mainSeriesProperties.candleStyle.borderUpColor": col.green,
          "mainSeriesProperties.candleStyle.borderDownColor": col.red,
          "mainSeriesProperties.candleStyle.wickUpColor": col.green,
          "mainSeriesProperties.candleStyle.wickDownColor": col.red,
          "mainSeriesProperties.hollowCandleStyle.upColor": col.green,
          "mainSeriesProperties.hollowCandleStyle.downColor": col.red,
          "mainSeriesProperties.hollowCandleStyle.borderUpColor": col.green,
          "mainSeriesProperties.hollowCandleStyle.borderDownColor": col.red,
          "mainSeriesProperties.haStyle.upColor": col.green,
          "mainSeriesProperties.haStyle.downColor": col.red,
          "mainSeriesProperties.haStyle.borderUpColor": col.green,
          "mainSeriesProperties.haStyle.borderDownColor": col.red,
          "mainSeriesProperties.barStyle.upColor": col.green,
          "mainSeriesProperties.barStyle.downColor": col.red,
        },
        loading_screen: { backgroundColor: "bg_color" }
        });
      //save_load_adapter.widget
      let self = this;
      this.widget.onChartReady(function() {
        /*if (self.props.saved_data && self.props.saved_data.visible_range) {
          self.widget.chart().setVisibleRange(
              self.props.saved_data.visible_range,
          );        
        }*/
        self.widget.subscribe('onAutoSaveNeeded', function() {
          self.widget.save((saved_data)=>{
            //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            self.props.onSaveData({chart: saved_data});
          });
        });
        let chart = self.widget.chart();
        chart.onIntervalChanged().subscribe(null, (interval, timeframeObj) => {
      		console.log('onIntervalChanged', interval, timeframeObj);
          self.props.onSaveData({interval: interval});
          if (timeframeObj.timeframe && timeframeObj.timeframe.type == "period-back")
            self.props.onSaveData({timeframe: timeframeObj.timeframe.value});
          setTimeout(function() {
            self.onClearTrs();
            self.onDrawTrs(false);
          }, 500);
        });
        chart.onVisibleRangeChanged().subscribe(null, () => {
          //self.props.onSaveData({visible_range: self.widget.chart().getVisibleRange()});
      		//console.log('onVisibleRangeChanged');
          setTimeout(function() {self.onDrawTrs(false);}, 0);
        });
        setTimeout(function() {self.onDrawTrs(false);}, 0);
        self.onChangeTheme();
      });
    }
  }

  componentWillUnmount() {
    //console.log("componentWillUnmount");
    if (this.widget) {
      if (this.show_trades) {
        TrsForChart.removeListener(this.onDrawTrs);
        this.onClearTrs();
      }
      this.widget.remove();
      this.widget = null;
      delete this.data_feed;
      this.data_feed = null;
    }
  }

  onChangeTheme() {
    //if (this.widget) this.widget.changeTheme(Theme.current);
  }

  onSymbolsChange(connected) {
    //console.log("onSymbolsChange", this.props.id, " widget =", this.widget, " connected =", wspub.connected);
    this.componentDidMount(); //init widget
  }  

  /*onWSStateChange() {
    //console.log("onWSPubChange", this.props.id, " widget =", this.widget, " connected =", wspub.connected, wspriv.connected);
    if (wspub.connected) {
      if (this.widget) {// reset data
        //_.each(this.data_feed.subscrs, function(v, k) {
        //  v.onResetCacheNeededCallback();
        //});
        if (this.widget._ready) this.widget.chart().resetData();
        let self = this;
        setTimeout(function() {self.onDrawTrs(false);}, 500);
        //console.log("reset");
      }
      else this.componentDidMount(); //init widget
    }
  }*/

  render() {
    //console.log("render");
  	return <React.Fragment>
      <SymbolsListener onChange={this.onSymbolsChange} />
	    <div ref={this.tabRef}/>
	  </React.Fragment>;
  }
}
//      <ThemeListener onChange={this.onChangeTheme}/>

export const Chart = withWSPrivKey(withWSPubKey(withFilter(withThemeKey(withLanguageKey(Chart_)))));
