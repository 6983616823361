/* eslint-disable eqeqeq */
import React from 'react';
import { strings } from '../localization/strings';

import { Button } from "../components/button";

import { wspub } from "../ws-pub/ws-pub";
import { wspriv } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import { toast } from 'react-toastify';

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function APITester(props) {
  const [request, setRequest] = React.useState(`{"P": "g_tr", "D": {"sids": null, "mt": 9999999999999999999, "lim": 10}}`);
  const [reply, setReply] = React.useState("");
  const [websocket, setWebsocket] = React.useState("wspub");
	return <div className="default-column">
		{!props.no_title && <React.Fragment>
			<div className="api-title p1b">{strings.APITester}</div>
			<br/>
		</React.Fragment>}
    <div className="default-info">
      <select value={websocket} onChange={(e)=>setWebsocket(e.target.value)}>
      	<option value="wspub">Public WebSocket</option>
      	<option value="wspriv">Private WebSocket</option>
      </select>
      {'\u00A0'}{'\u00A0'}
      <Button className="bg-blue"
      	onClick={()=>{
      		let ws = websocket == "wspub" ? wspub : wspriv;
      		if (!isJson(request)) {
      			toast.error(strings.Api_.NotValidJson);
      			return;
      		}
      		if (websocket == "wspriv" && !ws.authorized) {
      			toast.error(strings.Security_.NotSignedIn);
      			return;
      		}
      		if (!ws.connected) {
      			toast.error(strings.NoConnection);
      			return;
      		}
					new Subscribtion({
			      ws: ws,
			      smsg: JSON.parse(request),
			      eternal: false,
			      onResult: function(msg) {
			      	setReply(JSON.stringify(msg));
			      }, 
			    });
      	}}>{strings.Api_.Send}
      </Button>
    </div>
    <div>
      <div className="p4b">{strings.Api_.Request}:</div>
			<textarea value={request} onChange={(e)=>setRequest(e.target.value)} style={{height: "90px", width: "calc(100% - 12px)"}}>
			</textarea>
    </div>        
    <div>
      <div className="p4b">{strings.Api_.Reply}:</div>
			<textarea defaultValue={reply} rows="21" style={{width: "calc(100% - 12px)"}} readOnly>
			</textarea>
    </div>        
	</div>
}

export function APIChooseVersion(props) {
	return <div className="default-column">
		{!props.no_title && <React.Fragment>
			<div className="api-title p1b">{strings.APIDoc}</div>
			<br/>
		</React.Fragment>}
		<div className="p3">{'\u00A0'}{strings.Api_.TestEnvDescription}:{'\u00A0'}<a href="https://art.viking.trade">https://art.viking.trade</a></div>
		<div className="p3">{'\u00A0'}{strings.Api_.TestEnvDescription2}</div>
		<div className="p3">{'\u00A0'}{strings.Api_.ChooseVer}:</div>
		<ul className="p3">
      <li><a href={`https://viking.trade/doc/api`}>{strings.Api_.ProductionEnv}</a></li>
    	<li><a href={`https://art.viking.trade/doc/api`}>{strings.Api_.TestEnv}</a></li>
		</ul>
  </div>
}