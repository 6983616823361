/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import { strings } from '../localization/strings';

import { withFilter } from '../utils/utils'

import Checkbox from '../components/checkbox'

import { wspriv, withWSPrivKey } from "../ws-priv/ws-priv";
import Subscribtion from "../utils/subscribtion";

import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from '../components/popup-state';

//import { ToggleButton, ToggleButtonGroup } from '../components/toggle-button';
import { LoginOrRegister } from "../ws-priv/login-or-register";

import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import '../styles/css/api-keys.css';
import '../styles/css/accounts.css';

import { 
//  IRefresh,
  IBtnCross,
  IBtnCopyClipboard,
} from '../styles/icons';

import {dt_ns_msS} from "../utils/cell-formatters";

import { Button, ButtonConfirm, ButtonConfirm2FA } from "../components/button";

import { TwoFA, TwoFAState } from "../ws-priv/two-fa";

import { Log } from '../components/log';
//import { toast } from 'react-toastify';

export function ButtonCreateApiKey(props) {
  const [canTrade, setCanTrade] = React.useState(false);
  const [canManage, setCanManage] = React.useState(false);
  const [canWithdraw, setCanWithdraw] = React.useState(false);
  const [label, setLabel] = React.useState('');
  return <PopupState variant="popover">
    {(popupState) => (
      <React.Fragment>
        <button {...bindTrigger(popupState)} className="p4b bg-blue">{props.children}</button>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
          transformOrigin={{vertical: 'top',horizontal: 'left',}}>
          {!TwoFAState.current && <TwoFA/>}
          {TwoFAState.current && <div className="default-column">
            <div className="p4b">
              <div className="p4b">{strings.Api_.Permissions}:</div>
            </div>
            <div className="p4 default-info">
              <Checkbox label={strings.Api_.CanTrade} checked={canTrade} onChange={(e)=>setCanTrade(!canTrade)}/>
              <div className="p4">{strings.Api_.CanTradeDescr}</div>
            </div>
            <div className="p4 default-info">
              <Checkbox label={strings.Api_.CanManage} checked={canManage} onChange={(e)=>setCanManage(!canManage)}/>
              <div className="p4">{strings.Api_.CanManageDescr}</div>
            </div>
            <div className="p4 default-info">
              <Checkbox label={strings.Api_.CanWithdraw} checked={canWithdraw} onChange={(e)=>setCanWithdraw(!canWithdraw)}/>
            </div>
            <br/>
            <div>
              <div className="p4b">{strings.Api_.Label}:</div>
              <input type="text" value={label} onChange={(e)=>setLabel(e.target.value)}/>
            </div>
            <div className="default-info">
              <ButtonConfirm2FA 
                onClick={(key)=>{
                  popupState.close();
                  new Subscribtion({
                    ws: wspriv,
                    smsg: {"P": "c_key", "D":{"ll":label, "pt":canTrade, "pm": canManage, "pw": canWithdraw, "2fa": key}},
                    eternal: false,
                    onResult: function(msg) {
                      Log.success(strings.Api_.Created);
                      let keys = _.cloneDeep(props.self.state.keys);
                      keys.push(msg["D"]["key"]);
                      keys.sort(function (a, b) {return b["ct"] - a["ct"];});
                      props.self.setState({
                        keys: keys, 
                        kid: msg["D"]["key"]["kid"], 
                        secret: msg["D"]["key"]["k"]});
                    }, 
                    onError: function(msg) {
                    }
                  });
                }}>
                {strings.Next}
              </ButtonConfirm2FA>{'\u00A0\u00A0'}
              <Button 
                onClick={()=>popupState.close()} 
                className="p4b bg-blue">
                {strings.Cancel}
              </Button>
            </div>
          </div>}        
        </Popover>
      </React.Fragment>
    )}
  </PopupState>;
}

function KeyFormatter(v, self) {
  return <div style={{display: "flex", alignItems: "center"}}>
    <ButtonConfirm className="p4b bg-blue"
      onClick={()=>{
        new Subscribtion({
          ws: wspriv,
          smsg: {"P": "d_key", "D":{"kid":v}},
          eternal: false,
          onResult: function(msg) {
            //Log.success(strings.Api_.Removed);
            self.setState({keys: _.reject(self.state.keys, {kid: msg["D"]["key"]["kid"]})});
          }, 
          onError: function(msg) {
          }
        });
      }}>
      <IBtnCross size="13"/>
    </ButtonConfirm>
    {'\u00A0\u00A0' + v}
  </div>
}

function tickcross(v) {
  return v ? <b>&#10003;</b> : null;
}

export class APIKeys_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: null,
      kid: null,
      secret: null,
    }
    this.anchorRef = React.createRef();
    this.columns = [
      {title:"KeyID", field:"kid", formatter: KeyFormatter},
      {title:"CanTrade", field:"pt", formatter: tickcross},
      {title:"CanManage", field:"pm", formatter: tickcross},
      {title:"CanWithdraw", field:"pw", formatter: tickcross},
      {title:"Label", field:"ll"},
      {title:"CreateTime", field:"ct", formatter: dt_ns_msS},
    ]
  }

  componentDidMount() {
    if (wspriv.authorized) {    
      let self = this;
      new Subscribtion({
        ws: wspriv,
        smsg: {"P": "g_key"},
        eternal: false,
        onResult: function(msg) {
          let keys = _.cloneDeep(msg["D"]["keys"]);
          keys.sort(function (a, b) {return b["ct"] - a["ct"];});
          self.setState({keys: keys});
        }, 
      });
    }
  }

  componentWillUnmount() {
  }

  render() {
    //console.log("render", this.state.addrs);
    let self = this;
    //console.log(lim);
    if (this.state.keys === null) return <LoginOrRegister/>;
    return <div className="default-column" ref={this.anchorRef}>
      <div className="default-info">
        <ButtonCreateApiKey self={this}> 
          {strings.Api_.Create}
        </ButtonCreateApiKey>
      </div>
      {this.state.keys.length > 0 && <div className="t-accs">
        <table>
          <thead className="p3 col-night-sky"><tr>
             {this.columns.map((col)=>{return <th key={col.title} className="bg-grey-light">
                <div className="th-content">
                  <div className="th-title">
                    {strings.Api_[col.title]}
                  </div>
                </div>
              </th>})}
          </tr></thead>
          <tbody className="p3 col-grey">
          {this.state.keys.map((key)=>{return <tr key={key.kid}>
              {this.columns.map((col)=>{return <td key={col.title}>
                { key[col.field]
                  ? (col.formatter 
                    ? col.formatter(key[col.field], self) 
                    : key[col.field])
                  : ""
                }
                </td>})
              }
            </tr>})
          }
          </tbody>
        </table>
      </div>} 
      <Popover
        open={this.state.secret != null}
        onClose={()=>this.setState({secret: null})}
        anchorEl={this.anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="default-popup-title p4b">
          {strings.Api_.SaveKey}
        </div>
        <div className="default-column">
          <div className="default-info">
            <div className="p4">{strings.Api_.KeyID}:</div>
              <Button onClick={()=>{
                copy(this.state.kid);
                toast.info(strings.CopiedToCB, {autoClose: 3000});
              }} className="p4b bg-blue">
              <IBtnCopyClipboard size="13"/>
            </Button>{'\u00A0'}{'\u00A0'}
            <div className="p4b">{this.state.kid}</div>
          </div>
          <div className="default-info">
            <div className="p4">{strings.Api_.Secret}:</div>
            <Button onClick={()=>{
                copy(this.state.secret);
                toast.info(strings.CopiedToCB, {autoClose: 3000});
              }} className="p4b bg-blue">
              <IBtnCopyClipboard size="13"/>
            </Button>{'\u00A0'}{'\u00A0'}
            <div className="p4b">{this.state.secret}</div>
          </div>
          <br/>
          <div>
            <div className="p4">{strings.Api_.SaveNote}</div>
          </div>
          <div className="confirm-buttons">
            <Button 
              onClick={()=>this.setState({secret: null})} 
              className="p4b bg-blue">
              {strings.Ok}
            </Button>
          </div>
        </div>
      </Popover>
      <LoginOrRegister/>
    </div>
 }
}

export const APIKeys = withFilter(withWSPrivKey(APIKeys_));
