/* eslint-disable eqeqeq */
import React from "react";
import _ from "lodash";
import BigNumber from "bignumber.js";
import pako from 'pako'

import moment from "moment";

/*import amplitude from 'amplitude-js';

export const amplitudeClient = amplitude.getInstance();
amplitudeClient.init("fff525f375cadb55fa0cbdc82bcfcab6"); //test
amplitudeClient.logEvent("page loaded");*/

export const end_of_day = moment.utc(Date.now()); // initial time for filters
end_of_day.seconds(0);
end_of_day.minutes(59);
end_of_day.hours(23);

export const hot_table_length = 500;

export const globals = {
  rest_reconnect_min: 999, //ms
  rest_reconnect_rand_max: 5, //secs
  ws_reconnect_min: 999, //ms
  ws_reconnect_rand_max: 5, //secs
  ws_disconnect_on_lost_visibility: 10000,//600000, //ms
  ws_disconnect_on_lost_focus: 300000,//600000, //ms
  ws_disconnect_on_user_doing_something_important: 600000, //ms
};  

export function withFilter(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.key = 0;
    }

    render() {
        if (!_.isEqual(this.props.filter, this.filter)) {
            ++this.key;
            this.filter = _.cloneDeep(this.props.filter);
        }
      return <WrappedComponent key={this.key} {...this.props} />;
    }
  };
}


export function parseParams(str, param)
{
    str = str.split(param);
    if (str.length < 2) return "";
    str = str[1];
    str = str.split("&")[0];
    str = str.split("#")[0];
    return str;
}

export function copyObject(to, from) {
    _.each(from, function(v, k) {
        to[k] = v;
    });    
}

export function getMinNsecTime(arr, key, max_time) {
    arr.forEach(function(v) {
        if (BigNumber(v[key]).comparedTo(max_time) <= 0) {
            max_time = BigNumber(v[key]).minus(1).toString();
        }
    });
    return max_time;    
}

export function buf2hex(buffer) { // buffer is an ArrayBuffer
  return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
};

export function hex2ascii(str) { // buffer is an hex string
  return str.match(/.{1,2}/g).map((x)=>String.fromCharCode(parseInt(x, 16))).join('');
};

export function zipAndBase64(str) {
  return btoa(pako.deflate(str, { to: 'string' }));
}

export function unBase64AndUnZip(str) {
  return pako.inflate(atob(str), { to: 'string' });
}

export function noExponent(v) {
  if (v != 0) return (new BigNumber(v)).toFixed();
  else return v;
}

export const isTestEnv = (window.location.host.startsWith('art.viking.trade') || window.location.host.startsWith('localhost'));

export const isLocalhost = (window.location.host.startsWith('localhost'));

export const exchangeAPIUrl = (isTestEnv ? "wss://apitest.viking.trade" : "wss://api.viking.trade")

export const exchangeUrl = (isTestEnv ? "https://art.viking.trade" : "https://viking.trade")

/*
if (document.URL.indexOf("file://") !== -1 || document.URL.indexOf("9090") !== -1)
{//test
    return "ws://127.0.0.1:12300";
} else
*/
